import { Component } from '@angular/core';
import { CustomersService } from '../../../customers/customers.service';

@Component({
  selector: 'exa-new-virtual-meter',
  templateUrl: './new-virtual-meter.component.html',
  styleUrls: ['./new-virtual-meter.component.scss'],
})
export class NewVirtualMeterComponent {

  constructor(public customer: CustomersService) {
  }

}
