import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material';
import { SharedModule } from '../../shared/shared.module';
import { UtilityBillYearViewModule } from '../utility-bill-year-view/utility-bill-year-view.module';
import { UtilityBillsUpsertModule } from '../utility-bills-upsert/utility-bills-upsert.module';
import { UtilityBillsFiltersComponent } from './utility-bills-filters/utility-bills-filters.component';
import { UtilityBillsSectionComponent } from './utility-bills-section/utility-bills-section.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UtilityBillsUpsertModule,
    UtilityBillYearViewModule,
    MatProgressBarModule,
  ],
  exports: [
    UtilityBillsSectionComponent,
  ],
  declarations: [UtilityBillsSectionComponent, UtilityBillsFiltersComponent],
})
export class UtilityBillsSectionModule {
}
