import { Router } from '@angular/router';
import { UserGroupGuard } from 'ex-library';
import { AuthService } from './auth.service';


export const UserGroupGuardFactory = (auth: AuthService, router: Router) => {
  return new UserGroupGuard(auth, router);
};

export const UserGroupGuardProvider = {
  provide: 'UserGroupGuard',
  useFactory: UserGroupGuardFactory,
  deps: [AuthService, Router],
};

/**
 * usergroups should be stored in this enum
 * to be used throw the routing module.
 */
export enum Roles {
  'low' = 'low',
  'med' = 'mid',
  'high' = 'high',
  'superuser' = 'superuser',
}


export const GROUPS = {
  all:     [Roles.superuser, Roles.low, Roles.med, Roles.high ],
  high:    [Roles.superuser, Roles.high],
  med:     [Roles.superuser, Roles.med],
  low:     [Roles.superuser, Roles.low],
  notLow:  [Roles.superuser, Roles.med, Roles.high],
  notMed:  [Roles.superuser, Roles.low, Roles.high],
  notHigh: [Roles.superuser, Roles.low, Roles.med],
  noOne:   [Roles.superuser],
};

export function filterRoutesByGroup(route: any, group: string[], rolesKey = 'availableOn') {
  const availableGroup = route[rolesKey] || [];
  return availableGroup.reduce((val, role) => val || group.includes(role), false);
}

export function getRoutesByGroup(routes: any[], group: string[], rolesKey = 'availableOn') {
  const filteredRoutes = routes.filter(route => filterRoutesByGroup(route, group, rolesKey));
  filteredRoutes.forEach(route => route.children = getRoutesByGroup(route.children || [], group));
  return filteredRoutes;
}

/**
 * @todo(hassan)
 * develop a class that generate the settings depending
 * on the route. to make it easy to change these settings.
 */
