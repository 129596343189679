export interface IMultipleSelectOption {
  name: string;
  id: string;
  isGroup?: boolean;
  parent?: string;
  childs?: IMultipleSelectOption[];
}


export class MultipleSelect {
  list: IMultipleSelectOption[] = this.createOptions(this.data, this.childsKeyName, this.childsLabel);


  get flatList(): IMultipleSelectOption[] {
    return this.list.reduce((newOptions, option) => {
      newOptions.push(option);
      if (option.childs) {
        newOptions.push(...option.childs);
      }
      return newOptions as IMultipleSelectOption[];
    }, []);
  }


  constructor(
    private data: any[],
    private childsKeyName: string,
    private childsLabel?: string
  ) {}


  getSelectedOptions(current, next): IMultipleSelectOption[] {
    const selected: IMultipleSelectOption = this.getSelected(current, next);
    const deselected: IMultipleSelectOption = this.getDeselected(current, next);
    const isSelection = !!selected.id;
    const isAll = (isSelection ? selected : deselected)['id'] === 'all';
    const isGroup = (isSelection ? selected : deselected)['isGroup'];
    const parent = this.flatList.find(i => i.id === (isSelection ? selected : deselected)['parent']);

    if (!isSelection) {
      return this.deselect(current, deselected, parent, isAll, isGroup);
    } else {
      return this.select(current, selected, parent, isAll, isGroup);
    }
  }


  getOptionsStr(options): string {
    const all = options.find(opt => opt.id === 'all');
    const groups = options.filter(opt => opt.isGroup);
    const optionsList = options.filter(opt => opt.id !== 'all' && !opt.isGroup);
    const optionsStr = optionsList.filter(i => !groups.find(g => g.id === i.parent))
      .map(opt => opt.name)
      .join(', ');
    let tempStr = '';

    if (all) {
      tempStr = `All ${this.childsLabel || this.childsKeyName}`;
    } else if (groups && groups.length) {
      tempStr = `${groups.map(g => g.name).join(', ')}`;
      tempStr = `${tempStr}, ${optionsStr}`;
    } else {
      tempStr = optionsList.map(opt => opt.name).join(', ');
    }

    return tempStr.replace(/(,\s)+$/, '');
  }


  private select(current, selected, parent, isAll, isGroup): IMultipleSelectOption[] {
    if (isAll) { // if select all options
      return this.flatList;
    }

    if (isGroup) { // if select group
      return this.selectGroup(selected, current);
    }
    // if option is selected
    // and if should/should not select parent and all.
    return this.selectOption(selected, current, parent);
  }


  private deselect(current, deselected, parent, isAll, isGroup) {
    if (isAll) { // if deselect all options
      return [];
    }

    if (isGroup) { // if deselect group
      return this.deselectGroup(deselected, current);
    }
    // if option is deselected
    // and if should/should not select parent and all.
    return this.deselectOption(deselected, current, parent);
  }


  private createOptions(list: any[], childsKeyName?, childsLabel?): IMultipleSelectOption[] {
    const options: IMultipleSelectOption[] = [
      { id: 'all', name: `All ${childsLabel || childsKeyName}` }
    ];

    if (!childsKeyName) {
      return options.concat(list);
    }

    list.forEach((item) => {
      const childsList = item[childsKeyName];
      options.push({
        id: item.id,
        name: item.name,
        isGroup: !!childsList,
        childs: childsList && childsList.map(({ id, name }) => ({ id, name, parent: item.id })),
      });
    });

    return options.filter(item => {
      if (item.isGroup) {
        return !!item.childs.length;
      }
      return !!item;
    });
  }


  private getSelected(current, next): IMultipleSelectOption {
    const selected = next.filter(item => !current.find(cur => cur.id === item.id));
    return selected[0] || {};
  }


  private getDeselected(current, next): IMultipleSelectOption {
    const deselected = current.filter(item => !next.find(n => n.id === item.id));
    return deselected[0] || {};
  }


  private isWholeGroupSelected(selection, parent): boolean {
    return parent.childs.reduce((c, n) => {
      const isFound = selection.find(i => i.id === n.id);
      return c && !!isFound;
    }, true);
  }


  private selectGroup(selected, current): IMultipleSelectOption[] {
    const concatedSelection = current.concat(selected);
    const selection = concatedSelection.concat(
      selected.childs
        .filter(s => !concatedSelection.find(i => i.id === s.id) && s.id !== selected.id),
    );
    const hasAll = selection.find(i => i.id === 'all');
    if (selection.length === this.flatList.length - 1 && !hasAll) {
      selection.push(this.flatList.find(i => i.id === 'all'));
    }
    return selection;
  }


  private deselectGroup(deselected, current): IMultipleSelectOption[] {
    const selection = current
      .filter(item => !deselected.childs.includes(item))
      .filter(item => deselected.id !== item.id)
      .filter(item => item.id !== 'all');
    return selection;
  }


  private selectOption(selected, current, parent): IMultipleSelectOption[] {
    const selection = current.concat(selected);

    if (parent && this.isWholeGroupSelected(selection, parent)) {
      selection.push(parent);
    }

    if (selection.length === this.flatList.length - 1 && !selection.find(i => i.id === 'all')) {
      selection.push(this.flatList.find(i => i.id === 'all'));
    }

    return selection;
  }


  private deselectOption(deselected, current, parent): IMultipleSelectOption[] {
    const selection = current.filter(item => item.id !== deselected.id)
      .filter(item => item.id !== 'all')
      .filter(item => !parent || (item.id !== parent.id));
    return selection;
  }
}
