import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

const icons = [
  'alert__mono',
  'asset_90__mono',
  'asset_91__mono',
  'asset_91__color',
  'asset_92__mono',
  'buildings__mono',
  'costumer__mono',
  'create__mono',
  'criteria__mono',
  'dashboard__mono',
  'electrical_waste (2)__mono',
  'electrical_waste__mono',
  'import__mono',
  'list__mono',
  'logout1__mono',
  'logout2__mono',
  'logout3__mono',
  'meters__mono',
  'priority__mono',
  'report__mono',
  'weather__mono',
  'alert__color',
  'asset_111__color',
  'asset_113__color',
  'asset_114__color',
  'asset_114__mono',
  'buildings__color',
  'costumer__color',
  'create__color',
  'criteria__color',
  'dashboard__color',
  'electrical_waste_(2)__color',
  'electrical_waste__color',
  'import__color',
  'list__color',
  'logout1__color',
  'logout2__color',
  'logout3__color',
  'meters__color',
  'priority__color',
  'report__color',
  'weather__color'
];

@Injectable({
  providedIn: 'root',
})
export class IconsRegistryService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
  }

  init() {
    icons.forEach(iconName => this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/svg-icons/icons/${iconName}.svg`),
    ));
  }
}
