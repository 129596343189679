import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationScheduleLegendModule } from '../features/operation-schedule-legend/operation-schedule-legend.module';
import { SharedModule } from '../shared/shared.module';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { DashboardComponent } from './dashboard.component';
import { BenchmarkingComponent } from './benchmarking/benchmarking.component';
import { WasteEventsModule } from './waste-events/waste-events.module';
import { WasteMapComponent } from './waste-map/waste-map.component';
import { GoogleMapComponent } from './waste-map/google-map.component';
import { GoogleMapTooltipComponent } from './waste-map/google-map-tooltip.component';
import { WasteMapFiltersComponent } from './waste-map/waste-map-filters.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import {MatSelectModule} from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardService } from './dashboard.service';
import { BuildingDetailsComponent } from './modals/building-details.component';
import { BuildingOperationScheduleComponent } from './modals/building-operation-schedule/building-operation-schedule.component';
import { WeaklyHoursScheduleViewComponent } from './modals/weakly-hours-schedule-view/weakly-hours-schedule-view.component';
import { NotesModule } from '../notes/notes.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { WeatherComparisonModule } from '../features/weather-comparison/weather-comparison.module';
import { WeatherKwhSectionModule } from '../features/weather-kwh-section/weather-kwh-section.module';
import { WeatherKwhHeatmapSectionModule } from '../features/weather-kwh-heatmap-section/weather-kwh-heatmap-section.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OperationScheduleLegendModule,
    WasteEventsModule,
    MatExpansionModule,
    NgxMatDrpModule,
    BrowserAnimationsModule,
    MatSelectModule,
    NotesModule,
    AttachmentsModule,
    WeatherComparisonModule,
    WeatherKwhSectionModule,
    WeatherKwhHeatmapSectionModule
  ],

  declarations: [
    BenchmarkingComponent,
    DashboardComponent,
    SearchFiltersComponent,
    WasteMapComponent,
    GoogleMapComponent,
    GoogleMapTooltipComponent,
    WasteMapFiltersComponent,
    BuildingDetailsComponent,
    BuildingOperationScheduleComponent,
    WeaklyHoursScheduleViewComponent
  ],
  providers: [DashboardService],
  entryComponents: [BuildingDetailsComponent]
})
export class DashboardModule { }
