import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type Element<T> = T & {
  verbose_name: string;
}

export interface ElementsModalData<T> {
  elements: Element<T>[];
  title: string;
  selectPlaceholder: string;
  addBtnText: string;
}

@Component({
  selector: 'exa-utility-bills-add-element-modal',
  templateUrl: './utility-bills-add-element-modal.component.html',
  styleUrls: ['./utility-bills-add-element-modal.component.scss']
})
export class UtilityBillsAddElementModalComponent<T> implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<UtilityBillsAddElementModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public modalData: ElementsModalData<T>,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      element: new FormControl(this.modalData.elements[0])
    });
  }

  addElement() {
    this.dialogRef.close(this.form.value.element);
  }
}
