import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAvailable'
})
export class NotAvailablePipe implements PipeTransform {

  transform(value: any, notAvailableMsg = 'N/A'): any {
    return value ? value : notAvailableMsg;
  }

}
