import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, merge, Subject, Subscription } from 'rxjs';
import { catchError, switchMap, tap, filter } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { Building } from '../../../shared/models/building.model';
import { Customer } from '../../../shared/models/customer.model';
import { Meter } from '../../../shared/models/meter.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { BillUpdateConfig } from '../../utility-bill-year-view/utility-bill-year-view/utility-bill-year-view.component';
import { UtilityBillsDataService } from '../utility-bills-data.service';
import { UtilityBillsResponse } from '../utility-bills.models';

@Component({
  selector: 'exa-utility-bills-section',
  templateUrl: './utility-bills-section.component.html',
  styleUrls: ['./utility-bills-section.component.scss'],
  providers: [UtilityBillsDataService],
})
export class UtilityBillsSectionComponent implements OnInit, OnChanges, OnDestroy {

  basicData$ = new Subject<{ buildings: Building[] }>();
  loadBasicData$ = new Subject();
  selectedBuilding$ = new Subject<Building>();
  meters$ = new BehaviorSubject<Meter[]>([]);
  loadingBasicDataStats = new SectionLoadingStates();
  subscriptions = new Subscription();
  updateBillSubscription: Subscription;

  @Input() customer: Customer;

  constructor(private data: DataService, private billsDataSvc: UtilityBillsDataService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.updateBillSubscription && this.updateBillSubscription.unsubscribe();
  }

  ngOnInit() {
    const basicDataSubscription = merge(
      this.loadBasicData$,
    ).pipe(
      tap(() => this.loadingBasicDataStats.setLoadingState()),
      switchMap(() => this.loadBuildings(this.loadingBasicDataStats)),
      tap(buildings => this.basicData$.next({buildings})),
      tap(() => this.loadingBasicDataStats.resetLoadingState()),
    ).subscribe();

    this.subscriptions.add(basicDataSubscription);

    const selectedBuildingSubscription = this.selectedBuilding$.pipe(
      switchMap(building => this.loadMeters(building)),
      tap(meters => this.meters$.next(meters)),
    ).subscribe();

    this.subscriptions.add(selectedBuildingSubscription);

    this.load();
  }

  ngOnChanges({customer}: SimpleChanges): void {
    if (customer.previousValue !== customer.currentValue && !customer.isFirstChange()) {
      this.load();
      this.billsDataSvc.clearTheState();
    }
  }

  onBuildingSelected(building: Building) {
    this.selectedBuilding$.next(building);
  }

  onUpdateBill(config: BillUpdateConfig) {
    const {bill, month, billDef} = config;
    const lastQuery = this.billsDataSvc.lastYearlyQuery$.value;
    const updateDialogReg = this.billsDataSvc.openBillUpdateDialog({
      bill,
      billType: billDef.id,
      month,
      year: lastQuery.year,
      utilityMeter: lastQuery.meter,
    }, bill);
    this.updateBillSubscription && this.updateBillSubscription.unsubscribe();
    this.updateBillSubscription = updateDialogReg.afterClosed().pipe(
      filter(res => !!res),
      switchMap((res) => this.billsDataSvc.getCategorizedMeterYearBill(lastQuery.meter, lastQuery.year, billDef.id)),
    ).subscribe();
  }

  reloadYearlyBills() {
    const {meter, year} = this.billsDataSvc.lastYearlyQuery$.value;
    const reloadSubscription = this.billsDataSvc.getMeterYearBills(meter, year).subscribe();
    this.subscriptions.add(reloadSubscription);
  }

  private loadBuildings(loadingStats: SectionLoadingStates) {
    return this.data.buildings.list({'customer_id': this.customer.id}).pipe(
      catchError(err => {
        loadingStats.setErrorState();
        return EMPTY;
      }),
    );
  }

  private loadMeters(building: Building) {
    return this.data.meters.listUtility({building_id: building.id}).pipe(
      catchError(err => EMPTY),
    );
  }

  private load() {
    this.loadBasicData$.next();
  }
}
