import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'exa-list-base-text',
  template: `
    <ng-container *ngIf="params">
      <span *ngIf="valueStr?.length <= 30">{{params?.value}}</span>
      <span *ngIf="valueStr?.length > 30" [matTooltip]="params?.value">{{params?.value}}</span>
    </ng-container>
  `,
  styles: []
})
export class ListBaseTextComponent {
  params: any;
  field: string;
  data: any;
  valueStr: string;

  constructor() { }

  agInit(params: any) {
    this.field = params.colDef.field;
    this.data = params.node.data;
    this.params = params;
    this.valueStr = `${params.value}`;
  }
}
