import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'exa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() user;
  @Input() width: number;
  @Input() maxWidth: number;
  @Input() leftPos: number;
  @Input() isSmall: boolean;
  @Output() logout = new EventEmitter();
  @Output() toggleMenu = new EventEmitter();
  @Output() toggleCollapse = new EventEmitter();
  @Output() hover = new EventEmitter();

  onClickLogout() {
    this.logout.emit();
  }

  onClickToggle() {
    this.isSmall ? this.toggleMenu.emit() : this.toggleCollapse.emit();
  }

  get isCollapsed() {
    return this.width < this.maxWidth;
  }
}
