import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { GROUPS, getRoutesByGroup } from '../../../auth/user-group-guard.provider';
import { SideNavService } from '../../../layout/side-nav.service';

const ROUTES = [
  {
    id: 0,
    name: 'Meters Integration',
    link: ['/account-settings'],
    availableOn: GROUPS.all,
  },
];


@Component({
  selector: 'exa-account-settings-main-pg',
  templateUrl: './account-settings-main-pg.component.html',
  styleUrls: ['./account-settings-main-pg.component.scss']
})
export class AccountSettingsMainPgComponent implements OnInit {

  routes$: Observable<any[]>;

  constructor(public menu: SideNavService, private auth: AuthService) { }

  ngOnInit() {
    this.routes$ = this.auth.loggedInUser$.pipe(
      map(user => getRoutesByGroup(ROUTES, (user && user.group) || []))
    );
  }

}
