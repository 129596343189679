import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationScheduleLegendModule } from '../features/operation-schedule-legend/operation-schedule-legend.module';
import { WeaklyHoursScheduleModule } from '../features/weakly-hours-schedule/weakly-hours-schedule.module';
import { SharedModule } from '../shared/shared.module';
import { BuildingOperationScheduleEditComponent } from './building-operation-schedule-edit/building-operation-schedule-edit/building-operation-schedule-edit.component';
import { BuildingsListComponent } from './buildings-list/buildings-list.component';
import { CreateBuildingComponent } from './create-building/create-building.component';
import { NewBuildingComponent } from './create-building/new-building/new-building.component';
import { ImportBuildingComponent } from './create-building/import-building/import-building.component';
import { BuildingsComponent } from './buildings.component';
import { BuildingsEditComponent } from './buildings-edit/buildings-edit.component';
import { BuildingsFormComponent } from './buildings-form/buildings-form.component';
import { BuildingsDetailsComponent } from './buildings-details/buildings-details.component';
import { NotesModule } from '../notes/notes.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { BuildingOperationScheduleComponent } from './building-operation-schedule/building-operation-schedule.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NotesModule,
    AttachmentsModule,
    WeaklyHoursScheduleModule,
    OperationScheduleLegendModule,
    MatExpansionModule
  ],
  declarations: [
    BuildingsListComponent,
    CreateBuildingComponent,
    NewBuildingComponent,
    ImportBuildingComponent,
    BuildingsComponent,
    BuildingsEditComponent,
    BuildingsFormComponent,
    BuildingsDetailsComponent,
    BuildingOperationScheduleComponent,
    BuildingOperationScheduleEditComponent
  ],
  entryComponents: [
    BuildingOperationScheduleEditComponent,
  ],
})
export class BuildingsModule { }
