import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesListComponent } from './notes-list/notes-list.component';
import { NotesCreateComponent } from './notes-create/notes-create.component';
import { NotesCreateDialogComponent } from './notes-create/notes-create-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    NotesListComponent,
    NotesCreateComponent,
    NotesCreateDialogComponent,
  ],
  exports: [
    NotesListComponent,
    NotesCreateComponent,
    NotesCreateDialogComponent,
  ],
  entryComponents: [
    NotesCreateDialogComponent,
  ],
})
export class NotesModule { }
