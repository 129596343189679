import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export function getLookupValidator(key?: string) {
  return (control: FormControl) => {
    const hasValue = key ? !!(control.value && control.value[key]) : control.value;
    const errors = control.errors || {};
    const isRequired =  errors.required && !control.value;

    return hasValue || isRequired ? null : { chooseFromList: true };
  };
}

@Component({
  selector: 'exa-lookup-dropdown',
  template: `
  <div class="full-width" [formGroup]="lookupFormGroup">
    <mat-form-field class="full-width" *ngIf="lookupFormGroup && lookupFormGroupName" [formGroupName]="lookupFormGroupName">
      <input type="text"
            [placeholder]="label"
            matInput
            [matAutocomplete]="autoComplete"
            [formControlName]="lookupFormControlName"
            #searchBox
             autocomplete="off"
            (input)="onInputSearch(searchBox.value)">
      <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="customDisplayFn" (optionSelected)="onSelected($event)">
        <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
          {{option[displayKey]}}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="lookupFormGroup.get([lookupFormGroupName, lookupFormControlName])?.errors as errors">
        <ng-container *ngIf="errors.chooseFromList">
          Please choose {{replaceAstrisk(label)}} from the list.
        </ng-container>

        <ng-container *ngIf="errors.required">
          {{replaceAstrisk(label)}} field is required.
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="lookupFormGroup && !lookupFormGroupName">
      <input type="text"
            [placeholder]="label"
            matInput
            [matAutocomplete]="auto"
            [formControlName]="lookupFormControlName"
            #searchBox
            (input)="onInputSearch(searchBox.value)">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="customDisplayFn" (optionSelected)="onSelected($event)">
        <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
          {{option[displayKey]}}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="lookupFormGroup.get(lookupFormControlName)?.errors as errors">
        <ng-container *ngIf="errors.chooseFromList">
          Please choose {{replaceAstrisk(label)}} from the list.
        </ng-container>

        <ng-container *ngIf="errors.required">
          {{replaceAstrisk(label)}} field is required.
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>
  `,
  styles: [],
})
export class LookupDropdownComponent implements OnChanges {
  @Input() options: any[] = [];
  @Input() label: string;
  @Input() lookupFormGroup: FormGroup;
  @Input() lookupFormGroupName: string;
  @Input() lookupFormControlName: string;
  @Input() displayKey = 'name';
  @Input() filterBy = 'name';
  @Input() disabled = false;
  @Output() selected = new EventEmitter();
  @Output() lookup = new EventEmitter();
  changes$: BehaviorSubject<SimpleChanges> = new BehaviorSubject(null);
  filter$: BehaviorSubject<string> = new BehaviorSubject(null);

  filteredOptions$ = combineLatest(this.changes$, this.filter$).pipe(
    map(([options, filter]) => filter),
    debounceTime(300),
    distinctUntilChanged(),
    tap((val) => this.lookup.emit(val)),
    map((val: string) => {
      const regex = new RegExp(val, 'ig');
      const sortedOpts = _.sortBy(this.options, this.filterBy);
      const list = sortedOpts.filter(option => regex.test(option[this.filterBy]));
      return list;
    })
  );

  customDisplayFn = (value) =>  value && value[this.displayKey];

  constructor() { }

  onInputSearch(val) {
    // const formControl = this.lookupFormGroupName
    //     ? [this.lookupFormGroupName, this.lookupFormControlName]
    //     : [this.lookupFormControlName];
    // const oldValue = this.lookupFormGroup.get(formControl).value;
    this.filter$.next(val);
  }

  replaceAstrisk(str: string) {
    return str.replace(/\*$/, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.changes$.next(changes);
    }
  }

  onSelected({ option }) {
    this.selected.emit(option && option.value);
  }

  resetValue() {
    this.lookupFormGroup.get(this.lookupFormControlName).setValue('');
    this.selected.emit();
  }
}
