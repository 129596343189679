import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'exa-insight-sunburst-filters',
  templateUrl: './insight-sunburst-filters.component.html',
  styles: []
})
export class InsightSunburstFiltersComponent implements OnInit {
  @Input() loading: boolean;
  @Input() hasEndDate = true;
  @Output() search = new EventEmitter();

  form = this.fb.group({
    from: [ moment().subtract(12, 'month').toDate(), Validators.required ],
    to: [ moment().toDate(), Validators.required ],
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.onSubmit();
  }

  onSubmit() {
    this.search.emit(this.form.value);
  }
}
