import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GroupWeatherSeries } from '../weather-comparison.models';

@Component({
  selector: 'exa-weather-comparison-scatter',
  templateUrl: './weather-comparison-scatter.component.html',
  styleUrls: ['./weather-comparison-scatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComparisonScatterComponent {
  maxTemp: number;
  minTemp: number;
  series: GroupWeatherSeries[];
  @Input()
  set groupsSeries(s: GroupWeatherSeries[]) {
    if(!s)
      return;
    this.series = s;
    const minRanges = s.map(g => g.series[0]);
    const maxRanges = s.map(g => g.series[g.series.length - 1]);
    this.minTemp = minRanges.sort((a, b) => a.start - b.start)[0].start;
    this.maxTemp = maxRanges.sort((a, b) => b.end - a.end)[0].end;
  }
}
