import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  readonly _defaultFormat = 'LLL';

  transform(value: string|Date, format?: string): any {
    if (value) {
      return moment(value).format(format || this._defaultFormat);
    }
    return '';
  }

}
