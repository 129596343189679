import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  chartDefaultOptions,
  dateOnlyLabelFormatterFactory,
  tooltipDateOnlyFormatterFactory,
} from 'ex-library';
import { Options, PointObject } from 'highcharts';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NumberFormatPipe } from '../../shared/pipes/number-format.pipe';


@Component({
  selector: 'exa-insight-trends-streamgraph',
  template: `
    <exlib-chart [chartOptions]='chartOptions'></exlib-chart>
  `,
  styles: [],
})
export class InsightTrendsStreamgraphComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() graphTitle: string;

  chartOptions: Options;

  constructor(private numberFormat: NumberFormatPipe) {
  }

  ngOnInit() {
    this.draw();
  }

  ngOnChanges({data}: any) {
    if (data.currentValue) {
      this.draw();
    }
  }

  draw() {
    const datetimeLabelFormatter = dateOnlyLabelFormatterFactory(null);

    const generateSeriesName = (hour: number) => moment().startOf('d').hour(hour).format('HH:mm');
    const mapHourSeriesData = (hourSeriesData: any[]) => hourSeriesData.map(p => {
      const pointMoment = moment(p.day).startOf('d').utc();
      const dateValue = pointMoment.toDate().valueOf();
      return [dateValue, p.value];
    });

    const series = _(this.data)
      .groupBy('hour')
      .map(sData => ({
        name: generateSeriesName(sData[0].hour),
        data: mapHourSeriesData(sData),
      }))
      .valueOf();

    const self = this;
    this.chartOptions = _.assign({}, chartDefaultOptions, {
      chart: _.assign({}, chartDefaultOptions.chart, {
        type: 'streamgraph',
        zoomType: 'x',
        height: 660,
      }),

      legend: {
        title: {
          text: 'Hours',
        },
        enabled: true,
        margin: 30,
        position: 'left',
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
      },


      tooltip: {
        formatter(): boolean | string {
          return self.tooltipFormatter(this);
        },
      },


      xAxis: {
        type: 'datetime',
        labels: {
          formatter: datetimeLabelFormatter,
        },
      },

      yAxis: _.assign({}, chartDefaultOptions.yAxis, {
        visible: false,
      }),

      series,
    });
  }

  private tooltipFormatter(instance: PointObject) {
    const datetimeHeader = tooltipDateOnlyFormatterFactory()(instance.x);
    const seriesBody = this.tooltipSeriesBodyFormatter(instance);
    return `${datetimeHeader}<br>${seriesBody}`;
  }

  private tooltipSeriesBodyFormatter(instance: any) {
    const series: {userOptions: any; color: string; name: string} = instance.series;
    const titlePart = `<span style="color:${series.color}">\u25CF</span> ${series.name}`;
    const valuePart = `<strong>${this.numberFormat.transform(instance.y)}</strong> kWh`;
    return `${titlePart}: ${valuePart}`;
  }

}
