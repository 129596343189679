import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetersIntegrationModule } from '../../features/meters-integration/meters-integration.module';
import { SharedModule } from '../../shared/shared.module';

import { AccountSettingsPageRoutingModule } from './account-settings-page-routing.module';
import { AccountSettingsMainPgComponent } from './account-settings-main-pg/account-settings-main-pg.component';
import { MetersIntegrationPgComponent } from './meters-integration-pg/meters-integration-pg.component';

@NgModule({
    imports: [
        CommonModule,
        AccountSettingsPageRoutingModule,
        SharedModule,
        MetersIntegrationModule,
    ],
  declarations: [AccountSettingsMainPgComponent, MetersIntegrationPgComponent],
})
export class AccountSettingsPageModule { }
