import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface FieldInput extends UtilityBillsLibraryModule.Field {
  inputType: 'text' | 'number';
}

@Pipe({
  name: 'fieldData'
})
export class FieldDataPipe implements PipeTransform {

  transform(attributeFromGroup: FormGroup, args?: any): FieldInput {
    const field = <UtilityBillsLibraryModule.Field> attributeFromGroup.value.field;
    return {
      ...field,
      inputType: field.dtype === 'string' ? 'text' : 'number',
    };
  }

}
