import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SelectFilesComponent } from 'ex-library';

@Component({
  selector: 'exa-select-file',
  template: `
    <exlib-select-files (selectFiles)="onFileSelected($event)"
                        [acceptedFormats]="acceptedFormats"></exlib-select-files>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem" class="margin--top">
      <button mat-button mat-raised-button class="mat-primary" type="button" (click)="selectFilesComponent.openSelector()"
              [disabled]="disabled">Select File
      </button>
      <div
          fxFlex="1 1 100%"
          class="text--primary bg--primary-offwhite padding--small">
        <ng-container
            *ngIf="selectedFile">
          {{selectedFile.name}}
        </ng-container>
        <ng-container
            *ngIf="!selectedFile">
          Please Select file.
        </ng-container>
      </div>
    </div>
  `,
  styles: [],
})
export class SelectFileComponent {

  selectedFile: File;

  @Input() acceptedFormats: string;
  @Input() disabled: boolean;
  @Output() selectFile = new EventEmitter<File>();

  @ViewChild(SelectFilesComponent) selectFilesComponent;

  constructor() { }

  onFileSelected(files: File[]) {
    if (!files.length) { return; }
    this.selectedFile = files[0];
    this.selectFile.emit(this.selectedFile);
  }

}
