import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../data/data.service';
import { ImportModalBase } from '../import-modal-base';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-import-meters',
  templateUrl: './import-meters.component.html',
  styleUrls: ['./import-meters.component.scss'],
})
export class ImportMetersComponent extends ImportModalBase {

  constructor(
      public dialogRef: MatDialogRef<ImportMetersComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: { building: { id: number, name: string } },
      protected fileSaver: FileSaverService,
      protected toastr: ToastrService,
      protected data: DataService,
      protected auth: AuthService,
      private customers: CustomersService,
    ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }

  setConfig() {
    const signedUrlMethod = () => {
      const id = this.customerId();
      return this.data.buildings.importMetersUrl(this.selectedFile);
    };

    const importMethod = () => this.data.buildings
        .importMeters(this.customerId(), this.signedUrl.path);

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.data.buildings.importMetersTemplate(this.customerId()),
      acceptedFileFormats: '.xlsx, text/xlsx',
      onFinish: () => this.close(),
      url: ""
    };
  }


  customerId() {
    return this.customers.selected.id;
  }

}
