import { ISideNavItem } from './side-nav-item/side-nav-item.model';
import { GROUPS } from '../../auth/user-group-guard.provider';

/**
 * @todo (hassan)
 * refactor dynamic link using Observables and a service
 * that listen to the selected customers and generate the links
 * dynamically to avoid the huge template.
 */
export const CUSTOMER_NAV_ITEMS: ISideNavItem[] = [
  {
    name: 'E. Dashboard',
    icon: 'dashboard',
    route: ['/dashboard'],
    availableOn: GROUPS.all,
  },

  {
    name: 'Buildings',
    icon: 'buildings',
    availableOn: GROUPS.all,
    children: [
      {
        name: 'Create',
        icon: 'create',
        exact: true,
        route: ['/buildings/create'],
        availableOn: GROUPS.noOne,
      },

      {
        name: 'Import',
        icon: 'import',
        exact: true,
        route: ['/buildings/create/bulk'],
        availableOn: GROUPS.noOne,
      },

      {
        name: 'List',
        icon: 'list',
        route: ['/buildings'],
        exact: true,
        availableOn: GROUPS.all,
      },
    ],
  },

  {
    name: 'Waste Tracker',
    icon: 'electrical_waste',
    availableOn: GROUPS.all,
    children: [
      {
        name: 'Create Waste Criteria',
        icon: 'create',
        route: ['/criteria/create'],
        exact: true,
        availableOn: GROUPS.noOne,
      },
      {
        name: 'Waste Criteria',
        icon: 'criteria',
        route: ['/criteria'],
        exact: true,
        availableOn: GROUPS.notLow,
      },

      {
        name: 'Waste Events',
        icon: 'alert',
        route: ['/criteria/waste-events'],
        availableOn: GROUPS.all,
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'report',
    availableOn: GROUPS.all,
    route: ['/c-reports'],
    exact: true,
  },
];


export const ALL_USERS_NAV_ITEMS: ISideNavItem[] = [
  {
    name: 'Customers',
    icon: 'costumer',
    availableOn: GROUPS.noOne,
    children: [
      {
        name: 'Create Customer',
        icon: 'create',
        exact: true,
        route: ['/customers/new'],
        availableOn: GROUPS.all,
      },

      {
        name: 'Customers List',
        icon: 'list',
        exact: true,
        route: ['/customers'],
        availableOn: GROUPS.all,
      },

      {
        name: 'Create User',
        icon: 'create',
        exact: true,
        route: ['/customers/users/create'],
        availableOn: GROUPS.all,
      },

      {
        name: 'Users List',
        icon: 'list',
        exact: true,
        route: ['/customers/users'],
        availableOn: GROUPS.all,
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'report',
    availableOn: GROUPS.noOne,
    route: ['/admin-reports'],
  }
];

