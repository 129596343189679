import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CombinationEquationModule } from '../combination-equation/combination-equation.module';
import { UtilityMeterCreateComponent } from './utility-meter-create/utility-meter-create.component';
import { ElectricalMeterCreateSectionComponent } from './sections/electrical-meter-create-section/electrical-meter-create-section.component';
import { VirtualMeterCreateComponent } from './virtual-meter-create/virtual-meter-create.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CombinationEquationModule,
  ],
  declarations: [UtilityMeterCreateComponent, ElectricalMeterCreateSectionComponent, VirtualMeterCreateComponent],
    exports: [
        UtilityMeterCreateComponent,
        VirtualMeterCreateComponent,
    ],
  entryComponents: [
    ElectricalMeterCreateSectionComponent,
  ],
})
export class MeterCreateModule { }
