import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data/data.service';
import { SunburstRouteBase, ISunburstParams, ISunburstDataAnnualWeekly } from '../insight-sunburst/sunburst-route-base';
import { of, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'exa-insight-opportunities',
  templateUrl: './insight-opportunities.component.html',
  styles: []
})
export class InsightOpportunitiesComponent extends SunburstRouteBase implements OnInit {

  constructor(private data: DataService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setInitialSettings() {
    this.type$ = of('opportunities');
    this.title$ = this.type$.pipe(map(() => 'Saving Opportunities'));
  }


  getDataObservabe(type: string, params: ISunburstParams): Observable<ISunburstDataAnnualWeekly> {
    const { start_date } = params;
    return combineLatest(
      this.data.insight.opportunities({ start_from: start_date, period: 'year' }),
      this.data.insight.opportunities({ start_from: start_date, period: 'week' }),
    ).pipe(
      map((res) => {
        return {
          annual: this.nestDataByParent(res[0]),
          weekly: this.nestDataByParent(res[1]),
        };
      })
    );
  }

}
