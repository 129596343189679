import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { ApiService } from '../../../data/api.service';
import { DataService } from '../../../data/data.service';
import { SmtSubscriptionsService } from '../../../data/smt-subscriptions/smt-subscriptions.service';
import { ImportModalBase } from '../../../imports/import-modal-base';
import { Customer } from '../../../shared/models/customer.model';

@Component({
  selector: 'exa-smt-add-subscription',
  templateUrl: './smt-add-subscription.component.html',
  styleUrls: ['./smt-add-subscription.component.scss']
})
export class SmtAddSubscriptionComponent extends ImportModalBase {

  maxHistoryDateLimit = {
    min: moment().subtract(2, 'y').toDate(),
    max: moment().toDate(),
  };

  subscriptionForm: FormGroup = this.fb.group({
    subscriptionId: ['', [Validators.required]],
    importFromDate: this.maxHistoryDateLimit.min,
  });

  errorsMap = {
    id: 'Subscription ID',
    import_from_date: 'Import Historical Data From Date',
  };

  @Output() onSubscriptionSaved = new EventEmitter<{ task_id: string; subscriptionId: string;}>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SmtAddSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { customer: Customer },
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
    protected auth: AuthService,
    private api: ApiService,
    private smtSubSvc: SmtSubscriptionsService,
  ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }


  setConfig() {
    const {customer} = this.modalData;
    const signedUrlMethod = () => this.api.tasks.getSignedUrl({
      id: customer.id,
      entity: 'meter',
      content_type: 'xlsx',
      filename: this.selectedFile.name,
    }, {customer_id: customer.id}).pipe(
      map((res: any) => ({
        ...res,
        url: (new URL(res.url)).pathname,
        customerId: customer.id
      }))
    );

    const importMethod = () => {
      const {subscriptionId, importFromDate} = this.subscriptionForm.value;
      return this.smtSubSvc.addSubscription({
        subscriptionId,
        customerId: customer.id,
        meters_filepath: this.signedUrl.path,
        import_from_date: importFromDate,
      }).pipe(
        tap(res => this.onSubscriptionSaved.emit({
          task_id: (res as any).task_id,
          subscriptionId: subscriptionId,
        })),
        catchError(err => {
          this.submitting = false;
          this.processing = false;
          this.errorMessage = err.error;
          return EMPTY;
        }),
      );
    };

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.smtSubSvc.getTemplate(customer.id),
      acceptedFileFormats: '.xlsx',
      onFinish: () => this.close(),
      successToastrMessage: () => `Subscription has been activated successfully, please check the historical data in 1 hour from now`,
      url: ""
    };
  }
}
