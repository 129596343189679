import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { WeaklyHoursProfileSchedule } from '../../shared/models/operation-schedule/models';

export enum Actions {
  updateOperationsSchedule = 'UPDATE_OPERATIONS_SCHEDULE',
  updateProfileSchedule = 'UPDATE_PROFILE_SCHEDULE',
}

@Injectable()
export class BuildingOperationScheduleEditStateService {

  operationsScheduleStore$ = new BehaviorSubject<WeaklyHoursProfileSchedule[]>(null);
  operationScheduleChange$ = new Subject<{
    state: WeaklyHoursProfileSchedule[];
    action: string;
  }>();

  constructor() {
  }

  updateSchedule(schedule: WeaklyHoursProfileSchedule[]) {
    this.operationsScheduleStore$.next(schedule);
    this.operationScheduleChange$.next({
      state: this.operationsScheduleStore$.getValue(),
      action: Actions.updateOperationsSchedule,
    });
  }

  updateProfileSchedule(schedule: WeaklyHoursProfileSchedule) {
    const currentState = this.operationsScheduleStore$.getValue();
    const unChangedSchedules = currentState.filter(s => s.profile.id !== schedule.profile.id);
    const updatedState = [].concat(unChangedSchedules, [schedule]);
    this.operationsScheduleStore$.next(updatedState);
    this.operationScheduleChange$.next({
      state: this.operationsScheduleStore$.getValue(),
      action: Actions.updateProfileSchedule,
    });
  }
}
