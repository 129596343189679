import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../data/data.service';
import { ImportModalBase } from '../import-modal-base';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-import-load-factor',
  templateUrl: './import-load-factor.component.html',
  styles: []
})
export class ImportLoadFactorComponent extends ImportModalBase {

  constructor(
      public dialogRef: MatDialogRef<ImportLoadFactorComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: { building: { id: number, name: string } },
      protected fileSaver: FileSaverService,
      protected toastr: ToastrService,
      protected data: DataService,
      protected auth: AuthService,
      private customers: CustomersService,
    ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }

  setConfig() {
    const signedUrlMethod = () => this.data
      .buildings
      .importLoadFactorUrl(this.modalData.building.id, this.selectedFile, this.customerId());

    const importMethod = () => this.data.buildings.importLoadFactor(this.modalData.building.id, this.signedUrl.path);

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.data.buildings.importLoadFactorTemplate(),
      acceptedFileFormats: '.csv, text/csv',
      onFinish: () => this.close(),
      url: ""
    };
  }

  customerId() {
    return this.customers.selected.id;
  }

}
