import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { tap, switchMap, catchError, finalize, map, share } from 'rxjs/operators';
import { ListBaseComponent } from '../../shared/components/list-base/list-base.component';
import { throwError, Subscription, Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { SideNavService } from '../../layout/side-nav.service';
import * as moment from 'moment';

const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};


@Component({
  selector: 'exa-alerts-list',
  templateUrl: './criteria-alerts.component.html',
  styleUrls: ['./criteria-alerts.component.scss']
})
export class CriteriaAlertsComponent extends ListBaseComponent<any[]> implements OnInit {
  customerSub: Subscription;
  filterModelMap = {
    'meter.name': 'meter__name__icontains',
    'meter.building.name': 'meter__building__name__icontains',
    'criteria.name': 'criteria__name__icontains',
    'started_at': 'started_at',
    'ended_at': 'ended_at',
    'duration': 'duration',
  };

  sortModelMap = {
    avg_waste: 'avg_waste',
    started_at: 'started_at',
    ended_at: 'ended_at',
    total_waste: 'total_waste',
    avg_corrected_waste: 'avg_corrected_waste',
    avg_remaining_waste: 'avg_remaining_waste',
    'duration': 'duration',
  };

  displayedColumns: any[] = [
    {
      headerName: 'Criteria',
      field: 'criteria.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions
    },

    {
      headerName: 'Meter',
      field: 'meter.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },
    {
      headerName: 'Building',
      field: 'meter.building.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },
    {
      headerName: 'Started At',
      field: 'started_at',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true, placehoder: 'MM/DD/YYYY' },
      ...customFilterOptions,
      floatingFilterComponent: 'ListBaseDateFilterComponent',
      sortable: true,
      valueFormatter: function (param) {
        const { value } = param || { value: null };
        return value ? moment(value).format('DD MMM, YYYY') : '';
      },
    },

    {
      headerName: 'Duration',
      field: 'duration',
      filter: false,
      sortable: true,
    },


    {
      headerName: 'Total Waste',
      field: 'total_waste',
      filter: false,
      sortable: true,
    },

    {
      headerName: 'Avg. Waste',
      field: 'avg_waste',
      filter: false,
      sortable: true,
    },
  ];


  constructor(
      private customers: CustomersService,
      private data: DataService,
      auth: AuthService,
      sidenav: SideNavService,
    ) {
    super(auth, sidenav);
  }


  ngOnInit() {
    super.ngOnInit();
    this.gridOptions = Object.assign({}, this.gridOptions, {
      context: {
        ...this.gridOptions.context,
        componentParent: this,
      },
    });

    this.customerSub = this.customers.selected$.subscribe((customer) => {
      if (this.gridParams) {
        this.onGridReady(this.gridParams);
      }
    });
  }


  getDataObservable(reqParams: any): Observable<any> {
    return this.data.alertsList({
      ...reqParams
    }).pipe(
      share(),
      map((res: any) => {
      res.results = res.results || [];

      res.results.forEach(item => {
        item.gridLinks = [
          { key: 'criteria.name', route: (data) => ['/criteria', data.criteria.id]},
          { key: 'meter.name', route: (data) => ['/meters', data.meter.id]},
          { key: 'meter.building.name', route: (data) => ['/buildings', data.meter.building.id]},
        ];
      });
      return res;
    }));
  }




}
