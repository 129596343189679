import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CustomerReportsComponent } from './customer-reports/customer-reports.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    CustomerReportsComponent,
  ],
  declarations: [CustomerReportsComponent],
})
export class CustomerReportsModule { }
