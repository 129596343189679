import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PROXY_API, BE_DAY_DATE_FORMAT } from '../../constants';
import { DataService } from '../data.service';

export interface NewSmtSubscriptionBody {
  subscriptionId: string;
  customerId: string;
  meters_filepath: string;
  import_from_date: Date;
}

@Injectable({
  providedIn: 'root',
})
export class SmtSubscriptionsService {

  constructor(private http: HttpClient, private data: DataService) {
  }

  getTemplate(customerId: string) {
    return this.http.get(`${PROXY_API}/external-meters-template/smt`);
  }

  addSubscription(subscriptionBody: NewSmtSubscriptionBody) {
    const url = `${environment.apiBase}/v1/customers/smt/subscriptions`;
    const body = {
      id: subscriptionBody.subscriptionId,
      customer: subscriptionBody.customerId,
      meters_filepath: subscriptionBody.meters_filepath,
      import_from_date: moment(subscriptionBody.import_from_date).format(BE_DAY_DATE_FORMAT),
    };
    return this.http.post(url, body);
  }

  getList() {
    const url = `${environment.apiBase}/v1/customers/smt/subscriptions`;
    return this.http.get(url);
  }

  deleteSubscription(subscriptionId: string) {
    const url = `${environment.apiBase}/v1/customers/smt/subscriptions/${subscriptionId}`;
    return this.http.delete(url);
  }
}
