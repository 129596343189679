import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CustomersService } from './customers.service';

@Injectable()
export class CustomersInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private customers: CustomersService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const hasCustomerId = req.params.has('customer_id');
    return this.auth.user$.pipe(
      take(1),
      withLatestFrom(this.auth.isAdmin$),
      switchMap( ([user, isAdmin]) => isAdmin && !hasCustomerId ? this.withCustomerParam(req, next) : next.handle(req.clone())),
    );
  }

  private withCustomerParam(req: HttpRequest<any>, next: HttpHandler) {
    return this.customers.selectedCustomerSubj.pipe(
      take(1),
      switchMap(customer => {
        if (!customer)
          return next.handle(req.clone());
        const params = req.params.set('customer_id', customer.id);
        return next.handle(req.clone({params}));
      }),
    );
  }
}

