import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { predictedKwhMethods } from '../../../../constants';
import { MeterMediaTypeSectionBase } from '../meter-media-type-section-base';


@Component({
  selector: 'exa-electrical-meter-create-section',
  templateUrl: './electrical-meter-create-section.component.html',
  styleUrls: ['./electrical-meter-create-section.component.scss']
})
export class ElectricalMeterCreateSectionComponent extends MeterMediaTypeSectionBase implements OnInit, OnDestroy {

  predictedKwhMethods = Object.values(predictedKwhMethods);
  baseloadError$: Observable<string>;
  subscriptionsSink = new Subscription();

  sectionFormGroup = this.fb.group({
    wasteSettings: this.fb.group({
      calculateWaste: [false, [Validators.required]],
      predicted_kwh_method: ['', []],
    }),
    baseloadSettings: this.fb.group({
      auto_baseload: [true, [Validators.required]],
      baseload: [null, [Validators.min(0)]],
    }),
  });

  @Input() meterForm: FormGroup;

  constructor(fb: FormBuilder) {
    super(fb);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const baseloadControl = this.sectionFormGroup.get('baseloadSettings.baseload');
    this.baseloadError$ = baseloadControl.statusChanges.pipe(
      map(() => {
        const errors = baseloadControl.errors;
        if (!errors) {
          return null;
        }
        if (errors.required) {
          return 'This is field is required';
        }
        if (errors.min) {
          return 'Please enter a valid value';
        }
      }),
      shareReplay(1),
    );

    const predictedKwhValidators = [Validators.required];
    const calculateWaste = this.sectionFormGroup.get('wasteSettings.calculateWaste');
    const predictedKwhMethodControl = this.sectionFormGroup.get('wasteSettings.predicted_kwh_method');
    this.subscriptionsSink.add(
      calculateWaste.valueChanges.pipe(
        tap(v => {
          const validators = v ? predictedKwhValidators : [];
          predictedKwhMethodControl.setValidators(validators);
          predictedKwhMethodControl.updateValueAndValidity();
        }),
      ).subscribe(),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptionsSink.unsubscribe();
  }

  bodyData(): { [p: string]: any } {
    const fGValue = this.sectionFormGroup.value;
    const {baseload, auto_baseload} = fGValue.baseloadSettings;
    const {calculateWaste, predicted_kwh_method} = fGValue.wasteSettings;
    return {
      baseload,
      auto_baseload,
      no_waste: !calculateWaste,
      predicted_kwh_method: calculateWaste ? predicted_kwh_method : null,
    };
  }
}
