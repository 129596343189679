import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material';
import { LoadingContentComponent } from './loading-content/loading-content.component';

@NgModule({
  declarations: [LoadingContentComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
  exports: [LoadingContentComponent]
})
export class LoadingContentModule { }
