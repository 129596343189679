import { Directive, OnInit, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { PermissionChecker } from './permission-guard.directive';

@Directive({
  selector: '[exaPermissionButtonDisabled]'
})
export class PermissionButtonDisabledDirective implements OnInit, OnDestroy {

  permChecker: PermissionChecker;
  userSub: Subscription;

  @Input('exaPermissionButtonDisabled') actionName: string;
  @HostBinding('disabled') disabled: boolean;

  constructor(private auth: AuthService) {
  }

  ngOnInit(): void {
    this.permChecker = new PermissionChecker(this.actionName);
    this.userSub = this.auth.loggedInUser$.pipe(
      map(user => this.permChecker.checkUserPermission(user)),
      tap(hasPermission => this.disabled = !hasPermission),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

}
