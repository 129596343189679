import { FormControl, Validators, FormGroup } from '@angular/forms';

export const nameLengthBoundaries = {
  min: 3,
  max: 70,
};

export function createBasicInfo() {
  return new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.minLength(nameLengthBoundaries.min), Validators.maxLength(nameLengthBoundaries.max)]),
    utilityCompany: new FormControl(null, [Validators.required]),
    month: new FormControl(null, [Validators.required]),
    building: new FormControl('', [Validators.required]),
    meters: new FormControl([]),
    serviceStartDate: new FormControl(null),
    serviceEndDate: new FormControl(null),
  });
}

