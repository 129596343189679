import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { LoadingContentModule } from '../../widgets/loading-content/loading-content.module';
import { VirtualMeterEquationComponent } from './virtual-meter-equation/virtual-meter-equation.component';
import { VirtualMeterSummarySectionComponent } from './virtual-meter-summary-section/virtual-meter-summary-section.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    MatButtonModule,
    LoadingContentModule,
    SharedModule,
  ],
    exports: [
        VirtualMeterSummarySectionComponent,
    ],
    declarations: [VirtualMeterEquationComponent, VirtualMeterSummarySectionComponent],
})
export class VirtualMeterSummarySectionModule { }
