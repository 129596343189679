import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../shared/shared.module';
import { WeatherBoundariesComponent } from './weather-boundaries.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedModule,
  ],
  declarations: [WeatherBoundariesComponent],
  exports: [WeatherBoundariesComponent]
})
export class WeatherBoundariesModule { }
