import moment from 'moment';

export function toUtcMoment(momentInput: moment.MomentInput) {
  return moment.utc(momentInput);
}

export function toUtcDate(momentInput: moment.MomentInput) {
  return toUtcMoment(momentInput).toDate();
}

export function toUtcDateValue(momentInput: moment.MomentInput) {
  return toUtcDate(momentInput).valueOf();
}
