import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { tap, catchError, filter, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Meter } from '../../shared/models/meter.model';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import {
  UtilityBillsUpsertModalComponent,
  BillUpsertModalData,
} from '../utility-bills-upsert/utility-bills-upsert-modal/utility-bills-upsert-modal.component';
import { UtilityBillsResponse } from './utility-bills.models';
import { BillType, UtilsBillDefinition, UTILITY_BILLS_DEFS } from './utils-bills.definitions';

interface BillSection {
  id: BillType;
  def: UtilsBillDefinition;
  bills$: BehaviorSubject<UtilityBillsResponse[]>;
  loadingStatus: SectionLoadingStates;
}

export interface BillUpdateDialogConfig {
  month: number;
  year: number;
  meter
}

@Injectable()
export class UtilityBillsDataService {

  meterYearBillsStates = new SectionLoadingStates();
  yearBills$ = new BehaviorSubject<UtilityBillsResponse[]>(null);
  lastYearlyQuery$ = new BehaviorSubject<{meter: Meter; year: number;}>(null);
  billSections: BillSection[] = UTILITY_BILLS_DEFS.map(def => ({
    id: def.id,
    def: def,
    bills$: new BehaviorSubject(null),
    loadingStatus: new SectionLoadingStates(),
  }));

  constructor(private http: HttpClient, private dialog: MatDialog) {
  }

  getMeterYearBills(meter: Meter, year: number) {
    this.lastYearlyQuery$.next({meter, year});
    this.setLoadingForAllSections();
    return (this.http.get(`${environment.apiBase}/utility-bills`, {
      params: {year: year.toString(), utility_meter_id: meter.id},
    }) as Observable<UtilityBillsResponse[]>).pipe(
      tap(() => this.resetLoadingForAllSections()),
      tap(data => this.yearBills$.next(data)),
      tap(data => this.updateSectionsBills(data)),
      catchError((err) => {
        this.setErrorForAllSections();
        return EMPTY;
      }),
    );
  }

  getCategorizedMeterYearBill(meter: Meter, year: number, billType: BillType) {
    const specifiedSection = this.billSections.find(s => s.id === billType);
    specifiedSection.loadingStatus.setLoadingState();
    return (this.http.get(`${environment.apiBase}/utility-bills`, {
      params: {year: year.toString(), utility_meter_id: meter.id, bill_type: billType},
    }) as Observable<UtilityBillsResponse[]>).pipe(
      //TODO Abdalla 08 Mar 2020 : remove this after it handled in the BE
      map(bills => bills.filter(b => b.bill_type === billType)),
      tap(data => specifiedSection.bills$.next(data)),
      tap(() => specifiedSection.loadingStatus.resetLoadingState()),
      catchError(err => {
        specifiedSection.loadingStatus.setErrorState();
        return err;
      }),
    );
  }

  openBillUpdateDialog(config: BillUpsertModalData = <BillUpsertModalData>{}, toUpdateBill: UtilityBillsResponse) {
    const {utility_meter, bill_type, month, year} = toUpdateBill || <UtilityBillsResponse>{};
    return this.dialog.open(UtilityBillsUpsertModalComponent, {
      data: <BillUpsertModalData>{
        bill: toUpdateBill,
        billType: bill_type,
        utilityMeter: utility_meter,
        month,
        year,
        ...config,
      },
    });
  }

  clearTheState() {
    this.meterYearBillsStates = new SectionLoadingStates();
    this.yearBills$.next(null);
    this.lastYearlyQuery$.next(null);
    this.billSections.forEach(b => {
      b.bills$.next(null);
      b.loadingStatus = new SectionLoadingStates();
    });
  }

  private setLoadingForAllSections() {
    this.meterYearBillsStates.setLoadingState();
    this.billSections.forEach(s => s.loadingStatus.setLoadingState());
  }

  private resetLoadingForAllSections() {
    this.meterYearBillsStates.resetLoadingState();
    this.billSections.forEach(s => s.loadingStatus.resetLoadingState());
  }

  private setErrorForAllSections() {
    this.meterYearBillsStates.setErrorState();
    this.billSections.forEach(s => s.loadingStatus.setErrorState());
  }

  private updateSectionsBills(bills: UtilityBillsResponse[]) {
    this.billSections.forEach((section) => {
      const sectionBills = bills.filter(b => b.bill_type === section.id);
      section.bills$.next(sectionBills);
    });
  }
}
