import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OperationScheduleProfiles } from '../constants';
import {
  OperationsScheduleResponse,
  OperationsScheduleResponseData,
  WeaklyHoursProfileSchedule,
  DaysScheduleResponse,
  WeaklyHoursScheduleData,
  DaySchedule,
} from '../shared/models/operation-schedule/models';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class OperationScheduleDataService {
  baseUrl = `${environment.apiBase}/schedule`;
  apiBase = environment.apiBase;

  constructor(private http: HttpClient, private data: DataService) {
  }

  getBuildingSchedule(buildingId: string) {
    return this.data.buildings.details(buildingId).pipe(
      switchMap(building => this.getSchedule(building.operation_schedule)),
    );
  }

  getSchedule(scheduleId: string) {
    return (this.http.get(`${this.apiBase}/buildings/schedules/detail/${scheduleId}`) as Observable<OperationsScheduleResponse>).pipe(
      map(schedule => ({
        ...schedule,
        mappedData: OperationScheduleDataService.mapProfilesSchedules(schedule.data),
      })),
    );
  }

  static mapProfilesSchedules(scheduleData: OperationsScheduleResponseData) {
    return Object.values(OperationScheduleProfiles).map(profile => {
      const profileSchedule = scheduleData[profile.id];
      return {
        profile,
        scheduleData: profileSchedule && OperationScheduleDataService.mapDaysSchedule(profileSchedule) || {},
      };
    });
  }

  static mapDaysSchedule(daysSchedule: DaysScheduleResponse) {
    return Object.entries(daysSchedule).reduce((accSchedule, [dayIndex, daySchedule]) => {
      const mappedSchedule = daySchedule
        .map(hourQuarter => ({
          hour: parseInt(hourQuarter.slice(0, 2)),
          min: parseInt(hourQuarter.slice(2)),
        }))
      ;
      return {
        ...accSchedule,
        [dayIndex]: mappedSchedule,
      };
    }, {});
  }

  static mapDaysScheduleToResponseFormat(daysSchedule: WeaklyHoursScheduleData) {
    return Object.entries(daysSchedule).reduce((accSchedule, [dayIndex, daySchedule]) => {
      const mappedDaySchedule = daySchedule.map(({hour, min}) => hour.toString().padStart(2, '0') + min.toString().padStart(2, '0'));
      return {
        ...accSchedule,
        [dayIndex]: mappedDaySchedule,
      };
    }, {});
  }

  updateSchedule(scheduleId: string, schedules: WeaklyHoursProfileSchedule[]) {
    const mappedSchedule = schedules.reduce((accSchedule, profileSchedule) => {
      return {
        ...accSchedule,
        [profileSchedule.profile.id]: OperationScheduleDataService.mapDaysScheduleToResponseFormat(profileSchedule.scheduleData),
      };
    }, {});
    return this.http.put(`${this.apiBase}/buildings/schedules/setup`, {
      data: mappedSchedule,
      Id: scheduleId
    });
  }

  getSchedulesPeriods(schedules: WeaklyHoursProfileSchedule[]) {

    const calculateNextQuarter = ({hour, min}: { hour: number, min: number }) => {
      const m = moment().startOf('d').hours(hour).minute(min).add(15, 'm');
      return {
        hour: m.hour(),
        min: m.minute(),
      };
/*
      return {
        hour: min < 45 ? hour : hour < 23 ? hour + 1 : 0,
        min: min < 45 ? min + 15 : 0,
      };
*/
    };

    const prevQuarter = ({hour, min}: { hour: number, min: number }) => {
      const m = moment().startOf('d').hours(hour).minute(min).subtract(15, 'm');
      return {
        hour: m.hour(),
        min: m.minute(),
      };
    };

    const isQuarterExist = (quarters: DaySchedule, {hour, min}: { hour: number, min: number }) => {
      return !!quarters.find(q => q.hour == hour && q.min == min);
    };

    const isSameQuarter = (a: { hour: number, min: number }, b: { hour: number, min: number }) => {
      return a.hour == b.hour && a.min == b.min;
    };

    const extractRanges = (quarters: DaySchedule) => {
      let toBeProcessedQuarters = quarters.sort((a, b) => {
        if (a.hour == b.hour) {

        }
        return (a.hour - b.hour) * 1000 + (a.min - b.min);
      });
      let ranges = [];
      let processedQuarter = toBeProcessedQuarters[0];
      toBeProcessedQuarters = toBeProcessedQuarters.slice(1);
      while (processedQuarter) {
        let nextQuarter = calculateNextQuarter(processedQuarter);
        while (toBeProcessedQuarters.length && isSameQuarter(nextQuarter, toBeProcessedQuarters[0])) {
          nextQuarter = calculateNextQuarter(nextQuarter);
          toBeProcessedQuarters = toBeProcessedQuarters.slice(1);
        }
        const endTime = moment().startOf('d').hour(nextQuarter.hour).minute(nextQuarter.min).subtract(1, 'm');
        ranges.push({
          start: {...processedQuarter},
          end: {
            hour: endTime.hour(),
            min: endTime.minute(),
          },
        });

        if (toBeProcessedQuarters.length) {
          processedQuarter = toBeProcessedQuarters[0];
          toBeProcessedQuarters = toBeProcessedQuarters.slice(1);
        } else {
          processedQuarter = null;
        }
      }

      return ranges;
    };

    return schedules.map(profileSchedule => {
      const mappedSchedule = Object.entries(profileSchedule.scheduleData)
        .reduce((accSchedule, [dayIndex, quarters]) => {
          return {
            ...accSchedule,
            [dayIndex]: extractRanges(quarters),
          };
        }, {});
      return {
        ...profileSchedule,
        ranges: mappedSchedule,
      };
    });
  }
}
