import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data/data.service';
import { Observable, throwError, BehaviorSubject, Subscribable, Subscription } from 'rxjs';
import { Customer } from '../../shared/models/customer.model';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getLookupValidator } from '../../shared/components/lookup-dropdown.component';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

const phoneNumberRegex = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';

@Component({
  selector: 'exa-customers-users-create',
  templateUrl: './customers-users-create.component.html',
  styleUrls: ['./customers-users-create.component.scss']
})
export class CustomersUsersCreateComponent implements OnInit {
  customersIsLoading$ = new BehaviorSubject(false);
  groupLoading$ = new BehaviorSubject(false);
  groupError = false;
  userGroups$: Observable<any>;
  loadingError = false;
  customer$: Observable<Customer>;
  search$ = new BehaviorSubject('');
  customers$: Observable<Customer[]>;
  userForm: FormGroup;
  showPassword = false;
  submitting = false;
  submittingError = null;
  submittingSub: Subscription;
  labelsMap = {
    customer_id: 'Customer',
    'user.email': 'User Email Address',
    'user.password': 'User Password',
    'user.group': 'User Group',
    first_name: 'User first name',
    last_name: 'User last name',
  };

  constructor(
    private data: DataService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.loadCustomers();
    this.loadGroups();
    this.userForm = this.fb.group({
      customer: ['', [Validators.required, getLookupValidator('id')]],
      user: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        group: ['', [Validators.required, getLookupValidator('id')]],
      }),
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address: [''],
      phone_number: ['', [Validators.pattern(phoneNumberRegex)]],
    });
  }


  loadCustomers() {
    this.customersIsLoading$.next(true);
    this.loadingError = false;
    this.customers$ = this.data.customers.list()
      .pipe(
        tap((res) => {
          const params = this.route.snapshot.queryParams || {};
          const id = params.customer;
          const control = this.userForm.get('customer');
          if (!control) { return; }
          control.setValue(res.find(customer => customer.id === id) || '');
        }),
        finalize(() => this.customersIsLoading$.next(false)),
        catchError((err) => {
          this.loadingError = true;
          this.customersIsLoading$.next(false);
          return throwError(err);
        }),
      );
  }

  loadGroups() {
    this.groupLoading$.next(true);
    this.groupError = false;
    this.userGroups$ = this.data.users.getGroups()
      .pipe(
        finalize(() => this.groupLoading$.next(false)),
        catchError((err) => {
          this.loadingError = true;
          this.groupLoading$.next(false);
          return throwError(err);
        }),
      );
  }

  onSubmit() {
    const form = _.cloneDeep(this.userForm.value);
    form.customer_id = form.customer.id;
    form.user.group = [form.user.group.id];
    delete form.customer;

    this.submitting = true;
    this.submittingError = null;

    this.submittingSub = this.data.customers.createUser(form)
      .pipe(
        finalize(() => this.submitting = false),
        catchError(err => {
          this.submittingError = err && err.error;
          return throwError(err);
        })
      ).subscribe(() => {
        this.submittingSub.unsubscribe();
        this.router.navigate(['/customers/users']);
      });
  }
}
