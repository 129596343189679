import {
  OnInit,
  Input,
  OnDestroy,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, Subject, merge, EMPTY, zip, Subscription } from 'rxjs';
import { filter, switchMap, tap, catchError, map, startWith, shareReplay, delay } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { METER_SERVICES, MediaTypeMeterBaseService, MeterDef } from '../../data/meters/media-type-meter-base.service';
import { MeterMediaType } from '../../data/meters/models/media-types.models';
import { MeterTypes } from '../../data/meters/models/meters-defs.constants';
import { Building } from '../../shared/models/building.model';
import { Customer } from '../../shared/models/customer.model';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import { ElectricalMeterCreateSectionComponent } from './sections/electrical-meter-create-section/electrical-meter-create-section.component';
import { MeterMediaTypeSectionBase } from './sections/meter-media-type-section-base';
import { AllOrSearchConfig } from '../../shared/components/all-or-search-filter/all-or-search-filter.component';
import { onlyBuildingFilterBasicConfig } from '../../shared/components/all-or-search-filter/all-or-search-filter.constants';

const MeterTypeCompConfigs: { meterType: MeterMediaType; comp: MeterMediaTypeSectionBase; }[] = [
  { meterType: 'electrical', comp: <MeterMediaTypeSectionBase><any>ElectricalMeterCreateSectionComponent },
  { meterType: 'water', comp: null },
];

const MeterTypesDefs = Object.values(MeterTypes)
  .map(meterDef => {
    const config = MeterTypeCompConfigs.find(config => config.meterType === meterDef.type);
    return {
      ...meterDef,
      ...config,
    };
  });

export interface MeterTypeDef extends MeterDef {
  comp: MeterMediaTypeSectionBase;
  listLink: string;
}

interface LookupEntry {
  [key: string]: string;
}

interface BasicData {
  lookups: {
    wnm_lookup: LookupEntry[];
    idr_source_lookup: LookupEntry[];
  };
}

export class MeterCreateComponentBase implements OnInit, OnDestroy {
  createForm: FormGroup;
  meterTypesDefs = MeterTypesDefs;
  reloadBasicData$ = new Subject();
  basicDataLoadingStates = new SectionLoadingStates();
  customerSub$ = new BehaviorSubject<Customer>(null);
  basicData: BasicData;
  buildingsInputConfig: AllOrSearchConfig<Building>;
  customer$ = this.customerSub$.asObservable().pipe(
    filter(c => Boolean(c)),
  );

  basicLabelsMap = {
    name: 'Meter Name',
    building_id: 'Building Name',
    idr_source: 'IDR Source',
    wnm: 'Weather Normalization Method',
    served_area: 'Served Area',
    predicted_kwh_method: 'kWh Prediction Method',
    baseload: 'Baseload Value',
  };

  customLabelsMap = {};

  labelsMap = {};

  createMeter$ = new Subject();

  subscriptionsSink = new Subscription();

  createMeterStates = new SectionLoadingStates();
  meterCreation$ = this.createMeter$.pipe(
    tap(() => this.createMeterStates.setLoadingState()),
    switchMap(
      () => this.createMeter().pipe(
        catchError(err => {
          this.createMeterStates.setErrorState(err);
          return EMPTY;
        }),
      ),
    ),
    tap(() => this.createMeterStates.resetLoadingState()),
    tap((v) => {
      this.toastr.success('Meter is created successfully');
      this.router.navigate([v.meterType.listLink]);
    }),
  );

  meterTypeSettingsVcr$ = new BehaviorSubject<ViewContainerRef>(null);
  meterTypeSettingsCompRef$ = new BehaviorSubject<ComponentRef<MeterMediaTypeSectionBase>>(null);

 

  @Input()
  set customer(customer: Customer) {
    this.customerSub$.next(customer);
  }

  @ViewChild('meterTypeSettings', { read: ViewContainerRef })
  set meterTypeSettings(vcr: ViewContainerRef) {
    this.meterTypeSettingsVcr$.next(vcr);
  }

  trackMeterTypesBy = (option: { meterType: MeterMediaType }) => option.meterType;

  constructor(
    private resolver: ComponentFactoryResolver,
    protected fb: FormBuilder,
    private router: Router,
    protected toastr: ToastrService,
    protected data: DataService,
    @Inject(METER_SERVICES) protected meterServices: MediaTypeMeterBaseService[],
  ) {

  }

  ngOnInit(): void {
    this.basicData = { lookups: { idr_source_lookup: [{ "csv": "CSV Upload" }], wnm_lookup: [{ "default": "Default Method" }] } };
    this.buildingsInputConfig = {
      ...onlyBuildingFilterBasicConfig,
      valueOnChangeExtractorFn: building => building && building.id,
      searchItems: (name) => this.data.buildings.lookup(typeof name == "object" ? name : { name: name || '' }).pipe(map((res: any) => {
        return res.results.map(res => {
          return { id: res.id, name: res.name }
        });
      },
      )),
    };
    this.createForm = this.fb.group({
      basicInfo: this.fb.group({
        meterType: [this.meterTypesDefs[0], [Validators.required]],
        name: ['', [Validators.required]],
        served_area: ['', [Validators.required]],
        area: ['', [Validators.required]],
        building_id: [null, [Validators.required]],
        idr_source: [null, [Validators.required]],
        weather_normalization_method: [null, [Validators.required]],
      }),
    });

    this.subscriptionsSink.add(this.meterCreation$.subscribe());

    const meterType$ = this.createForm.get(['basicInfo', 'meterType']).valueChanges;

    const meterTypeChanges$ = this.meterTypeSettingsVcr$.pipe(
      filter(v => Boolean(v)),
      switchMap(
        vcr => meterType$.pipe(
          startWith(this.createForm.value.basicInfo.meterType),
          delay(0),
          tap(v => this.onMeterTypeUpdated(v, vcr)),
        ),
      ),
    );

    this.subscriptionsSink.add(meterTypeChanges$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptionsSink.unsubscribe();
  }

  protected onMeterTypeUpdated(meterType: MeterTypeDef, vcr: ViewContainerRef) {
    vcr.clear();
    if (!meterType.comp)
      return;
    const compFactory = this.resolver.resolveComponentFactory(<any>meterType.comp);
    const componentRef = vcr.createComponent(compFactory);
    (<MeterMediaTypeSectionBase>componentRef.instance).meterForm = this.createForm;
    this.meterTypeSettingsCompRef$.next(<any>componentRef);
  }

  protected generateBasicBodyData() {
    return this.createForm.value.basicInfo;
  }

  createMeter() {
    const { basicInfo } = this.createForm.value;
    const meterType = <MeterTypeDef>basicInfo.meterType;
    const meterTypeCompInstance = meterType.comp ? this.meterTypeSettingsCompRef$.value.instance : null;
    const meterTypeSettings = meterTypeCompInstance ? meterTypeCompInstance.bodyData() : {};
    const body = {
      ...this.generateBasicBodyData(),
      ...meterTypeSettings,
    };
    this.labelsMap = {
      ...this.basicLabelsMap,
      ...this.customLabelsMap,
      ...(meterTypeCompInstance ? meterTypeCompInstance.labelsMap() : {}),
    };
    const meterService = this.meterServices.find(s => s.mediaType === meterType.type);
    return meterService.create(body).pipe(
      map(response => ({
        response,
        meterType,
      }))
    );
  }
}
