import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherComparisonModule } from '../features/weather-comparison/weather-comparison.module';
import { WeatherKwhHeatmapSectionModule } from '../features/weather-kwh-heatmap-section/weather-kwh-heatmap-section.module';
import { WeatherKwhSectionModule } from '../features/weather-kwh-section/weather-kwh-section.module';

import { WeatherRoutingModule } from './weather-routing.module';
import { WeatherMainPageComponent } from './weather-main-page/weather-main-page.component';
import { WeatherComparisonComponent } from './weather-comparison/weather-comparison.component';
import { WeatherKwhComponent } from './weather-kwh/weather-kwh.component';
import { SharedModule } from '../shared/shared.module';
import { WeatherKwhHeatmapPageComponent } from './weather-kwh-heatmap-page/weather-kwh-heatmap-page.component';

@NgModule({
  imports: [
    CommonModule,
    WeatherRoutingModule,
    SharedModule,
    WeatherComparisonModule,
    WeatherKwhSectionModule,
    WeatherKwhHeatmapSectionModule,
  ],
  declarations: [WeatherMainPageComponent, WeatherComparisonComponent, WeatherKwhComponent, WeatherKwhHeatmapPageComponent]
})
export class WeatherModule { }
