import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { map, switchMap, tap, catchError, share, finalize } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { throwError, Subscription } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';

interface BaseLineChoice {
  id: string;
  name: string;
  offset: number;
}

const bestPracticeBaseline = {id: 'best-practice', name: 'Best Practice'};

@Component({
  selector: 'exa-criteria-create',
  templateUrl: './criteria-create.component.html',
  styles: []
})
export class CriteriaCreateComponent {
  constructor(private data: DataService) { }

  saveCallback(data, criteria, customer) {
    return this.data.criterias.create({ ...data });
  }
}
