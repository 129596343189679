import { Component, OnInit, Input, ApplicationRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription, combineLatest, EMPTY, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, tap, catchError, finalize, map } from 'rxjs/operators';
import { predictedKwhMethods } from '../../constants';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { ElectricalMeterService } from '../../data/meters/electrical-meter.service';
import { Building } from '../../shared/models/building.model';
import {multiMetersFilterBasicConfig} from '../../shared/components/all-or-search-filter/all-or-search-filter.constants';


@Component({
  selector: 'exa-criteria-form',
  templateUrl: './criteria-form.component.html',
  styles: []
})
export class CriteriaFormComponent implements OnInit {
  @Input() saveCallback: Function;
  @Input() criteria: any;
  @Input() redirectTo: string[];
  @Input() disabledKeys: string[] = [];
  loading = false;
  loadingError = false;
  createLoading = false;
  createSub: Subscription;
  times: any = {};
  customer$ = this.customers.selected$;

  metersInputConfig = {
    searchItems: this.metersSearchFn.bind(this),
    ...multiMetersFilterBasicConfig,
  };


  get isTimesSet() {
    return Object.keys(this.times).length;
  }

  error: any;

  labelsMap = {
    name: 'Criteria name',
    description: 'Criteria description',
    effective_date: 'Criteria effective date',
    meters: 'meters that criteria will be applied to',
    kwh_threshold: 'Waste',
    days_count_threshold: 'Period',
    modeling_method: 'Modeling Method',
    customer_id: 'Customer'
  };

  criteriaForm: FormGroup;

  modelingMethodChoices: {id: string, name: string}[] = [{id: null, name: 'All'}].concat(Object.values(predictedKwhMethods));

  constructor(
      private customers: CustomersService,
      private data: DataService,
      private metersSvc: ElectricalMeterService,
      private fb: FormBuilder,
      private router: Router,
      private appRef: ApplicationRef,
    ) {
  }


  ngOnInit() {
    const defaultModelingMethodId = (this.criteria && this.criteria.modeling_method) || null;
    const defaultModelingMethod = this.modelingMethodChoices.find(c => c.id === defaultModelingMethodId);

    this.criteriaForm = this.fb.group({
      name: [
        { value: this.getDefaultValue('name'), disabled: this.disabledKeys.includes('name') },
        [Validators.required]
      ],

      description: [
        {
          value: this.getDefaultValue('description'),
          disabled: this.disabledKeys.includes('description'),
        },
        [Validators.required, Validators.maxLength(300)]
      ],
      effective_date: [
        {
          value: this.getDefaultValue('effective_date'),
          disabled: this.disabledKeys.includes('effective_date'),
        },
        [Validators.required]
      ],
      meters: [null],
      days_count_threshold: [
        {
          value: this.getDefaultValue('days_count_threshold'),
          disabled: this.disabledKeys.includes('days_count_threshold'),
        },
        [Validators.required, Validators.max(7), Validators.min(1)]],
      kwh_threshold: [
        {
          value: this.getDefaultValue('kwh_threshold'),
          disabled: this.disabledKeys.includes('kwh_threshold'),
        },
        [Validators.required, Validators.min(0)]],
      baseline: [
        {
          value: defaultModelingMethod,
          disabled: this.disabledKeys.includes('baseline'),
        },
        [Validators.required]
      ],
    });

    const baselineControl = this.criteriaForm.get('baseline');
    const metersControl = this.criteriaForm.get('meters');
    const baselineControlSubscription = baselineControl.valueChanges
      .pipe(
        tap(() => {
          metersControl.reset(null);
        }),
      )
      .subscribe();
  }

  onSelectTimes(times) {
    this.times = times;
  }

  onSubmit() {
    const formValue = this.criteriaForm.value;

    const data = Object.assign(
      {},
      formValue, {
        effective_date: moment(formValue.effective_date).format('YYYY-MM-DD'),
        selected_hours: this.times,
        meters: formValue.meters != null ? formValue.meters.filter(i => i.id !== 'all' && !i.isGroup).map(i => i.id) : null,
        modeling_method: formValue.baseline.id,
      },
    );

    this.createLoading = true;
    this.error = null;

    this.createSub = this.saveCallback(data, this.getDefaultValue('id'), this.customers.selected)
      .pipe(finalize(() => this.createLoading = false))
      .subscribe(
        (res) => {
          if (this.redirectTo) {
            this.router.navigate(this.redirectTo);
          }
          this.createSub.unsubscribe();
        },
        (err) => {
          this.error = err.error;
        }
      );
  }

  metersSearchFn(name: string) {
    const {id} = this.criteriaForm.value.baseline;
    const baselineParams = !id ? null : {
      predicted_kwh_method__icontains: id
    };
    
    if(name == null){
      return []; 
    }

    return this.metersSvc.filterByName(name, null, baselineParams);
  }

  private getDefaultValue(key, type = 'string') {
    const criteria = this.criteria || {};

    if (type === 'string') {
      return criteria[key] || '';
    }

    return criteria[key] || { id: '', name: '' };
  }
}

