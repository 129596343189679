import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap, catchError, delay } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { OperationScheduleDataService } from '../../data/operation-schedule-data.service';
import { Building } from '../../shared/models/building.model';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { IDeleteConfirmationData, DeleteConfirmationDialogComponent } from '../../shared/components/delete-confirmation-dialog.component';
import { AuthService } from '../../auth/auth.service';
import { Roles } from '../../auth/user-group-guard.provider';
import { CustomersService } from '../../customers/customers.service';
import { OperationsScheduleResponse } from '../../shared/models/operation-schedule/models';
import { actionsDef } from '../../shared/permissions/permissions-actions';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import { Router } from '@angular/router';

import {
  BuildingOperationScheduleEditComponent,
  WeaklyHoursScheduleEditData,
} from '../building-operation-schedule-edit/building-operation-schedule-edit/building-operation-schedule-edit.component';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'exa-buildings-details',
  templateUrl: './buildings-details.component.html',
  styleUrls: ['./buildings-details.component.scss']
})
export class BuildingsDetailsComponent implements OnInit {

  isAdmin = false;
  loading = false;
  loadingError = false;
  building: Building;
  building$: Observable<Building>;
  buildingFound = true;
  createdAt: any;
  meters$ = new BehaviorSubject([]);
  customer$ = this.customers.selected$;
  notes$ = new BehaviorSubject([]);
  attachments$ = new BehaviorSubject([]);
  operationScheduleId$ = new BehaviorSubject<string>(null);
  operationSchedule$: Observable<any>;
  operationScheduleSection = new SectionLoadingStates();
  permActionDefs = actionsDef;


  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private operationScheduleDataSvc: OperationScheduleDataService,
    private dialog: MatDialog,
    private auth: AuthService,
    private customers: CustomersService,
    private router: Router
  ) { }

  ngOnInit() {
    const user = this.auth.getUser() || { group: [] };
    this.isAdmin = user.group.indexOf(Roles.superuser) > -1;
    this.loadBuilding();
    this.operationSchedule$ = this.operationScheduleId$.pipe(
      filter(id => !!id),
      delay(0),
      switchMap(id => this.loadOperationSchedule(id)),
    );
  }

  loadBuilding() {
    this.loading = true;
    this.loadingError = false;
    this.building$ = this.route.params.pipe(
      switchMap((params) => this.data.buildings.details(params.id)),
      tap(() => this.loading = false),
      tap(building => this.building = <Building>building),
      tap((res: any) => this.meters$.next(res.meters || [])),
      tap((res: any) => this.notes$.next(res.notes || [])),
      tap((res: any) => this.attachments$.next(res.attachments || [])),
      tap(res => this.operationScheduleId$.next(res.operation_schedule)),
      catchError((err) => {
        if (err.status === 404) {
          this.buildingFound = false;
        } else {
          this.loadingError = true;
        }
        return throwError(err);
      }),
    );
  }

  onDeleteBuilding(building) {
    return this.data.buildings.delete(building.id);
  }

  onClickDelete(building, event) {
    const dialogData: IDeleteConfirmationData = {
      entity: building,
      deleteRequest: this.onDeleteBuilding.bind(this),
      title: `Delete "${building.name}" Building`,
      body: `Confirm deleting this Building`,
      success: `Building with name ${building.name} has been deleted successfully`,
      failure: `failed to delete building with name ${building.name}. please try again`,
      route: ['/buildings'],
    };

    this.dialog.open(DeleteConfirmationDialogComponent, {
      data: dialogData,
      minWidth: '30%',
    });

    event.stopPropagation();
  }


  onNewNote(note) {
    const notes = this.notes$.value || [];
    this.notes$.next([note].concat(notes));
  }

  onDeleteNote(id) {
    this.notes$.next(this.notes$.value.filter(i => i.id !== id));
  }

  onNewAttachment(attachment) {
    const attachments = this.attachments$.value;
    this.attachments$.next([attachment, ...attachments]);
  }


  onDeleteAttachment(id) {
    this.attachments$.next(
      this.attachments$.value.filter(i => i.key !== id)
    );
  }

  loadOperationSchedule(scheduleId: string) {
    const section = this.operationScheduleSection;
    section.setLoadingState();
    return this.operationScheduleDataSvc.getSchedule(scheduleId).pipe(
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState();
        return EMPTY;
      }),
    );
  }

  createMeter() {
    this.router.navigate(['meters/create']);
  }

  goToMeter(meter){
    this.router.navigate([`meters/electrical/${meter.id}`]);
  }

  editBuilding(building, event) {
    this.router.navigate(['buildings/edit', building.id]);
    event.stopPropagation();
  }

  editOperationSchedule() {
    const editDialogRef = this.dialog.open(BuildingOperationScheduleEditComponent, {
      data: <WeaklyHoursScheduleEditData>{ building: this.building },
      disableClose: true,
      minWidth: 700,
    });

    editDialogRef.afterClosed().subscribe(updated => {
      if (updated) {
        this.operationScheduleId$.next(this.building.operation_schedule);
      }
    })
  }
}
