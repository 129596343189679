import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

export function createServiceSection(service: UtilityBillsLibraryModule.Service, initialData?: { [fieldId: string]: any;}) {
  let fieldsControls = [];
  if (initialData) {
    const initialDataFieldsControls = Object.entries(initialData)
      .map(([fieldId, val]) => {
        const fieldDef = Object.values(service.allowedFields).find(f => f.id === fieldId);
        return createFieldFormGroup(fieldDef, val);
      });
    fieldsControls = [].concat(initialDataFieldsControls);
  } else {
    const basicFields = Object.values(service.allowedFields).filter(f => !!f.basic);
    fieldsControls = [].concat(
      basicFields.map(f => createFieldFormGroup(f)),
    );
  }

  return new FormGroup({
    service: new FormControl(service),
    fields: new FormArray(fieldsControls, [Validators.required]),
  })
}

export function createFieldFormGroup(field: UtilityBillsLibraryModule.Field, initialValue = null) {
  return new FormGroup({
    field: new FormControl(field),
    value: new FormControl(initialValue, [Validators.required]),
  });
}
