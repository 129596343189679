import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../../shared/models/customer.model';
import { actionsDef } from '../../../shared/permissions/permissions-actions';

@Component({
  selector: 'exa-meters-integration',
  templateUrl: './meters-integration.component.html',
  styleUrls: ['./meters-integration.component.scss']
})
export class MetersIntegrationComponent implements OnInit {

  actions = actionsDef;

  @Input() customer: Customer;

  constructor() { }

  ngOnInit() {
  }

}
