import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WindowRefService } from '../../shared/services/window-ref.service';
import { BehaviorSubject, throwError, Observable } from 'rxjs';


interface GMapLoaderOptions {
  key: string;
  language: string;
  libraries: string[];
  region: string;
}

interface WindowWithGoogle extends Window {
  google?: any;
}

const GMapSrcDefaults: GMapLoaderOptions = {
  key: '',
  language: 'en',
  region: 'us',
  libraries: ['visualization'],
};

@Injectable({
  providedIn: 'root'
})
export class GoogleMapLoaderService {
  mapPromise: Promise<any>;
  error: any;
  protected loadingSub$ = new BehaviorSubject(false);
  loading$ = this.loadingSub$.asObservable();
  protected googleSub$ = new BehaviorSubject(null);
  google$ = this.googleSub$.asObservable();
  protected $scriptEl: HTMLScriptElement;
  protected scriptId = 'google-maps-api';
  private host = 'https://maps.googleapis.com/maps/api/js';
  private $document: Document;
  private $window: WindowWithGoogle;
  private config: GMapLoaderOptions = {
    ...GMapSrcDefaults,
    ...(environment.googleMap || {}),
   };

  constructor(public windowRef: WindowRefService) {
    this.$window = windowRef.nativeWindow;
    this.$document = windowRef.nativeDocument;
  }


  load(): Observable<any> {
    const { googleSub$, $document, $window } = this;
    let $script = $document.getElementById(this.scriptId) as HTMLScriptElement;

    if ($window.google && $window.google.maps) {
      if (!googleSub$.value) {
        googleSub$.next(this.$window.google);
      }
      return this.google$;
    }

    if ($script) {
      return this.google$;
    }

    $script = $document.createElement('script') as HTMLScriptElement;
    $script.async = true;
    $script.defer = true;
    $script.src = this.getScriptUrl();
    $script.id = this.scriptId;
    this.$scriptEl = $script;
    return this.injectScript();
  }

  private getScriptUrl(): string {
    const params = Object.keys(this.config)
      .reduce((par, key) => {
        if (Array.isArray(this.config[key])) {
          return `${par}&${key}=${this.config[key].join(',')}`;
        }
        return `${par}&${key}=${this.config[key]}`;
      }, '');
    return `${this.host}?${params}`;
  }


  private injectScript(): Observable<any> {
    const $body = this.$document.body;
    this.loadingSub$.next(true);

    this.$scriptEl.onload = () => {
      this.loadingSub$.next(false);
      this.googleSub$.next(this.$window.google);
    };

    this.$scriptEl.onerror = (err) => {
      this.loadingSub$.next(false);
      this.googleSub$.error(err);
    };

    $body.appendChild(this.$scriptEl);

    return this.google$;
  }
}
