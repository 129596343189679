import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material';
import { WeatherDailyHoursSummaryComponent } from './weather-daily-hours-summary.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    FlexLayoutModule,
  ],
  declarations: [WeatherDailyHoursSummaryComponent],
  exports: [WeatherDailyHoursSummaryComponent]
})
export class WeatherDailyHoursSummaryModule { }
