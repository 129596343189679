import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { CustomersService } from '../../../customers/customers.service';
import { MediaTypeMeterBaseService } from '../../../data/meters/media-type-meter-base.service';
import { SideNavService } from '../../../layout/side-nav.service';
import { ListBaseComponent } from '../../../shared/components/list-base/list-base.component';
import { ColWidths } from '../../../shared/components/list-base/list.constants';
import { operationsPipe } from '../../../shared/helpers/data.helpers';
import { Meter, PaginatedMeters } from '../../../shared/models/meter.model';


const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};


@Component({
  selector: 'exa-meters-list',
  templateUrl: './meters-list.component.html',
  styles: [],
})
export class MetersListComponent extends ListBaseComponent<Meter> implements OnInit, OnDestroy {
  filterModelMap: {[key: string]: string} = {
    name: 'name__icontains',
    'building.customer': 'building__company__name__icontains',
    'building.name': 'building__name__icontains',
    'building.address_line1': 'building__address_line1__icontains',
    'area': 'area',
    'active_utility_id.key': 'utility_ids__key__icontains',
    served_area: 'served_area__icontains',
  };

  displayedColumns: any[] = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: ColWidths.xLarge,
      filter: 'agTextColumnFilter',
      filterParams: {keep: true},
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },
    {
      headerName: 'Building',
      field: 'building.name',
      minWidth: ColWidths.xLarge,
      filter: 'agTextColumnFilter',
      ...customFilterOptions,
    },
    {
      headerName: 'Address',
      width: ColWidths.xLarge,
      minWidth: ColWidths.large,
      field: 'building.address_line1',
      filter: 'agTextColumnFilter',
      ...customFilterOptions,
    },
    {
      headerName: 'Area (sq.ft.)',
      field: 'area',
      minWidth: ColWidths.small,
      width: ColWidths.small,
      maxWidth: ColWidths.small,
      filter: 'agTextColumnFilter',
      ...customFilterOptions,
    },
    {
      headerName: 'Served Area',
      field: 'served_area',
      minWidth: ColWidths.large,
      filter: 'agTextColumnFilter',
      ...customFilterOptions,
    },
    {
      headerName: 'Utility ID',
      field: 'active_utility_id.key',
      minWidth: ColWidths.normal,
      maxWidth: ColWidths.normal,
      filter: 'agTextColumnFilter',
      ...customFilterOptions,
    },

    {
      headerName: '',
      field: 'action',
      cellRenderer: 'ListBaseActionsComponent',
    },
  ];

  customerSub: Subscription;

  get meterDetailsUrl(): string {
    throw Error('"meterDetailsUrl" getter is not implemented');
  }

  constructor(
      protected customerService: CustomersService,
      protected metersSvc: MediaTypeMeterBaseService,
      auth: AuthService,
      sidenav: SideNavService,
    ) {
    super(auth, sidenav);
  }

  ngOnInit() {
    // to make table actions have access to this component
    // methods
    super.ngOnInit();
    this.gridOptions.context = {
      componentParent: this,
    };

    this.customerSub = this.customerService.selected$.subscribe((customer) => {
      if (this.gridParams) {
        this.onGridReady(this.gridParams);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.customerSub) {
      this.customerSub.unsubscribe();
    }
  }


  getDataObservable(reqParams: any): Observable<any> {
    const mapNameLink = meters => meters.map(m => ({
      ...m,
      gridLinks: [...(m.gridLinks || []), {
        key: 'name', route: (data) => [this.meterDetailsUrl, data.id],
      }],
    }));
    const mapEditAction = meters => meters.map(m => ({
      ...m,
      gridActions: [...(m.gridActions || []), {
        type: 'icon',
        icon: 'edit',
        text: 'edit',
        route: [`${this.meterDetailsUrl}/${m.id}/edit`],
      }],
    }));


    return (<Observable<PaginatedMeters>><Observable<any>>this.metersSvc.list(reqParams)).pipe(
      map(res => {
        const results = res.results || [];
        const baseOperations = [mapNameLink];
        const operations = this.isAdmin ? [].concat(baseOperations, [mapEditAction]) : baseOperations;
        return {
          ...res,
          results: operationsPipe(
            results,
            ...operations,
          ),
        };
      }),
    );
  }
}
