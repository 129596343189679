import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'exa-list-base-loading',
  template: `
    <div
        class="list-base-loading full-width"
        fxLayout="row"
        fxLayoutAlign="center center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  `,
  styles: [`
    .list-base-loading {

    }
  `]
})
export class ListBaseLoadingComponent {
  api: any;

  constructor() { }

  agInit(params: any) {
    this.api = params.api;
  }

}
