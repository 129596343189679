import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartObject } from 'highcharts';
import * as moment from 'moment';
import { Observable, Subject, Subscription, BehaviorSubject, zip, EMPTY } from 'rxjs';
import { catchError, tap, map, share } from 'rxjs/operators';
import { CustomersService } from '../../../customers/customers.service';
import { DataService } from '../../../data/data.service';
import { ElectricalMeterService } from '../../../data/meters/electrical-meter.service';
import { Meter } from '../../../shared/models/meter.model';
import {
  WasteEventAverageResponsePoint,
  WasteEventTsData,
  Criteria,
} from '../../../shared/models/waste/waste-events.models';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import * as WasteEventUiModels from '../waste-event.models';
import { Config as WasteEventsAvgConfig } from '../waste-events-average/waste-events-average.component';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DashboardCommonChild, eventSubscriber } from '../../dashboard-common-child.interface';
import { DashboardService } from '../../dashboard.service';
import { SideNavService } from '../../../layout/side-nav.service';
import { NumberFormatPipe } from '../../../shared/pipes/number-format.pipe';
import { ListBaseComponent } from '../../../shared/components/list-base/list-base.component';
import { AuthService } from '../../../auth/auth.service';

interface Query {
  nativeFilters: any;
  params: any;
}

interface BasicData {
  criteriaList: Criteria[];
}

const criteriaAnyOption = <Criteria>{
  id: null,
  name: 'Any',
}

const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};

@Component({
  selector: 'exa-waste-event-page',
  templateUrl: 'waste-event-page.component.html',
  styleUrls: ['waste-event-page.component.scss'],
})

export class WasteEventPageComponent extends ListBaseComponent<any[]> implements OnInit, DashboardCommonChild, OnDestroy {
  customer$ = this.customers.selected$;
  basicData$: Observable<BasicData>;
  filtersForm: FormGroup;
  startTime: string;
  endTime: string;
  criteriaList: Criteria[];
  buildingId: string;
  basicDataStates = new SectionLoadingStates();
  avgChart: ChartObject;

  lastQuery: any;

  wasteEventsSection = new SectionLoadingStates();

  subscriptionSink = new Subscription();

  queries$ = new Subject<Query>();
  wasteEventsConfigs$ = new BehaviorSubject<{ avgConfig: WasteEventsAvgConfig }>(null);

  metersSearchFn = this.electricalMetersSvc.filterByName.bind(this.electricalMetersSvc);

  resizeToFit = false;

  customerSub: Subscription;
  filterModelMap = {
    'meter.name': 'meter_name',
    'meter.building.name': 'building_name',
    'criteria.name': 'criteria_name',
    'started_at': 'started_at',
    'ended_at': 'ended_at',
    duration: 'duration',
  };

  sortModelMap = {
    weekly_avg_waste: 'weekly_avg_waste',
    started_at: 'started_at',
    ended_at: 'ended_at',
    total_waste: 'total_waste',
    avg_corrected_waste: 'avg_corrected_waste',
    avg_remaining_waste: 'avg_remaining_waste',
    duration: 'duration',
  };

  displayedColumns: any[] = [
    {
      headerName: 'Building',
      field: 'meter.building.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },
    {
      headerName: 'Meter',
      field: 'meter.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },

    {
      headerName: 'Criteria',
      field: 'criteria.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
      width: 140,
    },
    {
      headerName: 'Waste Event ID',
      field: 'verbose_id',
      filter: false
    },
    {
      headerName: 'Start Date',
      field: 'started_at',
      sortable: true,
      cellRenderer: function (param) {
        const { value } = param || { value: null };
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
    {
      headerName: 'End Date',
      field: 'ended_at',
      sortable: true,
      cellRenderer: function (param) {
        const { value } = param || { value: null };
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
    {
      headerName: 'Avg. Waste (KWh/week)',
      field: 'weekly_avg_waste',
      filter: false,
      sortable: true,
      width: 160,
    },
    {
      headerName: 'Total Waste Incurred (KWh)',
      field: 'total_waste',
      filter: false,
      sortable: true,
      width: 160,
    },

    {
      headerName: 'Avg Remaining Waste (KWh/week)',
      field: 'avg_remaining_waste',
      filter: false,
      sortable: true,
      width: 160,
    },
    {
      headerName: 'Avg Corrected Waste (KWh/week)',
      field: 'avg_corrected_waste',
      filter: false,
      sortable: true,
      width: 160,
    },
    {
      headerName: 'Duration (days)',
      field: 'duration',
      filter: false,
      sortable: true,
      width: 120,
    }
  ];

  latestQueryOperands: any[];

  static generateEventsColorsMap(wasteEvents: { id: string }[]) {
    return wasteEvents.reduce((accMap, wasteEvent, i) => {
      return {
        ...accMap,
        [wasteEvent.id]: Highcharts.getOptions().colors[i],
      };
    }, {});
  }

  static bindEventColor(wasteEvents: { id: string }[], colorsMap: { [eventId: string]: string }) {
    return wasteEvents.map(e => ({
      ...e,
      color: colorsMap[e.id],
    }));
  }

  static setExtremesEventListenerFactory(connectedAxis: Highcharts.AxisObject) {
    return function (event: Highcharts.AxisEvent) {
      if ((event as any).isSideEffect) {
        return;
      }
      connectedAxis.setExtremes(event.min, event.max, true, true, { isSideEffect: true });
    };
  }

  constructor(public customers: CustomersService,
    private data: DataService,
    private electricalMetersSvc: ElectricalMeterService,
    private fb: FormBuilder,
    private appService: DashboardService,
    auth: AuthService,
    sidenav: SideNavService,
    private numberFormat: NumberFormatPipe) {
    super(auth, sidenav);
    this.executeAction = this.executeAction.bind(this);
    eventSubscriber(appService.subscription, this.executeAction);
  }

  ngOnDestroy(): void {
    eventSubscriber(this.appService.subscription, this.executeAction, true);
  }

  executeAction(params?) {
    this.startTime = params.fromDate;
    this.endTime = params.toDate;
    this.buildingId = params.buildingId;
    if(!this.filtersForm || (!this.startTime && !this.endTime)){
      return;
    }
    setTimeout(() => {
      super.refreshDataSource()
      this.loadEvents({ criteria: this.filtersForm.value.criteria, startTime: this.startTime, endTime: this.endTime, buildingId: this.buildingId });
    }, 100);
  }

  ngOnInit() {
    super.ngOnInit();
    this.filtersForm = this.fb.group({
      criteria: [criteriaAnyOption],
    });

    this.gridOptions = Object.assign({}, this.gridOptions, {
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      frameworkComponents: {
        ...this.gridOptions.frameworkComponents
      },
      getRowStyle: (params) => {
        if (params.data && params.data.pinned) {
          return {
            'background-color': '#ccc9f9',
          };
        }
        return null;
      },
      context: {
        ...this.gridOptions.context,
        componentParent: this,
      },
    });

    this.customerSub = this.customers.selected$.subscribe((customer) => {
      if (this.gridParams) {
        this.onGridReady(this.gridParams);
      }
    });

    this.loadBasicData();
    this.subscriptionSink.add(
      this.queries$
        .pipe(
          tap(query => this.loadWasteEvents(query)),
        )
        .subscribe(),
    );

    setTimeout(() => {
      this.loadEvents({ criteria: this.filtersForm.value.criteria, startTime: this.startTime, endTime: this.endTime, buildingId: this.buildingId });
    }, 100);
  }

  getDataObservable(reqParams: any): Observable<any> {
    return this.data.eventsList({
      ...reqParams,
      from_date: this.startTime,
      to_date: this.endTime,
      building_id: this.buildingId
    }).pipe(
      share(),
      map((res: any) => {
        res.results = res.results || [];

        res.results.forEach(item => {
          item.gridLinks = [
            { key: 'verbose_id', route: (data) => ['/criteria/waste-events', data.id] },
            { key: 'criteria.name', route: (data) => ['/criteria', data.criteria.id] },
            { key: 'meter.name', route: (data) => ['/meters/electrical', data.meter.id] },
            { key: 'meter.building.name', route: (data) => ['/buildings', data.meter.building.id] },
          ];
        });
        return res;
      }),
      map(res => {
        return {
          ...res,
          results: res.results.map(item => ({
            ...item,
            weekly_avg_waste: this.numberFormat.transform(item['weekly_avg_waste']),
          }))
        };
      }),
    );
  }

  onGridReady(params, resetPagination: boolean = false) {
    this.latestQueryOperands = [params, resetPagination];
    super.onGridReady(params, resetPagination);
  }

  loadBasicData() {
    const loadingStates = this.basicDataStates;
    loadingStates.setLoadingState();
    this.data.criterias.lookup().subscribe(
      (criteria: Criteria) => this.criteriaList = [].concat([criteriaAnyOption], criteria),
      (err) => loadingStates.setErrorState(err),
    );
  }

  criteriaChanged() {
    // super.refreshDataSource();
    this.loadEvents({ criteria: this.filtersForm.value.criteria, startTime: this.startTime, endTime: this.endTime, buildingId: this.buildingId });
  }

  loadEvents(form) {
    const { criteria, min_days, max_days } = form;
    const params: any = {
      from_date: moment(form.startTime).format('YYYY-MM-DD'),
      to_date: moment(form.endTime).format('YYYY-MM-DD'),
      building_id : form.buildingId
    };

    if (min_days) {
      params.min_length = min_days;
      params.max_length = max_days;
    }

    if (criteria && criteria.id) {
      params.criteria_id = form.criteria && form.criteria.id;
    }

    params.meters = form.meters && form.meters.map(i => i.id);
    params.buildingId = form.buildingId;

    const query = {
      nativeFilters: form,
      params: params,
    };

    this.lastQuery = query;
    this.queries$.next(query);
  }

  loadWasteEvents(query: Query) {
    const section = this.wasteEventsSection;
    section.setLoadingState();
    const { params } = query;
    const wasteEvents$ = zip(
      (this.data.eventsGraph(params) as Observable<WasteEventAverageResponsePoint[]>),
    ).pipe(
      tap(() => section.resetLoadingState()),
      map(([avg, ts]) => ({ avg, ts })),
      map(({ avg, ts }) => {
        const colorsMap = WasteEventPageComponent.generateEventsColorsMap(avg);
        return {
          avg: <WasteEventUiModels.WasteEventAvgPoint[]>WasteEventPageComponent.bindEventColor(avg, colorsMap)
        };
      }),
      map(({ avg }) => {
        const filters = {
          startTime: params.from_date,
          endTime: params.to_date,
        };
        return {
          avgConfig: <WasteEventsAvgConfig>{ events: avg, filters }
        };
      }),
      tap(configs => { this.wasteEventsConfigs$.next(configs); this.basicDataStates.resetLoadingState() }),
      catchError(err => {
        section.setErrorState();
        return EMPTY;
      }),
    );

    this.subscriptionSink.add(wasteEvents$.subscribe());
  }

  registerAvgChart(chart) {
    this.avgChart = chart;
  }
}
