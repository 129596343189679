import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PageEvent } from '@angular/material';

interface IBlockPaginator {
  length: number;
  pageSize: number;
}

export interface IBlockAction {
  type: string;
  text?: string;
  icon?: string;
  color?: string;
  route?: string | string[];
}

export interface IBlockActions {
  header?: IBlockAction[];
  paginator?: IBlockPaginator;
}

@Component({
  selector: 'exa-block-with-card',
  template: `
    <div class="block" [ngClass]="['block--' + theme, 'block--' + color]">
      <div class="block__card padding" [ngClass]="{'block__card--huge-shadow': hugeShadow }" style="border: 1px solid rgb(223 223 223); background: white">
        <div class="block__header" fxLayout="row" fxLayoutAlign="start center">
          <div class="block__title">
            <mat-icon *ngIf="titleIcon">{{titleIcon}}</mat-icon>
            <span>{{title}}</span>
          </div>
          <span style="flex: 1;"></span>
          <ng-container *ngFor="let action of actions?.header">
            <button
                *ngIf="action.type === 'icon'"
                mat-icon-button type="button"
                [matTooltip]="action.text || ''"
                [color]="action.color || ''"
                (click)="onActionClick(action)">
              <mat-icon>{{action.icon}}</mat-icon>
            </button>
          </ng-container>
        </div>

        <div>
          <mat-icon class="block__body-icon" *ngIf="bodyIcon">{{bodyIcon}}</mat-icon>
          <div class="block__body" [ngClass]="{ 'block__body--no-content': isEmpty }">
            <ng-content></ng-content>
          </div>
        </div>

        <mat-card-actions
            *ngIf="actions">
            <span style="flex: 1"></span>
            <mat-paginator
                *ngIf="actions?.paginator?.length"
                [hidePageSize]="true"
                [length]="actions?.paginator.length"
                [pageSize]="actions?.paginator.pageSize"
                (page)="onPageChange($event)">
            </mat-paginator>
        </mat-card-actions>
      </div>
    </div>
  `,
  styleUrls: ['./block-with-card.component.scss']
})
export class BlockWithCardComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() bodyIcon: string;
  @Input() isEmpty = false;
  // TODO Abdalla 19 May 2020 : It would be better to control actions as a content projection instead of config
  @Input() actions: IBlockActions;
  @Input() theme: 'dark' | 'light' = 'light';
  @Input() color: 'primary' | 'green' | 'accent' | 'gray' | 'warn' = 'gray';
  @Input() hugeShadow = false;
  @Output() page = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onPageChange(page: PageEvent) {
    this.page.emit(page);
  }

  onActionClick(action) {
    const { data, callback } = action || { data: null, callback: null };
    if (callback && typeof callback === 'function') {
      callback(data);
    }
  }
}
