import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { tempToHexColor } from '../../utils/weather-colors';
import { TempRange } from './temperature-range';
import {
  WeatherQueryGroup,
  WeatherGroupData,
  WeatherAnalyzedData,
  WeatherGroupResponse,
  WeatherGroupSeries,
} from './weather-comparison.models';

const startTimeFormatter = (d: Date) => moment(d).startOf('d').format(BE_DATETIME_NO_TIMEZONE_FORMAT);
const endTimeFormatter = (d: Date) => moment(d).startOf('d').hour(23).format(BE_DATETIME_NO_TIMEZONE_FORMAT);

@Injectable({
  providedIn: 'root',
})
export class WeatherComparisonService {

  constructor(
    private http: HttpClient,
    private data: DataService,
    private customers: CustomersService,
  ) {
  }

  exportData(groupName: string, {buildingId, timeRange}: WeatherQueryGroup) {
    const filename = `weather-data${groupName ? '-' + groupName : ''}_${timeRange.startDate}_${timeRange.endDate}.xlsx`;
    return this.http.post(`${environment.apiBase}/weather/export`, {
      building_id: buildingId,
      start_date: startTimeFormatter(timeRange.startDate),
      end_date: endTimeFormatter(timeRange.endDate),
    });
  }

  comparisonData(queryGroups: WeatherQueryGroup[]) {
    const groupsSeriesDataArr = queryGroups.map(q => this.groupSeriesData(q));
    return zip(...groupsSeriesDataArr).pipe(
      map((groupsData) => groupsData.map((v, i) => ({
        data: v,
        groupName: queryGroups[i].groupName,
      }))),
    );
  }

  analyzeAnalysisWeatherData({data, groupName}: WeatherGroupResponse): WeatherAnalyzedData {
    const boundaries = WeatherComparisonService.getWeatherBoundaries(data.series);
    const tempRanges = WeatherComparisonService.generateTempRanges(boundaries.min, boundaries.max);
    const series = this.generateTempRangeSeries(data.series, tempRanges);
    return {
      boundaries: {boundaries, groupName: groupName},
      series: {groupName, series},
      tempRanges: tempRanges,
      totalDegree: {
        groupName,
        totalDegree: {
          hours: {
            cdh: data.total_cdh,
            hdh: data.total_hdh,
          },
          days: {
            regular: {
              cdd: data.total_cdd,
              hdd: data.total_hdd,
            },
            weighted: {
              cdd: data.total_weighted_cdd,
              hdd: data.total_weighted_hdd,
            },
          },
        }
      },
    };
  }

  generateTempRangeSeries(weatherData: WeatherGroupSeries, ranges: TempRange[]) {
    let pointsArr = weatherData.sort((a, b) => a.temperature - b.temperature);
    return ranges.map(range => {
      const nextRangeTempIndex = pointsArr.findIndex(p => p.temperature > range.end);
      const rangeTempArr = nextRangeTempIndex == -1 ?
        pointsArr.splice(0, pointsArr.length) :
        pointsArr.splice(0, nextRangeTempIndex);
      const rangeAvgTemp = (range.end + range.start) / 2;
      
      const tempPoints:WeatherGroupSeries = [];
      for(var i = 0; i < 24; i++){
          var totalCount = 0;
          totalCount += rangeTempArr.filter(range => range.hour == i).reduce((accumulator, object) => {
            return accumulator + object.count;
          }, 0);

          if(totalCount == 0)
          {
            continue;
          }

          tempPoints.push({count: totalCount, hour: i, temperature: Math.floor((range.start + range.end) / 2)})
      }

      return {...range, points: tempPoints, hours:  tempPoints.reduce((sum, point) => sum + point.count, 0), color: tempToHexColor(rangeAvgTemp)};
    });
  }

  static getWeatherBoundaries(weatherData: WeatherGroupSeries) {
    const temperatureArr = weatherData.map(p => p.temperature);
    const min = Math.min(...temperatureArr);
    const max = Math.max(...temperatureArr);
    const avg = temperatureArr.reduce((acc, t) => acc + t, 0) / (temperatureArr.length);
    return {min, max, avg};
  }

  static generateTempRanges(min: number, max: number) {
    const tempRanges: TempRange[] = [];
    let endTemp = Math.floor(min/10) * 10 + 10;
    tempRanges.push(new TempRange(endTemp));
    while (max > endTemp) {
      endTemp += 10;
      tempRanges.push(new TempRange(endTemp));
    }
    return tempRanges;
  }

  private groupSeriesData({buildingId, timeRange}: WeatherQueryGroup) {
    return this.http.post<WeatherGroupData>(`${environment.apiBase}/electricalDashboard/buildingWeather/queryOne`, {
      building_id: buildingId,
      from_date: startTimeFormatter(timeRange.startDate),
      to_date: endTimeFormatter(timeRange.endDate),
    });
  }
}
