import {AllOrSearchConfig} from './all-or-search-filter.component';
import {Building} from '../../models/building.model';
import {Meter} from '../../models/meter.model';

export const multiBuildingsFilterBasicConfig = <AllOrSearchConfig<Building>>{
  selectMultiple: true,
  displayNameExtractorFn: building => building.name,
  inputPlaceholder: 'Search And Select Building',
  title: 'Select a Buildings',
  uniqueKey: 'id',
  specificationTitle: 'Select Specific Building/s',
};


export const multiMetersFilterBasicConfig = <AllOrSearchConfig<Meter>>{
  selectMultiple: true,
  displayNameExtractorFn: meter => meter.name,
  inputPlaceholder: 'Search And Select Meter',
  title: 'Select Meters',
  uniqueKey: 'id',
  specificationTitle: 'Select Specific Meter/s',
};

export const onlyBuildingFilterBasicConfig = <AllOrSearchConfig<Building>>{
  displayNameExtractorFn: building => building.name,
  inputPlaceholder: 'Select a Building',
  title: 'Select a Building',
  uniqueKey: 'id',
  specificationTitle: 'Select Specific Building',
};

export const onlyMeterFilterBasicConfig = <AllOrSearchConfig<Meter>> {
  displayNameExtractorFn: meter => meter.name,
  inputPlaceholder: 'Select Meter',
  title: 'Select Meter',
  uniqueKey: 'id',
  specificationTitle: 'Select Specific Meter',
};
