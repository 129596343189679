import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, BehaviorSubject, throwError, EMPTY } from 'rxjs';
import { map, tap, filter, switchMap, finalize, catchError, delay } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { ActivatedRoute } from '@angular/router';
import { actionsDef } from '../../shared/permissions/permissions-actions';
import { SectionLoadingStates } from '../../utils/section-loading-states';

@Component({
  selector: 'exa-criteria-waste-events-details',
  templateUrl: './criteria-waste-events-details.component.html',
  styleUrls: ['./criteria-waste-events-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CriteriaWasteEventsDetailsComponent implements OnInit {
  loading$ = new BehaviorSubject(false);
  loadingError$ = new BehaviorSubject(false);
  event$: Observable<any>;
  notes$ = new BehaviorSubject([]);

  pinned: boolean;
  pinnedLoadingStats = new SectionLoadingStates();
  permActionDefs = actionsDef;

  constructor(
    private data: DataService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loadWasteEvent();
  }


  loadWasteEvent() {
    this.event$ = this.route.params.pipe(
      map(() => this.route.snapshot.params),
      map(params => params.id),
      filter(id => typeof id !== 'undefined' && id !== null),
      delay(0),
      tap(() => this.loading$.next(true)),
      tap(() => this.loadingError$.next(false)),
      switchMap((id) => this.data.eventsDetails(id)),
      tap(({pinned}) => this.pinned = pinned),
      tap((res) => this.notes$.next(res && res.notes)),
      tap(() => this.loading$.next(false)),
      catchError(err => {
        this.loading$.next(false);
        this.loadingError$.next(true);
        return throwError(err);
      })
    );
  }

  onNewNote(note) {
    const notes = this.notes$.value || [];
    this.notes$.next([note].concat(notes));
  }

  onDeleteNote(id) {
    this.notes$.next(this.notes$.value.filter(i => i.id !== id));
  }

  togglePin(event: {id: string}) {
    this.pinnedLoadingStats.setLoadingState();
    this.data.pinEvent(event.id, !this.pinned).pipe(
      tap(() => {
        this.pinnedLoadingStats.resetLoadingState();
        this.pinned = !this.pinned;
      }),
      catchError(err => {
        this.pinnedLoadingStats.setErrorState();
        return EMPTY;
      }),
    ).subscribe();
  }
}
