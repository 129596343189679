import { Component, OnInit, Input } from '@angular/core';
import { GroupTotalDegreeHoursDays } from '../weather-comparison.models';

export interface TotalDegreeSection {
  title: string;
  categories: {
    title: string;
    value: number;
  }[];
}

export interface GroupSections {
  groupName: string;
  sections: TotalDegreeSection[];
}

@Component({
  selector: 'exa-total-degree-hours-days',
  templateUrl: './total-degree-hours-days.component.html',
  styleUrls: ['./total-degree-hours-days.component.scss']
})
export class TotalDegreeHoursDaysComponent {

  groupsSections: GroupSections[];
  @Input()
  set groupsTotalDegree(groupsTotalDegree: GroupTotalDegreeHoursDays[]) {
    if (!groupsTotalDegree)
      return;
    this.groupsSections = groupsTotalDegree.map(g => ({
      groupName: g.groupName,
      sections: [
        {
          title: 'Total Degree Hours',
          categories: [
            {title: 'CDH', value: g.totalDegree.hours.cdh},
            {title: 'HDH', value: g.totalDegree.hours.hdh},
          ],
        },
        {
          title: 'Total Regular Degree Days',
          categories: [
            {title: 'CDD', value: g.totalDegree.days.regular.cdd},
            {title: 'HDD', value: g.totalDegree.days.regular.hdd},
          ],
        },
        {
          title: 'Total Weighted Degree Days',
          categories: [
            {title: 'CDD', value: g.totalDegree.days.weighted.cdd},
            {title: 'HDD', value: g.totalDegree.days.weighted.hdd},
          ],
        },
      ],
    }));
  }

}
