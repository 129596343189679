export const styles = [
  {elementType: 'geometry', stylers: [{color: '#ced4f0'}]},
  {elementType: 'labels.text.stroke', stylers: [{color: '#c2ccf0'}]},
  {elementType: 'labels.text.fill', stylers: [{color: '#4f6bd2'}]},
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{color: '#a8b8f0'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#6b9a76'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{color: '#bcc7f0'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{color: '#c2ccf0'}]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{color: '#aebae7'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{color: '#a2b0e2'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{color: '#a8b8f0'}]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{color: '#a8b8f0'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{color: '#4f6bd2'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#a2b0e2'}]
  }
];
