import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { BuildingsModule } from './buildings/buildings.module';
import { SharedModule } from './shared/shared.module';
import { ImportsModule } from './imports/imports.module';
import { LayoutModule } from './layout/layout.module';
import { MetersModule } from './meters/meters.module';
import { CriteriaModule } from './criteria/criteria.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CustomersModule } from './customers/customers.module';
import { UserGroupGuardProvider } from './auth/user-group-guard.provider';
import { InsightModule } from './insight/insight.module';
import {RegPgeModule} from './reg-pge/reg-pge.module';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    AuthModule,
    SharedModule,
    BuildingsModule,
    MetersModule,
    ImportsModule,
    DashboardModule,
    CriteriaModule,
    CustomersModule,
    InsightModule,
    RegPgeModule,
    AppRoutingModule,
    LayoutModule,
  ],
  exports: [],
  providers: [UserGroupGuardProvider],
  bootstrap: [AppComponent],
})
export class AppModule {
}
