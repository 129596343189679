import { Component } from '@angular/core';
import { CustomersService } from '../../../customers/customers.service';

@Component({
  selector: 'exa-new-meter',
  templateUrl: './new-meter.component.html',
  styles: []
})
export class NewMeterComponent {

  customer$ = this.customers.selected$;

  constructor(
      private customers: CustomersService,
    ) { }

}
