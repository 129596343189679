import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable, EMPTY, of, zip, Subject, merge } from 'rxjs';
import { tap, catchError, switchMap, withLatestFrom, startWith, delay, filter, map } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import { DataService } from '../../../data/data.service';
import { UtilityBillsDataService, UtilityBillsFilters } from '../../../data/utility-bills-data.service';
import {
  IDeleteConfirmationData,
  DeleteConfirmationDialogComponent,
} from '../../../shared/components/delete-confirmation-dialog.component';
import { Building } from '../../../shared/models/building.model';
import { Customer } from '../../../shared/models/customer.model';
import { PaginationReqParams, PaginationResponse } from '../../../shared/models/pagination-response.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import {multiBuildingsFilterBasicConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';

interface BasicData {
  billsLib: UtilityBillsLibraryModule.Library;
}

@Component({
  selector: 'exa-utility-bills-list',
  templateUrl: './utility-bills-list.component.html',
  styleUrls: ['./utility-bills-list.component.scss'],
})
export class UtilityBillsListComponent implements OnInit, OnChanges {

  filtersForm = this.fb.group({
    buildings: null,
    month: null,
  });
  filters$: Observable<UtilityBillsFilters> = this.filtersForm.valueChanges.pipe(
    tap(() => this.paginationParams$.next(this.initialPaginationParams)),
    startWith(null),
  );

  initialPaginationParams: PaginationReqParams = {
    page: 0,
    pageSize: 1,
  };
  paginationParams$ = new BehaviorSubject<PaginationReqParams>(this.initialPaginationParams);

  loadBasicData$ = new BehaviorSubject<boolean>(null);
  basicData$ = new BehaviorSubject<BasicData>(null);

  rows$: Observable<any>;
  reloadRows$ = new Subject<boolean>();

  parentSectionStates = new SectionLoadingStates();
  dataLoadingStates = new SectionLoadingStates();

  buildingsInputConfig = {
    ...multiBuildingsFilterBasicConfig,
    searchItems: this.data.buildings.filterByName.bind(this.data),
  };

  displayedColumns = ['name', 'building', 'month', 'service_start_date', 'service_end_date', 'services', 'actions'];

  @Input() customer: Customer;

  constructor(private fb: FormBuilder, private dialog: MatDialog, private data: DataService, private billsDataSvc: UtilityBillsDataService) {
  }

  ngOnInit() {
    this.loadBasicData$.pipe(
      switchMap(() => this.loadBasicData()),
      tap(() => this.resetFilters()),
    ).subscribe();

    const paginationChange$ = this.paginationParams$.pipe(
      map(params => ({...params, page: params.page + 1})),
      withLatestFrom(this.filters$),
      switchMap(([paginationParams, filters]) => this.loadRows(filters, paginationParams)),
    );

    const reloadRowsChange$ = this.reloadRows$.pipe(
      withLatestFrom(this.paginationParams$, this.filters$),
      switchMap(([neglect, pagination, filters]) => this.loadRows(filters, {...pagination, page: pagination.page + 1})),
    );

    this.rows$ = merge(
      paginationChange$,
      reloadRowsChange$,
    );

  }

  ngOnChanges({customer}: SimpleChanges): void {
    if (!customer.firstChange && customer.currentValue !== customer.previousValue) {
      this.loadBasicData$.next(true);
    }
  }

  loadBasicData() {
    const section = this.parentSectionStates;
    section.setLoadingState();
    return zip(
      this.billsDataSvc.getLibrary(),
    ).pipe(
      map(([billsLib]) => ({billsLib})),
      tap((basicData) => this.basicData$.next(basicData)),
      tap(() => this.resetFilters()),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      }),
    );
  }

  resetFilters() {
    this.filtersForm.reset({
      buildings: null,
      month: null,
    });
  }

  loadRows(filters: UtilityBillsFilters, paginationParams) {
    const section = this.dataLoadingStates;
    const data$ = this.billsDataSvc.getBillsList(filters, paginationParams).pipe(
      withLatestFrom(this.basicData$.pipe(filter(data => !!data))),
      map(([billsRes, basicData]) => this.mapBillsResponse(billsRes, basicData)),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      }),
    );

    return of(true).pipe(
      delay(0),
      tap(() => section.setLoadingState()),
      switchMap(() => data$),
    );
  }

  updatePagination({pageIndex, pageSize}: PageEvent) {
    this.paginationParams$.next({
      page: pageIndex,
      pageSize: pageSize,
    });
  }

  private mapBillsResponse(billsRes: PaginationResponse<UtilityBillsLibraryModule.BillBody>, basicData: BasicData) {
    const mappedBills = billsRes.results.map(b => {
      const services = Object.values(basicData.billsLib.services)
        .filter(s => !!b[s.id]);
      const building = b.building;
      return {
        ...b,
        building,
        services,
      };
    });

    return {
      ...billsRes,
      results: mappedBills,
    };
  }

  resetFilter(fC: AbstractControl, value = null) {
    fC.setValue(value);
    fC.updateValueAndValidity();
  }

  deleteBill(bill: UtilityBillsLibraryModule.BillBody) {
    const dialogData: IDeleteConfirmationData = {
      deleteRequest: () => {
        return this.billsDataSvc.deleteBill(bill.id).pipe(
          tap(() => this.reloadRows$.next(true)),
        );
      },
      body: `Are you sure you want to delete Bill [${bill.name}]`,
      success: `Bill [${bill.name}] has been deleted successfully`,
      failure: `failed to delete bill [${bill.name}]. please try again`,
    };

    this.dialog.open(DeleteConfirmationDialogComponent, {
      data: dialogData,
      minWidth: DIALOG_STATIC_WIDTHS.small,
    });
  }

}
