import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { map, share, catchError } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { SideNavService } from '../../layout/side-nav.service';
import { ListBaseComponent } from '../../shared/components/list-base/list-base.component';
import { NumberFormatPipe } from '../../shared/pipes/number-format.pipe';
import { WasteEventsListPinCellComponent } from '../waste-events-list-pin-cell/waste-events-list-pin-cell.component';

const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};

interface WasteEventsOption {
  name: string;
  filters: {[flag: string]: string};
}

@Component({
  selector: 'exa-events-list',
  templateUrl: './criteria-waste-events.component.html',
  styleUrls: ['./criteria-waste-events.component.scss']
})
export class CriteriaWasteEventsComponent extends ListBaseComponent<any[]> implements OnInit {
  resizeToFit = false;

  customerSub: Subscription;
  filterModelMap = {
    'meter.name': 'meter_name',
    'meter.building.name': 'building_name',
    'criteria.name': 'criteria_name',
    'started_at': 'started_at',
    'ended_at': 'ended_at',
    duration: 'duration',
  };

  sortModelMap = {
    weekly_avg_waste: 'weekly_avg_waste',
    started_at: 'started_at',
    ended_at: 'ended_at',
    total_waste: 'total_waste',
    avg_corrected_waste: 'avg_corrected_waste',
    avg_remaining_waste: 'avg_remaining_waste',
    duration: 'duration',
  };

  displayedColumns: any[] = [
    {
      headerName: '',
      field: 'actions',
      filter: false,
      cellRenderer: 'pinRenderer',
      width: 80,
    },
    {
      headerName: 'Building',
      field: 'meter.building.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },
    {
      headerName: 'Meter',
      field: 'meter.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
    },

    {
      headerName: 'Criteria',
      field: 'criteria.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions,
      width: 140,
    },
    {
      headerName: 'Waste Event ID',
      field: 'verbose_id',
      filter: false,
    },
    {
      headerName: 'Start Date',
      field: 'started_at',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true, placehoder: 'MM/DD/YYYY' },
      ...customFilterOptions,
      floatingFilterComponent: 'ListBaseDateFilterComponent',
      sortable: true,
       cellRenderer: function (param) {
        const { value } = param || { value: null };
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
    {
      headerName: 'End Date',
      field: 'ended_at',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true, placehoder: 'MM/DD/YYYY' },
      ...customFilterOptions,
      floatingFilterComponent: 'ListBaseDateFilterComponent',
      sortable: true,
       cellRenderer: function (param) {
        const { value } = param || { value: null };
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },

    {
      headerName: 'Avg. Waste (KWh/week)',
      field: 'weekly_avg_waste',
      filter: false,
      sortable: true,
      width: 160,
    },

    {
      headerName: 'Total Waste Incurred (KWh)',
      field: 'total_waste',
      filter: false,
      sortable: true,
      width: 160,
    },

    {
      headerName: 'Avg Remaining Waste (KWh/week)',
      field: 'avg_remaining_waste',
      filter: false,
      sortable: true,
      width: 160,
    },

    {
      headerName: 'Avg Corrected Waste (KWh/week)',
      field: 'avg_corrected_waste',
      filter: false,
      sortable: true,
      width: 160,
    },


    {
      headerName: 'Duration (days)',
      field: 'duration',
      filter: false,
      sortable: true,
      width: 120,
    },
  ];

  wasteEventsOptions: WasteEventsOption[] = [
    {
      name: 'All Waste Events',
      filters: {},
    },
    {
      name: 'Pinned Waste Events',
      filters: {
        pinned: 'true',
      },
    }
  ];

  selectedWasteEvents = this.wasteEventsOptions[0];

  latestQueryOperands: any[];

  constructor(
      private customers: CustomersService,
      private data: DataService,
      auth: AuthService,
      sidenav: SideNavService,
      private numberFormat: NumberFormatPipe
    ) {
    super(auth, sidenav);
  }


  ngOnInit() {
    super.ngOnInit();
    this.gridOptions = Object.assign({}, this.gridOptions, {
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      frameworkComponents: {
        ...this.gridOptions.frameworkComponents,
        pinRenderer: WasteEventsListPinCellComponent,
      },
      getRowStyle: (params) => {
        if (params.data && params.data.pinned) {
          return {
            'background-color': '#ccc9f9',
          };
        }
        return null;
      },
      context: {
        ...this.gridOptions.context,
        componentParent: this,
      },
    });

    this.customerSub = this.customers.selected$.subscribe((customer) => {
      if (this.gridParams) {
        this.onGridReady(this.gridParams);
      }
    });
  }


  getDataObservable(reqParams: any): Observable<any> {
    return this.data.eventsList({
      ...reqParams,
      ...this.selectedWasteEvents.filters,
    }).pipe(
      share(),
      map((res: any) => {
        res.results = res.results || [];

        res.results.forEach(item => {
          item.gridLinks = [
            {key: 'verbose_id', route: (data) => ['/criteria/waste-events', data.id]},
            {key: 'criteria.name', route: (data) => ['/criteria', data.criteria.id]},
            {key: 'meter.name', route: (data) => ['/meters/electrical', data.meter.id]},
            {key: 'meter.building.name', route: (data) => ['/buildings', data.meter.building.id]},
          ];
        });
        return res;
      }),
      map(res => {
        return {
          ...res,
          results: res.results.map(item => ({
            ...item,
            weekly_avg_waste: this.numberFormat.transform(item['weekly_avg_waste']),
          }))
        };
      }),
    );
  }

  onGridReady(params, resetPagination: boolean = false) {
    this.latestQueryOperands = [params, resetPagination];
    super.onGridReady(params, resetPagination);
  }

  togglePinEvent(event) {
    const newPinnedVal = !event.pinned;
    return this.data.pinEvent(event.id, newPinnedVal).pipe(
      map(r => ({pinned: newPinnedVal})),
      catchError(err => EMPTY),
    );
  }
}
