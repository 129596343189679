import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ImportBuildingComponent } from './import-building/import-building.component';
import { ImportMetersComponent } from './import-meters/import-meters.component';
import { ImportMetersDataComponent } from './import-meters-data/import-meters-data.component';
import { ImportLoadFactorComponent } from './import-load-factor/import-load-factor.component';
import { ImportMeterThresholdEfficiencyComponent } from './import-meter-threshold-efficiency/import-meter-threshold-efficiency.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],

  declarations: [
    ImportBuildingComponent,
    ImportMetersComponent,
    ImportMetersDataComponent,
    ImportLoadFactorComponent,
    ImportMeterThresholdEfficiencyComponent,
  ],

  entryComponents: [
    ImportBuildingComponent,
    ImportMetersComponent,
    ImportMetersDataComponent,
    ImportLoadFactorComponent,
    ImportMeterThresholdEfficiencyComponent,
  ],
})
export class ImportsModule { }
