import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { permissionsMap } from './permissions-actions';

export class PermissionChecker {
  allowedGroups: string[];

  constructor(private actionName) {
    this.allowedGroups = permissionsMap[this.actionName];
  }

  public checkUserPermission(user) {
    return (user.group as string[]).some(g => this.allowedGroups.indexOf(g) > -1);
  }
}

@Directive({
  selector: '[exaPermissionGuard]',
})
export class PermissionGuardDirective implements OnInit, OnDestroy {

  permChecker: PermissionChecker;
  userSub: Subscription;

  // tslint:disable-next-line:no-input-rename
  @Input('exaPermissionGuard') actionName: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private auth: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.permChecker = new PermissionChecker(this.actionName);
    this.userSub = this.auth.loggedInUser$.pipe(
      map(user => this.permChecker.checkUserPermission(user)),
      tap(hasPermission => this.updateView(hasPermission)),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  private updateView(hasPermission: boolean) {
    if (hasPermission) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
