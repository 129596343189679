import { Component, OnInit, Input } from '@angular/core';
import { colors } from '../../../constants';

interface ScoreCard {
  min_kwh: number;
  max_kwh: number;
  total_kwh: number;
}

const colorsClasses = {
  green: `normal`,
  boldGreen: `normal-max`,
  warn: `warn`,
  boldWarn: `warn-max`,
  danger: `danger`,
  boldDanger: `danger-max`,
};

@Component({
  selector: 'exa-priority-meters-list',
  templateUrl: './priority-meters-list.component.html',
  styleUrls: ['./priority-meters-list.component.scss']
})
export class PriorityMetersListComponent implements OnInit {
  @Input() priorities: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  getPriorityClass(meter) {
    const { min_kwh: min } = meter;
    const prefix = 'priority-meters__list-item-card--';

    const cls = PriorityMetersListComponent;
    const clsName = min <= 0 ? cls.getClsName(meter) : cls.allPositiveValClsName(meter);
    return `${prefix}${clsName}`;
  }

  static allPositiveValClsName(scoreCard: ScoreCard) {
    const {min_kwh, max_kwh, total_kwh} = scoreCard;
    const greenAreaMax = ((max_kwh - min_kwh) * 0.1) + min_kwh;

    return PriorityMetersListComponent.getClsName(scoreCard, greenAreaMax);
  }

  static getClsName(scoreCard: ScoreCard, greenAreaMax = 0) {
    const {min_kwh, max_kwh, total_kwh} = scoreCard;

    const otherAreas = max_kwh - greenAreaMax;
    const otherAreasColors = [
      colorsClasses.warn,
      colorsClasses.boldWarn,
      colorsClasses.danger,
      colorsClasses.boldDanger,
    ];
    const eachAreaRange = otherAreas / 4;

    if(total_kwh <= greenAreaMax / 2) {
      return colorsClasses.boldGreen;
    } else if (total_kwh <= greenAreaMax) {
      return colorsClasses.green;
    } else {
      const neededRanges = (total_kwh - greenAreaMax) / eachAreaRange;
      const colorIndex = neededRanges > 0 ? Math.ceil(neededRanges) - 1 : 0;
      return otherAreasColors[colorIndex];
    }
  }
}
