import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, zip, EMPTY } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { DataService } from '../../data/data.service';
import { ElectricalMeterService } from '../../data/meters/electrical-meter.service';
import { IMultipleSelectOption } from '../../shared/components/multiple-select';
import { Building } from '../../shared/models/building.model';
import {
  WeatherKwhHeatmapQuery,
  WeatherKwhHeatmapQueryRaw,
} from '../../shared/models/waste/weather-kwh-heatmap-query.models';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import { WeatherKwhDataService } from './weather-kwh-data.service';

@Injectable()
export class WeatherKwhHeatmapStateService {

  basicData$ = new BehaviorSubject<{ buildings: Building[] }>(null);
  lastQuery: WeatherKwhHeatmapQuery;
  heatMapData$ = new BehaviorSubject(null);
  heatMapLoadingState = new SectionLoadingStates();

  buildingsSearchFn = this.data.buildings.filterByName.bind(this.data);
  metersSearchFn = this.metersSvc.filterByName.bind(this.metersSvc);

  constructor(private wasteService: WeatherKwhDataService, private data: DataService, private metersSvc: ElectricalMeterService) {
  }

  onCustomerChange() {
    this.lastQuery = null;
  }

  loadHeatMapData(query: WeatherKwhHeatmapQuery) {
    this.lastQuery = query;
    const startDate = moment(query.from_date).startOf('d').format(BE_DATETIME_NO_TIMEZONE_FORMAT);
    const endDate = moment(query.to_date).startOf('d').hour(23).format(BE_DATETIME_NO_TIMEZONE_FORMAT);
    const entity = {
      building_id: query.building_id,
    };
    const mappedQuery: WeatherKwhHeatmapQueryRaw = {
      ...entity,
      from_date: startDate,
      to_date: endDate,
    };
    this.heatMapLoadingState.setLoadingState();
    return zip(
      this.wasteService.getPowerConsumption(mappedQuery),
      this.wasteService.getWdd(mappedQuery),
      this.wasteService.getWdd(mappedQuery, true),
    ).pipe(
      map(([powerConsumption, weatherDd, weightedWeatherDd]) => ({powerConsumption, weatherDd, weightedWeatherDd})),
      tap(data => this.heatMapData$.next(data)),
      tap(() => this.heatMapLoadingState.resetLoadingState()),
      catchError((err) => {
        this.heatMapLoadingState.setErrorState();
        return EMPTY;
      }),
    );
  }
}
