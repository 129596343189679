import { HttpEventType } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, timer } from 'rxjs';
import { map, finalize, share, switchMap, filter } from 'rxjs/operators';
import { DataService } from '../data/data.service';
import { assign } from 'lodash';


export interface ImportConfig {
  signedUrlMethod: () => Observable<any>;
  importMethod: (...any) => any;
  importingProgress: (taskId) => Observable<any>;
  acceptedFileFormats?: string;
  pollerStartInSec?: number;
  pollerPeriodInSec?: number;
  templateMethod?: () => Observable<any>;
  dataForm?: FormGroup;
  onInit?: () => any;
  onStartSubmitting?: () => any;
  onSubmittingFinished?: () => any;
  onFinish?: () => any;
  successToastrMessage?: () => string;
  url: string
}

export interface SignedUrlResponse {
  path: string;
  url: string;
  method: string;
  content_type: string;
  customerId: string;
}


export class ImportBase implements OnInit {
  config: ImportConfig;
  selectedFile: File;

  gettingSignedUrl: boolean;
  importing: boolean;
  processing: boolean;
  submitting: boolean;

  errorMessage: string;

  signedUrl: SignedUrlResponse;
  signedUrl$: Observable<any>;
  upload$: Observable<any>;
  import$: Observable<any>;

  subscription = new Subscription();

  constructor(
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
  ) { }


  ngOnInit() {
    this.setConfig();
    if (this.config.onInit) { this.config.onInit(); }
  }


  setConfig() {
    throw Error('"setConfig" method is not implemented!');
  }


  downloadTemplate() {
    return this.config.templateMethod()
      .subscribe((res) => this.fileSaver.save(res.blob, res.name));
  }


  onFileSelected(file) {
    this.selectedFile = file;
  }


  getSignedUrl() {
    this.gettingSignedUrl = true;
    return this.config.signedUrlMethod().pipe(
      finalize(() => this.gettingSignedUrl = false),
      share(),
    );
  }


  uploadFile(url: string) {
    const { selectedFile } = this;
    return this.data.tasks
      .uploadFile(url, selectedFile)
      .subscribe((response: any) => {
        if (response.status == 200) {
          this.finish();
        }
      },
        (err: any) => {
          this.importing = false;
          this.processing = false;
          this.submitting = false;
          if (err && err.error && err.error.Errors) {
            for (const key in err.error.Errors) {
              this.errorMessage = (this.errorMessage ? this.errorMessage + key.split(" - ")[0] : key.split(" - ")[0]) + ": \n\r";
              for (const index in err.error.Errors[key]) {
                this.errorMessage += "- " + err.error.Errors[key][index] + " \n\r";
              }
            }
          }
        }
      )
  }


  import() {
    this.importing = true;
    return this.config.importMethod().pipe(
      finalize(() => this.importing = false),
      share(),
    );
  }


  onUploadingFinished(res) {
    this.processing = true;
    const pollerStart = (this.config.pollerStartInSec || 0) * 1000;
    const pollerPeriod = (this.config.pollerPeriodInSec || 1) * 1000;
    const importingProgress$ = timer(pollerStart, pollerPeriod).pipe(
      switchMap(() => this.checkImportingProgress(res.task_id)),
      share(),
      finalize(() => {
        this.processing = false;
        this.submitting = false;
        if (this.config.onSubmittingFinished) { this.config.onSubmittingFinished(); }
      }),
    );

    const statusSubscription = importingProgress$.subscribe(
      (status) => {
        if (!status.done) { return; }
        const toastrMsg = this.config.successToastrMessage ? this.config.successToastrMessage() :
          `${this.selectedFile.name} imported successfully!`;
        this.toastr.success(toastrMsg);
        this.finish();
      },
      (error) => this.errorMessage = error || `Importing ${this.selectedFile.name} Failed!`,
    );
    this.subscription.add(statusSubscription);
  }


  private checkImportingProgress(taskId) {
    return this.config.importingProgress(taskId).pipe(
      map((res) => {
        if (res.status === 'FAILURE') { throw new Error(res.error); }

        return assign({}, res, {
          started: true,
          done: res.status === 'SUCCESS',
        });
      }),
    );
  }


  submit() {
    var importMethod = this.config.importMethod();
    this.errorMessage = null;
    this.submitting = true;
    var url = this.config.url;
    if(importMethod && importMethod.dataUnitType && importMethod.buildingId) {
      url += "/" + importMethod.buildingId + "/"+importMethod.dataUnitType;
    }
    
    this.uploadFile(url)
  }

  finish() {
    this.subscription.unsubscribe();
    if (this.config.onFinish) {
      this.config.onFinish();
    }
  }
}
