import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from '../../shared/shared.module';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    SharedModule,
  ],
  exports: [
    MonthPickerComponent,
  ],
  declarations: [MonthPickerComponent, CalendarHeaderComponent],
  entryComponents: [CalendarHeaderComponent],
})
export class MonthPickerModule { }
