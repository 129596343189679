import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../../data/data.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription, throwError, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

export interface IDeleteConfirmationData {
  deleteRequest: (entity: any) => Observable<any>;
  entity?: any;
  icon?: string;
  title?: string;
  body: string;
  success: string;
  failure: string;
  route?: string[];
  confirmBtnText?: string;
  cancelBtnText?: string;
}

@Component({
  selector: 'exa-delete-confirmation-dialog',
  template: `
  <h1 mat-dialog-title class="text--warn" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="align--middle margin--right">{{ dialogData.icon || 'warning' }}</mat-icon>
    <span>{{dialogData.title || 'Warning'}}</span>
  </h1>
  <div mat-dialog-content>
    <p>{{dialogData.body}}</p>
    <exa-loading-error *ngIf="error" reload="onConfirm()"></exa-loading-error>
  </div>
  <div mat-dialog-actions>
    <div fxLayout="row" class="full-width">
      <span fxFlex="1 1 100%"></span>
      <button mat-button (click)="onCancel()" [disabled]="loading">{{dialogData.cancelBtnText || 'Cancel'}}</button>
      <button mat-button mat-raised-button (click)="onConfirm()" color="warn" [disabled]="loading">{{dialogData.confirmBtnText || 'Confirm'}}</button>
    </div>
  </div>
  `,
  styles: []
})
export class DeleteConfirmationDialogComponent {
  loading = false;
  error = false;
  deleteSub: Subscription;


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IDeleteConfirmationData,
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  onCancel() {
    this.dialogRef.close();
  }


  onConfirm() {
    this.loading = true;
    this.error = false;
    this.deleteSub = this.dialogData.deleteRequest(this.dialogData.entity)
      .pipe(
        catchError((err) => {
          this.loading = false;
          this.error = true;
          this.toastr.error(this.dialogData.failure);
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      ).subscribe((res) => {
        this.toastr.success(this.dialogData.success);
        this.deleteSub.unsubscribe();
        this.dialogRef.close();

        if (this.dialogData.route) {
          this.router.navigate(this.dialogData.route);
        }
      });
  }

}
