import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerReportsModule } from '../../features/customer-reports/customer-reports.module';
import { CustomerReportsPgsRoutingModule } from './customer-reports-pgs-routing.module';
import { CustomersReportsPgComponent } from './customers-reports-pg/customers-reports-pg.component';

@NgModule({
  imports: [
    CommonModule,
    CustomerReportsPgsRoutingModule,
    CustomerReportsModule,
  ],
  declarations: [CustomersReportsPgComponent]
})
export class CustomerReportsPgModule { }
