import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap, switchMap, share, withLatestFrom, catchError, finalize } from 'rxjs/operators';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { merge, Observable, throwError, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'exa-buildings-edit',
  templateUrl: './buildings-edit.component.html',
  styleUrls: ['./buildings-edit.component.scss']
})
export class BuildingsEditComponent implements OnInit {

  loading = false;
  loadingError = false;
  building$: Observable<any>;

  constructor(
      private route: ActivatedRoute,
      private data: DataService,
    ) { }

  ngOnInit() {
    this.loadBuilding();
  }

  loadBuilding() {
    this.building$ = this.route.params.pipe(
      map(params => params.id),
      tap(() => this.loading = true),
      tap(() => this.loadingError = false),
      switchMap(id => this.data.buildings.details(id)),
      share(),
      tap(() => this.loading = false),
      catchError(err => {
        this.loadingError = true;
        return throwError(err);
      }),
    );
  }


  saveCallback(building, id) {
    return this.data.buildings.update(id, building);
  }
}
