import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

export interface CalendarData {
  fields: UtilityBillsLibraryModule.Field[];
  calendar: UtilityMonthlyAnalysisModule.ServiceCalendarData;
}

interface Month {
  id: string;
  name: string;
  monthNum: number;
}

@Component({
  selector: 'exa-utility-bills-monthly-analysis-table',
  templateUrl: './utility-bills-monthly-analysis-table.component.html',
  styleUrls: ['./utility-bills-monthly-analysis-table.component.scss'],
})
export class UtilityBillsMonthlyAnalysisTableComponent implements OnInit {

  months: Month[] = moment.monthsShort().map((name, i) => ({
    id: moment.monthsShort(i).toLowerCase(),
    name,
    monthNum: i + 1,
    bill: undefined,
  }));
  dataRowsDisplayedCols = ['item'].concat(this.months.map(m => m.id));

  @Input() calendarData: CalendarData;

  constructor() {
  }

  ngOnInit() {
  }

}
