export interface TempBoundaries {
  highestTemp: number;
  lowestTemp: number;
}

// const highestTemp = 134.1;
// const lowestTemp = -128.6;
const globalHighestTemp = 130;
const globalLowestTemp = -20;

const highestHue = 250;
const lowestHue = 0;

type HslColor = [number, number, number]

export function tempToHslColor(temp: number, localTemp = {highestTemp: globalHighestTemp, lowestTemp: globalLowestTemp}) {
  const {highestTemp, lowestTemp} = localTemp;
  const tempPercentage = (temp - lowestTemp) / (highestTemp - lowestTemp);
  const mappedHue = tempPercentage * (lowestHue - highestHue) + highestHue;
  const hslColor = <HslColor> [mappedHue, 100, 50];
  return  hslToHex(hslColor);
}


// source: https://css-tricks.com/converting-color-spaces-in-javascript/
export function hslToHex(hslColor: HslColor) {
  let [h, s, l] = hslColor;
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    m = l - c/2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  // Having obtained RGB, convert channels to hex
  const convertChannelToHexPortion = (channel: number) => {
    const mappedString = Math.round((channel + m) * 255).toString(16);
    return mappedString.length === 1 ? `0${mappedString}` : mappedString;
  };

  const mappedChannels = [r, g, b].map(channel => convertChannelToHexPortion(channel)).join('');
  return "#" + mappedChannels;
}

const colors = [
  '#2f4f4f',
  '#008000',
  '#bdb76b',
  '#4b0082',
  '#ffa500',
  '#ff0000',
  '#ffff00',
  '#8b4513',
  '#00ff00',
  '#ff00ff',
  '#00fa9a',
  '#0000ff',
  '#00ffff',
  '#ee82ee',
  '#6495ed',
  '#ffb6c1',
];

export function tempToHexColor(temp: number) {
  const tempPercentage = (temp - globalLowestTemp) / (globalHighestTemp - globalLowestTemp);
  const colorIndex = Math.floor(tempPercentage * colors.length);
  return colorIndex >= 0 ? colors[colorIndex] : '#000';
}
