import { Component, OnInit, Input } from '@angular/core';
import { VirtualMeterEqElemMapped } from '../../../data/meters/media-type-meter-base.service';
import { Meter } from '../../../shared/models/meter.model';

@Component({
  selector: 'exa-virtual-meter-equation',
  templateUrl: './virtual-meter-equation.component.html',
  styleUrls: ['./virtual-meter-equation.component.scss']
})
export class VirtualMeterEquationComponent implements OnInit {

  @Input() equation: VirtualMeterEqElemMapped<Meter>[];

  constructor() { }

  ngOnInit() {
  }

}
