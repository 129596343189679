import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../../customers/customers.service';

@Component({
  selector: 'exa-meters-integration-pg',
  templateUrl: './meters-integration-pg.component.html',
  styleUrls: ['./meters-integration-pg.component.scss']
})
export class MetersIntegrationPgComponent implements OnInit {

  constructor(public customers: CustomersService) { }

  ngOnInit() {
  }

}
