import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountSettingsMainPgComponent } from './account-settings-main-pg/account-settings-main-pg.component';
import { MetersIntegrationPgComponent } from './meters-integration-pg/meters-integration-pg.component';

const routes: Routes = [
  {
    path: '',
    component: AccountSettingsMainPgComponent,
    children: [
      {
        path: 'meters-integration',
        component: MetersIntegrationPgComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'meters-integration'
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountSettingsPageRoutingModule { }
