import {Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';

export interface PaginatorContext {
  $implicit: any[];
  controller: {
    next: Function;
    prev: Function;
  };
  hasNext: boolean;
  hasPrev: boolean;
  currentPageIndex: number;
  totalPages: number;
}

/**
 * @example
 * <div *exaPaginator="let shownItems of items; pageSize 5; let ctrl = controller; let hasNext=hasNext; let hasPrev=hasPrev">
 *    <div *ngFor="let item of shownItems">{{item.name}}</div>
 *    <button [disabled]="!hasPrev" (click)="ctrl.prev()">go back</button>
 *    <button [disabled]="!hasNext" (click)="ctrl.next()">go next</button>
 * </div>
 */

/*
* usage <div *exaPagniator="let page of pages; pageSize 5; let ctrl = controller"></div>
* */
@Directive({
  selector: '[exaPaginator]'
})
export class PaginatorDirective implements OnChanges {

  context: PaginatorContext;
  pages: any[][];
  currentPageIndex = 0;

  @Input() exaPaginatorOf: any[];
  @Input() exaPaginatorPageSize: number;

  constructor(private tpl: TemplateRef<PaginatorContext>, private vcr: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.vcr.clear();
    const pagesCount = Math.ceil(this.exaPaginatorOf.length / this.exaPaginatorPageSize);
    this.pages = new Array(pagesCount).fill(null)
      .map((v, i) => {
        const startPoint = i * this.exaPaginatorPageSize;
        return this.exaPaginatorOf.slice(startPoint, startPoint + this.exaPaginatorPageSize);
      });
    this.context = {
      $implicit: this.pages[0],
      controller: {
        next: () => this.next(),
        prev: () => this.prev(),
      },
      hasPrev: false,
      hasNext: this.pages.length > 1,
      currentPageIndex: 0,
      totalPages: pagesCount,
    };
    this.vcr.createEmbeddedView(this.tpl, this.context);
  }

  next() {
    if (!this.context.hasNext) {
      return;
    }
    this.currentPageIndex++;
    this.updateContext();
  }

  prev() {
    if (!this.context.hasPrev) {
      return;
    }
    this.currentPageIndex--;
    this.updateContext();
  }

  private updateContext() {
    const pagesHighestIndex = this.pages.length - 1;
    this.context.$implicit = this.pages[this.currentPageIndex];
    this.context.currentPageIndex = this.currentPageIndex;
    this.context.hasNext = this.currentPageIndex < pagesHighestIndex;
    this.context.hasPrev = this.currentPageIndex > 0;
  }
}
