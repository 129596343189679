import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UsedMatModule } from '../../used-mat.module';
import { MultipleSelectInputComponent } from './multiple-select-input/multiple-select-input.component';

@NgModule({
  imports: [
    CommonModule,
    UsedMatModule,
    MatCheckboxModule,
    FormsModule,
  ],
  declarations: [
    MultipleSelectInputComponent,
  ],
  exports: [
    MultipleSelectInputComponent,
  ],
})
export class MultipleSelectModule { }
