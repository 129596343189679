import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription, Observable } from 'rxjs';
import { tap, finalize, switchMap, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { IBlockAction, IBlockActions } from '../../shared/components/block-with-card/block-with-card.component';
import { PermissionChecker } from '../../shared/permissions/permission-guard.directive';
import { AttachmentsCreateDialogComponent } from '../attachments-create/attachments-create-dialog.component';

@Component({
  selector: 'exa-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss']
})
export class AttachmentsListComponent implements OnInit {
  @Input() attachments: any[];
  @Input() entity: string;
  @Input() entityId: string;
  @Input() addAttachmentActionId: string;
  @Input() deleteAttachmentActionId: string;
  @Input() viewMode: boolean;
  @Output() newAttachment = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() delete = new EventEmitter();
  dialogRef: MatDialogRef<AttachmentsCreateDialogComponent>;
  dialogData: any;
  downloadSub: Subscription;
  downloading = false;
  deleteSub: Subscription;
  deleting = false;
  actionsDefs = {
    addAttachment: <IBlockAction> {
      type: 'icon',
      icon: 'cloud_upload',
      text: 'Add Attachment',
      color: 'primary',
      callback: () => this.onClickCreateAttachment(),
    },
  };
  actions$: Observable<IBlockActions>;
  isAdmin$ = this.auth.isAdmin$;

  constructor(
    private fileSaver: FileSaverService,
    private dialog: MatDialog,
    private data: DataService,
    private customers: CustomersService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    const addAttachPermChecker = new PermissionChecker(this.addAttachmentActionId);
    this.actions$ = this.auth.loggedInUser$.pipe(
      map(user => {
        const headerActions = this.viewMode != true && addAttachPermChecker.checkUserPermission(user) ? [this.actionsDefs.addAttachment] : [];
        return {
          header: headerActions,
        };
      }),
    );
  }

  onClickCreateAttachment() {
    const width = window.innerWidth > 500 ? 480 : window.innerWidth - 20;
    const { entity, entityId } = this;
    this.dialogData = {
      disableClose: true,
      width: `${ width }px`,
      data: { entity, entityId },
    };
    this.dialogRef = this.dialog.open(AttachmentsCreateDialogComponent, this.dialogData);

    this.dialogRef.afterClosed().subscribe(() => {
      const newAttachment = this.dialogRef.componentInstance.newAttachment$.value;
      if (newAttachment) {
        this.newAttachment.emit(newAttachment);
      }
    });
  }


  onClickDownload(id) {
    if (this.downloadSub) {
      this.downloadSub.unsubscribe();
    }

    this.data.openAttachment(id, this.entity, this.entityId, null, this.entity === 'customer')
  }

  onClickDelete(id) {
    this.deleting = true;
    if (this.deleteSub) {
      this.deleteSub.unsubscribe();
    }
    this.deleteSub = this.data.deleteAttachment(id, this.entity, this.entity === 'customer')
      .pipe(finalize(() => this.deleting = false))
      .subscribe(() => this.delete.emit(id));
  }
}
