import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsListComponent } from './attachments-list/attachments-list.component';
import { AttachmentsCreateComponent } from './attachments-create/attachments-create.component';
import { AttachmentsCreateDialogComponent } from './attachments-create/attachments-create-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    AttachmentsListComponent,
    AttachmentsCreateComponent,
    AttachmentsCreateDialogComponent,
  ],
  exports: [
    AttachmentsListComponent,
    AttachmentsCreateComponent,
    AttachmentsCreateDialogComponent,
  ],
  entryComponents: [
    AttachmentsCreateDialogComponent,
  ],
})
export class AttachmentsModule { }
