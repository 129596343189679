// This is an auto-generate file, created by generate-actions-permissions script
export const actionsDef = { dashboard:
   { benchmark: { export_idr_data: 'dashboard__benchmark__exportIdrData' } },
  weather:
   { weather_comparison: { extract_data: 'weather__weatherComparison__extractData' } },
  utility_bill: { add_bill: 'utilityBill__addBill' },
  building:
   { create: 'building__create',
     import: 'building__import',
     edit: 'building__edit',
     import_idr_data: 'building__importIdrData',
     import_load_factor: 'building__importLoadFactor',
     edit_operations_schedule: 'building__editOperationsSchedule',
     add_note: 'building__addNote',
     delete_note: 'building__deleteNote',
     add_attachment: 'building__addAttachment',
     delete_attachment: 'building__deleteAttachment' },
  meter:
   { create: 'meter__create',
     create_virtual_meter: 'meter__createVirtualMeter',
     import: 'meter__import',
     edit: 'meter__edit',
     import_best_practice_model: 'meter__importBestPracticeModel',
     add_note: 'meter__addNote',
     delete_note: 'meter__deleteNote',
     add_attachment: 'meter__addAttachment',
     delete_attachment: 'meter__deleteAttachment' },
  waste_tracker:
   { waste_criteria:
      { create: 'wasteTracker__wasteCriteria__create',
        edit: 'wasteTracker__wasteCriteria__edit' },
     waste_event: { pin_event: 'wasteTracker__wasteEvent__pinEvent' } },
  customer_settings:
   { gbc_integration: { show: 'customerSettings__gbcIntegration__show' },
     smt_integration: { show: 'customerSettings__smtIntegration__show' } } };
export const permissionsMap = { dashboard__benchmark__exportIdrData: [ 'superuser' ],
  weather__weatherComparison__extractData: [ 'superuser', 'high', 'mid' ],
  utilityBill__addBill: [ 'superuser', 'high' ],
  building__create: [ 'superuser' ],
  building__import: [ 'superuser' ],
  building__edit: [ 'superuser' ],
  building__importIdrData: [ 'superuser', 'high' ],
  building__importLoadFactor: [ 'superuser', 'high' ],
  building__editOperationsSchedule: [ 'superuser', 'high', 'mid' ],
  building__addNote: [ 'superuser', 'high', 'mid' ],
  building__deleteNote: [ 'superuser', 'high', 'mid' ],
  building__addAttachment: [ 'superuser', 'high', 'mid' ],
  building__deleteAttachment: [ 'superuser', 'high', 'mid' ],
  meter__create: [ 'superuser' ],
  meter__createVirtualMeter: [ 'superuser' ],
  meter__import: [ 'superuser' ],
  meter__edit: [ 'superuser' ],
  meter__importBestPracticeModel: [ 'superuser' ],
  meter__addNote: [ 'superuser', 'high', 'mid' ],
  meter__deleteNote: [ 'superuser', 'high', 'mid' ],
  meter__addAttachment: [ 'superuser', 'high', 'mid' ],
  meter__deleteAttachment: [ 'superuser', 'high', 'mid' ],
  wasteTracker__wasteCriteria__create: [ 'superuser' ],
  wasteTracker__wasteCriteria__edit: [ 'superuser' ],
  wasteTracker__wasteEvent__pinEvent: [ 'superuser', 'high', 'mid' ],
  customerSettings__gbcIntegration__show: [ 'high' ],
  customerSettings__smtIntegration__show: [ 'superuser' ] };
