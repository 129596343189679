import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ISideNavItem } from './side-nav-item.model';
import { Router } from '@angular/router';

@Component({
  selector: 'exa-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
})
export class SideNavItemComponent {
  @Input() item: ISideNavItem;
  @Input() depth = 0;
  @Input() menuCollapsed = false;
  @Input() menuHovered = false;
  @Input() menuWidth: number;
  @ViewChild('menuItemEl') $menuItemEl: ElementRef;
  hovered = false;
  collapsed = true;
  rippleColor = 'rgba(113, 106, 202, .2)';
  submenuTransform = '';

  constructor(public router: Router) {}

  onItemClick() {
    this.collapsed = !this.collapsed;
  }

  onItemHover(value) {
    this.hovered = value;
    // if (value && this.menuCollapsed && this.item.children) {
    if (value && this.item.children) {
      this.showSubMenu();
    } else if (this.submenuTransform) {
      this.submenuTransform = '';
    }
  }

  showSubMenu() {
    const $menuItemEl = this.$menuItemEl.nativeElement;
    const bounding = $menuItemEl.getBoundingClientRect();
    const submenuHeight = this.item.children.length * bounding.height;
    const height = window.innerHeight;
    const translateY = bounding.top + submenuHeight > height
      ? height - submenuHeight
      : bounding.top;

    this.submenuTransform = `translate3d(${this.menuWidth - 16}px, ${translateY}px, 0)`;
  }

  isGroupActive() {
    const childs = this.item.children || [];
    const groupName = this.item.name.toLowerCase();

    return childs.reduce((current, child) => {
      const childUrl = child.route.join('');
      const isChildRoute = childUrl === this.router.url;
      const isChildRoutePart = this.router.url.includes(childUrl.split('/')[1]);
      return current ? current : (isChildRoute || isChildRoutePart);
    }, false);
  }

  iconClass(item) {
    return {
      [`exa-sidenav__link-icon--${item.icon}`]: true,
    };
  }
}
