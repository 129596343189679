import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMultipleSelectOption } from './multiple-select';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MultipleSelect } from './multiple-select';


@Component({
  selector: 'exa-multiple-select',
  templateUrl: './multiple-select.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true
    }
  ],
})
export class MultipleSelectComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() optionsData: any[];
  @Input() placeholder: string;
  @Input() childsName: string;
  @Input() childsLabel: string;
  @Input() error: boolean;
  @Input() required: boolean;
  @Input() defaultValue: string | string[] | {id: string, name?: string}[];
  @Output() selected = new EventEmitter();
  options: MultipleSelect;
  selection: IMultipleSelectOption[];
  propagateChanges: (selection: IMultipleSelectOption[]) => void;
  selectionText = this.placeholder;
  selection$ = new BehaviorSubject([]);
  selectionSub: Subscription;
  isDefaultsSet = false;

  get isSelectionUndefined() {
    return typeof this.selection === 'undefined' || this.selection === null;
  }

  constructor() { }

  ngOnInit() {
    const data = this.optionsData || [];
    this.options = new MultipleSelect(data, this.childsName, this.childsLabel);
    this.subscribeToSelection();
    this.setDefaultValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.optionsData && !changes.optionsData.firstChange) {
      this.ngOnDestroy();
      this.selection = [];
      this.selectionSub && this.selectionSub.unsubscribe();
      this.selection$ = new BehaviorSubject([]);
      this.isDefaultsSet = false;
      this.ngOnInit();
    }
  }

  subscribeToSelection() {
    if (this.selectionSub) { this.selectionSub.unsubscribe(); }
    this.selectionSub = this.selection$.subscribe((nextSelection) => {
      this.selection = this.options.getSelectedOptions(this.selection || [], nextSelection);
      this.selectionText = this.options.getOptionsStr(this.selection);
      this.selected.emit(this.selection);
      !!this.propagateChanges && this.propagateChanges(this.selection);
    });
  }


  ngOnDestroy(): void {
    if (this.selectionSub) { this.selectionSub.unsubscribe(); }
  }

  setDefaultValue() {
    const { defaultValue, isSelectionUndefined, isDefaultsSet } = this;
    const isArray = Array.isArray(defaultValue);
    const isStr = typeof defaultValue === 'string';
    const isStrArray = isArray && typeof defaultValue[0] === 'string';
    let defaultOptions = [];


    if (!defaultValue || isDefaultsSet) { return; }

    if (isStr) {
      defaultOptions = this.options.flatList.filter(i => i.id === defaultValue);
    } else if (isStrArray) {
      defaultOptions = this.options.flatList.filter((item) => {
        return !!(defaultValue as any[]).find(val => val === item.id);
      });
    } else {
      defaultOptions = this.options.flatList.filter(i => {
        return !!(defaultValue as any[]).find((val: any) => val.id === i.id);
      });
    }


    defaultOptions.forEach(option => {
      const selection = this.selection || [];
      this.selection$.next([...selection, option]);
    });

    this.isDefaultsSet = true;
  }

  writeValue(selection: IMultipleSelectOption[]): void {
    if (!selection) {
      return;
    }
    const selectedOptions = this.options.flatList.filter(i => {
      return !!(selection as any[]).find((val: any) => val.id === i.id);
    });
    this.selection$.next(selectedOptions);
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

}
