import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'exa-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAvailableComponent {
  @Input() isAvailable: boolean;
  @Input() notAvailableMsg = 'N/A';
}
