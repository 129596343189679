import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomersApi, BuildingsApi, MetersApi, CriteriasApi, UsersApi, TasksApi } from './api-entities';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  customers = new CustomersApi(this.http);
  buildings = new BuildingsApi(this.http);
  meters = new MetersApi(this.http);
  criterias = new CriteriasApi(this.http);
  users = new UsersApi(this.http);
  tasks = new TasksApi(this.http);
  insight = {
    trends: (data) => {
      return this.http.post(`${environment.apiBase}/insight/trends`, data);
    },

    distribution: (data): Observable<any> => {
      return this.http.post(`${environment.apiBase}/insight/distribution`, data);
    },

    opportunities: (data): Observable<any> => {
      return this.http.post(`${environment.apiBase}/insight/waste-saving`, data);
    },
  };

  constructor(private http: HttpClient) { }

  alertsList(params = {}) {
    return this.http.get(`${environment.apiBase}/alerts`, { params });
  }

  eventsList(params?) {
    return this.http.post(`${environment.apiBase}/criteria/alerts/detail/queryPage?pageNumber=${params.page}&pageSize=${params.pageSize}`, { ...params });
  }

  eventsGraph(body, params = {}) {
    return this.http.post(`${environment.apiBase}/electricalDashboard/wasteEvents/queryAll`, body, { params });
  }

  priority(body, params = {}) {
    return this.http.post(`${environment.apiBase}/meters-priority`, body, { params });
  }

  eventsDetails(id) {
    return this.http.get(`${environment.apiBase}/waste-events/${id}`);
  }

  pinEvent(id, pinned: boolean) {
    return this.http.put(`${environment.apiBase}/criteria/alerts/togglePinned`, { pinned: pinned, Id: id });
  }

  wasteMap(filters: any) {
    return this.http.post(`${environment.apiBase}/map`, filters);
  }

  downloadFile(url, extension, customerId?) {
    return this.http.get(url, {responseType: 'blob'})
      .pipe(map(blob => ({blob, extension})));
  }

  createNote(note: any) {
    const url = note.entity === 'meter'
        ? `${environment.apiBase}/meters/notes/setup/create`
        : `${environment.apiBase}/buildings/notes/setup/create`;
    return this.http.post(url, note);
  }

  deleteNote(params?) {
    const base = environment.apiBase;
    const url = params.entity === 'meter'
      ? `${base}/meters/notes/setup/${params.id}`
      : `${base}/buildings/notes/setup/${params.id}`;
    return this.http.delete(url);
  }

  createAttachment(body: any) {
    const url = body.entity === 'meter'
        ? `${environment.apiBase}/meters/file/${body.entity_id}`
        : `${environment.apiBase}/${body.entity}/file/${body.entity_id}`;

    const { file, entity, entity_id } = body;
    // const url = `${environment.apiBase}/attachments`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('entity', entity);
    formData.append('entity_id', entity_id);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }

  openAttachment(id: string, entity: string, customerId: string, isCustomer?: boolean) {
    const base = environment.apiBase;
    const url = entity === 'meter'
      ? `${base}/meters/file/${id}`
      : `${base}/${entity}/file/${id}`;
      window.open(url, "_blank");
  }


  deleteAttachment(id: string, entity: string, isCustomer?: boolean) {
    const base = environment.apiBase;
    const url = entity === 'meter'
      ? `${base}/meters/file/${id}`
      : `${base}/${entity}/file/${id}`;
    return this.http.delete(url);
  }
}
