import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WeaklyHoursProfileSchedule, ScheduleProfileInfo } from '../../shared/models/operation-schedule/models';

interface ProfileScheduleOption {
  id: string;
  displayName: string;
  profile?: ScheduleProfileInfo;
  profilesSchedule: WeaklyHoursProfileSchedule[];
}

@Component({
  selector: 'exa-building-operation-schedule',
  templateUrl: './building-operation-schedule.component.html',
  styleUrls: ['./building-operation-schedule.component.scss']
})
export class BuildingOperationScheduleComponent {
  selectedProfileScheduleOption: ProfileScheduleOption;
  profileScheduleOptions: ProfileScheduleOption[];
  profiles: ScheduleProfileInfo[];
  @Input()
  set profilesSchedules(schedules: WeaklyHoursProfileSchedule[]) {
    if (!schedules)
      return;
    this.profiles = schedules.map(s => s.profile);
    const allOption: ProfileScheduleOption = {
      id: 'all',
      displayName: 'All',
      profilesSchedule: schedules,
    };

    this.profileScheduleOptions = [allOption].concat(
      schedules.map(s => ({
        id: s.profile.id,
        displayName: s.profile.displayName,
        profile: s.profile,
        profilesSchedule: [s],
      })),
    );

    this.selectedProfileScheduleOption = allOption;
  }
  @Output() update = new EventEmitter();
}
