import { FileSaverService } from 'ngx-filesaver';
import { taskStatuses } from '../../constants';
import { ApiService } from '../api.service';
import { timer, throwError, Subject, interval, Observable } from 'rxjs';
import {
  switchMap,
  map,
  tap,
  takeUntil,
  takeWhile,
  delay,
  repeat,
  delayWhen,
  defaultIfEmpty,
  startWith,
} from 'rxjs/operators';

export class TasksData {
  constructor(private fileSaver: FileSaverService, private api: ApiService) {}

  status(taskId: string) {
    return this.api.tasks.status(taskId);
  }


  uploadFileUsingSignedUrl(method: string, url: string, file: File, customerId) {
    return this.api.tasks.uploadToSignedUrl(method, url, file, customerId);
  }

  uploadFile(url, file: File) : Observable<any>{
    return this.api.tasks.uploadFile(url, file);
  }

  exportIdr(params: any) {
    return this.api.tasks.exportIdr(params);
  }

  downloadExportedIdr(url) {
    return this.api.tasks.downloadExportedIdr(url);
  }

  exportDownload(url: string, filename: string) {
    return this.downloadExportedIdr(url).pipe(
      tap((blobFile) => this.fileSaver.save(blobFile, filename)),
    );
  }

  taskPollar(taskId, customerId, start = 0, end = 1) {
    const pollerPeriod = end * 5000;
    let isFirstTime = true;

    const poller$ = new Subject();

    return poller$.pipe(
      startWith({}),
      delayWhen(() => isFirstTime ? interval(0) : interval(pollerPeriod)),
      switchMap(() => this.api.tasks.status(taskId).pipe(
        tap(v => console.log(v)),
      )),
      map((res: any) => {
        if (res.status === 'FAILURE') {
          throw new Error(res && res.error);
        }
        return Object.assign({}, res, { done: res.status === 'SUCCESS'});
      }),
      tap(res => {
        if (res.status === taskStatuses.pending) {
          isFirstTime = false;
          poller$.next();
        }
      }),
    );
  }


}
