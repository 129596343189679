import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {
  UploadFileModalComponent,
  UploadFileModalData,
} from 'ex-library';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap, debounceTime } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { ImportBuildingComponent } from '../../imports/import-building/import-building.component';
import { DeleteConfirmationDialogComponent, IDeleteConfirmationData } from '../../shared/components/delete-confirmation-dialog.component';

export interface CustomerListElem {
  id: string;
  name: string;
}

@Component({
  selector: 'exa-customers-list',
  templateUrl: './customers-list.component.html',
  styles: [],
})
export class CustomersListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'actions'];
  customersSearch$ = new BehaviorSubject('');
  customers$: Observable<CustomerListElem[]>;
  loadingCustomers: boolean;
  loadingError = false;

  constructor(private dialog: MatDialog, private data: DataService) {
  }

  ngOnInit() {
    this.loadCustomers();
  }

  loadCustomers() {
    this.loadingCustomers = true;
    this.loadingError = false;
    return this.customers$ = <Observable<CustomerListElem[]>> this.customersSearch$
      .pipe(
        debounceTime(500),
        tap(() => this.loadingCustomers = true),
        switchMap((search) => {
          const params: any = {};
          if (search) { params.search = search; }
          return this.data.customers.list(params);
        }),
        tap(() => this.loadingCustomers = false),
        catchError((err) => {
          this.loadingError = true;
          this.loadingCustomers = false;
          return throwError(err);
        }),
      );
  }

  importBuildingsFile(customer: CustomerListElem) {
    this.dialog.open(ImportBuildingComponent, {
      data: {
        customer: customer
      },
      minWidth: '30%'
    });
  }

  onDeleteCustomer(customer) {
    return this.data.customers.delete(customer.id)
      .pipe(tap(() => this.ngOnInit()));
  }

  onClickDelete(customer) {
    const dialogData: IDeleteConfirmationData = {
      entity: customer,
      deleteRequest: this.onDeleteCustomer.bind(this),
      body: `Are you sure you want to delete the customer: ${customer.name}.
            click confirm to continue otherwise click cancel`,
      success: `Customer with name ${customer.name} has been deleted successfully`,
      failure: `failed to delete customer with name ${customer.name}. please try again`,
      route: ['/customers'],
    };

    this.dialog.open(DeleteConfirmationDialogComponent, {
      data: dialogData,
      minWidth: '30%',
    });
  }
}
