import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SideNavService } from '../layout/side-nav.service';
import { GROUPS, getRoutesByGroup } from '../auth/user-group-guard.provider';
import { AuthService } from '../auth/auth.service';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { DataService } from '../data/data.service';
import { tap, finalize, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AllOrSearchConfig } from '../shared/components/all-or-search-filter/all-or-search-filter.component';
import { Building } from '../shared/models/building.model';
import { ActivatedRoute } from '@angular/router';
import { onlyBuildingFilterBasicConfig } from '../shared/components/all-or-search-filter/all-or-search-filter.constants';
import { MatDialog } from '@angular/material';
import { BuildingDetailsComponent } from './modals/building-details.component';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from './dashboard.service';

interface FilterMeter {
  id: string;
  name: string;
}

interface FilterBuilding {
  id: string;
  name: string;
}


const ROUTES = [
  {
    id: 0,
    name: 'Benchmarking',
    link: ['benchmarking'],
    availableOn: GROUPS.all,
    fragment: ''
  },

  {
    id: 1,
    name: 'Waste Events Graph',
    link: ['waste-events'],
    availableOn: GROUPS.all,
    fragment: ''
  },
  {
    id: 2,
    name: 'Weather Comparison',
    link: ['weather-comparison'],
    availableOn: GROUPS.all,
    fragment: ''
  },
  {
    id: 3,
    name: 'Weather vs KWh',
    link: ['weather-vs-kwh'],
    availableOn: GROUPS.all,
    fragment: ''
  },
  {
    id: 4,
    name: 'Weather-KWh Heatmap',
    link: ['weather-kwh-heatmap'],
    availableOn: GROUPS.all,
    fragment: ''
  }
];

@Component({
  selector: 'exa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewChecked  {
  user = this.auth.getUser() || {};
  routes = getRoutesByGroup(ROUTES, (this.user && this.user.group) || []);
  selectedMeters = [];
  isBuildingLoaded: boolean; 
  buildingsInputConfig: AllOrSearchConfig<Building>;

  @Input() buildingsSearchFn: (name: string) => Observable<Building[]>;

  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  meters: Array<FilterMeter> = [];
  buildings: Array<FilterBuilding> = [];
  buildingId: string = null;
  buildingForm: FormGroup;
  buildingFC: FormControl;
  hideMeters: boolean;
  isMeterLoaded: boolean;
  isDateSelected: boolean;
  isLoadingData: boolean;

  constructor(
    public menu: SideNavService,
    private auth: AuthService,
    private data: DataService,
    private route: ActivatedRoute,
    private appService: DashboardService,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cdRef : ChangeDetectorRef,
    private toastr: ToastrService
  ) {
  }

  search() {
    var fromDate = this.range.fromDate.getFullYear() + "-" + (this.range.fromDate.getMonth() + 1) + "-" + this.range.fromDate.getDate();
    var toDate = this.range.toDate.getFullYear() + "-" + (this.range.toDate.getMonth() + 1) + "-" + this.range.toDate.getDate();
    var selectedBuildingsIds = this.buildingFC && this.buildingFC.value ? this.buildingFC.value : this.buildingId;
    if(this.isBuildingLoaded && this.isDateSelected && (this.isMeterLoaded || this.hideMeters)){
      this.appService.executeAction({ fromDate: fromDate, toDate: toDate, meters: this.selectedMeters, buildingId: selectedBuildingsIds });
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      const urlParams = new URLSearchParams(fragment);
      if (urlParams.get('buildingId')) {
        this.buildingId = urlParams.get('buildingId')
        localStorage["lastBuildingId"] =  this.buildingId;
      } else {
        if(localStorage["lastBuildingId"]){
          this.buildingId = localStorage["lastBuildingId"];
          this.router.navigate(
            [],
            {
              fragment: 'buildingId=' + this.buildingId
            });
        }
      }
    });
    
    this.user = this.auth.getUser();

    ROUTES.forEach(route => route.fragment = "buildingId="+this.buildingId);

    this.routes = getRoutesByGroup(ROUTES, (this.user && this.user.group) || []);
    var me = this;

    this.loadBuilding();
    this.buildingForm = this.fb.group({
      building: [{},],
    });
    this.buildingFC = this.buildingForm.controls.building as FormControl;
    this.buildingFC.valueChanges.subscribe(v => {
      this.router.navigate(
        [],
        {
          fragment: 'buildingId=' + v
        });
        this.buildingId = v;
        this.loadMeters();
    });

    this.buildingsInputConfig = {
      ...onlyBuildingFilterBasicConfig,
      valueOnChangeExtractorFn: building => building && building.id,
      searchItems: (name) => this.data.buildings.lookup(typeof name == "object" ? name : { name: name || '' }).pipe(map((res: any) => {
        return res.results.map(res => {
          return { id: res.id, name: res.name }
        });
      },
      )),
    };

    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(today.getFullYear(), today.getMonth() - 1, 1), toDate: today },
      applyLabel: "Submit",
    };
  }

  ngAfterViewInit() {
    if(!this.buildingId){
      setTimeout(() => {
        this.toastr.warning("Please select a building first");
      }, 0);
    }
  }

  // handler function that receives the updated date range object
  updateRange(range: Range) {
    this.range = range;
    if(range){
      this.isDateSelected = true;
      this.search();  
    }
  }

  openBuidlingDetailsModal() {
    this.dialog.open(BuildingDetailsComponent, {
      data: {
        building: { id: this.buildingId }
      },
      minWidth: '50%'
    });
  };

  onActivateRoute(event) {
    if (event.constructor.name == "BenchmarkingComponent") {
      this.hideMeters = false;
      if (!this.isMeterLoaded) {
        setTimeout(() => {
          this.loadMeters();
          }, 0);
      } else {
        setTimeout(() => {
        this.search();
        }, 0);
      }
    }
    else {
      this.hideMeters = true;
      this.search();
    }
  }

  onMeterSelected(event) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.selectedMeters.push(event.source.value);
      } else {
        var index = this.selectedMeters.indexOf(event.source.value);
        if (index != -1) {
          this.selectedMeters.splice(event.source.value, 1);
        }
      }
      this.isMeterLoaded = true;
      this.search();
    }
  }

  loadMeters() {
    if(this.buildingId){
      this.meters = [];
      this.selectedMeters = [];
      this.data.meters.mainMeters(this.buildingId)
        .pipe(map((res: any) => {
          this.selectedMeters = [];
          this.meters = [];
          res.forEach(meter => {
            this.meters.push({ id: meter.id, name: meter.name, });
            if (meter.main_meter) {
              this.selectedMeters = [meter.id];
            }
          })
          this.isMeterLoaded = true;
          this.search();
          return res;
        },
        )).subscribe();
    }
  };

  loadBuilding() {
    if(this.buildingId){
      this.data.buildings.details(this.buildingId).subscribe(response => {
        this.isBuildingLoaded = true;
        this.buildingFC.setValue({ id: response.id, name: response.name });
      })
    }
  };

  // helper function to create initial presets
  setupPresets() {

    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
    ]
  }
}
