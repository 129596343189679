import { OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

export const MeterTypeSettingsFGName = 'meterTypeSettings';

export class MeterMediaTypeSectionBase implements OnInit, OnDestroy {

  sectionFormGroup: FormGroup;

  @Input() meterForm: FormGroup;

  constructor(protected fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.sectionFormGroup)
      throw Error('"sectionFormGroup" is not defined yet!');
    this.meterForm.addControl(MeterTypeSettingsFGName, this.sectionFormGroup);
  }

  ngOnDestroy(): void {
    this.meterForm.removeControl(MeterTypeSettingsFGName);
    this.meterForm.updateValueAndValidity();
  }

  bodyData(): {[key: string]: any} {
    throw Error('"bodyData" method is not implemented');
  }

  labelsMap() {
    return {};
  }
}
