import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { ImportLoadFactorComponent } from '../../imports/import-load-factor/import-load-factor.component';
import { ImportMetersDataComponent } from '../../imports/import-meters-data/import-meters-data.component';
import { ImportMetersComponent } from '../../imports/import-meters/import-meters.component';
import { ListBaseComponent } from '../../shared/components/list-base/list-base.component';
import { ColWidths } from '../../shared/components/list-base/list.constants';
import { Building } from '../../shared/models/building.model';
import { AuthService, ERoles } from '../../auth/auth.service';
import { Roles } from '../../auth/user-group-guard.provider';
import { SideNavService } from '../../layout/side-nav.service';

const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};

@Component({
  selector: 'exa-buildings-list',
  templateUrl: './buildings-list.component.html',
  styles: [],
})
export class BuildingsListComponent extends ListBaseComponent<Building> implements OnInit, OnDestroy {
  user;
  isAdmin = false;
  filterModelMap = {
    zip_code: 'zip_code',
    city: 'city',
    'type.name': 'type_name',
    'state.name': 'state_name',
    name: 'name',
  };

  displayedColumns: any[] = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: ColWidths.xLarge,
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions
    },

    {
      headerName: 'State',
      field: 'state.name',
      minWidth: ColWidths.normal,
      width: ColWidths.normal,
      maxWidth: ColWidths.normal,
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      ...customFilterOptions,
    },
    {
      headerName: 'City',
      field: 'city',
      minWidth: ColWidths.normal,
      width: ColWidths.normal,
      maxWidth: ColWidths.normal,
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      ...customFilterOptions,
    },
    {
      headerName: 'Zip code',
      field: 'zip_code',
      minWidth: ColWidths.normal,
      width: ColWidths.normal,
      maxWidth: ColWidths.normal,
      filter: 'agNumberColumnFilter',
      filterParams: { keep: true },
      ...customFilterOptions,
    },
    {
      headerName: 'Type',
      field: 'type.name',
      minWidth: ColWidths.medium,
      width: ColWidths.medium,
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      ...customFilterOptions,
    },

    { headerName: '', field: 'action', cellRenderer: 'ListBaseActionsComponent' },
  ];
  // loading: boolean;
  buildingSearch$ = new BehaviorSubject('');
  buildings$: Observable<Building[]>;
  customerSub: Subscription;


  constructor(
      private dialog: MatDialog,
      private data: DataService,
      private customerService: CustomersService,
      auth: AuthService,
      sidenav: SideNavService,
    ) {
    super(auth, sidenav);
  }

  ngOnInit() {
    // to make table actions have access to this component
    // methods
    super.ngOnInit();
    this.gridOptions.context = {
      componentParent: this,
    };

    this.customerSub = this.customerService.selected$.subscribe((customer) => {
      if (this.gridParams) {
        this.onGridReady(this.gridParams);
      }
    });

    
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.customerSub) {
      this.customerSub.unsubscribe();
    }
  }

  importMeters(building) {
    this.dialog.open(ImportMetersComponent, {
      data: {building: building},
      minWidth: '30%',
    });
  }

  importMetersData(building) {
    this.dialog.open(ImportMetersDataComponent, {
      data: {building: building},
      minWidth: '30%',
    });
  }

  importLoadFactor(building) {
    this.dialog.open(ImportLoadFactorComponent, {
      data: {building: building},
      minWidth: '30%',
    });
  }

  


  getDataObservable(reqParams: any): Observable<any> {
    const customer = this.customerService.selected;
    return this.data.buildings.list({
      ...reqParams,
      company_id: customer ? customer.id : customer,
    }).pipe(
      withLatestFrom(this.auth.loggedInUser$),
      map(([res, user]) => {
      res.results = res.results || [];
      if(!localStorage["lastBuildingId"] && res.count > 0){
        localStorage["lastBuildingId"] =  res.results[0].id;
      }
      res.results.forEach(item => {
        item.gridLinks = [{ key: 'name', route: (data) => ['/dashboard/benchmarking'], params: (data) => "buildingId=" + data.id}];

        if (!user.isAdmin && user.group.indexOf(Roles.high) <= -1) {
          return;
        }

        const editAction = {
          type: 'icon',
          icon: 'edit',
          text: 'edit',
          route: ['/buildings/' + item.id],
        };

        const menuAction = {
          type: 'menu',
          icon: 'more_vert',
          items: []
        };

        const menuActionItems = {
          importMetersData: { method: 'importMetersData', text: 'Import IDR data file' },
        };

        item.gridActions = [
          editAction,
          {
            ...menuAction,
            items: Object.values(menuActionItems),
          }
        ];
      });
      return res;
    }));
  }
}
