import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxClickAction, MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';

function unique(values: any[]): any[] {
  return values.filter((v, i) => values.indexOf(v) === i);
}

class Option {
  isSelected = false;
  isHidden = false;

  constructor(public obj, private parent: Group) {
  }

  toggleSelection() {
    throw Error('"toggleSelection" method is not implemented');
  }

  select() {
    throw Error('"select" method is not implemented');
  }

  deselect() {
    throw Error('"deselect" method is not implemented');
  }

  notifyParent() {
    if (this.parent) {
      this.parent.onChildToggled();
    }
  }
}

class Child extends Option {

  constructor(public obj, parent: Group) {
    super(obj, parent);
  }

  toggleSelection() {
    this.isSelected = !this.isSelected;
    this.notifyParent();
  }

  select() {
    this.isSelected = true;
  }

  deselect() {
    this.isSelected = false;
  }
}

class Group extends Option {
  isGroup = true;

  get flatOptions(): Option[] {
    const children = this.children;
    const isGroupChildren = (children[0] as Group).isGroup;
    if (!isGroupChildren) {
      return children;
    }
    return (children as Group[]).reduce((childrenFlatArr, g) => {
      return [].concat(childrenFlatArr, g.flatOptions);
    }, []);
  }

  constructor(public obj, public children: Option[], parent: Group) {
    super(obj, parent);
  }

  toggleSelection() {
    this.isSelected ? this.deselect() : this.select();
    this.notifyParent();
  }

  select(updateChildren = true) {
    this.isSelected = true;
    if (updateChildren) {
      this.children.forEach(c => c.select());
    }
  }

  deselect(updateChildren = true) {
    this.isSelected = false;
    if (updateChildren) {
      this.children.forEach(c => c.deselect());
    }
  }

  onChildToggled() {
    const childrenSelectionStatuses: boolean[] = this.children.map(c => c.isSelected);
    const anyOptionIsNotSelected = childrenSelectionStatuses.some(s => !s);
    if (anyOptionIsNotSelected && this.isSelected) {
      this.deselect(false);
      this.notifyParent();
      return;
    }

    const allOptionsSelected = childrenSelectionStatuses.every(s => !!s);
    if (allOptionsSelected && !this.isSelected) {
      this.select(false);
      this.notifyParent();
      return;
    }
  }
}

class MultipleSelectModel {

  static getUniqueOptions(groupByElem: string, rawOptions: any[]) {
    const groupElemValues = rawOptions.map(o => o[groupByElem]);
    const uniqueGroupElemIds = unique(groupElemValues.map(v => v.id));
    return uniqueGroupElemIds.map(id => groupElemValues.find(v => v.id === id));
  }

  static createChildrenTree(parent: Group, availableRawOptions: any[], groupBy: string[]) {
    if (groupBy.length) {
      const layerGroupElem = groupBy[0];
      const nextLayersGroupElems = groupBy.slice(1);
      const layerGroupRawOptions = MultipleSelectModel.getUniqueOptions(layerGroupElem, availableRawOptions);
      parent.children = layerGroupRawOptions.map(groupRawOption => {
        const group = new Group(groupRawOption, [], parent);
        const currentGroupAvailableOptions = availableRawOptions.filter(o => o[layerGroupElem].id === groupRawOption.id);
        MultipleSelectModel.createChildrenTree(group, currentGroupAvailableOptions, nextLayersGroupElems);
        return group
      });
    } else {
      parent.children = availableRawOptions.map(o => new Child(o, parent));
    }
  }

  mappedOptions: Group[];
  flatOptions: Child[];

  get selectedOptionsValues(): any[] {
    return !this.flatOptions || !this.flatOptions.length? [] : this.flatOptions.filter(o => o.isSelected).map(o => o.obj);
  }

  constructor(protected options: any[], protected groupBy: string[], protected hasAllOption = true, protected allTitle = 'All') {
    if(!options || !options.length) {
      this.mappedOptions = [];
      return;
    }
    this.generateOptions();
    this.generateFlatOptions();
  }

  private generateFlatOptions() {
    this.flatOptions = !this.mappedOptions.length? [] : this.mappedOptions.reduce((optionsArr, g) => {
      return [].concat(optionsArr, g.flatOptions);
    }, []);
  }

  protected generateOptions() {
    const rootParent = new Group({id: 'all', name: this.allTitle}, [], null);
    rootParent.isHidden = !this.hasAllOption;
    MultipleSelectModel.createChildrenTree(rootParent, this.options, this.groupBy);
    this.mappedOptions = [rootParent];
  }

  private resetAll() {
    this.mappedOptions.forEach(g => g.deselect());
  }

  select(selectedOptions: { id: any }[]) {
    if (!this.mappedOptions.length) {
      return;
    }
    this.resetAll();
    const allOptions = this.flatOptions;
    if (!allOptions.length) {
      return;
    }
    selectedOptions.forEach(optionVal => {
      const option = allOptions.find(o => o.obj.id === optionVal.id);
      option.toggleSelection();
    });
  }
}

@Component({
  selector: 'exa-multiple-select-input',
  templateUrl: './multiple-select-input.component.html',
  styleUrls: ['./multiple-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectInputComponent),
      multi: true,
    },
    {
      provide: MAT_CHECKBOX_CLICK_ACTION,
      useValue: <MatCheckboxClickAction>'noop',
    }
  ],
})
export class MultipleSelectInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  multipleSelectModel: MultipleSelectModel;

  selectedOptions: any[];
  updateSelectedOptions: (selectedOption: any[]) => void;

  selectedText: string;

  @Input() options: any[];
  @Input() groupBy: string[];
  @Input() hasAllOption = true;

  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() allTitle = 'All';

  @Input() searchable = false;

  searchInput$ = new BehaviorSubject('');
  optionsResults: Observable<Option[]>;

  constructor() { }

  ngOnInit() {
    this.multipleSelectModel = new MultipleSelectModel(this.options, this.groupBy, this.hasAllOption, this.allTitle);
    this.optionsResults = this.searchInput$.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      map(text => {
        return this.multipleSelectModel.flatOptions.filter(({obj}) => {
          const name = ((obj.displayName || obj.name) as string).toLowerCase();
          return name.includes(text.toLowerCase());
        });
      }),
    );
  }

  ngOnChanges({options}: SimpleChanges): void {
    if (options.previousValue !== options.currentValue && !options.firstChange) {
      this.multipleSelectModel = new MultipleSelectModel(this.options, this.groupBy, this.hasAllOption, this.allTitle);
      if (this.selectedOptions) {
        this.multipleSelectModel.select(this.selectedOptions);
        this.createSelectedText();
      }
    }
  }

  writeValue(obj: any): void {
    this.selectedOptions = obj;
    if (this.multipleSelectModel) {
      this.multipleSelectModel.select(this.selectedOptions);
      this.createSelectedText();
    }
  }

  registerOnChange(fn: any): void {
    this.updateSelectedOptions = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onOptionClick(option: Option) {
    option.toggleSelection();
    this.selectedOptions = this.multipleSelectModel.selectedOptionsValues;
    this.updateSelectedOptions(this.selectedOptions);
    this.createSelectedText();
  }

  private createSelectedText() {
    if (!this.multipleSelectModel.mappedOptions.length) {
      return;
    }
    if (this.hasAllOption) {
      const allOption = this.multipleSelectModel.mappedOptions[0];
      if (allOption.isSelected) {
        this.selectedText = allOption.obj.name;
        return;
      }
    }

    const selection = this.multipleSelectModel.selectedOptionsValues.map(o => o.name);
    this.selectedText = selection.join(', ');
  }

}
