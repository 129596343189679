import { Component, OnInit } from '@angular/core';
import { ElectricalMeterService } from '../../data/meters/electrical-meter.service';
import { Meter } from '../../shared/models/meter.model';
import { mapDataMock } from './waste-map-data-mock';
import { CustomersService } from '../../customers/customers.service';
import { Building } from '../../shared/models/building.model';
import { Observable, zip } from 'rxjs';
import { tap, switchMap, share, map } from 'rxjs/operators';
import { Customer } from '../../shared/models/customer.model';
import { DataService } from '../../data/data.service';

@Component({
  selector: 'exa-waste-map',
  templateUrl: './waste-map.component.html',
  styles: []
})
export class WasteMapComponent implements OnInit {
  wasteData: any[];
  targetDensity: string;
  loadingError = false;
  loading = false;
  customer$ = this.customers.selected$;
  customer: Customer;
  pristine = true;

  buildingsSearchFn = this.data.buildings.filterByName.bind(this.data);
  metersSearchFn = this.electricalMeterSvc.filterByName.bind(this.electricalMeterSvc);

  constructor(private customers: CustomersService, private data: DataService, private electricalMeterSvc: ElectricalMeterService) { }

  ngOnInit() {
    this.loadingError = false;

    this.customer$ = this.customers.selected$;

    this.customer$.subscribe(
      (customer: Customer) => this.customer = customer,
      (err) => this.loadingError = true,
    );
  }

  onSearch(filtersAndData) {
    this.pristine = false;
    filtersAndData.metrics$.subscribe((res) => {
      this.targetDensity = filtersAndData.filters.target;
      this.wasteData = res;
    });
  }
}
