import { Component, OnInit } from '@angular/core';
import { ImportBase } from '../../../imports/import-base';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../data/data.service';
import { CustomersService } from '../../../customers/customers.service';
import { map, finalize, catchError, debounceTime, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'exa-import-building',
  templateUrl: './import-building.component.html',
  styles: []
})
export class ImportBuildingComponent extends ImportBase {
  uploadDisabled = false;
  customer$ = this.customers.selected$;

  constructor(
      private fb: FormBuilder,
      protected fileSaver: FileSaverService,
      protected toastr: ToastrService,
      protected data: DataService,
      private customers: CustomersService,
      private router: Router,
    ) {
    super(fileSaver, toastr, data);
  }

  setConfig() {
    const importMethod = () => {
      const id = this.customerId();
      return this.data.customers
        .importBuildings(id, this.signedUrl.path);
    };

    const signedUrlMethod = () => {
      const id = this.customerId();
      return this.data.customers.importBuildingUrl(id, this.selectedFile);
    };

    const importBuildingTemplate = () => {
      const id = this.customerId();
      return this.data.customers.importBuildingsTemplate(id);
    };

    const watchTask = (taskId) => {
      return this.data.tasks.status(taskId);
    };

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: watchTask,
      templateMethod: importBuildingTemplate,
      acceptedFileFormats: '.csv',
      dataForm: this.fb.group({
        typeId: [{value: null, disabled: this.submitting}, [Validators.required]],
        ownerName: ['', [Validators.required]]
      }),
      onStartSubmitting: () => this.uploadDisabled = true,
      onSubmittingFinished: () => this.uploadDisabled = false,
      onFinish: () => this.router.navigate(['/buildings']),
      url: "/api/buildings/import"
    };
  }

  customerId () {
    const { selected } = this.customers;
    return selected ? selected.id : null;
  }
}

