import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetersListComponent } from './meters-list/meters-list.component';
import { CustomersBuildingsFilterComponent } from './customers-buildings-filter/customers-buildings-filter.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [MetersListComponent, CustomersBuildingsFilterComponent],
  exports: [MetersListComponent],
})
export class MetersListModule { }
