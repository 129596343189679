import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CustomersCreateComponent } from './customers-create/customers-create.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { CustomersService } from './customers.service';
import { CustomersListDetailsComponent } from './customers-list-details/customers-list-details.component';
import { CustomersUsersCreateComponent } from './customers-users-create/customers-users-create.component';
import { CustomersUsersListComponent } from './customers-users-list/customers-users-list.component';
import { CustomersUsersDetailsComponent } from './customers-users-details/customers-users-details.component';
import { NotesModule } from '../notes/notes.module';
import { AttachmentsModule } from '../attachments/attachments.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NotesModule,
    AttachmentsModule,
  ],
  exports: [CustomersListComponent],
  declarations: [
    CustomersCreateComponent,
    CustomersListComponent,
    CustomersListDetailsComponent,
    CustomersUsersCreateComponent,
    CustomersUsersListComponent,
    CustomersUsersDetailsComponent
  ],
  providers: [CustomersService],
})
export class CustomersModule {
}
