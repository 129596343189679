import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map, switchMap, publishReplay, refCount, take } from 'rxjs/operators';
import { CustomersService } from '../../../customers/customers.service';
import { DataService } from '../../../data/data.service';
import { MediaTypeMeterBaseService, METER_SERVICES } from '../../../data/meters/media-type-meter-base.service';
import { MeterTypes } from '../../../data/meters/models/meters-defs.constants';
import { ImportBase } from '../../../imports/import-base';

@Component({
  selector: 'exa-import-meters',
  templateUrl: './import-meters.component.html',
  styles: []
})
export class ImportMetersComponent extends ImportBase implements OnInit {
  customer$ = this.customers.selected$;

  meterTypes = Object.values(MeterTypes);
  settingsForm = this.fb.group({
    meterType: [this.meterTypes[0], [Validators.required]],
  });
  meterTypeFC = <FormControl>this.settingsForm.get('meterType');
  metersSvc$: Observable<MediaTypeMeterBaseService> = this.meterTypeFC.valueChanges.pipe(
    startWith(this.meterTypeFC.value),
    map(meterType => this.meterServices.find(svc => svc.mediaType === meterType.type)),
    publishReplay(1),
    refCount(),
  );

  constructor(
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
    private customers: CustomersService,
    private router: Router,
    private fb: FormBuilder,
    @Inject(METER_SERVICES) private meterServices: MediaTypeMeterBaseService[]
  ) {
    super(fileSaver, toastr, data);
  }


  setConfig() {
    const signedUrlMethod = () => {
      return this.data.buildings.importMetersUrl(this.selectedFile);
    };

    const importMethod = () => {
      return this.metersSvc$.pipe(
        take(1),
        switchMap(
          svc => svc.importMeters(this.signedUrl.path),
        ),
      );
    };

    this.config = {
      onInit: () => {
        this.subscription.add(
          this.metersSvc$.subscribe(),
        );
      },
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => {
        return this.metersSvc$.pipe(
          take(1),
          switchMap(
            svc => svc.importMetersTemplate(),
          ),
        );  
      },
      acceptedFileFormats: '.csv',
      onFinish: () => this.router.navigate(['/buildings']),
      url: '/api/meters/import'
    };
  }
}
