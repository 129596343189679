import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { SideNavService } from '../../layout/side-nav.service';
import { map, withLatestFrom, delay, toArray } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, merge } from 'rxjs';

@Component({
  selector: 'exa-create-meter',
  templateUrl: './create-meter.component.html',
  styles: []
})
export class CreateMeterComponent implements OnInit {
  activeLinkIndex$: Observable<number>;
  tabClicks$ = new BehaviorSubject(0);

  routes = [
    {
      id: 0,
      name: 'Create New Meter',
      link: ['/meters/create'],
    },

    {
      id: 1,
      name: 'Create Virtual Meter',
      link: ['/meters/create/virtual'],
    },

    {
      id: 2,
      name: 'Import New Meter(s)',
      link: ['/meters/create/bulk'],
    },
  ];

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      public menu: SideNavService,
    ) { }

  ngOnInit() {
    this.setInitialActiveTab();
  }


  setInitialActiveTab() {
    this.activeLinkIndex$ = merge(
        this.tabClicks$,
        this.route.url,
      ).pipe(
        delay(100),
        map((data) => {
          const { url } = this.router;
          const selectedRoute = this.routes.find(r => r.link[0] === url);
          return selectedRoute.id;
        }),
      );
  }

  onTabClick(index) {
    this.tabClicks$.next(index);
  }
}
