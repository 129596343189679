import { Options, ChartOptions } from 'highcharts';

export const chartOptionDefault: ChartOptions = {
  backgroundColor: null,
  style: {
    fontFamily: 'Roboto',
  }
};

export const chartDefaultOptions: Options = {
  chart: chartOptionDefault,
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  colors: [
    '#CC6666',
    '#FF9999',
    '#999999',
    '#666666',
    '#FF3366',
    '#FFCC99',
    '#99CC66',
    '#99CC99',
    '#FF66CC',
    '#000066',
    '#CC99FF',
    '#993399',
    '#FF9966',
    '#CC9966',
    '#996633',
    '#0066CC',
    '#99CCFF',
    '#99CCCC',
    '#666699',
    '#9966FF',
    '#99FF66',
    '#33CC33',
    '#FF00FF',
    '#CC66CC',
    '#CC3366',
    '#66CC99',
    '#993366',
    '#CC3399',
    '#FF33CC',
    '#0000CC',
    '#CC99CC',
    '#00FF00',
    '#66CCCC',
    '#0099FF',
    '#33CCCC',
    '#00FF99',
    '#6666FF',
    '#9999FF',
    '#9966CC',
    '#CC0099',
    '#FFCCCC',
    '#FF6666',
    '#CCCC99',
    '#996666',
    '#999966',
    '#66FF00',
    '#FF9900',
    '#669933',
    '#99CC33',
    '#FF3300',
    '#990099',
    '#FF0089',
    '#9900CC',
    '#990066',
    '#EE82EE',
    '#CC33CC',
    '#CCCC66',
    '#FFFF99',
    '#FFCC00',
    '#FFFF00',
    '#330099',
    '#6633CC',
    '#333399',
    '#3366CC',
    '#00CCFF',
    '#00CCCC',
    '#66FFCC',
    '#99FFFF',
    '#0033FF',
    '#339999',
    '#CC6600',
    '#66CC00',
    '#33FF33',
    '#99FF33',
    '#33FF99',
    '#CCCCCC',
    '#99FF99',
    '#33CC66',
    '#339966',
    '#006699',
    '#CC3333',
    '#CC9933',
    '#FF0000',
    '#FFCC66',
    '#FFCC33',
    '#CC6633',
    '#33CC99',
    '#CC9999',
    '#CC6699',
    '#9999CC',
    '#00CC33',
    '#3399FF',
    '#0099CC',
    '#3366FF',
    '#FF33FF',
    '#9933CC',
    '#CC00FF',
    '#3399CC',
    '#669999',
    '#663366',
  ],
};

