import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Building } from '../../../shared/models/building.model';
import { Meter } from '../../../shared/models/meter.model';
import { UtilityBillsDataService } from '../utility-bills-data.service';

@Component({
  selector: 'exa-utility-bills-filters',
  templateUrl: './utility-bills-filters.component.html',
  styleUrls: ['./utility-bills-filters.component.scss'],
})
export class UtilityBillsFiltersComponent implements OnInit, OnChanges, OnDestroy {

  filtersForm: FormGroup;
  subscription = new Subscription();
  submitSubscription: Subscription;

  yearOptions: string[] = UtilityBillsFiltersComponent.generateYearOptions();
  meterFormControl: FormControl;

  @Input() buildings: Building[];
  @Input() meters: Meter[];
  @Output() onBuildingSelected = new EventEmitter<Building>();

  constructor(private fb: FormBuilder, private dataService: UtilityBillsDataService) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.meterFormControl = this.fb.control({value: '', disabled: true}, [Validators.required]);
    this.filtersForm = this.fb.group({
      building: ['', [Validators.required]],
      meter: this.meterFormControl,
      year: [this.yearOptions[0], [Validators.required]],
    });

    const buildingControl = this.filtersForm.controls.building;
    this.subscription.add(
      buildingControl.valueChanges.subscribe(b => this.onSelectBuilding(b)),
    );
  }

  ngOnChanges({buildings, meters}: SimpleChanges): void {
    if (buildings && !buildings.firstChange) {
      this.onBuildingsUpdated();
    }
    if (meters && !meters.firstChange && meters.currentValue !== meters.previousValue) {
      this.onMetersUpdated();
    }
  }

  onSelectBuilding(building: Building) {
    this.meterFormControl.reset({value: '', disabled: true});
    this.onBuildingSelected.emit(building);
  }

  onMetersUpdated() {
    this.meterFormControl.reset({value: this.meters[0], disabled: false});
  }

  onBuildingsUpdated() {
    const buildingFormControl = this.filtersForm.get('building');
    buildingFormControl.reset('');
  }

  onSubmit() {
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
    const {meter, year} = this.filtersForm.value;
    this.submitSubscription = this.dataService.getMeterYearBills(meter, year).subscribe();
  }

  static generateYearOptions() {
    const currentYear = (new Date()).getFullYear();
    const pastYear = 2000;
    return (new Array(currentYear - pastYear))
      .fill(null)
      .map((v, i) => (currentYear - i).toString());
  }
}
