import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'exa-popover',
  template: `
    <div
        class="popover"
        [ngClass]="classes">
      <div class="popover__toggle" (click)="toggle($event)">
        <ng-content select="[toggleButton]"></ng-content>
      </div>

      <div class="popover__content">
        <ng-content></ng-content>

        <button type="button" mat-icon-button (click)="hide()" class="popover__close">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  @Input() direction = 'bottom-right';
  @Input() disabled: boolean;
  @Output() opened = new EventEmitter();
  active = false;
  get classes () {
    return {
      'popover--active': this.active,
      ['popover--' + this.direction]: true,
    };
  }

  constructor() { }

  ngOnInit() {
  }

  hide() {
    this.active = false;
  }

  show() {
    this.active = true;
    this.opened.emit();
  }

  toggle(event) {
    if(this.disabled)
      return event.preventDefault();
    return this.active ? this.hide() : this.show();
  }
}
