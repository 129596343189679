import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, throwError, of } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../data/data.service';
import { map } from 'rxjs/operators';
import { SunburstRouteBase, ISunburstGraphDataItem, ISunburstParams } from '../insight-sunburst/sunburst-route-base';

@Component({
  selector: 'exa-insight-distribution',
  templateUrl: './insight-distribution.component.html',
  styles: []
})
export class InsightDistributionComponent extends SunburstRouteBase implements OnInit {
  constructor(private data: DataService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setInitialSettings() {
    this.type$ = this.route.params.pipe(map(params => params.type));
    this.title$ = this.type$.pipe(
      map(type => type === 'waste'
      ? 'Waste kWh (based on all 24 hours. Waste only)'
      : 'consumption')
    );
  }


  getDataObservabe(type: string, params: ISunburstParams): Observable<ISunburstGraphDataItem[]> {
    const metric = type === 'waste' ? type : 'kwh';
    return this.data.insight.distribution({ ...params, metric }).pipe(
      map((data) => this.nestDataByParent(data)),
    );
  }
}

