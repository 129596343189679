import { Component, OnInit } from '@angular/core';
import { ListBaseTextComponent } from './list-base-text.component';

@Component({
  selector: 'exa-list-base-link',
  template: `
    <ng-container *ngIf="params">
      <a [routerLink]="getLink()" [fragment]="getParams()" *ngIf="params.value?.length <= 30">{{params?.value}}</a>
      <a [routerLink]="getLink()" [fragment]="getParams()" *ngIf="params.value?.length > 30" [matTooltip]="params?.value">{{params?.value}}</a>
    </ng-container>
  `,
  styles: []
})
export class ListBaseLinkComponent extends ListBaseTextComponent {

  constructor() { super(); }

  //TODO Abdalla 01 Apr 2020 : This will make a performance issue
  getLink() {
    const { data, field } = this;
    let link;
    if (!data || !data.gridLinks) { return; }

    link = data.gridLinks.find(item => item.key === field);

    if (link) {
      return link.route(data);
    }
  }

  getParams() {
    const { data, field } = this;
    let link;
    if (!data || !data.gridLinks) { return; }

    link = data.gridLinks.find(item => item.key === field);

    if (link && link.params) {
      return link.params(data);
    }
  }
}
