import { Component, OnInit } from '@angular/core';
import { GROUPS, getRoutesByGroup } from '../auth/user-group-guard.provider';
import { AuthService } from '../auth/auth.service';
import { SideNavService } from '../layout/side-nav.service';


const ROUTES = [
  {
    id: 0,
    name: 'Trends Over Time',
    link: ['trends'],
    availableOn: GROUPS.all,
  },

  {
    id: 1,
    name: 'Distribution of Consumption',
    link: ['distribution', 'consumption'],
    availableOn: GROUPS.all,
  },

  {
    id: 2,
    name: 'Distribution of Waste',
    link: ['distribution', 'waste'],
    availableOn: GROUPS.all,
  },

  {
    id: 3,
    name: 'Saving Opportunities',
    link: ['opportunities'],
    availableOn: GROUPS.all,
  },
];

@Component({
  selector: 'exa-insight',
  template: `
    <div class="page" [class.collapsed]="menu.collapsed && !menu.isSmall">
      <div class="page__tabs mat-elevation-z2 bg--primary">
        <div class="page__tabs-title mat-display-1 text--grayscale-light" style="margin: 0">
          <div class="padding--large">
            Insights
          </div>
        </div>
        <nav mat-tab-nav-bar color="primary" backgroundColor="primary">
          <a
            *ngFor="let route of routes; let i = index"
            [routerLink]="route.link"
            routerLinkActive #rla="routerLinkActive"
            [routerLinkActiveOptions]="{exact: true}"
            [active]="rla.isActive"
            mat-tab-link>
            {{route.name}}
          </a>
        </nav>
      </div>
      <div class="page__tabs-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styles: []
})
export class InsightComponent implements OnInit {
  user = this.auth.getUser() || {};
  routes = getRoutesByGroup(ROUTES, (this.user && this.user.group) || []);


  constructor(
      public menu: SideNavService,
      private auth: AuthService,
    ) {
  }  ngOnInit() {
    this.user = this.auth.getUser();
    this.routes = getRoutesByGroup(ROUTES, (this.user && this.user.group) || []);
  }
}

