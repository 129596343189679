import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { IBlockActions } from '../../shared/components/block-with-card/block-with-card.component';
import { PermissionChecker } from '../../shared/permissions/permission-guard.directive';
import { NotesCreateDialogComponent } from '../notes-create/notes-create-dialog.component';
import { Subscription, Observable, BehaviorSubject, throwError} from 'rxjs';
import { finalize, tap, map, catchError } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'exa-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {
  @Input() notes: any[];
  @Input() entity: string;
  @Input() entityId: string;
  @Input() addNoteActionId: string;
  @Input() deleteNoteActionId: string;
  @Input() viewMode: boolean;
  @Output() newNote = new EventEmitter();
  @Output() delete = new EventEmitter();
  deleting = false;
  deleteSub: Subscription;
  dialogRef: MatDialogRef<NotesCreateDialogComponent>;
  dialogData: any;
  content: string;
  loading = false;
  error = false;
  errorMessage: any = null;
  newNote$ = new BehaviorSubject(null);
  actionsDefs = {
    addNote: {
      type: 'icon',
      icon: 'add_comment',
      text: 'Add Note',
      color: 'primary',
      callback: () => this.onClickCreateNote(),
    },
  };
  actions$: Observable<IBlockActions>;

  isAdmin$ = this.auth.isAdmin$;

  constructor(
    private dialog: MatDialog,
    private data: DataService,
    private auth: AuthService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    const addAttachPermChecker = new PermissionChecker(this.addNoteActionId);
  }

  onClickCreateNote() {
    const width = window.innerWidth > 500 ? 480 : window.innerWidth - 20;
    const { entity, entityId } = this;
    this.dialogData = {
      disableClose: true,
      width: `${ width }px`,
      data: { entity, entityId },
    };
    this.dialogRef = this.dialog.open(NotesCreateDialogComponent, this.dialogData);

    this.dialogRef.afterClosed().subscribe(() => {
      const newNote = this.dialogRef.componentInstance.newNote$.value;
      if (newNote) {
        this.newNote.emit(newNote);
      }
    });
  }

  onClickDelete(id) {
    this.deleting = true;
    if (this.deleteSub) {
      this.deleteSub.unsubscribe();
    }

    this.deleteSub = this.data.deleteNote({id: id, entity: this.entity})
      .pipe(finalize(() => this.deleting = false))
      .subscribe(() => this.delete.emit(id));
  }

  onClickSaveNote(){
    const note = { content: this.content, entity: this.entity, entity_id: this.entityId };
    this.loading = true;
    this.error = false;

   this.data.createNote(note)
      .pipe(
        tap((res) => this.newNote$.next(res)),
        finalize(() => this.loading = false),
        catchError(err => {
          this.error = true;
          this.errorMessage = err && err.error;
          return throwError(err);
        })
      ).subscribe((res) => {
        this.content = null;
        this.toastr.success('Note has been created successfully.');
        this.notes.unshift(res);
      });
  }
}
