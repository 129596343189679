import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, Subscription, throwError} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {predictedKwhMethods} from '../../constants';
import {DataService} from '../../data/data.service';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {PopoverComponent} from '../../shared/components/popover/popover.component';
import {MatDialog} from '@angular/material';
import {DeleteConfirmationDialogComponent, IDeleteConfirmationData} from '../../shared/components/delete-confirmation-dialog.component';
import {AuthService} from '../../auth/auth.service';
import {Roles} from '../../auth/user-group-guard.provider';
import {CustomersService} from '../../customers/customers.service';
import {LoadingStatesBSubject, startLoadingSync, updateLoading} from '@sigmaproit/loading-states';
import {ElectricalMeterService} from '../../data/meters/electrical-meter.service';
import {PaginationReqParams, PaginationResponse} from '../../shared/models/pagination-response.model';
import {Meter} from '../../shared/models/meter.model';
import {pageSizeParam} from 'ex-library';

@Component({
  selector: 'exa-criteria-details',
  templateUrl: './criteria-details.component.html',
  styleUrls: ['./criteria-details.component.scss']
})
export class CriteriaDetailsComponent implements OnInit {
  @ViewChild('popover') $popover: PopoverComponent;
  isAdmin = false;
  criteria$: Observable<any>;
  applyCriteriaStartDay: any = moment(new Date()).subtract(1, 'month').toDate();
  applyMaxDate = moment(new Date());
  applyingCriteria = false;
  applyingCriteriaSub: Subscription;
  applyingCriteriaError: any;
  loading: boolean;
  error = null;
  selectedHours$ = new BehaviorSubject({});
  meters$ = new BehaviorSubject([]);
  weekdayNames = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  hours = (new Array(24)).fill(null).map((_, i) => i);
  displayedColumns = ['name', ...this.hours.map(hr => `${hr}`)];
  weekDays$ = this.selectedHours$.pipe(
    map(selectedHours => {
      return Object.keys(this.weekdayNames)
        .map((name) => {
          const day = { name };
          this.hours.forEach(hr => {
            const selectedDayName = this.weekdayNames[name];
            const selectedDayHours = selectedHours[selectedDayName.toLowerCase()] || [];
            day[hr] = selectedDayHours.includes(hr);
          });
          return day;
        });
    }),
  );

  initialMetersPaginationParams: PaginationReqParams = {
    page: 0,
    pageSize: 7
  };
  metersPaginationParams$ = new BehaviorSubject<PaginationReqParams>(this.initialMetersPaginationParams);
  metersCount = null;

  metersLoadingStates$ = new LoadingStatesBSubject();
  loadMeters$ = new BehaviorSubject<string>(null);

  constructor(
      private route: ActivatedRoute,
      private data: DataService,
      private metersSvc: ElectricalMeterService,
      private toastr: ToastrService,
      private dialog: MatDialog,
      private auth: AuthService,
      private customers: CustomersService,
    ) { }

  ngOnInit() {
    const user = this.auth.getUser() || { group: [] };
    this.isAdmin = user.group.indexOf(Roles.superuser) > -1;
    this.loadCriteria();

    const loadMetersSubscription = combineLatest(this.loadMeters$, this.metersPaginationParams$).pipe(
      map(([criteriaId, paginationParams]) => ({criteriaId, paginationParams})),
      filter(({criteriaId, paginationParams}) => !!criteriaId && !!paginationParams),
      switchMap(({criteriaId, paginationParams}) => this.loadMeters(criteriaId, paginationParams))
    ).subscribe();
  }

  loadCriteria() {
    this.loading = true;
    this.criteria$ = this.route.params.pipe(
      filter(params => !!params.id),
      tap(() => {
        this.loading = true;
        this.error = false;
      }),
      switchMap(({ id }) => this.data.criterias.details(id)),
      map((criteria: any) => ({
        ...criteria,
        baseline: Object.values(predictedKwhMethods).find(method => method.id === criteria.modeling_method),
      })),
      tap((res: any) => this.selectedHours$.next(res.selected_hours)),
      tap(({id}) => this.loadMeters$.next(id)),
      catchError(err => {
        this.loading = false;
        this.error = true;
        return throwError(err);
      }),
      tap(() => this.loading = false),
    );
  }

  loadMeters(criteriaId: string, paginationParams: PaginationReqParams) {
    startLoadingSync(this.metersLoadingStates$);
    return (this.metersSvc.criteriaMeter({
      waste_criteria_id: criteriaId,
      ...paginationParams,
    }) as any as Observable<PaginationResponse<Meter[]>>).pipe(
      updateLoading(this.metersLoadingStates$, {swallowError: true}),
      tap(({results: meters, count}) => {
        this.metersCount = count;
        this.meters$.next(meters);
      })
    );
  }

  onClickApplyCriteria(id) {
    const data = { first_day: moment(this.applyCriteriaStartDay).format('YYYY-MM-DD') };
    const selected = this.customers.selected;

    this.applyingCriteria = true;
    this.applyingCriteriaSub = this.data.criterias.immediateApply(id, data)
      .pipe(
        finalize(() => {
          this.applyingCriteria = false;
        })
      ).subscribe(
        () => {
          this.applyingCriteriaSub.unsubscribe();
          this.$popover.hide();
          this.toastr.success('Criteria is running now, when it\'s done you\'ll receive an email', null, {
            timeOut: 10000
          });
        },
        (err) => this.applyingCriteriaError = err && err.error,
      );
  }

  onDeleteCriteria(criteria) {
    return this.data.criterias.delete(criteria.id);
  }

  onClickDelete(criteria) {
    const dialogData: IDeleteConfirmationData = {
      entity: criteria,
      deleteRequest: this.onDeleteCriteria.bind(this),
      body: `Are you sure you want to Delete the criteria: ${criteria.name}.
            click confirm to continue otherwise click cancel`,
      success: `Criteria with name ${criteria.name} has been deleted successfully`,
      failure: `failed to delete criteria with name ${criteria.name}. please try again`,
      route: ['/criteria'],
    };

    this.dialog.open(DeleteConfirmationDialogComponent, {
      data: dialogData,
      minWidth: '30%',
    });
  }

  onMetersPaginationUpdate(page, pageSize = this.initialMetersPaginationParams[pageSizeParam]) {
    this.metersPaginationParams$.next({page: page + 1, pageSize});
  }
}
