import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'exa-details-banner',
  templateUrl: './details-banner.component.html',
  styleUrls: ['./details-banner.component.scss']
})
export class DetailsBannerComponent implements OnInit {
  @Input() color = 'primary';
  @Input() theme = 'dark';
  @Input() shadow = 'normal';

  constructor() { }

  ngOnInit() {
  }
}
