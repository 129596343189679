import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityBillsCreateModule } from '../../features/utility-bills-create/utility-bills-create.module';
import { UtilityBillsListModule } from '../../features/utility-bills-list/utility-bills-list.module';
import { UtilityBillsMonthlyAnalysisModule } from '../../features/utility-bills-monthly-analysis/utility-bills-monthly-analysis.module';

import { UtilityBillsPageRoutingModule } from './utility-bills-page-routing.module';
import { UtilityBillsCreatePgComponent } from './utility-bills-create-pg/utility-bills-create-pg.component';
import { UtilityBillsListPgComponent } from './utility-bills-list-pg/utility-bills-list-pg.component';
import { UtilityBillsMonthlyAnalysisPgComponent } from './utility-bills-monthly-analysis-pg/utility-bills-monthly-analysis-pg.component';

@NgModule({
  imports: [
    CommonModule,
    UtilityBillsPageRoutingModule,
    UtilityBillsCreateModule,
    UtilityBillsListModule,
    UtilityBillsMonthlyAnalysisModule,
  ],
  declarations: [UtilityBillsCreatePgComponent, UtilityBillsListPgComponent, UtilityBillsMonthlyAnalysisPgComponent]
})
export class UtilityBillsPageModule { }
