import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

const TYPES = ['waste', 'consumption'];

@Injectable({
  providedIn: 'root'
})
export class DistributionGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const { params } = next;
    const type = params && params.type;

    if (type && TYPES.includes(type)) {
      return true;
    }

    this.router.navigateByUrl(`/insight/distribution/${TYPES[0]}`);

    return false;
  }
}
