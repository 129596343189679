import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data/data.service';
import { map, filter, switchMap, tap, finalize, share } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'exa-criteria-edit',
  templateUrl: './criteria-edit.component.html',
  styles: []
})
export class CriteriaEditComponent implements OnInit {

  loading = false;
  criteria$ = this.route.params.pipe(
    map((params: any) => params.id),
    filter(id => !!id),
    switchMap(id => this.data.criterias.details(id)),
  );

  constructor(private data: DataService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadCriteria();
  }

  loadCriteria() {
    this.criteria$ = this.route.params.pipe(
      tap(() => this.loading = true),
      map((params: any) => params.id),
      filter(id => !!id),
      switchMap(id => this.data.criterias.details(id)),
      tap(() => this.loading = false),
      share(),
      finalize(() => this.loading = false),
    );
  }

  saveCallback(data, id) {
    return this.data.criterias.update(id, data);
  }
}
