import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap, catchError, delay } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { OperationScheduleDataService } from '../../data/operation-schedule-data.service';
import { Building } from '../../shared/models/building.model';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../auth/auth.service';
import { Roles } from '../../auth/user-group-guard.provider';
import { CustomersService } from '../../customers/customers.service';
import { actionsDef } from '../../shared/permissions/permissions-actions';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import { filter } from 'rxjs/internal/operators/filter';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'exa-buildings-details-modal',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.scss']
})
export class BuildingDetailsComponent implements OnInit {

  loading = false;
  loadingError = false;
  building: Building;
  building$: Observable<Building>;
  buildingFound = true;
  meters$ = new BehaviorSubject([]);
  notes$ = new BehaviorSubject([]);
  attachments$ = new BehaviorSubject([]);
  operationScheduleId$ = new BehaviorSubject<string>(null);
  operationSchedule$: Observable<any>;
  operationScheduleSection = new SectionLoadingStates();
  permActionDefs = actionsDef;


  constructor(
    private activatedRoute: ActivatedRoute,
    private data:  DataService,
    private operationScheduleDataSvc: OperationScheduleDataService,
    private dialog: MatDialog,
    private auth: AuthService,
    private customers: CustomersService,
    public dialogRef: MatDialogRef<any>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public modalData: { building: { id: string } },
  ) { }

  ngOnInit() {
    const user = this.auth.getUser() || { group: [] };
    this.loadBuilding();
    this.operationSchedule$ = this.operationScheduleId$.pipe(
      filter(id => !!id),
      delay(0),
      switchMap(id => this.loadOperationSchedule(id)),
    );
  }

  loadBuilding() {
    this.loading = true;
    this.loadingError = false;
    this.building$ = this.activatedRoute.params.pipe(
      switchMap((params) => this.data.buildings.details(this.modalData.building.id)),
      tap(() => this.loading = false),
      tap(building => this.building = <Building>building),
      tap((res: any) => this.meters$.next(res.meters || [])),
      tap((res: any) => this.notes$.next(res.notes || [])),
      tap((res: any) => this.attachments$.next(res.attachments || [])),
      tap(res => this.operationScheduleId$.next(res.operation_schedule)),
      catchError((err) => {
        if (err.status === 404) {
          this.buildingFound = false;
        } else {
          this.loadingError = true;
        }
        return throwError(err);
      }),
    );
  }

  loadOperationSchedule(scheduleId: string) {
    const section = this.operationScheduleSection;
    section.setLoadingState();
    return this.operationScheduleDataSvc.getSchedule(scheduleId).pipe(
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState();
        return EMPTY;
      }),
    );
  }

  goToBuilding(){
    this.close();
    this.router.navigate(['/buildings/' + this.modalData.building.id]);
  }

  close() {
    this.dialogRef.close();
  }
}
