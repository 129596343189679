import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingStatesBSubject, startLoading, updateLoading } from '@sigmaproit/loading-states';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Subscription, Subject, EMPTY } from 'rxjs';
import { delay, switchMap, tap, map, catchError } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import {
  GbcCustodian,
  GreenButtonConnectService, GbcMappedMeter,
} from '../../../data/green-button-connect/green-button-connect.service';
import { MeterTypes } from '../../../data/meters/models/meters-defs.constants';
import { Customer } from '../../../shared/models/customer.model';
import { GreenButtonConnectMapMetersComponent } from '../green-button-connect-map-meters/green-button-connect-map-meters.component';

export interface GbcManageInfo {
  custodian: GbcCustodian;
}

@Component({
  selector: 'exa-green-button-connect-manage-subscription',
  templateUrl: './green-button-connect-manage-subscription.component.html',
  styleUrls: ['./green-button-connect-manage-subscription.component.scss']
})
export class GreenButtonConnectManageSubscriptionComponent implements OnInit {

  displayedColumns = {
    building: 'building',
    meter: 'meter',
    usagePoint: 'usage_point_id',
    actions: 'actions',
  };

  displayedColIds = Object.values(this.displayedColumns);

  metersLoadingStates$ = new LoadingStatesBSubject();
  loadMeters$ = new BehaviorSubject(null);
  meters$ = this.loadMeters$.pipe(
    delay(0),
    startLoading(this.metersLoadingStates$),
    switchMap(
      () => this.gbcSvc.mappedMeters(this.gbcManageInfo.custodian.id).pipe(
        updateLoading(this.metersLoadingStates$),
      )
    ),
    map(res => res.map( p => ({
      ...p,
      meter: {
        ...p.meter,
        // todo: BE should return the media type of the meter
        // url: `${Object.values(MeterTypes).find(t => t.type === p.meter.meter_type).route}/${p.meter.id}`,
        building: {
          ...p.meter.building,
          url: `/buildings/${p.meter.building.id}`,
        },
      },
    }))),
    tap(console.log)
  );

  unmapMeterLoadingStates$ = new LoadingStatesBSubject();
  unmapMeter$ = new Subject<GbcMappedMeter>();
  onUnmapMeter$ = this.unmapMeter$.pipe(
    startLoading(this.unmapMeterLoadingStates$),
    switchMap(
      (gbcMeter) => this.gbcSvc.unmapMeter(gbcMeter.id).pipe(
        updateLoading(this.unmapMeterLoadingStates$),
        catchError(err => {
          this.toastr.error(`Unmapping Meter ${gbcMeter.meter.name} has been failed!`);
          return EMPTY;
        }),
        map(() => gbcMeter),
      ),
    ),
    tap(gbcMeter => this.toastr.success(`Meter ${gbcMeter.meter.name} has been unmapped successfully!`)),
  );

  subSink = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<GreenButtonConnectManageSubscriptionComponent>,
    private dialog: MatDialog,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public gbcManageInfo: GbcManageInfo,
    private gbcSvc: GreenButtonConnectService,
  ) {
  }

  ngOnInit() {
    this.subSink.add(
      this.onUnmapMeter$.subscribe(),
    );
  }

  unmapMeter(meter: GbcMappedMeter) {
    this.unmapMeter$.next(meter);
  }

  mapMeters() {
    this.dialogRef.close();
    return this.dialog.open(GreenButtonConnectMapMetersComponent, {
      width: DIALOG_STATIC_WIDTHS.medium,
      data: {
        custodian: this.gbcManageInfo.custodian,
      },
    })
  }

}
