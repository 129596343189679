import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { MeterMediaType } from '../meters/models/media-types.models';
import { Building } from '../../shared/models/building.model';

export class BuildingsData {
  constructor(private api: ApiService) {}

  create(building): Observable<any> {
    return this.api.buildings.create(building);
  }

  update(id, building): Observable<any> {
    return this.api.buildings.update(id, building);
  }

  delete(id): Observable<any> {
    return this.api.buildings.delete(id);
  }

  /**
   * @todo: try to fix the behavior of behavior subject
   * and then use it to share the same lookups and
   * don't request it every time once data is loaded.
   *
   */
  lookups() {
    return this.api.buildings.lookup();
  }

  list(params?): Observable<any> {
    return this.api.buildings.list(params);
  }

  lookup(params?): Observable<any> {
    return this.api.buildings.lookup(params);
  }

  filterByName(name: string) {
    return this.api.buildings.list({
      name: name,
    });
  }

  details(id: string) {
    return this.api.buildings.details(id);
  }

  types() {
    return this.api.buildings.types();
  }

  importMetersUrl(file: File) {
    return this.api.tasks.getSignedUrl({
      content_type: 'text/csv',
      entity: 'meter',
      filename: file.name,
    }, { }).pipe(map(res => ({ ...res })));
  }

  importMeters(customerId: string, filePath: string) {
    return this.api.buildings.importMeters({filepath: filePath}, customerId);
  }

  importMetersTemplate(customerId) {
    return this.api.buildings.importMetersTemplate(customerId).pipe(
      switchMap(({ path, url }: any) => {
        const extension = path.slice(path.search(/\.\w+$/)).replace(/\./, '');
        return this.api.downloadFile(url, extension, customerId);
      }),
      map(({ blob, extension}) => {
        return {
          blob,
          name: `meters-template.${extension}`,
        };
      }),
    );
  }

  importMetersDataUrl(buildingId: number, file: File) {
    return this.api.tasks.getSignedUrl({
      id: buildingId,
      content_type: 'text/csv',
      entity: 'meters-data',
      filename: file.name,
    }, { }).pipe(map(res => ({ ...res })));
  }

  getMeterData(params?) {
    return params;
  }

  importMetersDataTemplate(buildingId: number) {
    return this.api.buildings.importMetersDataTemplate(buildingId).pipe(
      map((templateBlob) => {
        return {
          blob: templateBlob,
          name: `building-${buildingId}-idr-data-template.csv`,
        };
      }),
    );
  }

  importLoadFactorUrl(buildingId: number, file: File, customer_id) {
    return this.api.tasks.getSignedUrl({
      id: buildingId,
      content_type: 'text/csv',
      entity: 'load-factor',
      filename: file.name,
    }, { customer_id }).pipe(map(res => ({ ...res, customerId: customer_id })));
  }

  importLoadFactor(buildingId: number, filePath: string) {
    return this.api.buildings.importLoadFactor(buildingId, {filepath: filePath});
  }

  importLoadFactorTemplate() {
    return this.api.buildings.importLoadFactorTemplate().pipe(
      map((templateBlob) => {
        return {
          blob: templateBlob,
          name: 'load-factor-template.csv',
        };
      })
    );
  }

  getGooglePlaces(buildingId: string) {
    return this.api.buildings.getGooglePlaces(buildingId);
  }

  setGooglePlace(buildingId: string, placeId: string) {
    return this.api.buildings.setGooglePlace(buildingId, placeId);
  }

  getStates(){
    return this.api.buildings.getStates();
  }

  getCountries(){
    return this.api.buildings.getCountries();
  }
}
