import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { datetimeLabelFormatterFactory, tooltipDatetimeFormatterFactory } from 'ex-library';
import { Options, PointObject } from 'highcharts';
import { toUtcDateValue } from '../../../shared/helpers/time.helper';
import { NumberFormatPipe } from '../../../shared/pipes/number-format.pipe';
import { WasteEventTsPoint } from '../waste-event.models';

export interface WasteEventsTsConfig {
  metersEventsTs: WasteEventTsPoint[];
  filters: {
    startTime: string;
    endTime: string;
  };
}

@Component({
  selector: 'exa-waste-events-ts-chart',
  template: `
    <exlib-chart [chartOptions]="chartOptions" (chartRendered)="chartRendered.emit($event)"></exlib-chart>
  `,
  styles: []
})
export class WasteEventsTsChartComponent implements OnChanges {

  chartOptions: Options;
  @Input() config: WasteEventsTsConfig;
  @Output() chartRendered = new EventEmitter();

  private datetimeLabelFormatter = datetimeLabelFormatterFactory(null);

  constructor(private numberFormat: NumberFormatPipe) {
  }

  ngOnChanges({config}: SimpleChanges): void {
    if (config.currentValue !== config.previousValue) {
      this.buildChart();
    }
  }

  private buildChart() {
    const self = this;
    const {startTime, endTime} = this.config.filters;
    this.chartOptions = <Options> {
      chart: {
        type: 'spline',
        zoomType: 'x',
        height: 300,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter(): boolean | string {
          return self.tooltipFormatter(this);
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: this.datetimeLabelFormatter,
          padding: 30,
        },
        min: toUtcDateValue(startTime),
        max: toUtcDateValue(endTime),
        tickPixelInterval: 150,
      },
      yAxis: {
        title: {
          text: 'Daily Waste in kWh'
        },
      },
      series: this.buildSeries(),
    };
  }

  private buildSeries() {
    const {metersEventsTs} = this.config;
    return metersEventsTs
      .map(meterEvents => {
        const {meter, results} = meterEvents;
        return {
          id: meterEvents.id,
          name: `${meter.building.name}/ ${meter.name}`,
          data: results.map(p => [toUtcDateValue(p.day), p.waste]),
          color: meterEvents.color,
          event: meterEvents
        };
      })
      .sort((aPoint, bPoint) => {
        return aPoint.name < bPoint.name ? -1 : aPoint.name > bPoint.name ? 1 : 0;
      });
  }

  private tooltipFormatter(instance: PointObject) {
    const datetimeHeader = tooltipDatetimeFormatterFactory()(instance.x);
    const seriesBody = this.tooltipSeriesBodyFormatter(instance);
    return `${datetimeHeader}<br>${seriesBody}`;
  }

  private tooltipSeriesBodyFormatter(instance: any) {
    const series: {userOptions: any; color: string; name: string} = instance.series;
    const meterNameLine = `<span>${series.name}</span>`;
    const titlePart = `<span style="color:${series.color}">\u25CF</span> ${series.userOptions.event.verbose_id}`;
    const valuePart = `<strong>${this.numberFormat.transform(instance.y)}</strong> kWh`;
    return `${meterNameLine}<br>${titlePart}: ${valuePart}`;
  }
}
