import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingStatesModule } from '@sigmaproit/loading-states';
import {
  ExLibraryModule,
  MaterialDynamicFormsModule,
  UploadFilesModule,
  IndicatorsModule,
  ChartsModule,
} from 'ex-library';

import { ToastrModule } from 'ngx-toastr';

import { DataModule } from '../data/data.module';
import { UsedMatModule } from '../used-mat.module';
import { LoadingContentModule } from '../widgets/loading-content/loading-content.module';
import { MultipleSelectModule } from '../widgets/multiple-select/multiple-select.module';
import { NumberInputMaskModule } from '../widgets/number-input-mask/number-input-mask.module';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { SelectFileComponent } from './components/select-file.component';
import { LoadingErrorComponent } from './components/loading-error/loading-error.component';
import { OwnerAutocompleteComponent } from './components/owner-autocomplete.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { AttachedMetersComponent } from './components/attached-meters.component';
import { PermissionsModule } from './permissions/permissions.module';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { NotAvailablePipe } from './pipes/not-available.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { WindowRefService } from './services/window-ref.service';
import { PopoverComponent } from './components/popover/popover.component';
import { SearchBoxComponent } from './components/search-box.component';
import { AgGridModule } from 'ag-grid-angular';
import { ListBaseActionsComponent } from './components/list-base/list-base-actions.component';
import { ListBaseLoadingComponent } from './components/list-base/list-base-loading.component';
import { ListBaseLinkComponent } from './components/list-base/list-base-link.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog.component';
import { LookupDropdownComponent } from './components/lookup-dropdown.component';
import { ListBaseFilterComponent } from './components/list-base/list-base-filter.component';
import { DetailsBannerComponent } from './components/details-banner/details-banner.component';
import { BlockWithCardComponent } from './components/block-with-card/block-with-card.component';
import { ContentWithLoadingErrorComponent } from './components/content-with-loading-error.component';
import { ListBaseDateFilterComponent } from './components/list-base/list-base-date-filter.component';
import { ListBaseTextComponent } from './components/list-base/list-base-text.component';
import { ListBaseHeaderComponent } from './components/list-base/list-base-header.component';
import { RouterModule } from '@angular/router';
import { InputErrorPipe } from './pipes/input-error.pipe';
import { AllOrSearchFilterComponent } from './components/all-or-search-filter/all-or-search-filter.component';
import { DisplayNameExtractorPipe } from './components/display-name-extractor.pipe';
import { SearchSelectMultipleComponent } from './components/search-select-multiple/search-select-multiple.component';
import { CustomSearchSelectComponent } from './components/search-select/custom-search-select.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ExLibraryModule,
    MaterialDynamicFormsModule,
    UploadFilesModule,
    ChartsModule,
    RouterModule,
    LoadingStatesModule,

    FlexLayoutModule,
    UsedMatModule,
    DataModule,
    AgGridModule.withComponents([
      ListBaseActionsComponent,
      ListBaseLoadingComponent,
      ListBaseLinkComponent,
      ListBaseTextComponent,
      ListBaseFilterComponent,
      ListBaseDateFilterComponent,
      ListBaseHeaderComponent,
    ]),
    LoadingContentModule,
    MultipleSelectModule,
    PermissionsModule,
    NumberInputMaskModule,
  ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        ExLibraryModule,
        MaterialDynamicFormsModule,
        UploadFilesModule,
        IndicatorsModule,
        ChartsModule,
        RouterModule,
        LoadingStatesModule,

        FlexLayoutModule,
        UsedMatModule,
        DataModule,
        InfiniteScrollComponent,
        SelectFileComponent,
        LoadingErrorComponent,
        OwnerAutocompleteComponent,
        MultipleSelectComponent,
        AttachedMetersComponent,
        DateFormatPipe,
        NotAvailablePipe,
        NumberFormatPipe,
        PopoverComponent,
        SearchBoxComponent,

        AgGridModule,
        DeleteConfirmationDialogComponent,
        LookupDropdownComponent,
        DetailsBannerComponent,
        BlockWithCardComponent,
        ContentWithLoadingErrorComponent,
        LoadingContentModule,
        MultipleSelectModule,
        PermissionsModule,
        NumberInputMaskModule,
        InputErrorPipe,
        AllOrSearchFilterComponent,
        SearchSelectMultipleComponent,
        CustomSearchSelectComponent,
        SearchSelectComponent
    ],
  declarations: [
    InfiniteScrollComponent,
    SelectFileComponent,
    LoadingErrorComponent,
    OwnerAutocompleteComponent,
    MultipleSelectComponent,
    AttachedMetersComponent,
    DateFormatPipe,
    NotAvailablePipe,
    NumberFormatPipe,
    PopoverComponent,
    SearchBoxComponent,
    ListBaseActionsComponent,
    ListBaseLoadingComponent,
    ListBaseTextComponent,
    ListBaseLinkComponent,
    ListBaseHeaderComponent,
    DeleteConfirmationDialogComponent,
    LookupDropdownComponent,
    ListBaseFilterComponent,
    ListBaseDateFilterComponent,
    DetailsBannerComponent,
    BlockWithCardComponent,
    ContentWithLoadingErrorComponent,
    InputErrorPipe,
    AllOrSearchFilterComponent,
    DisplayNameExtractorPipe,
    SearchSelectMultipleComponent,
    CustomSearchSelectComponent,
    SearchSelectComponent
  ],
  providers: [
    WindowRefService,
    NumberFormatPipe,
  ],
  entryComponents: [
    DeleteConfirmationDialogComponent,
  ],
})
export class SharedModule {
}
