import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NotAvailableModule } from '../widgets/not-available/not-available.module';
import { CriteriaCreateComponent } from './criteria-create/criteria-create.component';
import { CriteriaComponent } from './criteria.component';
import { WeeklyHoursRangeComponent } from './weekly-hours-range/weekly-hours-range.component';
import { CriteriaListComponent } from './criteria-list/criteria-list.component';
import { CriteriaDetailsComponent } from './criteria-details/criteria-details.component';
import { CriteriaEditComponent } from './criteria-edit/criteria-edit.component';
import { CriteriaFormComponent } from './criteria-form/criteria-form.component';
import { CriteriaWasteEventsComponent } from './criteria-waste-events/criteria-waste-events.component';
import { CriteriaAlertsComponent } from './criteria-alerts/criteria-alerts.component';
import { CriteriaWasteEventsDetailsComponent } from './criteria-waste-events-details/criteria-waste-events-details.component';
import { NotesModule } from '../notes/notes.module';
import { WasteEventsListPinCellComponent } from './waste-events-list-pin-cell/waste-events-list-pin-cell.component';
import { AgGridModule } from 'ag-grid-angular';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NotesModule,
        AgGridModule.withComponents([WasteEventsListPinCellComponent]),
        NotAvailableModule,
    ],
  declarations: [
    CriteriaCreateComponent,
    CriteriaComponent,
    WeeklyHoursRangeComponent,
    CriteriaListComponent,
    CriteriaDetailsComponent,
    CriteriaEditComponent,
    CriteriaFormComponent,
    CriteriaWasteEventsComponent,
    CriteriaAlertsComponent,
    CriteriaWasteEventsDetailsComponent,
    WasteEventsListPinCellComponent,
  ],
})
export class CriteriaModule { }
