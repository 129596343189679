import { Component, OnInit, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material';

interface EquOption {
  nativeElem: any;
  displayName: string;
}

export class EquElem implements EquOption {
  nativeElem: any;
  displayName: string;
  operator: string;
  showDeleteButton = false;

  constructor({nativeElem, displayName}: EquOption, private allowedOperators: string[], operator?: string) {
    this.nativeElem = nativeElem;
    this.displayName = displayName;
    this.operator = operator || this.allowedOperators[0];
  }

  toggleOperator() {
    const operators = this.allowedOperators;
    const opIndex = operators.indexOf(this.operator);
    this.operator = opIndex < operators.length - 1 ? operators[opIndex + 1] : operators[0];
  }
}

export interface CombinationEquationConfig {
  displayNameExtractor: (elem: any) => string;
  options: any[];
  operators?: string[];
}

const defaultConfig: CombinationEquationConfig = {
  options: [],
  operators: ['+', '-'],
  displayNameExtractor: elem => elem,
};

@Component({
  selector: 'exa-combination-equation',
  templateUrl: './combination-equation.component.html',
  styleUrls: ['./combination-equation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CombinationEquationComponent),
      multi: true
    }
  ],
})
export class CombinationEquationComponent implements ControlValueAccessor {
  equPartsArr: EquElem[] = [];
  propagateChanges: (val: EquElem[]) => void;
  onTouch: () => void;
  options: EquOption[];
  allowedOptions: EquOption[];
  operators: string[];
  states = {
    steady: 'steady',
    adding: 'adding',
  };
  state = this.states.steady;
  disabled: boolean;

  @Input()
  set config(config: CombinationEquationConfig) {
    this.operators = this.operators || ['+', '-'];
    this.options = (config && config.options || defaultConfig.options).map(o => ({
      nativeElem: o,
      displayName: (config && config.displayNameExtractor || defaultConfig.displayNameExtractor)(o),
    }));
    this.allowedOptions = this.options.slice();
  }

  writeValue(equPartsArr: EquElem[]): void {
    this.state = this.states.steady;
    this.equPartsArr = equPartsArr.map(e => new EquElem(e, this.operators, e.operator));
    this.updateAllowedOptions();
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addElem({value: elem}: MatSelectChange) {
    const equElem: EquElem = new EquElem(elem, this.operators);
    this.equPartsArr.push(equElem);
    this.state = this.states.steady;
    this.onTouch();
    this.propagateChanges(this.equPartsArr);
    this.updateAllowedOptions();
  }

  onClickAdd() {
    this.state = this.states.adding;
  }

  onBlur() {
    this.onTouch();
  }


  deleteElem(elem: EquElem) {
    const elemIndex = this.equPartsArr.indexOf(elem);
    let updatedEqu = this.equPartsArr.slice();
    updatedEqu.splice(elemIndex, 1);
    this.equPartsArr = updatedEqu;
    this.propagateChanges(this.equPartsArr);
    this.updateAllowedOptions();
  }

  private updateAllowedOptions() {
    const selectedElemIds = this.equPartsArr.map(e => e.nativeElem.id);
    this.allowedOptions = this.options.filter(o => !selectedElemIds.includes(o.nativeElem.id));
  }

}
