import { Component, OnInit, ComponentFactoryResolver, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../data/data.service';
import { METER_SERVICES, MediaTypeMeterBaseService } from '../../../data/meters/media-type-meter-base.service';
import { MeterCreateComponentBase } from '../meter-create-base.component';

@Component({
  selector: 'exa-utility-meter-create',
  templateUrl: './utility-meter-create.component.html',
  styleUrls: ['./utility-meter-create.component.scss']
})
export class UtilityMeterCreateComponent extends MeterCreateComponentBase implements OnInit {

  customLabelsMap = {
    'utility_id.key': 'Utility Id',
  };

  constructor(
    resolver: ComponentFactoryResolver,
    fb: FormBuilder, data: DataService,
    router: Router,
    toastr: ToastrService,
    @Inject(METER_SERVICES) meterServices: MediaTypeMeterBaseService[]
  ) {
    super(resolver, fb, router, toastr, data, meterServices);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const basicInfoFG = <FormGroup> this.createForm.get('basicInfo');
    basicInfoFG.addControl('utility_id', this.fb.group({
      key: ['', [Validators.required]],
    }));
  }

  protected generateBasicBodyData() {
    const { basicInfo } = this.createForm.value;
    return {
      ...basicInfo,
      main_meter: true,
      meter_type: 'utility',
    };
  }

}
