import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WeatherKwhHeatmapChartModule } from '../weather-kwh-heatmap-chart/weather-kwh-heatmap-chart.module';
import { WeatherKwhSectionModule } from '../weather-kwh-section/weather-kwh-section.module';
import { WeatherKwhHeatmapSectionComponent } from './weather-kwh-heatmap-section/weather-kwh-heatmap-section.component';
import { WeatherKwhHeatmapFiltersComponent } from './weather-kwh-heatmap-filters/weather-kwh-heatmap-filters.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WeatherKwhSectionModule,
    WeatherKwhHeatmapChartModule,
  ],
  declarations: [WeatherKwhHeatmapSectionComponent, WeatherKwhHeatmapFiltersComponent],
  exports: [WeatherKwhHeatmapSectionComponent]
})
export class WeatherKwhHeatmapSectionModule { }
