import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject, combineLatest, throwError, zip, EMPTY } from 'rxjs';
import { tap, share, map, finalize, catchError } from 'rxjs/operators';
import { BE_DAY_DATE_FORMAT } from '../../constants';
import { DataService } from '../../data/data.service';
import { ElectricalMeterService } from '../../data/meters/electrical-meter.service';
import { Building } from '../../shared/models/building.model';
import { Meter } from '../../shared/models/meter.model';

const WHOLE_BUILDING = { id: null, name: 'All Meters' };



@Component({
  selector: 'exa-insight-trends',
  template: `
    <exa-insight-trends-filter
      #filters
      *ngIf="!(error$ | async)"
      [metersSearchFn]="metersSearchFn"
      [searchCallback]='searchCallback'>
    </exa-insight-trends-filter>

    <mat-card
        *ngIf="error$ | async">
      <exa-loading-error
          (reload)="ngOnInit()">
      </exa-loading-error>
    </mat-card>

    <mat-card
        *ngIf="loading$ | async" fxLayoutAlign="center center">
        <mat-spinner diameter="40"></mat-spinner>
    </mat-card>

    <br>
    <exa-block-with-card
        titleIcon="poll"
        [isEmpty]="!(waste$ | async)?.length || (searching$ | async)"
        title="Waste kWh (based on all 24 hours. Waste only)">
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="searching$ | async">
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <ng-container *ngIf="waste$ | async as waste">
        <exa-insight-trends-streamgraph
            *ngIf="waste?.length && !(searching$ | async)"
            graphTitle="Waste kWh (based on all 24 hours. Waste only)"
            [data]='waste'>
        </exa-insight-trends-streamgraph>

        <div *ngIf="!(searchingError$ | async) && !waste?.length && !(searching$ | async)">
          <span *ngIf="!isSearchedOnce">Please select a building, meter, and range of time then hit Create Graphs</span>
          <span *ngIf="isSearchedOnce">No Data to visualize.</span>
        </div>
      </ng-container>

      <exa-loading-error
          *ngIf="searchingError$ | async" (reload)="onSearchingError()">
      </exa-loading-error>
    </exa-block-with-card>

    <br />

    <exa-block-with-card
        titleIcon="poll"
        [isEmpty]="!(consumption$ | async)?.length || (searching$ | async)"
        title="Consumption kWh (based on all 24 hours)">
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="searching$ | async">
        <mat-spinner diameter="40"></mat-spinner>
      </div>
      <ng-container *ngIf="consumption$ | async as consumption">
        <exa-insight-trends-streamgraph
            *ngIf="consumption?.length && !(searching$ | async)"
            graphTitle="Consumption kWh (based on all 24 hours)"
            [data]='consumption'>
        </exa-insight-trends-streamgraph>

        <div *ngIf="!(searchingError$ | async) && !consumption?.length && !(searching$ | async)">
          <span *ngIf="!isSearchedOnce">Please select a building, meter, and range of time then hit Create Graphs</span>
          <span *ngIf="isSearchedOnce">No Data to visualize.</span>
        </div>
      </ng-container>


      <exa-loading-error
          *ngIf="searchingError$ | async" (reload)="onSearchingError()">
      </exa-loading-error>
    </exa-block-with-card>

  `,
  styles: []
})
export class InsightTrendsComponent implements OnInit {
  @ViewChild('filters') $filters: { onSubmit: () => void };
  loading$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject(false);
  searching$ = new BehaviorSubject(false);
  searchingError$ = new BehaviorSubject(false);
  consumption$ = new BehaviorSubject([]);
  waste$ = new BehaviorSubject([]);
  isSearchedOnce = false;
  metersSearchFn = this.metersSvc.filterByName.bind(this.metersSvc);
  searchCallback = (filters: any) => this.search(filters);

  constructor(private data: DataService, private metersSvc: ElectricalMeterService) { }

  ngOnInit() {
  }

  search(form) {
    const { meter, to, from } = form;
    const meter_id = meter && meter.id;
    const start_date = moment(from).format(BE_DAY_DATE_FORMAT);
    const end_date = moment(to).format(BE_DAY_DATE_FORMAT);
    const data = { meter_id, start_date, end_date };

    this.searching$.next(true);
    this.searchingError$.next(false);
    return combineLatest(
      this.data.insight.trends({ ...data, metric: 'kwh'  }),
      this.data.insight.trends({ ...data, metric: 'waste' }),
    ).pipe(
      share(),
      tap(([consumption, waste]: [any, any]) => {
        this.consumption$.next(consumption);
        this.waste$.next(waste);
      }),
      tap(() => this.isSearchedOnce = true),
      finalize(() => this.searching$.next(false)),
      catchError((err) => {
        this.consumption$.next([]);
        this.waste$.next([]);
        this.searchingError$.next(true);
        return throwError(err);
      })
    );
  }


  onSearchingError() {
    return this.$filters && this.$filters.onSubmit();
  }
}
