import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Meter } from '../../../shared/models/meter.model';
import { Criteria } from '../../../shared/models/waste/waste-events.models';
import {Observable} from 'rxjs';
import {Building} from '../../../shared/models/building.model';
import {AllOrSearchConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {multiMetersFilterBasicConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';

const criteriaAnyOption = <Criteria> {
  id: null,
  name: 'Any',
};

@Component({
  selector: 'exa-waste-events-filter',
  templateUrl: './waste-events-filter.component.html',
  styles: []
})
export class WasteEventsFilterComponent implements OnInit {
  @Input() loading: boolean;

  _criteriaList: Criteria[];
  @Input()
  set criteriaList(list: Criteria[]) {
    this._criteriaList = [].concat([criteriaAnyOption], list);
  }

  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;

  @Input() loadingError: any;
  @Output() search = new EventEmitter();

  form = this.fb.group({
    criteria: [criteriaAnyOption],
    min_days: [''],
    max_days: [{ value: '', disabled: true }, [Validators.required]],
    startTime: [moment.utc().subtract(7, 'd').toDate(), [Validators.required]],
    endTime: [moment.utc().toDate(), [Validators.required]],
    meters: [null],
  });

  metersInputConfig: AllOrSearchConfig<Meter>;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.metersInputConfig = {
      searchItems: this.metersSearchFn,
      ...multiMetersFilterBasicConfig,
    };
  }

  onDays() {
    const minControl = this.form.get('min_days');
    const maxControl = this.form.get('max_days');

    if (minControl.value) {
      maxControl.enable();
    } else {
      maxControl.disable();
    }
  }

  onSubmit() {
    const form = this.form.value;
    this.search.emit(form);
  }
}
