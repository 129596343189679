import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightComponent } from './insight.component';
import { InsightTrendsComponent } from './insight-trends/insight-trends.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { InsightTrendsFilterComponent } from './insight-trends/insight-trends-filter.component';
import { InsightTrendsStreamgraphComponent } from './insight-trends/insight-trends-streamgraph.component';
import { InsightDistributionComponent } from './insight-distribution/insight-distribution.component';
import { InsightSunburstComponent } from './insight-sunburst/insight-sunburst.component';
import { InsightOpportunitiesComponent } from './insight-opportunities/insight-opportunities.component';
import { InsightSunburstFiltersComponent } from './insight-sunburst/insight-sunburst-filters.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [InsightComponent, InsightTrendsComponent, InsightTrendsFilterComponent, InsightTrendsStreamgraphComponent, InsightDistributionComponent, InsightSunburstComponent, InsightOpportunitiesComponent, InsightSunburstFiltersComponent]
})
export class InsightModule { }
