import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { MonthPickerModule } from '../month-picker/month-picker.module';
import { UtilityBillsListComponent } from './utility-bills-list/utility-bills-list.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MonthPickerModule,
    ],
  exports: [
    UtilityBillsListComponent,
  ],
  declarations: [UtilityBillsListComponent],
})
export class UtilityBillsListModule { }
