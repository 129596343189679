import { ApiService } from '../api.service';

export class CriteriasData {
  constructor(private api: ApiService) {}

  create(criteria) {
    return this.api.criterias.create(criteria);
  }

  lookup(params?) {
    return this.api.criterias.lookup(params);
  }

  list(params?) {
    return this.api.criterias.list(params);
  }

  queryPage(params?) {
    return this.api.criterias.queryPage(params);
  }

  details(id: string) {
    return this.api.criterias.details(id);
  }

  update(id: string, data) {
    return this.api.criterias.update(id, data);
  }

  delete(id) {
    return this.api.criterias.delete(id);
  }

  immediateApply(id: string, data) {
    return this.api.criterias.immediateApply(id, data);
  }

  exportWasteEvents() {
    this.api.criterias.exportWasteEvents();
  }
}
