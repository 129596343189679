import { Component } from '@angular/core';
import { MatCalendarHeader } from '@angular/material';

@Component({
  selector: 'exa-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent<D> extends MatCalendarHeader<D> {
  currentPeriodClicked(): void {
    this.calendar.currentView = this.calendar.currentView == 'multi-year' ? 'year' : 'multi-year';
  }
}
