import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { tap, catchError, switchMap, filter } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import { Customer } from '../../../shared/models/customer.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import {
  UtilityBillsAddElementModalComponent,
  ElementsModalData,
} from '../utility-bills-add-element-modal/utility-bills-add-element-modal.component';
import { createBasicInfo } from '../utility-bills-create-basic-info/utility-bills-basic-info.models';
import { createServiceSection } from '../utility-bills-create-service/utility-bills-create-service.models';
import { UtilityBillsCreateStateService, BillBlueprint } from '../utility-bills-create-state.service';

@Component({
  selector: 'exa-utility-bills-create',
  templateUrl: './utility-bills-create.component.html',
  styleUrls: ['./utility-bills-create.component.scss'],
  providers: [
    UtilityBillsCreateStateService,
  ],
})
export class UtilityBillsCreateComponent implements OnInit, OnChanges {

  billForm: FormGroup;
  basicInfoFormGroup: FormGroup;
  servicesFormGroups: FormGroup[];
  basicDataSectionStates = new SectionLoadingStates();
  saveBillStates = new SectionLoadingStates();

  @Input() customer: Customer;

  constructor(
    private fb: FormBuilder,
    //TODO Abdalla 28 Jun 2020 : this component shouldn't use route here the parent component can do it in its behalf
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private stateSvc: UtilityBillsCreateStateService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap(paramsMap => {
        const blueprintBillId = paramsMap.get('use');
        return this.initialize(blueprintBillId);
      }),
    ).subscribe();
  }

  ngOnChanges({customer}: SimpleChanges): void {
    if (customer.previousValue !== customer.currentValue) {
      this.stateSvc.customer$.next(this.customer);
    }
  }

  initialize(blueprintBillId) {
    this.billForm = this.fb.group({
      basicInfo: createBasicInfo(),
      services: this.fb.array([], [Validators.required]),
      createAnotherSameTemplate: [false],
    });
    this.basicInfoFormGroup = <FormGroup>this.billForm.controls.basicInfo;
    this.servicesFormGroups = (<FormGroup[]>(<FormArray>this.billForm.controls.services).controls);

    return this.stateSvc.loadBasicData$.pipe(
      switchMap(() => this.loadBasicData(blueprintBillId)),
      tap(basicData => {
        if (basicData.blueprint) {
          this.presetFormData(basicData.blueprint);
        }
      }),
    );
  }

  loadBasicData(blueprintBillId: string) {
    const section = this.basicDataSectionStates;
    section.setLoadingState();
    return this.stateSvc.loadBasicData(blueprintBillId).pipe(
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      })
    );
  }

  selectNewSection() {
    const addedServicesIds = (<any[]>this.billForm.controls.services.value)
      .map(serviceFormVal => (<UtilityBillsLibraryModule.Service>serviceFormVal.service).id);
    const notAddedServices = Object.values(this.stateSvc.basicData$.value.lib.services)
      .filter(service => addedServicesIds.indexOf(service.id) === -1);

    const dialogRef = this.dialog.open(UtilityBillsAddElementModalComponent, {
      width: DIALOG_STATIC_WIDTHS.small,
      data: <ElementsModalData<UtilityBillsLibraryModule.Service>>{
        elements: notAddedServices,
        title: 'Add New Service',
        selectPlaceholder: 'Select a Service',
        addBtnText: 'Add',
      },
    });
    dialogRef.afterClosed().pipe(
      filter(selectedElement => !!selectedElement),
      tap(service => {
        const serviceFormGroup = createServiceSection(service);
        (<FormArray>this.billForm.controls.services).push(serviceFormGroup);
      })
    ).subscribe();
  }

  removeServiceSection(serviceFGIndex: number) {
    (<FormArray>this.billForm.controls.services).removeAt(serviceFGIndex);
  }

  private generateBillBody() {
    const {basicInfo, services} = this.billForm.value;
    const servicesData = (<any[]>services).reduce((dataObj, serviceFormData) => {
      return {
        ...dataObj,
        [serviceFormData.service.id]: {
          data: (<any[]>serviceFormData.fields).reduce((serviceDataObj, fieldFormData) => {
            return {
              ...serviceDataObj,
              [fieldFormData.field.id]: fieldFormData.value,
            };
          }, {}),
        },
      };
    }, {});

    return {
      name: basicInfo.name,
      building_id: basicInfo.building.id,
      company_id: basicInfo.utilityCompany.id,
      meters_ids: basicInfo.meters.map(m => m.id),
      serviceStartDate: basicInfo.serviceStartDate,
      serviceEndDate: basicInfo.serviceEndDate,
      date: basicInfo.month,
      services: servicesData,
    };
  }

  save() {
    const billBody = this.generateBillBody();
    const states = this.saveBillStates;
    states.setLoadingState();
    const save$ = this.stateSvc.save(billBody).pipe(
      tap(() => states.resetLoadingState()),
      tap((bill) => this.toastr.success(`Bill ${bill.name} has been saved successfully!`)),
      tap(bill => {
        const useSameSchema = this.billForm.value.createAnotherSameTemplate;
        useSameSchema ? this.router.navigate(['/utility/new', {use: bill.id}]) : this.router.navigate(['/utility']);
      }),
    );
    save$.subscribe();
  }

  private presetFormData(blueprint: BillBlueprint) {
    const servicesFormArr = (<FormArray>this.billForm.controls.services);
    blueprint.services.forEach(({service, initialData}) => {
      const serviceFormGroup = createServiceSection(service, initialData);
      servicesFormArr.push(serviceFormGroup);
    });
  }

}
