import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import { Building } from '../../../shared/models/building.model';
import { Meter } from '../../../shared/models/meter.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { WeatherKwhQuery } from '../weather-kwh.models';
import {AllOrSearchConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {
  multiMetersFilterBasicConfig,
  onlyBuildingFilterBasicConfig
} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';
import {OnBuildingsSelected, onBuildingsSelectedFactory} from '../../../shared/components/all-or-search-filter/all-or-search-filter.utils';

@Component({
  selector: 'exa-weather-kwh-filters',
  templateUrl: './weather-kwh-filters.component.html',
  styleUrls: ['./weather-kwh-filters.component.scss'],
})
export class WeatherKwhFiltersComponent implements OnInit, OnDestroy {

  filtersForm: FormGroup;
  subscription = new Subscription();

  @Input() buildingsSearchFn: (name: string) => Observable<Building>;
  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;
  @Input() loadingStats: SectionLoadingStates;
  @Output() onSubmit = new EventEmitter<WeatherKwhQuery>();

  today = moment().startOf('d').toDate();
  lastWeek = moment(this.today).subtract(1, 'w').toDate();

  buildingsInputConfig: AllOrSearchConfig<Building>;
  metersInputConfig: AllOrSearchConfig<Meter>;

  onBuildingsSelected: OnBuildingsSelected;

  constructor(private fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.filtersForm = this.fb.group({
      building: [''],
      meters: [null],
      timeRange: this.fb.group({
        startDate: [this.lastWeek, [Validators.required]],
        endDate: [this.today, [Validators.required]],
      }),
    });

    this.buildingsInputConfig = {
      searchItems: this.buildingsSearchFn,
      ...onlyBuildingFilterBasicConfig,
    };

    this.metersInputConfig = {
      searchItems: this.metersSearchFn,
      ...multiMetersFilterBasicConfig,
    };

    const metersFC = this.filtersForm.get('meters') as FormControl;
    this.onBuildingsSelected = onBuildingsSelectedFactory(this.metersSearchFn, metersFC, config => {
      this.metersInputConfig = {
        ...this.metersInputConfig,
        ...config,
      };
    });

    const buildingControl = <FormControl> this.filtersForm.get('building');
    buildingControl.valueChanges.subscribe((v) => this.onBuildingsSelected([v]));
  }

  submit() {
    const {meters, buildingId} = this.filtersForm.value;
    const {startDate, endDate} = this.filtersForm.value.timeRange;
    this.onSubmit.emit({
      meters: meters && meters.map(m => m.id),
      building_id: buildingId,
      startDate,
      endDate,
    });
  }

}
