import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from '../../data/data.service';
import { Observable, Subscription, BehaviorSubject, throwError, of } from 'rxjs';
import { filter, finalize, catchError, tap, share } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType } from '@angular/common/http';

interface IAttachmentsData {
  entity: string;
  entityId: string;
}

interface IAttachmentBody {
  entity: string;
  entity_id: string;
  file: string;
}

@Component({
  selector: 'exa-attachments-create-dialog',
  template: `
    <h1 mat-dialog-title>Create New Attachment</h1>
    <div mat-dialog-file>
      <exlib-errors [labels]="labels" [error]="errorMessage" *ngIf="error"></exlib-errors>
      <exa-select-file
        acceptedFormats=".doc, .docx, .pdf"
        (selectFile)="onSelectFile($event)">
      </exa-select-file>

      <exlib-upload-percentage
          [upload$]="upload$">
      </exlib-upload-percentage>
    </div>

    <div mat-dialog-actions fxLayout="flex" fxLayoutAlign="end center" class="full-width">
      <button mat-button mat-dialog-close [disabled]="loading">cancel</button>
      <button mat-button [disabled]="loading || !file" (click)="onClickSave()">save</button>
    </div>
  `,
  styles: []
})
export class AttachmentsCreateDialogComponent {
  file: string;
  loading = false;
  error = false;
  errorMessage: any = null;
  errorMap = {
    entity: 'Attachment type',
    entity_id: 'Type Id',
    file: 'Attachment file',
  }
  saveSub: Subscription;
  newAttachment$ = new BehaviorSubject(null);
  upload$: Observable<any>;
  labels = {
    'file': 'Uploaded File',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAttachmentsData,
    public dialogRef: MatDialogRef<AttachmentsCreateDialogComponent>,
    private dataService: DataService,
    private toastr: ToastrService,
  ) { }

  onClickSave() {
    const { entity, entityId } = this.data;
    const { file } = this;
    const attachment = { file, entity, entity_id: entityId };
    this.loading = true;
    this.error = false;

    if (this.saveSub) { this.saveSub.unsubscribe(); }
    this.upload$ = this.dataService.createAttachment(attachment).pipe(share());
    this.saveSub = this.upload$.pipe(
      filter((event) => event.type === HttpEventType.Response),
      tap((res) => this.newAttachment$.next(res && res.body)),
      finalize(() => this.loading = false),
      catchError(err => {
        this.error = true;
        this.errorMessage = err && err.error;
        return throwError(err);
      })
    ).subscribe((res) => {
      this.file = null;
      this.dialogRef.close();
      this.toastr.success('Attachment has been created successfully.');
    });
  }

  onSelectFile(file) {
    this.file = file;
  }
}
