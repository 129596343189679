import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';
import { BehaviorSubject, throwError, combineLatest, Observable } from 'rxjs';
import { DataService } from '../../data/data.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';


/**
 * USER STORY:
 * 1. at first page load:
 *   - it should load meters then pass those meters to filter component and wait for
 *     filter component to set the initial values of the filters then it will emit `filter` event
 *     to make the first load start once it gets the meters list.
 *   - according to filters changes the pagination will be reset.
 *   - the first page will be stored in the `infinitePriorityList` which it's a buffer
 *     that is used to save the previously loaded priority list.
 *     __note__ this list will be reset once filters/meters have been changed.
 *
 *
 * 2. when user changes any of the filters fields:
 *   - pagination/infinitePriorityList should be reset.
 *   - then reload priority list upon the changes in filters with the new pagination.
 *
 *
 * 3. when user changes the selected customer:
 *   - should reload meters for the new customer.
 *   - then it should reset pagination and filters.
 *   - then it should load priority list according to those new assets.
 *
 *
 * 4. when user scroll down to bottom:
 *   - if bottom of list is visible should fire `loadMore` method.
 *   - thus loadMore method just modifies the pagination$ subject. and according to this
 *     priority$ observable will fetch a more data with same meters and filters settings.
 */
@Component({
  selector: 'exa-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss']
})
export class PriorityComponent implements OnInit {

  constructor(private customers: CustomersService, 
    private data: DataService,
     private router: Router,
    private auth: AuthService) {
  }

  ngOnInit() {
    if (this.auth.isAdmin$) {
      this.data.buildings.lookup({ pageSize: 1, pageNumber: 1 }).subscribe(((result) => {
        if (!localStorage["lastBuildingId"] && result.count > 0) {
          localStorage["lastBuildingId"] = result.results[0].id;
        }
        if (result.count > 1) {
          this.router.navigate(['/buildings']);
        } else if (result.count == 1) {
          this.router.navigate(['/dashboard/benchmarking'], {
            fragment: 'buildingId=' + result.results[0].id
          })
        } else {
          this.router.navigate(['/buildings/create']);
        }
      }))
    } else {
      this.router.navigate(['/buildings']);
    }
  }

}
