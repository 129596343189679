import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription, Observable, Subject } from 'rxjs';
import { Building } from '../../../shared/models/building.model';
import { debounceTime, switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { merge } from 'rxjs';
import { DataService } from '../../../data/data.service';
import { CustomersService } from '../../../customers/customers.service';

interface BuildingOption {
  id: string;
  name: string;
}

interface Filters {
  customerId?: string;
  buildingId?: string;
}

@Component({
  selector: 'exa-customers-buildings-filter',
  templateUrl: './customers-buildings-filter.component.html',
  styles: []
})
export class CustomersBuildingsFilterComponent {
  @Output() selectBuilding = new EventEmitter();
  buildings: Building[] = [];
  buildingFilter$ = new BehaviorSubject('');
  selectedBuilding: Building;
  buildings$: Observable<Building[]> = merge(this.customers.selected$, this.buildingFilter$)
    .pipe(
      debounceTime(500),
      map((val) => {
        const params: any = {};

        if (this.buildingFilter$.value) {
          params.search = this.buildingFilter$.value;
        }

        if (this.customers.selected) {
          params['building__company_id'] = this.customers.selected.id;
        }

        return params;
      }),

      switchMap((params) => this.data.buildings.list(params)),

      tap(val => this.buildings = val),
    );

  constructor(private data: DataService, private customers: CustomersService) {}

  onInputSearch(value) {
    this.buildingFilter$.next(value);

    if (this.selectedBuilding) {
      this.selectBuilding.emit({ id: null });
      this.selectedBuilding = null;
    }
  }


  onOptionSelected({ option }) {
    const name = option.value;
    const building = this.buildings.find((item) => item.name === name);
    this.selectedBuilding = building || null;
    this.selectBuilding.emit(building || { id: null });
  }
}
