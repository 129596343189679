import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meter } from '../../shared/models/meter.model';
import moment from 'moment';
import {BE_DAY_DATE_FORMAT} from '../../constants';
import { Building } from '../../shared/models/building.model';

export class MetersData {
  constructor(private api: ApiService) { }

  create(body): Observable<any> {
    return this.api.meters.create(body);
  }

  update(id, body) {
    return this.api.meters.update(id, body);
  }

  delete(id) {
    return this.api.meters.delete(id);
  }

  updateUtility(id, body) {
    return this.api.meters.updateUtility(id, body);
  }

  mainMeters(building_id: string) {
    return this.api.meters.mainMeters(building_id);
  }

  updatePredictedKwhMethod(id: string, body: {no_waste: boolean, method?: string, startingFrom?: Date}) {
    const {no_waste, method, startingFrom} = body;
    return this.api.meters.updatePredictedKwhMethod(id, {
      no_waste: no_waste,
      method: no_waste ? null : method,
      starting_from: no_waste ? null : moment(startingFrom).format(BE_DAY_DATE_FORMAT),
    });
  }

  list(params?): Observable<any> {
    return this.api.meters.list(params);
  }

  listUtility(params?): Observable<Meter[]> {
    return (this.api.meters.list(params) as Observable<Meter[]>)
      .pipe(
        map(meters => meters.filter(m => m.meter_type === 'utility')),
      );
  }

  details(id: string) {
    return this.api.meters.details(id);
  }

  importThresholdEfficiency(meterId: string, filePath: string) {
    return this.api.meters.importThresholdEfficiency(meterId, {filepath: filePath});
  }

  importThresholdEfficiencyTemplate() {
    return this.api.meters.importThresholdEfficiencyTemplate().pipe(
      map((templateBlob) => {
        return {
          blob: templateBlob,
          name: 'threshold-efficiency-template.csv',
        };
      })
    );
  }

  importThresholdEfficiencyUrl(meterId: string, file: File, customer_id) {
    return this.api.tasks.getSignedUrl({
      id: meterId,
      content_type: 'text/csv',
      entity: 'threshold-efficiency',
      filename: file.name,
    }, { customer_id }).pipe(map(res => ({ ...res, customerId: customer_id })));
  }
}
