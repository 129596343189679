import { Component, Input } from '@angular/core';
import { TempRangeSeries } from '../weather-comparison/weather-comparison.models';

@Component({
  selector: 'exa-weather-daily-hours-summary',
  templateUrl: './weather-daily-hours-summary.component.html',
  styleUrls: ['./weather-daily-hours-summary.component.scss'],
})
export class WeatherDailyHoursSummaryComponent {
  displayedColumns: string[] = ['name', 'hours'];
  @Input() groupSeries: TempRangeSeries[];
}
