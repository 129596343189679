import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  chartDefaultOptions,
  datetimeLabelFormatterFactory,
  tooltipDatetimeFormatterFactory,
  fixTimeRange,
  ChartComponent,
} from 'ex-library';
import { Options } from 'highcharts';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, EMPTY, from, zip } from 'rxjs';
import { switchMap, share, finalize, tap, map, filter, catchError } from 'rxjs/operators';
import { chartColors, OperationScheduleProfiles } from '../../constants';
import { CustomersService } from '../../customers/customers.service';

import { DataService } from '../../data/data.service';
import { ElectricalMeterService } from '../../data/meters/electrical-meter.service';
import { OperationScheduleDataService } from '../../data/operation-schedule-data.service';
import { Building } from '../../shared/models/building.model';
import { Customer } from '../../shared/models/customer.model';
import { Meter } from '../../shared/models/meter.model';
import { MetersMetricsFilters } from '../../shared/models/meters-metrics-filters.model';
import { MetricsPoint, BenchmarkResponse } from '../../shared/models/meters-metrics.model';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DashboardCommonChild, eventSubscriber } from '../dashboard-common-child.interface';
import { DashboardService } from '../dashboard.service';

interface SeriesDefinitions {
  id: string;
  name: string;
  unit?: string;
  title?: string;
  type?: string;
  yAxis: number;
  dashStyle?: string;
  color?: string;
  zones?: { color: string; value?: number; }[];
  tooltipValFormatter?: (point: any, series: any) => string;
  visible?: boolean,
}

@Component({
  selector: 'exa-benchmarking',
  templateUrl: './benchmarking.component.html',
  styles: [
    `
      .page .page__hero .page__hero-text {
        margin-bottom: 0;
      }
    `
  ],
})
export class BenchmarkingComponent implements OnInit, DashboardCommonChild, OnDestroy {
  metrics$: Observable<BenchmarkResponse>;
  chartOptions: Options;
  wasteChartOptions: Options;
  shouldDisplayDayName = false;
  filterDurationInDays;
  fromDate: string;
  toDate: string;
  meters = [];
  buildingId: string;
  defaultSeriesDefs: SeriesDefinitions[] = [
    {
      id: 'kwh',
      name: 'Power consumption',
      unit: 'kWh',
      yAxis: 0,
      color: chartColors.power,
    }, {
      id: 'predicted_kwh',
      name: 'Predicted kWh',
      unit: 'kWh',
      yAxis: 0,
      dashStyle: 'ShortDot',
      color: 'grey',
    }
  ];

  defaultYAxis = [
    {
      id: 'power',
      opposite: false,
      title: { text: 'Power Consumption in kWh' },
      // forcing min value lower than zero to allow the baseline title to be shown below the baseline plotLine
      // It's better to use softMin instead of min. but it seems that softMin is not existing in the current used highcharts version
      min: -5,
    }
  ];

  temperatureYAxis = [{
    id: 'temperature',
    opposite: true,
    title: { text: 'Temperature in °F' },
  }];

  humidityYAxis = [{
    id: 'humidity',
    opposite: true,
    title: { text: 'Relative Humidity (0.00-1.00)' },
    min: 0,
    max: 1,
  }];

  chartYAxis = [...this.defaultYAxis];

  temperatureDef: SeriesDefinitions[] = [{
    id: 'temperature',
    name: 'Temperature',
    unit: '°F',
    yAxis: 1,
    color: chartColors.temperature,
    visible: false,
  }];

  humidityDef: SeriesDefinitions[] = [{
    id: 'humidity',
    name: 'Relative Humidity',
    yAxis: 2,
    color: chartColors.water,
    visible: false,
  }];

  resolutionChoices: any[] = [
    { name: '1 Hour', id: '1h' },
    { name: '1 Day', id: '1d' },
    { name: 'Original Resolution', id: 'raw' },
  ];

  wasteDef: SeriesDefinitions = {
    id: 'waste',
    name: 'Waste',
    type: 'areasplinerange',
    unit: 'kWh',
    yAxis: 0,
    visible: false,
    zones: [{
      value: 0,
      color: '#23ff0090'
    }, {
      color: '#ff000090'
    }],
    tooltipValFormatter: (point, unit) => {
      const shownVal = point.low !== 0 ? point.low : point.high;
      return `<b>${_.round(shownVal, 2)}</b> ${unit}`;
    },
  };

  seriesDefs: SeriesDefinitions[] = [...this.defaultSeriesDefs];
  seriesData: any[];
  wasteSeriesData: any[];

  customer$: Observable<Customer>;
  customer: Customer;
  buildings$: Observable<Building[]>;
  searching: boolean;
  searchingError: boolean;
  searchingErrorRes: any;
  pristine = true;
  loadingError = false;
  loading: boolean;
  filtersForm: FormGroup;
  labelsMap = {
    building_ids: 'Selected Buildings',
    customer_id: 'Customer',
    from_date: 'Start Date',
    meters: 'Selected Meters',
    resolution: 'Resolution',
    to_date: 'End Date',
  };

  private operationSchedule: any;
  private operationScheduleBands: any[];
  private operationScheduleLines: any[];

  showOperationSchedule = false;
  isAllowedShowOperationSchedule = false;
  scheduleProfiles = Object.values(OperationScheduleProfiles);

  showBaseload = true;
  baseload: number;

  @ViewChild('chart') chartComp: ChartComponent;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private data: DataService,
    private metersSvc: ElectricalMeterService,
    private operationScheduleSvc: OperationScheduleDataService,
    private customerService: CustomersService,
    private appService: DashboardService) {
    this.executeAction = this.executeAction.bind(this);
    eventSubscriber(appService.subscription, this.executeAction);
  }

  ngOnDestroy(): void {
    eventSubscriber(this.appService.subscription, this.executeAction, true);
  }

  executeAction(params?) {
    this.fromDate = params.fromDate;
    this.toDate = params.toDate;
    this.meters = params.meters;
    this.buildingId = params.buildingId;
    this.search();
  }

  ngOnInit() {
    this.loadingError = false;

    this.customer$ = this.customerService.selected$;

    this.filtersForm = this.fb.group({
      resolution: [this.resolutionChoices[0], [Validators.required]],
    });

  }

  showData(filters: MetersMetricsFilters, metricsData: BenchmarkResponse) {
    this.baseload = metricsData.baseload;
    this.calculateSeriesData(filters, metricsData.series);
    this.drawChart();
    setTimeout(() => {
      this.updateOperationScheduleView(true);
      this.updateBaseloadView(true);
    }, 1000);
  }

  private calculateSeriesData(filters: MetersMetricsFilters, metricsData: MetricsPoint[]) {
    const [dataSample] = metricsData;
    const hasTemperature = dataSample && !_.isUndefined(dataSample.temperature);
    const hasHumidity = dataSample && !_.isUndefined(dataSample.humidity);
    const durationInDays = moment(filters.to_date).diff(filters.from_date, 'days');
    const timeRange = {
      start_time: filters.from_date,
      end_time: filters.to_date,
      resolution: filters.resolution,
    };

    this.filterDurationInDays = durationInDays;
    this.shouldDisplayDayName = durationInDays <= 14;

    this.seriesDefs = this.defaultSeriesDefs.concat(
      hasTemperature ? this.temperatureDef : [],
      hasHumidity ? this.humidityDef : [],
    );

    this.chartYAxis = (this.defaultYAxis as any[]).concat(
      hasTemperature ? this.temperatureYAxis : [],
      hasHumidity ? this.humidityYAxis : [],
    );

    const mappedResponse = metricsData
      .map(p => ({
        ...p,
        timeVal: moment.utc(p.time).valueOf(),
      }))
      .sort((pA, pB) => pA.timeVal - pB.timeVal);

    const basicSeriesData = _.map(this.seriesDefs, (seriesDef, index) => {
      const seriesData = _.map(mappedResponse, (p) => [p.timeVal, p[seriesDef.id]]);
      const forcedTimeRangeData = fixTimeRange(timeRange, seriesData);
      return _.assign({}, seriesDef, {
        data: forcedTimeRangeData,
      });
    });

    const wasteData = _.map(mappedResponse,
      (p) => p.waste >= 0 ? [p.timeVal, 0, p.waste] : [p.timeVal, p.waste, 0],
    );

    const forcedTimeRangeWasteData = fixTimeRange(timeRange, wasteData, 3);
    const wasteSeries = _.assign({}, this.wasteDef, {
      data: forcedTimeRangeWasteData,
    });

    this.seriesData = [...basicSeriesData, wasteSeries];
  }

  drawChart() {
    const datetimeLabelFormatter = datetimeLabelFormatterFactory(null, this.shouldDisplayDayName);
    const tooltipTimedateFormatter = tooltipDatetimeFormatterFactory();

    const pointFormatter = (point, series) => {
      const unit = series.userOptions.unit;
      const seriesTitlePart = `<span style="color:${point.color}">\u25CF</span> ${series.name}`;
      const valuePart = series.userOptions.tooltipValFormatter ?
        series.options.tooltipValFormatter(point, unit) :
        `<b>${_.round(point.y, 2)}</b>  ${unit ? unit : ''}<br/>`;
      return `${seriesTitlePart}: ${valuePart}`;
    };

    const seriesFormatter = (points) => {
      const formattedPoints = _.map(points, (p) => pointFormatter(p.point, p.series));
      return formattedPoints.join('<br>');
    };

    const tooltipFormatter = function () {
      const datetimeHeader = tooltipTimedateFormatter(this.x);
      const seriesBody = seriesFormatter(this.points);
      return [datetimeHeader, seriesBody].join('<br>');
    };

    const xAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: datetimeLabelFormatter,
      },
    };

    const commonChartOptions = {
      chart: {
        type: 'spline',
      },
      xAxis: {
        type: 'datetime',
        gridLineWidth: this.filterDurationInDays <= 30 ? 1 : 0,
        labels: {
          formatter: datetimeLabelFormatter,
          padding: 30,
        },
        tickPixelInterval: 150,
      },
    };

    this.chartOptions = <Options>_.assign({}, chartDefaultOptions, commonChartOptions, {
      yAxis: this.chartYAxis,
      tooltip: {
        formatter: tooltipFormatter,
        shared: true,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        }
      },
      series: this.seriesData,
      chart: _.assign({}, chartDefaultOptions.chart, commonChartOptions.chart, { zoomType: 'xy' }),
    });
  }

  updateOperationScheduleView(clearFirst = false) {
    const chart = this.chartComp.chart;
    const clearOptions = {
      xAxis: {
        crosshair: false,
        plotBands: undefined,
        plotLines: undefined,
      },
    };
    if (clearFirst) {
      chart.update(clearOptions);
    }

    if (this.showOperationSchedule) {
      chart.update({
        xAxis: {
          crosshair: true,
          plotBands: this.operationScheduleBands,
          plotLines: this.operationScheduleLines,
        },
      });
    } else {
      chart.update(clearOptions);
    }
  }

  updateBaseloadView(clearFirst = false) {
    const powerAxis = this.chartComp.chart.yAxis.find(axis => (axis as any).userOptions.id === 'power');
    if (clearFirst) {
      powerAxis.removePlotLine('baseload');
    }

    if (this.showBaseload) {
      powerAxis.addPlotLine({
        id: 'baseload',
        value: this.baseload,
        width: 1,
        zIndex: 4,
        color: 'gray',
        dashStyle: 'ShortDash',
        label: {
          text: `Baseload <b>${Math.round(this.baseload * 100) / 100}</b> kWh`,
          y: 16,
        },
      });
    } else {
      powerAxis.removePlotLine('baseload');
    }
  }

  private generateFiltersBody() {
    const formBody = this.filtersForm.value;
    return <MetersMetricsFilters>{
      from_date: this.fromDate,
      to_date: this.toDate,
      customer_id: null,
      resolution: formBody.resolution.id,
      meters: this.meters,
      building_id: this.buildingId
    };
  }

  modelChanged(event) {
    this.search();
  }

  search() {
    if(!this.filtersForm){
      return;
    }
    this.searching = true;
    const searchFilters = this.generateFiltersBody();
    const uiAllFilters = {
      ...searchFilters,
      viewFilters: this.filtersForm.value,
    };
    this.pristine = false;
    this.searching = true;
    this.searchingError = false;
    this.chartOptions = null;
    this.data.customers.metersMetrics(searchFilters)
      .pipe(
        switchMap(metrics => {
          return this.controlOperationSchedule(uiAllFilters)
            .pipe(map(() => metrics));
        }),
        finalize(() => this.searching = false),
      )
      .subscribe(
        this.showData.bind(this, uiAllFilters),
        (err) => {
          this.searchingError = true;
          this.searchingErrorRes = err && (err.error.Message || err.error);
        }
      );
  }

   controlOperationSchedule(filters: MetersMetricsFilters) {
    const buildingId  = filters.building_id;
    const { from_date, to_date, resolution } = filters;
    const timeDiffLessMonth = moment(to_date).diff(from_date, 'd') <= 30;
    if (!buildingId || resolution === '1d' || !timeDiffLessMonth) {
      this.isAllowedShowOperationSchedule = false;
      this.showOperationSchedule = false;
      return from([null]);
    }

    return this.operationScheduleSvc.getBuildingSchedule(buildingId).pipe(
      map(schedule => this.operationScheduleSvc.getSchedulesPeriods(schedule.mappedData)),
      tap(scheduleData => {
        this.operationSchedule = scheduleData;
        this.calculateOperationScheduleBands({ from: from_date, to: to_date }, scheduleData);
        this.isAllowedShowOperationSchedule = true;
      }),
    );
  }
  

  calculateOperationScheduleBands(timeRange: { from: string, to: string }, operationSchedule) {
    const startDateMoment = moment(timeRange.from).utc().startOf('d');
    const endDateMoment = moment(timeRange.to).utc().startOf('d');

    let bands = [];
    let lines = [];
    let currentDateMoment = startDateMoment.clone();
    while (moment(currentDateMoment).isSameOrBefore(endDateMoment)) {
      const dayIndex = currentDateMoment.day();
      operationSchedule.forEach(s => {
        const profileRanges = s.ranges[dayIndex];
        if (!profileRanges || !profileRanges.length) {
          return;
        }
        profileRanges.forEach(r => {
          const toTimeVal = moment(currentDateMoment).hour(r.end.hour).minute(r.end.min).toDate().valueOf();
          bands.push({
            from: moment(currentDateMoment).hour(r.start.hour).minute(r.start.min).toDate().valueOf(),
            to: toTimeVal,
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, s.profile.color], // start
                [0.03, s.profile.contrastColor], // end
                [0.1, '#ffffff'], // middle
              ],
            },
          });
          lines.push({
            value: toTimeVal,
            color: '#E6E6E6',
          });
        });
      });
      currentDateMoment = moment(currentDateMoment).add(1, 'd');
    }

    this.operationScheduleBands = bands;
    this.operationScheduleLines = lines;
  }
}
