import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaginatorDirective} from './paginator.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    PaginatorDirective
  ],
  declarations: [PaginatorDirective]
})
export class PaginatorModule { }
