import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'exa-attached-meters',
  template: `
  <mat-card fxFlex="1 1 100%" fxLayout="column" class="padding--strict padding--zero">
    <p class="mat-subheading-2 padding">
      <b>{{title}}:</b>
    </p>
    <div class="padding bg--primary-offwhite" fxFlex="1 1 100%">
      <ng-container *ngIf="meters?.length">
        <div *ngIf="showBuildingName">
          <ng-container *ngFor="let building of metersList?.buildings">
            <div *ngIf="metersList?.list[building]">
              <div>{{building}}</div>
              <mat-list>
                <mat-list-item *ngFor="let meter of metersList?.list[building]">
                    <span class="avatar margin--right-small">
                      <mat-icon class="avatar__icon">equalizer</mat-icon>
                    </span>
                    <span>{{meter.name}}</span>
                </mat-list-item>
              </mat-list>
            </div>
          </ng-container>
        </div>

        <mat-list *ngIf="!showBuildingName">
          <mat-list-item *ngFor="let meter of metersList">
            <span class="avatar margin--right-small">
              <mat-icon class="avatar__icon">equalizer</mat-icon>
            </span>
            <span>{{meter.name}}</span>
          </mat-list-item>
        </mat-list>
      </ng-container>
      <div *ngIf="!meters?.length" class="text--center text--gray-100">
        There are no attached meters
      </div>
    </div>
    <mat-paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[pageSize]"
        [length]="isLegacy ? meters?.length : totalCount"
        (page)="onMeterPageChanged($event)">
    </mat-paginator>
  </mat-card>
  `,
  styles: []
})
export class AttachedMetersComponent {

  @Input() title = 'Meters';
  @Input() meters: any[];
  @Input() pageSize = 8;
  @Input() showBuildingName = false;

  // legacy is frontend pagination
  @Input() isLegacy = false;
  @Input() totalCount: number;
  @Output() pageSelected = new EventEmitter<number>();

  page = 0;

  get metersList() {
    const list = this.meters || [];
    const { page, pageSize } = this;
    const buildings = _.sortBy(list.map(meter => meter.building), 'name');
    const meters = this.isLegacy ? list.slice(page * pageSize, (page + 1) * pageSize) : this.meters;

    if (!this.showBuildingName) {
      return meters;
    }

    return {
      buildings: Array.from(new Set(buildings.map(build => build.name))),
      list: _.groupBy(meters, (meter) => meter.building && meter.building.name),
    };
  }

  constructor() { }

  onMeterPageChanged(page) {
    this.page = page.pageIndex;
    this.pageSelected.next(page.pageIndex);
  }
}
