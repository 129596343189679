import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionGuardDirective } from './permission-guard.directive';
import { PermissionButtonDisabledDirective } from './permission-button-disabled.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    PermissionGuardDirective,
    PermissionButtonDisabledDirective,
  ],
  declarations: [PermissionGuardDirective, PermissionButtonDisabledDirective],
})
export class PermissionsModule { }
