import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NotesCreateDialogComponent } from './notes-create-dialog.component';

@Component({
  selector: 'exa-notes-create',
  templateUrl: './notes-create.component.html',
  styleUrls: ['./notes-create.component.scss']
})
export class NotesCreateComponent implements OnInit {
  @Input() icon = 'add_comment';
  @Input() entity: string;
  @Input() entityId: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  onClickCreateNote() {
    this.dialog.open(NotesCreateDialogComponent, {
      disableClose: true,
      data: {
        entity: this.entity,
        entityId: this.entityId,
      },
    })
  }
}
