import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { MetersMetricsFilters } from '../shared/models/meters-metrics-filters.model';
import { BehaviorSubject, timer, Subscription, Observable } from 'rxjs';
import { CustomersData, BuildingsData, MetersData, UsersData, CriteriasData, TasksData } from './entities';


@Injectable({
  providedIn: 'root',
})
export class DataService {
  lookups$ = new BehaviorSubject(null);
  lookupSub: Subscription;
  customers = new CustomersData(this.api);
  buildings = new BuildingsData(this.api);
  // should be removed
  meters = new MetersData(this.api);
  users = new UsersData(this.api);
  criterias = new CriteriasData(this.api);
  tasks = new TasksData(this.filesSaver, this.api);

  insight = {
    trends: (data) => {
      return this.api.insight.trends(data);
    },

    distribution: (data) => {
      return this.api.insight.distribution(data);
    },

    opportunities: (data) => {
      return this.api.insight.opportunities(data);
    }
  };


  constructor(private filesSaver: FileSaverService, private api: ApiService) {}

  alertsList(params?) {
    return this.api.alertsList(params);
  }

  eventsList(params?) {
    return this.api.eventsList(params);
  }

  eventsGraph(body) {
    return this.api.eventsGraph(body);
  }

  priority(body, params?): Observable<any> {
    return this.api.priority(body, params);
  }

  eventsDetails(id) {
    return this.api.eventsDetails(id);
  }

  pinEvent(id, pinned) {
    return this.api.pinEvent(id, pinned);
  }

  wasteMap(filters: any) {
    return this.api.wasteMap(filters);
  }

  createNote(note: any) {
    return this.api.createNote(note);
  }

  deleteNote(params?) {
    return this.api.deleteNote(params);
  }

  createAttachment(body: any) {
    return this.api.createAttachment(body);
  }

  openAttachment(id: string, entity: string, entityId: string, customerId: string, isCustomer?: boolean) {
    return this.api.openAttachment(id, entity, customerId, isCustomer);
  }


  deleteAttachment(id: string, entity: string, isCustomer?: boolean) {
    return this.api.deleteAttachment(id, entity);
  }
}
