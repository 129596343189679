import {Pipe, PipeTransform} from '@angular/core';

type DisplayNameExtractorFunction = (item: any) => string;

@Pipe({
  name: 'displayNameExtractor'
})
export class DisplayNameExtractorPipe implements PipeTransform {

  transform(value: any, displayNameExtractor: DisplayNameExtractorFunction): string {
    return displayNameExtractor(value);
  }

}
