import { ApiService } from '../api.service';

export class  UsersData {
  constructor(private api: ApiService) {}

  create(user) {
    return this.api.users.create(user);
  }

  delete(id: string) {
    return this.api.users.delete(id);
  }

  getUser(id: string, customer: string) {
    return this.api.users.getUser(id, customer);
  }

  activate(id: string) {
    return this.api.users.activate(id);
  }

  deactivate(id: string) {
    return this.api.users.deactivate(id);
  }

  getProfile() {
    return this.api.users.getProfile();
  }

  login(credentials) {
    return this.api.users.login(credentials);
  }

  forgotPassword(email) {
    return this.api.users.forgotPassword(email);
  }

  resetPassword(resetPasswordValues) {
    return this.api.users.resetPassword(resetPasswordValues);
  }

  logout() {
    return this.api.users.logout();
  }

  getGroups() {
    return this.api.users.getGroups();
  }
}
