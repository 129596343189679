import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UtilityBillsCreatePgComponent } from './utility-bills-create-pg/utility-bills-create-pg.component';
import { UtilityBillsListPgComponent } from './utility-bills-list-pg/utility-bills-list-pg.component';
import { UtilityBillsMonthlyAnalysisPgComponent } from './utility-bills-monthly-analysis-pg/utility-bills-monthly-analysis-pg.component';

const routes: Routes = [
  {
    path: '',
    component: UtilityBillsListPgComponent,
  },
  {
    path: 'new',
    component: UtilityBillsCreatePgComponent,
  },
  {
    path: 'monthly-analysis',
    component: UtilityBillsMonthlyAnalysisPgComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilityBillsPageRoutingModule { }
