import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { map, debounceTime } from 'rxjs/operators';
import { fromEvent, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { CustomerListElem } from '../../customers/customers-list/customers-list.component';
import { AuthService } from '../../auth/auth.service';
import { Roles } from '../../auth/user-group-guard.provider';
import { SideNavRoutesService } from './side-nav-routes.service';
import { ISideNavItem } from './side-nav-item/side-nav-item.model';


@Component({
  selector: 'exa-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() width: number;
  @Input() maxWidth: number;
  @Input() customers: CustomerListElem[];
  @Input() selectedCustomer: CustomerListElem;
  @Input() hovered: boolean;
  @Input() user: any;
  @Output() select = new EventEmitter();
  @Output() filter = new EventEmitter();
  @ViewChild('sidenav') sidenavRef: ElementRef;
  @ViewChild('searchBox') searchInput: ElementRef;

  edit$ = new BehaviorSubject(false);
  rippleColor = 'rgba(113, 106, 202, .2)';
  userGroup = (this.user && this.user.group) || [];
  isAdmin = this.userGroup.indexOf(Roles.superuser) > -1;
  customerNavItems$: Observable<ISideNavItem[]>;
  allUsersRoutes$: Observable<ISideNavItem[]>;
  clickOutSub: Subscription;

  /**
   * @todo(hassan)
   * try the autocomplete with ngModel
   */
  customerFC = new FormControl();
  selected = null;

  constructor(private auth: AuthService, private routes: SideNavRoutesService) {}

  ngOnInit(): void {
    const $ref = this.sidenavRef;
    const $el = $ref && $ref.nativeElement;

    this.clickOutSub  = fromEvent(document.body, 'click')
      .pipe(map(event => event.target), debounceTime(100))
      .subscribe((target) => {
        const $form = $el.querySelector('form');
        const isForm = target === $form;
        const itsChild = $form && $form.contains(target);

        if (this.edit$.value && !(isForm || itsChild)) {
          this.edit$.next(false);
        }
      });

    this.auth.token$.subscribe((res) => this.initRoutes(res));
  }

  ngOnDestroy(): void {
    if (this.clickOutSub.unsubscribe) {
      this.clickOutSub.unsubscribe();
    }
  }

  initRoutes(res) {
    var user = res || this.user;
    this.userGroup = (user && user.group) || [];
    this.isAdmin = this.userGroup.indexOf(Roles.superuser) > -1;
    this.customerNavItems$ = this.routes.customerNavItems$;

    this.allUsersRoutes$ = this.routes.allUsersRoutes$;
  }

  onClickToggleEdit(event) {
    const selectedCustomer = { name: ''};

    if (!this.isAdmin) { return; }

    setTimeout(() => {
      (this.searchInput.nativeElement as HTMLInputElement).focus();
    });

    this.customerFC.setValue(selectedCustomer.name);

    if (!this.edit$.value) {
      this.onSearch(selectedCustomer.name);
    }

    if (event) {
      event.stopPropagation();
    }
    this.edit$.next(!this.edit$.value);
  }


  onOptionSelected({ option }) {
    this.selected = this.customers.find(({ name }) => name === option.value);
    this.select.emit(this.selected);
    this.onClickToggleEdit(null);
  }


  onSearch(value) {
    this.filter.emit(value);
  }
}
