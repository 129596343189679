import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WeaklyHoursScheduleViewComponent } from './weakly-hours-schedule-view/weakly-hours-schedule-view.component';
import { WeaklyHoursScheduleEditComponent } from './weakly-hourse-schedule-edit/weakly-hours-schedule-edit.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  declarations: [
    WeaklyHoursScheduleViewComponent,
    WeaklyHoursScheduleEditComponent,
  ],
  exports: [
    WeaklyHoursScheduleViewComponent,
    WeaklyHoursScheduleEditComponent,
  ],
})
export class WeaklyHoursScheduleModule { }
