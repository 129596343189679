import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpErrorInterceptor, HttpErrorsModule, PaginationInterceptor } from 'ex-library';
import { TokenInterceptor } from '../auth/token.interceptor';
import { CustomersInterceptor } from '../customers/customers.interceptor';
import { ElectricalMeterService } from './meters/electrical-meter.service';
import { METER_SERVICES } from './meters/media-type-meter-base.service';
import { WaterMeterService } from './meters/water-meter.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpErrorsModule,
  ],
  exports: [
    HttpErrorsModule,
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PaginationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomersInterceptor,
      multi: true,
    },
    {
      provide: METER_SERVICES,
      useExisting: ElectricalMeterService,
      multi: true,
    }, {
      provide: METER_SERVICES,
      useExisting: WaterMeterService,
      multi: true,
    },
  ],
})
export class DataModule {
}
