import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BE_DAY_DATE_FORMAT } from '../constants';
import { Building } from '../shared/models/building.model';
import { PaginationResponse } from '../shared/models/pagination-response.model';

export interface UtilityBillsFilters {
  buildings?: Building[];
  month?: Date;
}

export interface CalendarAnalysisFilters {
  building: Building;
  from: Date;
  to: Date;
}

function mapFields(fields: UtilityBillsLibraryModule.Fields) {
  return Object.entries(fields).reduce((mappedFields, [fieldId, field]) => {
    return {
      ...mappedFields,
      [fieldId]: {
        ...field,
        id: fieldId,
      },
    };
  }, <UtilityBillsLibraryModule.Fields>{});
}

function mapService(service: UtilityBillsLibraryModule.Service, floatingFields: UtilityBillsLibraryModule.Fields) {
  const serviceMappedFields = mapFields(service.fields);
  return <UtilityBillsLibraryModule.Service> {
    ...service,
    fields: serviceMappedFields,
    allowedFields: {
      ...serviceMappedFields,
      ...floatingFields,
    },
  };
}

function mapLibrary(lib: UtilityBillsLibraryModule.Library) {
  const mappedFloatingFields = mapFields(lib.floating_fields);
  const mappedServices = Object.entries(lib.services).reduce((mappedLib, [serviceId, service]) => {
    return {
      ...mappedLib,
      [serviceId]: {
        ...mapService(service, mappedFloatingFields),
        id: serviceId,
      },
    };
  }, <UtilityBillsLibraryModule.Services>{});

  return <UtilityBillsLibraryModule.Library> {
    services: mappedServices,
    floating_fields: mappedFloatingFields,
  };
}

@Injectable({
  providedIn: 'root'
})
export class UtilityBillsDataService {

  private utilityBillsApiBase = `${environment.apiBase}/utility-bills`;

  constructor(private http: HttpClient) { }

  getLibrary() {
    return (<Observable<UtilityBillsLibraryModule.Library>> this.http.get(`${environment.apiBase}/bill-fields-lib`)).pipe(
      map(mapLibrary),
    );
  }

  saveBill(billData) {
    return (<Observable<UtilityBillsLibraryModule.BillBody>>this.http.post(
      this.utilityBillsApiBase,
      <UtilityBillsLibraryModule.BillBody>{
        name: billData.name,
        company_id: billData.company_id,
        building_id: billData.building_id,
        meters: billData.meters_ids,
        date: moment(billData.date).startOf('month').format(BE_DAY_DATE_FORMAT),
        service_start_date: moment(billData.serviceStartDate).format(BE_DAY_DATE_FORMAT),
        service_end_date: moment(billData.serviceEndDate).format(BE_DAY_DATE_FORMAT),
        ...billData.services,
      }));
  }

  getBill(billId: string) {
    return <Observable<UtilityBillsLibraryModule.BillBody>>this.http.get(`${this.utilityBillsApiBase}/${billId}`);
  }

  deleteBill(billId: string) {
    return this.http.delete(`${this.utilityBillsApiBase}/${billId}`);
  }

  getBillsList(filters?: UtilityBillsFilters, params?: any) {
    let filtersParams = <any> {};
    if (filters && filters.buildings && filters.buildings.length) {
      filtersParams.building_id = filters.buildings.map(b => b.id);
    }
    if (filters && filters.month) {
      filtersParams.date = moment(filters.month).startOf('month').format(BE_DAY_DATE_FORMAT);
    }
    return <Observable<PaginationResponse<UtilityBillsLibraryModule.BillBody>>> this.http.get(this.utilityBillsApiBase, {
      params: {
        ...params,
        ...filtersParams,
      },
    });
  }

  getCalendarAnalysis({to, from, building}: CalendarAnalysisFilters) {
    return <Observable<UtilityMonthlyAnalysisModule.AnalysisBody>> this.http.get(`${this.utilityBillsApiBase}/calendar`, {
      params: {
        from: moment(from).format(BE_DAY_DATE_FORMAT),
        to: moment(to).format(BE_DAY_DATE_FORMAT),
        ...building ? {buildings: building.id} : {},
      },
    });
  }

  getUtilityCompanies() {
    return <Observable<UtilityBillsLibraryModule.UtilityCompany[]>> this.http.get(`${environment.apiBase}/utility-company-lookup`);
  }
}
