import { Component, OnInit } from '@angular/core';
import { IconsRegistryService } from './core/icons-registry.service';

@Component({
  selector: 'exa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private iconRegistry: IconsRegistryService) {}

  ngOnInit(): void {
    this.iconRegistry.init();
  }
}
