import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WeatherKwhHeatmapChartComponent } from './weather-kwh-heatmap-chart/weather-kwh-heatmap-chart.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    WeatherKwhHeatmapChartComponent,
  ],
  declarations: [WeatherKwhHeatmapChartComponent],
})
export class WeatherKwhHeatmapChartModule { }
