export class TempRange {
  end: number;
  start: number;
  name: string;
  id: string;

  constructor(endTemp: number) {
    this.start = endTemp - 9;
    this.end = endTemp;
    this.name = `${this.start} F - ${this.end} F`;
    this.id = `temp-range__${this.start}__${this.end}`;
  }
}
