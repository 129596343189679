import { OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, throwError, of } from 'rxjs';
import { map, filter, debounceTime, tap, switchMap, share, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { BE_DAY_DATE_FORMAT } from '../../constants';


export interface ISunburstGraphDataItem {
  id: string;
  name: string;
  parent?: string;
  value?: number;
}

export interface ISunburstDataAnnualWeekly {
  annual: ISunburstGraphDataItem[];
  weekly: ISunburstGraphDataItem[];
}


export interface ISunburstParams {
  start_date: string;
  end_date: string;
}


export class SunburstRouteBase implements OnInit {
  state$ = new BehaviorSubject({ loading: false, error: false });
  type$: Observable<string>;
  graphData$: Observable<any>;
  today = moment();

  protected title$: Observable<string>;
  protected period$ = new BehaviorSubject(null);
  protected fullTitle$: Observable<string>;


  constructor() { }

  ngOnInit() {
    this.setInitialSettings();
    this.setFullTitle();
    // this.getGraphData();
  }

  setInitialSettings() {
    const msg = `please define method: setInitialSettings to set required properties`;
    throw new Error(msg);
  }

  private setFullTitle() {
    this.fullTitle$ = combineLatest(this.title$, this.period$)
      .pipe(
        filter(([title, period]) => !!title && period),
        map(([title, period]) => `The ${title} from ${period.from} to ${period.to}`),
      );
  }

  getGraphData(params: ISunburstParams) {
    this.graphData$ = this.type$.pipe(
      debounceTime(300),
      tap(() => this.state$.next({ error: false, loading: true })),
      switchMap((type: string) => this.getDataObservabe(type, params)),
      // map((data) => this.nestDataByParent(data)),
      tap(() => this.state$.next({ error: false, loading: false })),
      share(),

      catchError((err) => {
        this.state$.next({ error: true, loading: false });
        return throwError(err);
      })
    );
  }


  protected nestDataByParent(data): ISunburstGraphDataItem[] {
    const list = [];
    const maps = {
      countries: {},
      states: {},
      buildings: {},
      meters: {},
      cities: {},
      citiesPerStates: {},
    };

    data.forEach((item, i) => {
      const { value, country, city, state, building, meter } = item;
      const stateName = country + state;
      const cityName = stateName + city;
      const buildingName = cityName + building;
      let stateIndex = maps.states[stateName];
      let cityIndex = maps.cities[cityName];
      let buildingIndex = maps.buildings[buildingName];

      if (!stateIndex) {
        maps.states[stateName] = Object.keys(maps.states).length + 1;
        stateIndex = maps.states[stateName];
        list.push({ name: state, id: `0.${stateIndex}`, parent: '' });
      }

      if (!cityIndex) {
        maps.cities[cityName] = Object.keys(maps.cities).length + 1;
        cityIndex = maps.cities[cityName];
        list.push({ name: city, id: `1.${cityIndex}`, parent: `0.${stateIndex}` });
      }

      if (!buildingIndex) {
        maps.buildings[buildingName] = Object.keys(maps.buildings).length + 1;
        buildingIndex = maps.buildings[buildingName];
        list.push({ name: building, id: `2.${buildingIndex}`, parent: `1.${cityIndex}` });
      }

      list.push({ name: meter, id: `3.${i + 1}`, parent: `2.${buildingIndex}`, value});
    });

    return list;
  }

  getDataObservabe(type: string, params: ISunburstParams): Observable<ISunburstGraphDataItem[]>|Observable<ISunburstDataAnnualWeekly> {
    return throwError('getDataObservabe is not defined.');
  }

  protected onSearch({ to, from }) {
    const format = BE_DAY_DATE_FORMAT;
    const params = {
      start_date: moment(from).format(format),
      end_date: moment(to).format(format),
    };

    this.period$.next({
      to: moment(to).format('MM/DD/YYYY'),
      from: moment(from).format('MM/DD/YYYY'),
    });

    this.getGraphData(params);
  }
}

