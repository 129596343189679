import {FormControl} from '@angular/forms';
import {Meter} from '../../models/meter.model';
import {Observable} from 'rxjs';

export const extractIdsIfArray = (arr: { id: string }[]) => arr && arr.map(item => item.id);

export const onBuildingsSelectedFactory = (
  metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>,
  metersFormControl: FormControl,
  configSetter: (config) => void,
  defaultMetersValue = null,
) => {
  return (buildings) => {
    const buildingIds = buildings && buildings.map(b => b.id);
    configSetter({
      searchItems: (name) => metersSearchFn(name, buildingIds),
    });
    metersFormControl.setValue(defaultMetersValue);
  };
};

export type OnBuildingsSelected = (buildings: { id: string }[]) => void;
