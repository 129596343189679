import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, share, switchMap, filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Roles } from '../../auth/user-group-guard.provider';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { DataService } from '../../data/data.service';
import { Building } from '../../shared/models/building.model';
import { Customer } from '../../shared/models/customer.model';
import { Meter } from '../../shared/models/meter.model';
import { MetersMetricsFilters } from '../../shared/models/meters-metrics-filters.model';
import { MetricsPoint, BenchmarkResponse } from '../../shared/models/meters-metrics.model';
import {actionsDef} from '../../shared/permissions/permissions-actions';
import {ToastrService} from 'ngx-toastr';
import {AllOrSearchConfig} from '../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {
  extractIdsIfArray,
  OnBuildingsSelected,
  onBuildingsSelectedFactory
} from '../../shared/components/all-or-search-filter/all-or-search-filter.utils';
import {
  multiBuildingsFilterBasicConfig,
  multiMetersFilterBasicConfig
} from '../../shared/components/all-or-search-filter/all-or-search-filter.constants';


function timeRangeValidator(filtersForm: FormGroup): ValidatorFn {
  return c => {
    const startDateFC = c.get('startDate');
    const endDateFC = c.get('endDate');
    const resolutionVal = filtersForm.get('resolution').value;
    const todayMoment = moment();

    const startMoment = moment(startDateFC.value);
    const endMoment = moment(endDateFC.value);

    if (startMoment.isAfter(todayMoment, 'd') || endMoment.isAfter(todayMoment, 'd')) {
      return {
        invalidDates: true,
      };
    }

    if (!startMoment.isBefore(endMoment)) {
      return {
        invalidOrder: true,
      };
    }

    if (resolutionVal && resolutionVal.id === 'raw' && endMoment.diff(startMoment, 'd') > 90) {
      return {
        invalidResolutionRange: true,
      };
    }

    return null;
  };
}

@Component({
  selector: 'exa-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit {
  metrics$: Observable<BenchmarkResponse>;
  adminRole = Roles.superuser;
  user = null;
  userIsAdmin = false;
  filtersForm: FormGroup;
  periodsNavigator: {forward: {start: Date, end: Date}, backward: {start: Date, end: Date}} = {forward: null, backward: null};

  resolutionChoices: any[] = [
    { name: '1 Day', id: '1d' },
    { name: '1 Hour', id: '1h' },
    { name: 'Original Resolution', id: 'raw'},
  ];


  startTime = moment().utc().subtract(1, 'w').toDate();
  endTime = moment().utc().toDate();
  searching: boolean;
  exporting: boolean;
  exportingUrl$: Observable<any>;
  exportingUrlSub: Subscription;
  exportingFileSub: Subscription;
  exportingError: any;
  actionsDefs = actionsDef;

  buildingsInputConfig: AllOrSearchConfig<Building>;
  metersInputConfig: AllOrSearchConfig<Meter>;
  onBuildingsSelected: OnBuildingsSelected;

  @Input() customer: Customer;
  @Input() buildingsSearchFn: (name: string) => Observable<Building[]>;
  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;
  @Output() metrics = new EventEmitter<{metrics$: Observable<BenchmarkResponse>, filters: MetersMetricsFilters}>();

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private fileSaver: FileSaverService,
    private auth: AuthService,
    protected toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    this.buildingsInputConfig = {
      searchItems: this.buildingsSearchFn,
      ...multiBuildingsFilterBasicConfig,
    };

    this.metersInputConfig = {
      searchItems: this.metersSearchFn,
      ...multiMetersFilterBasicConfig,
    };

    const todayMoment = moment().utc();
    const initialStartDate = moment(todayMoment).subtract(1, 'w').toDate();
    const initialEndDate = todayMoment.toDate();
    this.user = this.auth.getUser() || { group: [] };
    this.userIsAdmin = this.user.group.indexOf(this.adminRole) > -1;
    this.filtersForm = this.fb.group({
      buildings: [null],
      meters: [null],
      resolution: [this.resolutionChoices[0], [Validators.required]],
      timeRange: this.fb.group({
        startDate: [initialStartDate, Validators.required],
        endDate: [initialEndDate, Validators.required],
      }),
    });

    const metersFC = this.filtersForm.get('meters') as FormControl;
    this.onBuildingsSelected = onBuildingsSelectedFactory(this.metersSearchFn, metersFC, config => {
      this.metersInputConfig = {
        ...this.metersInputConfig,
        ...config,
      };
    });

    const timeRangeFG = <FormGroup>this.filtersForm.get('timeRange');
    timeRangeFG.setValidators(timeRangeValidator(this.filtersForm));
    timeRangeFG.valueChanges.subscribe(v => this.generatePeriodsNavigator());

    const resolutionFC = <FormControl>this.filtersForm.get('resolution');
    resolutionFC.valueChanges.subscribe(v => timeRangeFG.updateValueAndValidity());

    const buildingsControl = <FormControl> this.filtersForm.get('buildings');
    buildingsControl.valueChanges.subscribe(v => {
      this.onBuildingsSelected(v);
      const buildingIds = v && v.map(b => b.id);
      this.metersInputConfig = {
        ...this.metersInputConfig,
        searchItems: (name) => this.metersSearchFn(name, buildingIds)
      };
    });

    this.generatePeriodsNavigator();
  }

  private generateTime() {
    const timeRange = this.filtersForm.get('timeRange').value;
    const startTime = moment(timeRange.startDate);
    const endTime = moment(timeRange.endDate);
    return {
      startTime,
      endTime,
      from_date: startTime.startOf('d').format(BE_DATETIME_NO_TIMEZONE_FORMAT),
      to_date: endTime.hour(23).minute(0).format(BE_DATETIME_NO_TIMEZONE_FORMAT),
    };
  }

  private generateFiltersBody() {
    const formBody = this.filtersForm.value;
    const { startTime, endTime, from_date, to_date } = this.generateTime();
    return <MetersMetricsFilters> {
      customer_id: this.customer.id,
      from_date,
      to_date,
      building_id: extractIdsIfArray(formBody.buildings)[0],
      meters: extractIdsIfArray(formBody.meters),
      resolution: formBody.resolution.id,
    };
  }

  search() {
    this.searching = true;
    const searchFilters = this.generateFiltersBody();
    const uiAllFilters = {
      ...searchFilters,
      viewFilters: this.filtersForm.value,
    };
    this.metrics$ = this.data.customers.metersMetrics(searchFilters).pipe(
      share(),
      finalize(() => this.searching = false),
    );
    this.metrics.emit({metrics$: this.metrics$, filters: uiAllFilters});
  }

  exportIdr() {
    const { from_date, to_date } = this.generateTime();
    const meters = extractIdsIfArray(this.filtersForm.value.meters);

    this.exporting = true;
    this.exportingError = null;
    this.exportingUrlSub = this.data.tasks.exportIdr({ meters, from_date, to_date })
      .pipe(
        finalize(() => this.exporting = false),
      ).subscribe(
        (res) => {
          this.toastr.success('We are exporting requested data, when it\'s ready you will receive an email');
          this.exportingUrlSub.unsubscribe();
        }, (err) => {
          this.exportingError = err.error;
        }
      );

    // this.exportingUrlSub = this.exportingUrl$.subscribe((result) => {
    //   this.exportingUrlSub.unsubscribe();
    //   this.exportDownload(result.url, `IDR-from-${from_date}-to-${to_date}.xlsx`);
    // },
    // (err) => {
    //   this.exportingError = err;
    // });
  }


  exportDownload(url: string, fileName: string) {
    this.exporting = true;
    this.exportingFileSub = this.data.tasks.downloadExportedIdr(url)
      .subscribe((blobFile) => {
        this.exportingFileSub.unsubscribe();
        this.fileSaver.save(blobFile, fileName);
        this.exporting = false;
      });
  }

  private generatePeriodsNavigator() {
    const timeRangeControl = this.filtersForm.get('timeRange');
    if (timeRangeControl.invalid) {
      return this.periodsNavigator = {
        backward: null,
        forward: null,
      };
    }
    const {startDate, endDate} = timeRangeControl.value;
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const diff = endMoment.diff(startMoment, 'd');
    const today = moment();
    const backward = {
      start: moment(startMoment).subtract(diff + 1, 'd').toDate(),
      end: moment(startMoment).subtract(1, 'd').toDate(),
    };

    if (today.isSame(endMoment, 'd')) {
      return this.periodsNavigator = {
        backward,
        forward: null,
      };
    }

    const expectedForwardEnd = moment(endMoment).add(diff + 1, 'd');
    const forward = {
      start: moment(endMoment).add(1, 'd').toDate(),
      end: today.isBefore(expectedForwardEnd, 'd') ? today.toDate() : expectedForwardEnd.toDate(),
    };

    return this.periodsNavigator = {
      backward,
      forward,
    };
  }

  navigatePeriod({start, end}: {start: Date, end: Date}) {
    const timeRangeFG = <FormGroup>this.filtersForm.get('timeRange');
    timeRangeFG.setValue({startDate: start, endDate: end});
    if(this.filtersForm.valid) {
      this.search();
    }
  }
}
