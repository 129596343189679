import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SmtSubscriptionsComponent } from './smt-subscriptions/smt-subscriptions.component';
import { SmtAddSubscriptionComponent } from './smt-add-subscription/smt-add-subscription.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SmtSubscriptionsComponent,
  ],
  declarations: [SmtSubscriptionsComponent, SmtAddSubscriptionComponent],
  entryComponents: [SmtAddSubscriptionComponent],
})
export class SmtSubscriptionsModule { }
