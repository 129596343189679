import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../shared/models/customer.model';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { filter, switchMap, tap, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'exa-customers-list-details',
  templateUrl: './customers-list-details.component.html',
  styleUrls: ['./customers-list-details.component.scss']
})
export class CustomersListDetailsComponent implements OnInit {
  loading$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject(null);
  customer$: Observable<Customer>;
  attachments$ = new BehaviorSubject(null);
  notes$ = new BehaviorSubject(null);
  customer = {};
  pageSize = 5;
  offset = 0;
  userHeaderActions = [{
    text: 'create new user',
    icon: 'person_add',
    type: 'icon',
    callback: () => this.onCreateUser(),
  }];

  constructor(private data: DataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loadCustomer();
  }

  loadCustomer() {
    this.loading$.next(true);
    this.error$.next(null);
    this.customer$ = this.route.params
      .pipe(
        filter(params => !!params.id),
        switchMap(({ id }) => this.data.customers.details(id)),
        tap(() => this.loading$.next(false)),
        tap((res: any) => this.notes$.next(res.notes || [])),
        tap((res: any) => this.attachments$.next(res.attachments || [])),
        tap((customer) => this.customer = customer),
        catchError(err => {
          this.loading$.next(false);
          this.error$.next(err);
          return throwError(err);
        })
      );
  }

  onPageChange(page: PageEvent) {
    this.offset = page.pageIndex;
  }

  getUsers(list) {
    const { offset, pageSize } = this;
    return list.slice(offset * pageSize, (offset * pageSize) + pageSize + 1);
  }

  onCreateUser() {
    const { id } = this.customer as Customer;
    this.router.navigateByUrl('/customers/users/create?customer=' + id);
  }


  onNewNote(note) {
    const notes = this.notes$.value || [];
    this.notes$.next([note].concat(notes));
  }

  onDeleteNote(id) {
    this.notes$.next(this.notes$.value.filter(i => i.id !== id));
  }

  onNewAttachment(attachment) {
    const attachments = this.attachments$.value;
    this.attachments$.next([attachment, ...attachments]);
  }


  onDeleteAttachment(id) {
    this.attachments$.next(
      this.attachments$.value.filter(i => i.id !== id)
    );
  }
}
