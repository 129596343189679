import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GreenButtonConnectModule } from '../green-button-connect/green-button-connect.module';
import { SmtSubscriptionsModule } from '../smt-subscriptions/smt-subscriptions.module';
import { MetersIntegrationComponent } from './meters-integration/meters-integration.component';

@NgModule({
    imports: [
        CommonModule,
        SmtSubscriptionsModule,
        SharedModule,
        GreenButtonConnectModule,
    ],
  exports: [
    MetersIntegrationComponent,
  ],
  declarations: [MetersIntegrationComponent],
})
export class MetersIntegrationModule {
}
