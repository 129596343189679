import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService,
    private toastr: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isToBE = req.url.includes(environment.apiBase);
    const token = this.auth.getToken();
    if (token && isToBE) {
      req = req.clone({
        setHeaders: {
          Authorization: `Token ${this.auth.getToken()}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((response) => {
        if (response.status === 401) {
          this.toastr.warning("Unauthorized");
        } else if (response.status === 403) {
          this.auth.whenUnauthorized();
        } else if (response.error.Message) {
          this.toastr.error(response.error.Message)
        } else if (response.error.data && response.error.data.Errors) {
          this.toastr.warning("Validation errors found");
        } else if (response.status === 500 || !response.error.data) {
          this.toastr.error("An error has occurred");
        } else {
          this.toastr.error("An error has occurred");
        }
        return throwError(response);
      }),
    );
  }

}
