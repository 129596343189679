import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'exa-list-base-actions',
  template: `
    <div class="text--right full-width text--gray" *ngIf="actions && actions.length">
      <ng-container *ngFor="let action of actions">
        <button
            mat-icon-button
            *ngIf="action.type === 'icon'"
            (click)="onActionClick(action, params.data, $event)">
          <mat-icon>{{action.icon}}</mat-icon>
        </button>

        <button
            mat-button
            *ngIf="action.type === 'button'"
            (click)="onActionClick(action, params.data, $event)">
          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          {{action.text}}
        </button>


        <ng-container *ngIf="action.type === 'menu'">
          <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
            <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <ng-container *ngIf="action.items">
              <button
                  mat-menu-item
                  *ngFor="let item of action.items"
                  (click)="onActionClick(item, params.data, $event)">
                <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                {{item.text}}
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
    </div>
    `,
  styles: []
})
export class ListBaseActionsComponent {
  params: any;
  actions: any[] = [];

  constructor(private router: Router) { }

  agInit(params: any) {
    this.params = params;
    this.actions = (params.data && params.data.gridActions) || [];
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onActionClick(action, data, mouseEvent) {
    const ctx = this.params.context;
    const callback = ctx.componentParent[action.method];

    if (action.route) {
      this.router.navigate(action.route);
    }

    if (typeof callback === 'function') {
      callback.call(ctx.componentParent, data, mouseEvent);
    } else if (!action.route) {
      const componentName = ctx.componentParent.constructor.name;
      throw new Error(`${action.method} method is not found in ${componentName}`);
    }
  }
}
