import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeterCreateModule } from '../features/meter-create/meter-create.module';
import { VirtualMeterSummarySectionModule } from '../features/virtual-meter-summary-section/virtual-meter-summary-section.module';
import { NotAvailableModule } from '../widgets/not-available/not-available.module';
import { MetersListModule } from './meters-list/meters-list.module';
import { PriorityComponent } from './priority/priority.component';
import { SharedModule } from '../shared/shared.module';
import { PriorityMetersListComponent } from './priority/priority-meters-list/priority-meters-list.component';
import { CreateMeterComponent } from './create-meter/create-meter.component';
import { NewMeterComponent } from './create-meter/new-meter/new-meter.component';
import { ImportMetersComponent } from './create-meter/import-meters/import-meters.component';
import { MetersComponent } from './meters.component';
import { PriorityFilterComponent } from './priority/priority-filter/priority-filter.component';
import { NotesModule } from '../notes/notes.module';
import { AttachmentsModule } from '../attachments/attachments.module';
import { NewVirtualMeterComponent } from './create-meter/new-virtual-meter/new-virtual-meter.component';
import { CombinationEquationModule } from '../features/combination-equation/combination-equation.module';

@NgModule({
    imports: [
        CommonModule,
        MetersListModule,
        SharedModule,
        NotesModule,
        AttachmentsModule,
        CombinationEquationModule,
        NotAvailableModule,
        VirtualMeterSummarySectionModule,
        MeterCreateModule,
    ],
  declarations: [
    PriorityComponent,
    PriorityMetersListComponent,
    CreateMeterComponent,
    NewMeterComponent,
    NewVirtualMeterComponent,
    ImportMetersComponent,
    MetersComponent,
    PriorityFilterComponent,
    NewVirtualMeterComponent,
  ],
})
export class MetersModule { }
