import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { GreenButtonConnectSubscriptionsComponent } from './green-button-connect-subscriptions/green-button-connect-subscriptions.component';
import { GreenButtonConnectManageSubscriptionComponent } from './green-button-connect-manage-subscription/green-button-connect-manage-subscription.component';
import { GreenButtonConnectMapMetersComponent } from './green-button-connect-map-meters/green-button-connect-map-meters.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    GreenButtonConnectSubscriptionsComponent,
  ],
  declarations: [GreenButtonConnectSubscriptionsComponent, GreenButtonConnectManageSubscriptionComponent, GreenButtonConnectMapMetersComponent],
  entryComponents: [GreenButtonConnectManageSubscriptionComponent, GreenButtonConnectMapMetersComponent],
})
export class GreenButtonConnectModule { }
