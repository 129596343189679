import { environment } from '../environments/environment';
import { ScheduleProfileInfo } from './shared/models/operation-schedule/models';

export const BE_DAY_DATE_FORMAT = 'YYYY-MM-DD';
export const BE_DATETIME_NO_TIMEZONE_FORMAT = `YYYY-MM-DD HH:mm:ss`;

export const colors = {
  primary: '#716aca',
};

export const chartColors = {
  power: colors.primary,
  water: '#03A9F4',
  temperature: '#D32F2F',
  // temperature: '#4CAF50',
};

export const predictedKwhMethods = {
  baseloadSetbackMode: {
    id: 'BaseloadSetback',
    name: 'Baseload/setback Mode',
  },
};

export const OperationScheduleProfiles: {[id: string]: ScheduleProfileInfo} = {
  open: {
    id: 'open',
    color: 'rgba(72, 169, 166, 1)',
    contrastColor: 'rgba(72, 169, 166, 0.5)',
    // color: 'rgb(255, 152, 0)',
    // contrastColor: 'rgb(255, 152, 0, 0.5)',
    displayName: 'Open for Business'
  },
  closed: {
    id: 'closed',
    color: 'rgba(113, 106, 202, 1)',
    contrastColor: 'rgba(113, 106, 202, 0.5)',
    // color: 'rgb(33, 150, 243)',
    // contrastColor: 'rgb(33, 150, 243, 0.5)',
    displayName: 'Setback (Closed for public)',
  },
  employee_only: {
    id: 'employee_only',
    color: 'rgba(193, 102, 107, 1)',
    contrastColor: 'rgba(193, 102, 107, 0.5)',
    // color: 'rgb(156, 204, 101)',
    // contrastColor: 'rgb(156, 204, 101, 0.5)',
    displayName: 'Employees Only/Shoulder Mode',
  },
};

export const PROXY_API = `${environment.apiBase}/v1/customers/proxy`;

export const DIALOG_STATIC_WIDTHS = {
  small: '500px',
  medium: '750px',
  large: '900px',
};

export const taskStatuses = {
  pending: 'PENDING',
  success: 'SUCCESS',
  failure: 'FAILURE',
};
