import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith, finalize } from 'rxjs/operators';
import { DataService } from '../../data/data.service';

const nameRegex = '^[a-zA-Z0-9 -]*$';
const fullNameRegex = '^[a-zA-Z ]+$';
const phoneNumberRegex = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';

@Component({
  selector: 'exa-customers-create',
  templateUrl: './customers-create.component.html',
  styles: [],
})
export class CustomersCreateComponent implements OnInit {

  creationLoading: boolean;
  creationError: boolean;
  creationErrorMessage: string;
  creationErrorObject: any;
  loadingError = false;
  labelsMap = {
    name: 'Customer Name',
    fax: 'Fax',
    phone: 'Phone',
    employer: 'Employer',
    email: 'Customer Email Address',
    password: 'Customer Password',
    address: 'Customer Address'
  };
  customers: any[];
  filteredCustomers: Observable<string[]>;
  loadingCustomers: boolean;
  loadingCustomersError: boolean;
  loadingCustomersErrorMessage: string;


  customerForm = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(nameRegex)]],
    email: ['', [Validators.email, Validators.required]],
    address: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.minLength(8), Validators.pattern(phoneNumberRegex)]],
    fax: ['', [Validators.minLength(8), Validators.pattern(phoneNumberRegex)]],
  });


  constructor(
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private toastr: ToastrService,
    private data: DataService) {

  }

  ngOnInit() {
    this.loadCustomers();
  }

  loadCustomers() {
    this.loadingCustomers = true;
    this.loadingCustomersError = false;
    this.loadingError = false;
    return this.data.customers.list()
    .pipe(finalize(() => this.loadingCustomers = false))
    .subscribe(
      (customers: any[]) => {
        this.customers = customers.map((c) => c.name);
        this.bindCustomersFilter();
      },
      (errorRes) => {
        this.loadingCustomersError = true;
        this.loadingCustomersError = errorRes.error.detail || 'something went wrong, please try again!';
        this.loadingError = true;
      },
    );
  }

  createCustomer() {
    this.creationLoading = true;
    this.creationError = false;
    this.creationErrorMessage = null;
    return this.data.customers.create(this.customerForm.value)
      .pipe(finalize(() => this.creationLoading = false))
      .subscribe(
        (res) => {
          this.creationError = false;
          this.toastr.success('Customer created successfully!');
          this.router.navigate(['/customers']);
        },
        (errorRes) => {
          this.creationError = true;
          const error = errorRes.error;
          _.isObject(error)
              ? this.creationErrorObject = error
              : this.creationErrorMessage = 'something went wrong, please try again!';
        },
      );
  }

  getErrorMessage(controlPath: string[], label: string) {
    const isRequired = this.customerForm.get(controlPath).errors.required;
    const isName = label.toLowerCase() === 'customer';
    const isPhoneOrFax = label.toLowerCase() === 'phone';

    return isRequired
      ? `${label} is required`
      : (isPhoneOrFax)
        ? `${label} should be like 804-555-0141`
        : (isName)
            ? 'it\'s not allowed to enter special characters except dashes'
            : `Please enter a valid ${label}`;
  }

  cancel() {
    return this.location.back();
  }

  private bindCustomersFilter() {
    this.filteredCustomers = this.customerForm.controls['name'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.customers.filter(option => option.toLowerCase().includes(filterValue));
  }
}
