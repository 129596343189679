import { Subject } from 'rxjs';

export interface DashboardCommonChild {
    executeAction();
}
let subscription;
export function eventSubscriber(action: Subject<any>, handler: (value) => void, off: boolean = false) {
    if (off && subscription) {
        subscription.unsubscribe();
    } else {
        subscription = action.subscribe((value) => handler(value));
    }
}