import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'exa-google-map-tooltip',
  template: `
    <div
        class="google-map__tooltip"
        [ngClass]="{ 'google-map__tooltip--active': active }"
        [style.top]="y + 'px'"
        [style.left]="x + 'px'">
      <div class="google-map__tooltip-content">
        <b>Building: </b> {{data?.building__name}}
        <br>

        <ng-container *ngIf="data?.building__address_line1">
          <b>Address: </b> {{data?.building__address_line1}}
        </ng-container>

        <ng-container *ngIf="densityMap[targetDensity] as density">
          <b>{{density?.key}}: </b> {{ data?.value }} {{density?.unit}}
        </ng-container>
      </div>

      <button
          mat-icon-button
          class="google-map__tooltip-close"
          (click)="onClick()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./google-map-tooltip.component.scss'],
})
export class GoogleMapTooltipComponent {
  @Input() active = false;
  @Input() posX = 0;
  @Input() posY = 0;
  @Input() parentBounding: { top: number, left: number};
  @Input() data: any;
  @Input() targetDensity: 'total_waste';
  @Output() close = new EventEmitter();

  densityMap = {
    'total_waste': {
      key: 'total waste of all waste events',
      unit: 'KWh',
    },

    'events_count': {
      key: 'Number of waste events per meter',
      unit: 'events',
    },

    'waste_days': {
      key: 'Total number of Wasteful days',
      unit: 'days',
    },

    'avg_days': {
      key: 'Average number of days per waste event',
      unit: 'days',
    },
  };

  get x() {
    return this.parentBounding ? this.posX - this.parentBounding.left : 0;
  }

  get y() {
    return this.parentBounding ? this.posY - this.parentBounding.top : 0;
  }
  constructor() { }

  onClick() {
    this.close.emit();
  }
}
