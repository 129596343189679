import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, map, catchError, share, tap } from 'rxjs/operators';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { getLookupValidator } from '../../shared/components/lookup-dropdown.component';
import { Building } from '../../shared/models/building.model';


@Component({
  selector: 'exa-buildings-form',
  templateUrl: './buildings-form.component.html',
  styles: []
})
export class BuildingsFormComponent implements OnInit, OnDestroy {
  @Input() building: Building;
  @Input() saveCallback: Function;
  @Input() redirectTo: string[];
  @Input() disabledKeys: string[] = [];
  error: any = null;
  formLoading = false;
  buildingTypes$: Observable<any>;
  creationLoading = false;
  loadingError = false;

  states$: Observable<any>;
  countries$: Observable<any>;
  selectedCountryId$ = new BehaviorSubject(null);
  hasStates = false;
  buildingTypesLoading = false;
  statesLoading = false;
  customer$ = this.customers.selected$;
  buildingForm: FormGroup;
  formSub: Subscription;

  subscriptionsSink = new Subscription();

  selectComparator = (o1, o2) => o1.id === o2.id;

  labelsMap = {
    name: 'Building Name',
    'owner.name': 'Owner Name',
    country_id: 'Country',
    type_id: 'Building Type',
    state_id: 'State',
    city: 'City',
    area: 'Area',
    zip_code: 'Zip Code',
    first_address_line: 'Address Line 1',
    second_address_line: 'Address Line 2',
    public_name: 'Public Name',
  };

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private customers: CustomersService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.subscriptionsSink.unsubscribe();
  }

  ngOnInit() {
    this.buildingForm = this.fb.group({
      name: [
        {
          value: this.getDefaultValue('name'),
          disabled: this.disabledKeys.includes('name'),
        },
        [Validators.required],
      ],
      owner: [
        {
          value: this.getDefaultValue('owner', '').name,
          disabled: this.disabledKeys.includes('owner'),
        },
        [Validators.required],
      ],

      type: [
        {
          value: this.getDefaultValue('type', ''),
          disabled: this.buildingTypesLoading || this.disabledKeys.includes('type'),
        },
        [Validators.required, getLookupValidator('id')]
      ],

      country: [
        {
          value: this.getDefaultValue('country', ''),
          disabled: this.statesLoading || this.disabledKeys.includes('country'),
        },
        [Validators.required, getLookupValidator('id')]
      ],

      state: [
        {
          value: this.getDefaultValue('state', ''),
          disabled: this.statesLoading || this.disabledKeys.includes('state') || (!this.hasStates && !this.building),
        },
        [Validators.required, getLookupValidator('id')],
      ],

      city: [
        {
          value: this.getDefaultValue('city'),
          disabled: this.disabledKeys.includes('city'),
        },
        [Validators.required]
      ],

      area: [
        {
          value: this.getDefaultValue('area'),
          disabled: this.disabledKeys.includes('area')
        },
        [Validators.required,  Validators.pattern(/\d+/)]
      ],

      first_address_line: [
        {
          value: this.getDefaultValue('first_address_line'),
          disabled: this.disabledKeys.includes('first_address_line'),
        }, [Validators.required]
      ],


      second_address_line: [
        {
          value: this.getDefaultValue('second_address_line'),
          disabled: this.disabledKeys.includes('second_address_line'),
        },
      ],

      zip_code: [
        {
          value: this.getDefaultValue('zip_code'),
          disabled: this.disabledKeys.includes('zip_code'),
        },
        [Validators.required]],
      public_name: [{
        value: this.getDefaultValue('public_name'),
        disabled: this.disabledKeys.includes('public_name'),
      }, [Validators.required]]
    });

    const countryFC = this.buildingForm.controls.country;
    this.subscriptionsSink.add(
      countryFC.valueChanges.pipe(
        tap(country => this.onCountrySelected(country)),
      ).subscribe(),
    );

    this.loadingError = false;
    this.loadBuildingTypes();
    this.loadStates();
    this.selectedCountryId$.next(this.getDefaultValue('country', ''));
  }


  getErrorMessage(controlPath: string[], label: string) {
    const isRequired = this.buildingForm.get(controlPath).errors.required;

    return isRequired
      ? `${label} is required`
      : `Please enter a valid ${label}`;
  }


  onSubmit() {
    let newBuilding = null;
    const form = { ...this.buildingForm.getRawValue() };
    const building = {
      type_id: form.type && form.type.id,
      state_id: form.state && form.state.id,
      country_id: form.country.id,
      owner: {
        name: form.owner,
      },
    };

    delete form.type;
    delete form.state;
    delete form.country;

    newBuilding = Object.assign({}, form, building);

    this.formLoading = true;
    this.error = null;
    this.formSub = this.saveCallback(newBuilding, this.getDefaultValue('id'))
      .pipe(
        finalize(() => this.formLoading = false),
      ).subscribe(
        () => {
          this.toastr.success('Building saved successfully');

          if (this.redirectTo) {
            this.router.navigate(this.redirectTo);
          }
          this.formSub.unsubscribe();
        },

        (err) => {
          this.error = err && err.error;
          this.formLoading = false;
          this.formSub.unsubscribe();
        }
      );
  }

  loadBuildingTypes() {
    this.buildingTypesLoading = true;
    this.buildingTypes$ = this.data.buildings.types().pipe(
      catchError((err) => {
        this.loadingError = true;
        return throwError(err);
      }),
      finalize(() => this.buildingTypesLoading = false)
    );
  }


  loadStates() {
    const lookups$ = this.data.buildings.getStates()
      .pipe(
        share(),
        catchError((err) => {
          this.loadingError = true;
          return throwError(err);
        }),
      );

    this.statesLoading = true;

    this.countries$ = this.data.buildings.getCountries()
    .pipe(
      catchError((err) => {
        this.loadingError = true;
        return throwError(err);
      }),
      finalize(() => this.statesLoading = false)
    );

    this.states$ = combineLatest(
      this.selectedCountryId$,
      this.data.buildings.getStates()
    ).pipe(
      map(([country, states]: [any, any]) => {
        return states.filter((state) => country && state.country_id === country.id);
      }),
      tap((states) => {
        if (states) {
          this.hasStates = !!states.length;
        }
      }),
      finalize(() => this.statesLoading = false)
    );
  }

  private getDefaultValue(key, type = 'string') {
    const building = this.building || {};

    if (type === 'string') {
      return building[key] || '';
    }

    return building[key] || { id: '', name: '' };
  }

  onCountrySelected(country) {
    let shouldDisableState;
    this.selectedCountryId$.next(country);
    shouldDisableState = this.statesLoading || this.disabledKeys.includes('state') || !this.hasStates;

    const stateFC = this.buildingForm.controls.state;
    stateFC.setValue(null);
    stateFC.updateValueAndValidity();

    if (shouldDisableState) {
      this.buildingForm.get('state').disable();
    } else {
      this.buildingForm.get('state').enable();
    }

  }
}
