import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { BE_DAY_DATE_FORMAT, predictedKwhMethods } from '../../../constants';
import { Meter } from '../../../shared/models/meter.model';
import * as _ from 'lodash';
import { PopoverComponent } from '../../../shared/components/popover/popover.component';
import {AllOrSearchConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {Observable} from 'rxjs';

interface ModelingMethodChoice {
  id: string;
  name: string;
}

const metersFilterBasicConfig = <AllOrSearchConfig<Meter>>{
  selectMultiple: true,
  displayNameExtractorFn: meter => meter.name,
  inputPlaceholder: 'Search And Select Meter',
  title: 'Select Meters',
  uniqueKey: 'id',
  specificationTitle: 'Select Specific Meter/s',
};

@Component({
  selector: 'exa-priority-filter',
  templateUrl: './priority-filter.component.html',
  styles: []
})
export class PriorityFilterComponent implements OnInit {
  modelingMethodChoices: ModelingMethodChoice[] = [{id: null, name: 'All'}].concat(Object.values(predictedKwhMethods));

  filtersForm = this.fb.group({
    startTime: [moment().utc().subtract(1, 'y').toDate(), Validators.required],
    endTime: [moment().utc().toDate(), Validators.required],
    method: [this.modelingMethodChoices[0], Validators.required],
    cost: [0.1, Validators.required],
    meters: [null, Validators.minLength(0)],
  });

  metersFilterConfig: any;

  formChanged = true;
  popoverOpenedOnce = false;
  @Input() loading = false;
  @Input() customerId;
  @Input() searchMeters: (keyword: string) => Observable<Meter[]>;
  @Output() filter = new EventEmitter();
  @ViewChild('popover') $popover: PopoverComponent;


  constructor(private fb: FormBuilder ) { }

  ngOnInit() {
    this.metersFilterConfig = {
      ...metersFilterBasicConfig,
      searchItems: this.searchMeters,
    };

    this.useUserPresets();
    this.onFilter();
  }

  onFilter() {
    const filters = this.filtersForm.value;
    const filterValue = {
      from_date: moment(filters.startTime).format(BE_DAY_DATE_FORMAT),
      to_date: moment(filters.endTime).format(BE_DAY_DATE_FORMAT),
      avg_cost: filters.cost,
      modeling_method: filters.method.id,
      meters: filters.meters,
    };

    localStorage.setItem('priorityFilter', JSON.stringify(filterValue));
    localStorage.setItem('priorityFilterCustomer', this.customerId);
    const {meters, ...basicFilterValue} = filterValue;
    this.filter.emit({
      ...basicFilterValue,
      meter_list: filters.meters && filters.meters.map(m => m.id),
    });

    this.$popover.hide();
    // this.formChanged = false;
  }

  useUserPresets() {
    const filters = JSON.parse(localStorage.getItem('priorityFilter')) || {};
    const customerId = localStorage.getItem('priorityFilterCustomer');
    const {from_date, to_date, avg_cost, meters, modeling_method} = filters;

    if (!from_date) {
      return;
    }

    this.filtersForm.setValue({
      startTime: moment(from_date).toDate(),
      endTime: moment(to_date).toDate(),
      cost: avg_cost,
      meters: customerId !== this.customerId || !meters || !meters.length ? null : meters,
      method: this.modelingMethodChoices.find(i => i.id === modeling_method) || this.modelingMethodChoices[0],
    });

    this.filtersForm.updateValueAndValidity();
  }
}
