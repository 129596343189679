import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class DashboardService {
    subscription = new Subject();
    executeAction(params?) {
        this.subscription.next(params);
    }
}