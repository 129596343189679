import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { WasteEventsFilterComponent } from './waste-events-filter/waste-events-filter.component';
import { WasteEventPageComponent } from './waste-events-page/waste-event-page.component';
import { WasteEventsAverageComponent } from './waste-events-average/waste-events-average.component';
import { WasteEventsTsChartComponent } from './waste-events-ts-chart/waste-events-ts-chart.component';
import { WasteEventsChartsLegendComponent } from './waste-events-charts-legend/waste-events-charts-legend.component';
import {PaginatorModule} from '../../widgets/paginator/paginator.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PaginatorModule,
    ],
  declarations: [
    WasteEventPageComponent,
    WasteEventsFilterComponent,
    WasteEventsAverageComponent,
    WasteEventsTsChartComponent,
    WasteEventsChartsLegendComponent,
  ],
  exports: [
    WasteEventPageComponent,
  ],
})
export class WasteEventsModule {}
