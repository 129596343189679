import {Component, ViewChild, ElementRef} from '@angular/core';
// import {ILoadingOverlayComponentAngularComp} from "ag-grid-angular";

@Component({
    selector: 'exa-list-base-grid-header',
    template: `
      <div fxLayout="row" fxLayoutAlign="start center" class="head-custom">
          <div
              *ngIf="params.enableMenu"
              #menuButton
              class="head-custom__menu"
              (click)="onMenuClicked($event)">
            <i class="fa {{params.menuIcon}}"></i>
          </div>

              <!-- class="customHeaderLabel "-->
          <div
              class="head-custom__display">
            <span class="head-custom__display-span" [matTooltip]="params.displayName">
              {{params.displayName}}
            </span>
          </div>

          <div
              *ngIf="params.enableSorting"
              (click)="onSortRequested($event)"
              class="head-custom__sort"
              [ngClass]="['head-custom__sort--' + activeSort]">
            <i class="material-icons" *ngIf="activeSortIndex === 2">arrow_downward</i>
            <i class="material-icons" *ngIf="activeSortIndex === 1">arrow_upward</i>
            <i class="material-icons" *ngIf="activeSortIndex === 0">unfold_more</i>
          </div>
      </div>
    `,
  styleUrls: ['./list-base-header.component.scss']
})
export class ListBaseHeaderComponent {
    params: any;
    activeSortIndex = 0;
    availableSorts = ['', 'asc', 'desc'];
    activeSort = this.availableSorts[this.activeSortIndex];

    @ViewChild('menuButton', {read: ElementRef}) public menuButton;

    agInit(params): void {
      this.params = params;

      params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
      this.onSortChanged();
    }

    onMenuClicked() {
      this.params.showColumnMenu(this.menuButton.nativeElement);
    }

    onSortChanged() {
      if (this.params.column.isSortAscending()) {
        this.activeSort = 'asc';
        this.activeSortIndex = 1;
      } else if (this.params.column.isSortDescending()) {
        this.activeSort = 'desc';
        this.activeSortIndex = 2;
      } else {
        this.activeSort = '';
        this.activeSortIndex = 0;
      }
    }

    onSortRequested(event) {
      let order = this.activeSortIndex + 1;
      order = order >= this.availableSorts.length ? 0 : order;
      this.params.setSort(this.availableSorts[order], event.shiftKey);
    }
}

