import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SideNavService } from './side-nav.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SideNavItemComponent } from './side-nav/side-nav-item/side-nav-item.component';
import { SideNavRoutesService } from './side-nav/side-nav-routes.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SideNavComponent,
    SideNavItemComponent,
  ],

  exports: [
    LayoutComponent,
  ],

  providers: [
    SideNavService,
    SideNavRoutesService,
  ],
})
export class LayoutModule { }
