import { Directive, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, DefaultValueAccessor } from '@angular/forms';

@Directive({
  selector: '[exaNumberInputMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputMaskDirective),
      multi: true,
    },
  ],
})
export class NumberInputMaskDirective extends DefaultValueAccessor implements ControlValueAccessor  {

  static inputRegex = /^(-?\d+(,\d)*)+(\.\d*)?$/i;
  private currentVal: number;

  writeValue(value: number): void {
    this.currentVal = value;
    const stringVal = !value && value !== 0 ? '' : NumberInputMaskDirective.mapValueToString(value);
    super.writeValue(stringVal);
  }

  @HostListener('input', ['$event'])
  private onInputChange(e: Event) {
    const inputVal = (e.target as HTMLInputElement).value;
    if (!!inputVal && !NumberInputMaskDirective.inputRegex.test(inputVal)) {
      this.writeValue(this.currentVal);
      return;
    }
    const newNumberVal = NumberInputMaskDirective.mapStringToNumber(inputVal);

    if (newNumberVal === this.currentVal)
      return;

    this.onChange(newNumberVal);
    this.writeValue(newNumberVal);
  }

  static mapValueToString(val: number) {
    const [intPart, reminderPart] = val.toString().split('.');
    const decimalPart = reminderPart ? '.' + reminderPart : '';
    // https://stackoverflow.com/a/2901298/4388565
    const intStr = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return intStr + decimalPart;
  }

  static mapStringToNumber(val: string) {
    const numberString = val.replace(/,*/g,'');
    return numberString ? parseFloat(numberString) : null;
  }
}
