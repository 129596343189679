import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WeatherBoundariesModule } from '../weather-boundaries/weather-boundaries.module';
import { WeatherDailyHoursScatterModule } from '../weather-daily-hours-scatter/weather-daily-hours-scatter.module';
import { WeatherDailyHoursSummaryModule } from '../weather-daily-hours-summary/weather-daily-hours-summary.module';
import { WeatherComparisonFiltersComponent } from './weather-comparison-filters/weather-comparison-filters.component';
import { WeatherComparisonSectionComponent } from './weather-comparison-section/weather-comparison-section.component';
import { WeatherComparisonScatterComponent } from './weather-comparison-scatter/weather-comparison-scatter.component';
import { WeatherComparisonBoundariesComponent } from './weather-comparison-boundaries/weather-comparison-boundaries.component';
import { WeatherComparisonHoursSummaryComponent } from './weather-comparison-hours-summary/weather-comparison-hours-summary.component';
import { TotalDegreeHoursDaysComponent } from './total-degree-hours-days/total-degree-hours-days.component';
import { MatFormFieldModule } from "@angular/material";
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WeatherDailyHoursScatterModule,
    WeatherBoundariesModule,
    WeatherDailyHoursSummaryModule,
    NgxMatDrpModule
  ],
  declarations: [WeatherComparisonFiltersComponent, WeatherComparisonSectionComponent, WeatherComparisonScatterComponent, WeatherComparisonBoundariesComponent, WeatherComparisonHoursSummaryComponent, TotalDegreeHoursDaysComponent],
  exports: [WeatherComparisonSectionComponent, WeatherComparisonScatterComponent, MatFormFieldModule]
})
export class WeatherComparisonModule { }
