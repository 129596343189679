import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from '../../../../../ex-library/src/lib/charts/charts.module';
import { WeatherKwhScatterComponent } from './weather-kwh-scatter.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
  ],
  declarations: [WeatherKwhScatterComponent],
  exports: [WeatherKwhScatterComponent]
})
export class WeatherKwhScatterModule { }
