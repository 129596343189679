import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, fromEvent, BehaviorSubject } from 'rxjs';
import { catchError, map, finalize, tap, filter } from 'rxjs/operators';
import { merge } from 'rxjs';
import { DataService } from '../data/data.service';
import { IAuthService } from 'ex-library';


export enum ERoles {
  high = 1,
  mid,
  low,
}


@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {

  currentTabToken$ = new BehaviorSubject(this.getToken());

  token$ = merge(
    this.currentTabToken$
  );

  isAdmin$ = new BehaviorSubject(false);
  user$ = new BehaviorSubject(null);
  loggedInUser$ = this.user$.pipe(
    filter(user => !!user),
  );

  private user: any;

  constructor(private router: Router, private data: DataService) {
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    // todo: this should be async as we may return an observable instead
    return this.user;
  }

  isLoggedIn(): Observable<any> {
    const userObservable = this.data.users.getProfile();
    userObservable
      .pipe(
        map((profile: any) => {
          const user = profile.user || { user: { group: [] } };
          const { group: oldGroup } = user;
          const isAdmin = profile.is_superuser;
          const group = [].concat(oldGroup || [])
            .map((g) => ERoles[g])
            .concat(isAdmin ? ['superuser'] : []);
          this.isAdmin$.next(isAdmin);
          return { ...profile, user: { ...user }, group, isAdmin };
        }))
      .subscribe(
        user => {
          this.user$.next(user);
          this.user = user;
          this.currentTabToken$.next(user);
        },
      );

    return userObservable.pipe(
      map((userDetails) => !!userDetails),
      catchError((err) => of(false)),
    );
  }

  login(credentials) {
    return this.data.users.login(credentials)
      .pipe(map((res: any) => {
          localStorage.setItem('token', res.token);
          this.user = res.user;
          return res;
        },
      ));
  }

  forgotPassword(email) {
    return this.data.users.forgotPassword(email)
      .pipe(map((res: any) => {

      },
      ));
  }

  resetPassword(resetPasswordValues) {
    return this.data.users.resetPassword(resetPasswordValues)
      .pipe(map((res: any) => {
        
      },
      ));
  }

  logout() {
    const logout$ = this.data.users.logout().pipe(
      finalize(() => this.whenUnauthorized()),
    );
    logout$.subscribe();
    return logout$;
  }

  whenUnauthorized() {
    localStorage.removeItem('token');
    this.currentTabToken$.next(this.getToken());
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
