import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Options } from 'highcharts';

type kwhVal = number;
type temperatureVal = number;

export interface WeatherKwhScatterSeries {
  id: string;
  name: string;
  points: {
    kwh: kwhVal;
    temperature: temperatureVal;
  }[];
}

@Component({
  selector: 'exa-weather-kwh-scatter',
  template: `
    <exlib-chart [chartOptions]="chartOptions" *ngIf="chartOptions"></exlib-chart>
  `,
  styleUrls: ['./weather-kwh-scatter.component.scss']
})
export class WeatherKwhScatterComponent implements OnChanges {
  chartOptions: Options;

  @Input() seriesArr: WeatherKwhScatterSeries[];

  constructor() { }

  ngOnChanges({seriesArr}: SimpleChanges): void {
    if (seriesArr.currentValue !== seriesArr.previousValue && !!this.seriesArr) {
      this.generateChartOptions();
    }
  }

  generateChartOptions() {
    const numberFormatter = (n: number) => Math.round(n * 100) / 100;
    const mappedSeries = WeatherKwhScatterComponent.mapInputSeries(this.seriesArr);
    this.chartOptions = {
      series: mappedSeries,
      chart: {
        type: 'scatter',
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            },
            symbol: 'circle',
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            pointFormatter: function () {
              return `Temperature: <b>${numberFormatter(this.x)}</b> F<br/>kWh: <b>${numberFormatter(this.y)}</b>`;
            },
          },
        }
      },
      xAxis: {
        title: {
          text: 'Temperature(F)',
        },
        tickInterval: 10,
      },
      yAxis: {
        title: {
          text: 'kWh Usage',
        },
        min: 0,
      }
    };
  }

  static mapInputSeries(series: WeatherKwhScatterSeries[]) {
    return series.map(s => ({
      ...s,
      data: <Array<[number, number]>> s.points.map(p => [p.temperature, p.kwh]),
    }));
  }
}
