import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { BuildingsListComponent } from './buildings/buildings-list/buildings-list.component';
import { WasteEventPageComponent } from './dashboard/waste-events/waste-events-page/waste-event-page.component';
import { LayoutComponent } from './layout/layout.component';
import { NewVirtualMeterComponent } from './meters/create-meter/new-virtual-meter/new-virtual-meter.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PriorityComponent } from './meters/priority/priority.component';
import { CreateBuildingComponent } from './buildings/create-building/create-building.component';
import { CreateMeterComponent } from './meters/create-meter/create-meter.component';
import { ImportMetersComponent } from './meters/create-meter/import-meters/import-meters.component';
import { ImportBuildingComponent } from './buildings/create-building/import-building/import-building.component';
import { NewBuildingComponent } from './buildings/create-building/new-building/new-building.component';
import { AuthenticatedGuard } from './auth/authenticated.guard';
import { CriteriaComponent } from './criteria/criteria.component';
import { CriteriaCreateComponent } from './criteria/criteria-create/criteria-create.component';
import { CriteriaListComponent } from './criteria/criteria-list/criteria-list.component';
import { CriteriaDetailsComponent } from './criteria/criteria-details/criteria-details.component';
import { BuildingsComponent } from './buildings/buildings.component';
import { BuildingsEditComponent } from './buildings/buildings-edit/buildings-edit.component';
import { BuildingsDetailsComponent } from './buildings/buildings-details/buildings-details.component';
import { MetersComponent } from './meters/meters.component';
import { CriteriaEditComponent } from './criteria/criteria-edit/criteria-edit.component';
import { NewMeterComponent } from './meters/create-meter/new-meter/new-meter.component';
import { CustomersCreateComponent } from './customers/customers-create/customers-create.component';
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { BenchmarkingComponent } from './dashboard/benchmarking/benchmarking.component';
import { WasteMapComponent } from './dashboard/waste-map/waste-map.component';
import { WeatherComparisonSectionComponent } from './features/weather-comparison/weather-comparison-section/weather-comparison-section.component';
import { WeatherKwhSectionComponent } from './features/weather-kwh-section/weather-kwh-section/weather-kwh-section.component';
import { WeatherKwhHeatmapSectionComponent } from './features/weather-kwh-heatmap-section/weather-kwh-heatmap-section/weather-kwh-heatmap-section.component';
import { CriteriaWasteEventsComponent } from './criteria/criteria-waste-events/criteria-waste-events.component';
import { CustomersListDetailsComponent } from './customers/customers-list-details/customers-list-details.component';
import { CustomersUsersCreateComponent } from './customers/customers-users-create/customers-users-create.component';
import { CustomersUsersListComponent } from './customers/customers-users-list/customers-users-list.component';
import { GROUPS } from './auth/user-group-guard.provider';
import { CustomersUsersDetailsComponent } from './customers/customers-users-details/customers-users-details.component';
import { CriteriaAlertsComponent } from './criteria/criteria-alerts/criteria-alerts.component';
import { InsightComponent } from './insight/insight.component';
import { InsightTrendsComponent } from './insight/insight-trends/insight-trends.component';
import { InsightDistributionComponent } from './insight/insight-distribution/insight-distribution.component';
import { DistributionGuard } from './insight/insight-distribution/distribution.guard';
import { InsightOpportunitiesComponent } from './insight/insight-opportunities/insight-opportunities.component';
import { CriteriaWasteEventsDetailsComponent } from './criteria/criteria-waste-events-details/criteria-waste-events-details.component';
import {RegPgeComponent} from './reg-pge/reg-pge.component';
import { AccountSettingsPageModule } from './routed-pages/account-settings-page/account-settings-page.module';
import { CustomerReportsPgModule } from './routed-pages/customer-reports-page/customer-reports-pg.module';
import { MetersListsPageModule } from './routed-pages/meters-lists-page/meters-lists-page.module';
import { UtilityBillsPageModule } from './routed-pages/utility-bills-page/utility-bills-page.module';
import { WeatherModule } from './weather/weather.module';
import { WeatherKwhHeatmapSectionModule } from './features/weather-kwh-heatmap-section/weather-kwh-heatmap-section.module';


const authenticatedRoutes: Routes = [
  {
    path: '',
    component: PriorityComponent,
    canActivate: ['UserGroupGuard'],
    data: {
      availableForGroup: GROUPS.notLow,
      notAvailableRedirect: '/dashboard',
    },
  },

  {
    path: 'buildings',
    component: BuildingsComponent,
    children: [
      {
        path: '',
        component: BuildingsListComponent,
      },

      {
        path: 'create',
        component: CreateBuildingComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.noOne,
          notAvailableRedirect: '/buildings',
        },
        children: [
          {
            path: '',
            component: NewBuildingComponent,
          },

          {
            path: 'bulk',
            component: ImportBuildingComponent,
          },
        ],
      },

      {
        path: ':id',
        component: BuildingsDetailsComponent,
      },

      {
        path: 'edit/:id',
        component: BuildingsEditComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.noOne,
          notAvailableRedirect: '/buildings',
        },
      },
    ],
  },


  {
    path: 'meters',
    component: MetersComponent,
    children: [
      {
        path: 'electrical',
        loadChildren: './routed-pages/electrical-meter-pages/electrical-meter-pages.module#ElectricalMeterPagesModule',
      },

      {
        path: 'water',
        loadChildren: './routed-pages/water-meter-pages/water-meter-pages.module#WaterMeterPagesModule',
      },

      {
        path: 'list',
        loadChildren: './routed-pages/meters-lists-page/meters-lists-page.module#MetersListsPageModule',
      },

      {
        path: 'create',
        component: CreateMeterComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.noOne,
          notAvailableRedirect: '/meters',
        },
        children: [
          {
            path: '',
            component: NewMeterComponent,
          },
          {
            path: 'virtual',
            component: NewVirtualMeterComponent,
          }, {
            path: 'bulk',
            component: ImportMetersComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'utility',
    loadChildren: () => UtilityBillsPageModule,
  },

  {
    path: 'weather',
    loadChildren: () => WeatherModule,
  },

  {
    path: 'account-settings',
    loadChildren: () => AccountSettingsPageModule,
    canActivate: ['UserGroupGuard'],
    data: {
      availableForGroup: GROUPS.high,
      notAvailableRedirect: '',
    },
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'benchmarking',
        component: BenchmarkingComponent,
      },

      {
        path: 'waste-events',
        component: WasteEventPageComponent,
      },

      {
        path: 'weather-comparison',
        component: WeatherComparisonSectionComponent,
      },

      {
        path: 'weather-vs-kwh',
        component: WeatherKwhSectionComponent,
      },
      
      {
        path: 'weather-kwh-heatmap',
        component: WeatherKwhHeatmapSectionComponent,
      },

      {
        path: '',
        redirectTo: 'benchmarking',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: 'water-dashboard',
    loadChildren: './routed-pages/water-dashboard-page/water-dashboard-page.module#WaterDashboardPageModule',
  },

  {
    path: 'criteria',
    component: CriteriaComponent,
    children: [
      {
        path: 'create',
        component: CriteriaCreateComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.noOne,
          notAvailableRedirect: '/criteria',
        },
      },

      {
        path: 'waste-events',
        component: CriteriaWasteEventsComponent,
      },

      {
        path: 'waste-events/:id',
        component: CriteriaWasteEventsDetailsComponent,
        data: {
          availableForGroup: GROUPS.notLow,
          notAvailableRedirect: '/criteria',
        },
      },

      {
        path: 'alerts',
        component: CriteriaAlertsComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.notLow,
          notAvailableRedirect: '/criteria',
        },
      },

      {
        path: '',
        component: CriteriaListComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.notLow,
          notAvailableRedirect: '/criteria',
        },
      },

      {
        path: ':id',
        component: CriteriaDetailsComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.notLow,
          notAvailableRedirect: '/',
        },
      },

      {
        path: 'edit/:id',
        component: CriteriaEditComponent,
        canActivate: ['UserGroupGuard'],
        data: {
          availableForGroup: GROUPS.noOne,
          notAvailableRedirect: '/criteria',
        },
      },
    ],
  },

  {
    path: 'c-reports',
    loadChildren: () => CustomerReportsPgModule,
  },

  {
    path: 'insight',
    component: InsightComponent,
    children: [
      {
        path: 'trends',
        component: InsightTrendsComponent,
      },

      {
        path: 'opportunities',
        component: InsightOpportunitiesComponent,
      },

      {
        path: 'distribution/:type',
        canActivate: [DistributionGuard],
        component: InsightDistributionComponent,
      },

      {
        path: '',
        redirectTo: 'trends',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: 'customers',
    canActivate: ['UserGroupGuard'],
    data: {
      availableForGroup: GROUPS.noOne,
      notAvailableRedirect: '/dashboard',
    },
    children: [
      {
        path: 'new',
        component: CustomersCreateComponent,
      },

      {
        path: 'users/create',
        component: CustomersUsersCreateComponent,
      },

      {
        path: 'users',
        component: CustomersUsersListComponent,
      },

      {
        path: ':customer/users/:id',
        component: CustomersUsersDetailsComponent,
      },

      {
        path: ':id',
        component: CustomersListDetailsComponent,
      },

      {
        path: '',
        component: CustomersListComponent,
      },
    ],
  },

  {
    path: 'admin-reports',
    canActivate: ['UserGroupGuard'],
    data: {
      availableForGroup: GROUPS.noOne,
      notAvailableRedirect: '/dashboard',
    },
    loadChildren: './routed-pages/admin-reports-page/admin-reports-page.module#AdminReportsPageModule'
  },

  {path: '**', redirectTo: '/', pathMatch: 'full'},
];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'password-reset/:userId/:token',
    component: PasswordResetComponent
  },
  {
    path: 'pge-register',
    component: RegPgeComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: authenticatedRoutes,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
