import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { datetimeLabelFormatterFactory } from 'ex-library';
import * as _ from 'lodash';
import * as moment from 'moment';
import { toUtcDateValue } from '../../../shared/helpers/time.helper';
import { WasteEventAvgPoint } from '../waste-event.models';
import { DateFormatPipe } from '../../../shared/pipes/date-format.pipe';

export interface Config {
  events: WasteEventAvgPoint[];
  filters: {
    startTime: string;
    endTime: string;
  };
}

@Component({
  selector: 'exa-waste-events-average',
  template: `<exlib-chart [chartOptions]="chartOptions" (chartRendered)="chartRendered.emit($event)" *ngIf="chartOptions"></exlib-chart>`,
  styleUrls: ['./waste-events-average.component.scss'],
  providers: [DateFormatPipe],
})
export class WasteEventsAverageComponent implements OnChanges {

  chartOptions: any;

  @Input() config: Config;
  @Output() chartRendered = new EventEmitter();

  constructor(private datePipe: DateFormatPipe) { }

  ngOnChanges({config}: SimpleChanges): void {
    if (config.previousValue !== config.currentValue) {
      this.drawChart();
    }
  }

  drawChart() {
    const self = this;
    const dateFormatter = (date) => this.datePipe.transform(date, 'l');
    const {events, filters} = this.config;
    const { startTime, endTime } = filters;

    // following is the same old implementation for creating the chart
    const startedFilter = moment(startTime).valueOf();
    const endedFilter = moment(endTime).valueOf();
    const seriesData = events.map(event => {
      const startedAt = moment.utc(event.started_at).valueOf();
      const endedAt = moment.utc(event.theoretical_end_date).valueOf();
      const timeVal = moment.utc(event.started_at).valueOf();
      const waste = event.avg_wasteful;

      return {
        x: waste,
        low: startedAt > startedFilter ? startedAt : startedFilter,
        high: endedAt < endedFilter ? endedAt : endedFilter,
        event: Object.assign({}, event),
      };
    });


    this.chartOptions = {
      chart: {
        type: 'columnrange',
        inverted: true,
        zoomType: 'y',
        height: 400,
      },

      // borderWidth: 2,

      plotOptions: {
        series: {
          states: {
            hover: {
              borderColor: '#5e57bd',
              animation: {
                duration: 250,
              }
            }
          }
        }
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        reversed: false,
        title: {
          text: 'Avg. Daily Waste (Wasteful Days)',
        },
        maxPadding: 0.1,
        minPadding: 0.1,
      },

      yAxis: {
        type: 'datetime',
        labels: {
          formatter: datetimeLabelFormatterFactory(null),
          padding: 30,
          autoRotation: false,
        },
        title: null,
        min: toUtcDateValue(startTime),
        max: toUtcDateValue(endTime),
        tickPixelInterval: 200,
        startOnTick: false,
        endOnTick: false,
      },

      tooltip: {
        valueSuffix: 'kWh',
        shared: true,
        events,
        borderWidth: 3,
        followPointer: true,
        formatter: function (point): string {
          const originalEvents = this.points.map(p => p && p.point && {...p.point.event, color: p.point.color});
          return originalEvents.reduce((template, event) => {
            const style = `style="color: ${event.color}; text-shadow: 1px 1px 1px rgba(0, 0, 0, .2)"`;
            return event && `
            ${template ? template + '<br/>ـــــــــــــــــــــــــــــــــــــــــــــــــــ<br/>' : template}
            <b>${event.verbose_id}</b>
            <br>
            ${dateFormatter(event.started_at)} - ${dateFormatter(event.theoretical_end_date)}
            <br>
            <b ${style}>Avg Waste: </b> ${event.avg_waste} KWh/day
            <br/>
            <b ${style}>Avg. Remaining Waste:</b> ${event.avg_remaining_waste || '----'}
            <br/>
            <b ${style}>Avg. Corrected Waste:</b> ${event.avg_corrected_waste || '----'}
            <br/>
            <b ${style}>Total Waste: </b> ${event.total_waste} KWh
            <br/>
            <b ${style}>Duration: </b> ${event.duration} Days
            <br/>
            <b ${style}>Building: </b> ${event.meter.building.name}
            <br/>
            <b ${style}>Meter: </b> ${event.meter.name}`;
          }, '');
        },
      },

      series: _.sortBy(seriesData.map((data) => ({
        name: data.event.meter.building.name + '/' + data.event.meter.name,
        id: data.event.id,
        data: [data],
        color: data.event.color,
        pointWidth: 10,
        animation: false,
      })), 'name'),
    };
  }
}
