import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SideNavService {
  _opened = true;
  _collapsed = false;
  collapsed$ = new BehaviorSubject(false);
  _largeScreenOpened = true;
  viewport = 1024;
  smallWidth = 80;
  maxWidth = 280;
  isSmall = window.innerWidth <= this.viewport;
  hovered$ = new BehaviorSubject(false);

  constructor() { }


  collapse() {
    this._collapsed = true;
    this.collapsed$.next(true);
  }

  ellapse() {
    this._collapsed = false;
    this.collapsed$.next(false);
  }


  open() {
    if (!this.isSmall) {
      this._largeScreenOpened = true;
    }
    this._opened = true;
  }


  close() {
    if (!this.isSmall) {
      this._largeScreenOpened = false;
    }
    this._opened = false;
  }


  onResize() {
    const { innerWidth } = window;
    const isSmall = this.viewport >= innerWidth;

    if (isSmall) {
      this.isSmall = true;
      this._opened = false;
    } else {
      this.isSmall = false;
      this._opened = this._largeScreenOpened;
    }
  }


  setHover(value = false) {
    this.hovered$.next(value);
  }

  get hovered() { return this.hovered$.value; }

  get collapsed() {
    return this._collapsed;
  }

  get width() {
    const { isSmall, smallWidth, maxWidth, collapsed } = this;
    return (isSmall || !collapsed) ? maxWidth : smallWidth;
  }

  get widthWithHover() {
    const { isSmall, smallWidth, maxWidth, collapsed, hovered } = this;
    return (isSmall || !collapsed || hovered) ? maxWidth : smallWidth;
  }

  get opened() {
    return this._opened;
  }
}
