import { Component, Input } from '@angular/core';
import { WeaklyHoursProfileSchedule } from '../../../shared/models/operation-schedule/models';
import { WEEKDAYS } from '../constants';
import { DailySchedule } from '../daily-schedule.models';

@Component({
  selector: 'exa-weakly-hours-schedule-view',
  templateUrl: './weakly-hours-schedule-view.component.html',
  styleUrls: ['./weakly-hours-schedule-view.component.scss']
})
export class WeaklyHoursScheduleViewComponent {

  hours = new Array(24).fill(null).map((v, i) => i);
  days = WEEKDAYS;
  dailySchedule: DailySchedule[];
  nameColumn = 'name';
  displayedColumns = [].concat([this.nameColumn], this.hours.map(h => h + ''));

  @Input()
  set schedules(s: WeaklyHoursProfileSchedule[]) {
    this.generateDailySchedule(s);
  }

  private generateDailySchedule(schedules: WeaklyHoursProfileSchedule[]) {
    this.dailySchedule = this.days.map(day => {
      const dayProfileSchedules = schedules
        .filter(profileSchedule => profileSchedule.scheduleData[`${day.index}`])
        .map(profileSchedule => ({
          profile: profileSchedule.profile,
          daySchedule: profileSchedule.scheduleData[`${day.index}`],
        }));

      const daySchedule = this.hours.map(h => {
        const hourQuarters = [0, 15, 30, 45].map(min => ({min, profile: null, style:{}}));
        return hourQuarters.map(q => {
          const allocatedProfile = dayProfileSchedules.find(s => !!(s.daySchedule.find(schedule => schedule.min === q.min && schedule.hour === h)));
          return {
            ...q,
            profile: allocatedProfile && allocatedProfile.profile,
            style: !allocatedProfile ? {} : {background: allocatedProfile.profile.color},
          };
        });
      });
      return {
        day,
        daySchedule,
      };
    });
  }
}
