import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { environment } from 'projects/ex-admin/src/environments/environment';

interface ISignedUrlBody {
  filename: string;
   entity: string;
   id?: any;
   content_type?: string;
}

export class TasksApi {
  apiBase = environment.apiBase;

  constructor(private http: HttpClient) {}

  status(taskId: string) {
    const url = `${this.apiBase}/task-status`;
    return this.http.get(`${url}/${taskId}`, { });
  }

  getSignedUrl(body: ISignedUrlBody, params) {
    return this.http.post(`${this.apiBase}/storage-signed-url`, body, { params });
  }

  uploadToSignedUrl(method: string, url: string, file: File, customerId: string) {
    let params = new HttpParams();
    params = params.set('customer_id', customerId);
    const req = new HttpRequest(method, url, file, {
      reportProgress: true,
      params,
    });
    return this.http.request(req);
  }

  uploadFile(url, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }

  exportIdr(body) {
    return this.http.post(`${this.apiBase}/export_idr`, body);
  }

  downloadExportedIdr(url) {
    return this.http.get(url, { responseType: 'blob' });
  }
}
