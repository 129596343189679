import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { uniqBy } from 'lodash';
import { Subject } from 'rxjs';
import { filter, debounceTime, map, distinctUntilChanged, tap, defaultIfEmpty } from 'rxjs/operators';

type seriesState = 'hover' | 'inactive' | 'select' | 'normal';

interface Meter {
  id: string;
  name: string;
  building?: { id: string; name: string; };
}

export interface WasteEvent {
  id: string;
  verbose_id: string;
  meter: Meter;
  color: any;
  visible?: boolean;
}

export interface WasteEventStateChangeEvent {
  event: WasteEvent;
  state: seriesState;
}

export interface WasteEventVisibilityChangeEvent {
  event: WasteEvent;
  visible: boolean;
}

interface MeterWasteEvents {
  meter: Meter;
  wasteEvents: WasteEvent[];
}

@Component({
  selector: 'exa-waste-events-charts-legend',
  templateUrl: './waste-events-charts-legend.component.html',
  styleUrls: ['./waste-events-charts-legend.component.scss'],
  animations: [
    trigger('paginationAnimation', [
      transition('* <=> *', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WasteEventsChartsLegendComponent implements AfterViewChecked {

  metersWasteEvents: MeterWasteEvents[];
  containerWidth$ = new Subject<number>();
  pageSize$ = this.containerWidth$.pipe(
    filter(val => !!val),
    debounceTime(100),
    distinctUntilChanged(),
    map(widthInPx => {
      const widthInRem = widthInPx / 16;
      const gapBetweenMeters = 2;
      const meterSectionWidth = 22;
      const rowElements = Math.floor((widthInRem + gapBetweenMeters) / (meterSectionWidth + gapBetweenMeters));
      return 2 * rowElements;
    }),
    tap(v => console.log(v)),
  );


  @Input()
  set wasteEvents(events: WasteEvent[]) {
    if (!events) {
      return;
    }
    const uniqueMeters = uniqBy(events.map(e => e.meter), 'id');
    this.metersWasteEvents = uniqueMeters.map(meter => {
      const meterEvents = events.filter(e => e.meter.id === meter.id);
      return {
        meter,
        wasteEvents: meterEvents.map(e => ({...e, visible: true})),
      };
    });
  }
  @Output() wasteEventVisibilityChanged = new EventEmitter<WasteEventVisibilityChangeEvent>();
  @Output() wasteEventStateChanged = new EventEmitter<WasteEventStateChangeEvent>();

  @ViewChild('container', {read: ElementRef}) containerElem: ElementRef<HTMLElement>;

  ngAfterViewChecked(): void {
    if (!this.containerElem) {
      return;
    }
    this.containerWidth$.next(this.containerElem.nativeElement.clientWidth);
  }

  onHover(event: WasteEvent, mouseEnter: boolean) {
    this.wasteEventStateChanged.emit({event, state: mouseEnter ? 'hover' : 'normal'});
  }

  onClick(event: WasteEvent) {
    const visibility = !event.visible;
    event.visible = visibility;
    this.wasteEventVisibilityChanged.emit({event, visible: visibility});
  }

}
