import { Component } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-weather-kwh-heatmap-page',
  templateUrl: './weather-kwh-heatmap-page.component.html',
  styleUrls: ['./weather-kwh-heatmap-page.component.scss']
})
export class WeatherKwhHeatmapPageComponent {
  constructor(public customer: CustomersService) { }
}
