import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTableModule,
  MatToolbarModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatRippleModule,
  MatTabsModule,
  MatPaginatorModule,
  MatRadioModule,
  MatChipsModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
} from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatRippleModule,
    MatTabsModule,
    MatPaginatorModule,
    MatRadioModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
  ],
  exports: [
    MatButtonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatTableModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatRippleModule,
    MatTabsModule,
    MatPaginatorModule,
    MatRadioModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
  ],
  declarations: [],
})
export class UsedMatModule {
}
