import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options, ChartObject } from 'highcharts';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsMore from 'highcharts/highcharts-more';
import StreamgraphModule from 'highcharts/modules/streamgraph.src';
import SunburstModule from 'highcharts/modules/sunburst';
import HeatmapModule from 'highcharts/modules/heatmap';
import { chartDefaultOptions } from './chart-default-options';

Highcharts.setOptions(chartDefaultOptions);

HighchartsNoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);
StreamgraphModule(Highcharts);
SunburstModule(Highcharts);
HeatmapModule(Highcharts);

@Component({
  selector: 'exlib-chart',
  template: `
    <div #chartElem></div>
  `,
  styles: [],
})
export class ChartComponent implements OnChanges {

  chart: ChartObject;

  @Input() chartOptions: Options;
  @Output() chartRendered = new EventEmitter();
  @ViewChild('chartElem') chartElem: ElementRef;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.chartOptions.currentValue) {
      this.renderChart();
    }
  }

  renderChart() {
    setTimeout(() => {
      this.chart = Highcharts.chart(this.chartElem.nativeElement, this.chartOptions);
      this.chartRendered.emit(this.chart);
    }, 500);
  }
}
