import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'exa-month-picker',
  template: `
    <ng-container [formGroup]="parentFormGroup">
      <mat-form-field style="width: 100%">
        <input
          [required]="required"
          matInput
          [formControl]="monthFormControl"
          [matDatepicker]="billMonthDp"
          readonly
          [placeholder]="placeholder">
        <mat-datepicker-toggle matSuffix [for]="billMonthDp"></mat-datepicker-toggle>
        <mat-datepicker #billMonthDp
                        startView="multi-year"
                        [calendarHeaderComponent]="headerComponent"
                        (yearSelected)="chosenYearHandler($event)"
                        (monthSelected)="chosenMonthHandler($event, billMonthDp)"></mat-datepicker>
        <mat-error>
          <ng-content select="error"></ng-content>
        </mat-error>

      </mat-form-field>
    </ng-container>
  `,
  styles: [],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthPickerComponent {

  headerComponent = CalendarHeaderComponent;

  @Input() parentFormGroup: FormGroup;
  @Input() monthFormControl: FormControl;
  @Input() placeholder: string;
  @Input() required: boolean;

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.monthFormControl.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.monthFormControl.setValue(ctrlValue);
  }

  chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.monthFormControl.value;
    ctrlValue.month(normlizedMonth.month());
    this.monthFormControl.setValue(ctrlValue);
    datepicker.close();
  }
}
