import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import {formatNumber} from '@angular/common';
import _ from 'lodash';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {}


  transform(value: any, digitsInfo = '1.0-2'): any {
    return _.isNumber(value) ? formatNumber(value, this.locale, digitsInfo) : value;
  }

}
