import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import * as moment from 'moment';
import { UtilityBillsResponse } from '../../utility-bills-section/utility-bills.models';
import {
  UtilsBillDefinition,
  BillGroup,
  BillNaiveItem,
  generateDefPath, CalculatedItem,
} from '../../utility-bills-section/utils-bills.definitions';
import {actionsDef} from '../../../shared/permissions/permissions-actions';

export interface BillUpdateConfig {
  month: number;
  billDef: UtilsBillDefinition;
  bill?: UtilityBillsResponse;
}

interface TableGroup {
  id: string;
  hierarchyPath: string;
  name: string;
  level: number;
  billDefItem: BillGroup | BillNaiveItem;
  columnsData: {
    [monthNum: string]: {
      available: boolean;
      data: any;
    }
  };
  isGroup: boolean;
  isServiceDates: boolean;
}

interface Month {
  id: string;
  name: string;
  monthNum: number;
  bill?: UtilityBillsResponse;
}

@Component({
  selector: 'exa-utility-bill-year-view',
  templateUrl: './utility-bill-year-view.component.html',
  styleUrls: ['./utility-bill-year-view.component.scss'],
})
export class UtilityBillYearViewComponent implements OnInit, OnChanges {
  months: Month[] = moment.monthsShort().map((name, i) => ({
    id: moment.monthsShort(i).toLowerCase(),
    name,
    monthNum: i+1,
    bill: undefined,
  }));
  dataRowsDisplayedCols = ['item'].concat(this.months.map(m => m.id));
  tableGroups: TableGroup[];
  _def: UtilsBillDefinition;
  actionsDef = actionsDef;
  @Input()
  set billTypeDefinition(def: UtilsBillDefinition) {
    this._def = def;
    const mainTableGroups = Object.entries(def.items).map(([id, groupOrItem]) => UtilityBillYearViewComponent.convertToTableGroups(groupOrItem, id, 0));
    this.tableGroups = [<TableGroup>{
      id: 'serviceDates',
      name: 'Service Dates',
      isServiceDates: true,
    }].concat(...mainTableGroups);
  }
  @Input() bills: UtilityBillsResponse[];

  @Output() onBillUpdate = new EventEmitter<BillUpdateConfig>();

  // this to fix the issue of table headers https://github.com/angular/components/issues/8663#issuecomment-347157740
  customTrackBy = (month: Month) => month.id;

  constructor() {
  }

  ngOnInit() {
    this.onBillsChange();
  }

  ngOnChanges({bills}: SimpleChanges): void {
    if (!bills.firstChange && bills.previousValue !== bills.currentValue) {
      this.onBillsChange();
    }
  }

  isGroupRow(i, row: TableGroup) {
    return row.isGroup;
  }

  updateMonthBill(monthNum: number) {
    const bill = this.months[monthNum - 1].bill;
    this.onBillUpdate.emit({
      billDef: this._def,
      month: monthNum,
      bill: bill,
    });
  }

  private onBillsChange() {
    this.updateMonths(this.bills);
    this.generateTableRows();
  }

  private updateMonths(bills: UtilityBillsResponse[]) {
    return this.months = this.months.map(m => ({
      ...m,
      bill: bills.find(b => b.month === m.monthNum),
    }));
  }

  private generateTableRows() {
    this.tableGroups = this.tableGroups.map(row => {
      return row.isGroup ? row : {
        ...row,
        columnsData: this.extractMonthlyBillData(row),
      };
    });
  }

  private extractMonthlyBillData(tableGroup: TableGroup) {
    return this.months.reduce((monthlyBillData, month) => {
      monthlyBillData[month.id] = UtilityBillYearViewComponent.extractMonthBillData(month, tableGroup, this._def);
      return monthlyBillData;
    }, {});
  }

  static extractMonthBillData({bill}: Month, tableGroup: TableGroup, billDef: UtilsBillDefinition) {
    if (!bill) {
      return {
        available: false,
        data: null,
      };
    }

    if (tableGroup.isServiceDates) {
      const dateFormatter = (dateString) => moment(dateString).format('MM/DD');
      return {
        available: true,
        data: `${dateFormatter(bill.service_start_date)} - ${dateFormatter(bill.service_end_date)}`,
      };
    }
    // you can use tableGroup.hierarchyPath to get the definition for this item and you can then know
    // if it is a number or not and you can calculate its availability but we are going to escape this calculation
    // until we get different item types

    const defPath = generateDefPath(tableGroup.hierarchyPath);
    const def = <BillNaiveItem>get(billDef.items, defPath);
    if (def.type === 'userDefined') {
      const data = get(bill, `bill_data.${tableGroup.hierarchyPath}`);
      return {
        available: data || data === 0,
        data,
      };
    }

    const data = (def.itemOptions as CalculatedItem).calculateFn(bill.bill_data, billDef);
    return {
      available: data || data === 0,
      data,
    };
  }

  static convertToTableGroups(groupOrItem: BillGroup | BillNaiveItem, hierarchyPath: string, level: number) {
    const splittedHierarchyPath = hierarchyPath.split('.');
    const isGroup = !!(groupOrItem as BillGroup).items;

    const tableGroups: TableGroup[] = [<TableGroup>{
      id: splittedHierarchyPath[splittedHierarchyPath.length - 1],
      hierarchyPath,
      name: groupOrItem.name,
      level: level,
      billDefItem: groupOrItem,
      isGroup,
    }];

    if (!isGroup)
      return tableGroups;

    const childTableGroups = Object
      .entries((groupOrItem as BillGroup).items)
      .map(([id, elem]) => UtilityBillYearViewComponent.convertToTableGroups(elem, `${hierarchyPath}.${id}`, level + 1));
    return tableGroups.concat(...childTableGroups);
  }

}
