import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {DataService} from '../data/data.service';
import {Observable, throwError} from 'rxjs';
import {Customer} from '../shared/models/customer.model';
import {catchError, finalize, map} from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'exa-reg-pge',
  templateUrl: './reg-pge.component.html',
  styleUrls: ['./reg-pge.component.scss']
})
export class RegPgeComponent implements OnInit {
  loading = false;
  loadingFailed = false;
  customersLoading = false;

  customers$: Observable<Customer[]>;
  owners$: Observable<any[]>;
  customer;
  customersList: Customer[];
  utilityCompanyForm = this.fb.group({
    customer_id: [
      {value: '', disabled: this.customersLoading},
      [Validators.required]
    ],
    owner_id: [
      {value: '', disabled: true},
      [Validators.required]
    ]
  });

  constructor(private fb: FormBuilder,
              private data: DataService) {
  }

  ngOnInit() {
    this.loadLookups();
  }

  loadLookups() {
    this.loading = true;
    this.customers$ = this.data.customers.lookupList()
      .pipe(
        map((data: Customer[]) => {
          this.customersList = data;
          return data;
        }),
        catchError((err) => {
          this.loadingFailed = true;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      );
  }

  customerSelected(event) {
    this.customer = _.find(this.customersList, {'id': event.value});
    if (this.utilityCompanyForm.value['customer_id']) {
      this.loading = true;
      this.owners$ = this.data.customers.ownersLookup(this.utilityCompanyForm.value)
        .pipe(
          catchError((err) => {
            this.loadingFailed = true;
            return throwError(err);
          }),
          finalize(() => this.loading = false)
        );
      this.utilityCompanyForm.controls['owner_id'].enable();
    } else {
      this.utilityCompanyForm.controls['owner_id'].disable();
    }
  }

  registerOwner() {
    this.loading = true;
    this.data.customers.registerPGEforOwner(this.utilityCompanyForm.value)
      .pipe(
        catchError((err) => {
          this.loadingFailed = true;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      ).subscribe((res) => window.location.replace(`//${this.customer.portal_url}${res['redirect']}`));
  }

}
