import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

const errorsMap = {
  required: 'This field is required',
  min: 'Please Enter a valid value',
  max: 'Please Enter a valid value',
};

@Pipe({
  name: 'inputError'
})
export class InputErrorPipe implements PipeTransform {

  transform(errors: ValidationErrors, priorities?: string[]): any {
    if (!errors)
      return;
    const mappedErrors = Object.entries(errors).reduce((result, [errorId, errorVal]) => {
      return {
        [errorId]: errorsMap[errorId] || errorVal,
        ...result,
      };
    }, {});

    if (priorities && priorities.length) {
      const firstErrorId = priorities.find(errorId => Boolean(mappedErrors[errorId])) || Object.keys(mappedErrors)[0];
      return mappedErrors[firstErrorId];
    }

    return Object.values(mappedErrors)[0];
  }

}
