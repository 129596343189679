import { Component, OnInit } from '@angular/core';
import { Observable, throwError, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap, catchError, share, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { predictedKwhMethods } from '../../constants';
import { CustomersService } from '../../customers/customers.service';
import { DataService } from '../../data/data.service';
import { SideNavService } from '../../layout/side-nav.service';
import { ListBaseComponent } from '../../shared/components/list-base/list-base.component';

@Component({
  selector: 'exa-criteria-list',
  templateUrl: './criteria-list.component.html',
  styleUrls: ['./criteria-list.component.scss'],
})
export class CriteriaListComponent extends ListBaseComponent<any[]> implements OnInit {

  loading = false;
  criterias$: Observable<any>;
  searchSubj = new BehaviorSubject('');
  displayedColumns = ['name', 'kwh_threshold', 'days_count_threshold', 'modelingMethodName'];
  loadingError = false;

  constructor(
      private data: DataService,
      private customer: CustomersService,
      auth: AuthService,
      sidenav: SideNavService,
    ) {
    super(auth, sidenav);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadCriterias();
    if (this.isAdmin) {
      this.displayedColumns.push('actions');
    }
  }


  loadMethod() {
    this.loadCriterias();
  }

  loadCriterias() {
    this.paginationRes$ = <any> combineLatest(this.customer.selected$, this.searchSubj).pipe(
      tap(() => {
        this.loading = true;
        this.loadingError = false;
      }),
      map(([customer, search]) => ({
        page: this.page,
        pageSize: this.pageSize,
        search: search,
        customer: customer.id,
      })),
      switchMap((params) => this.data.criterias.queryPage(params)),
      tap(() => this.loading = false),
      share(),
      catchError((err) => {
        this.loadingError = true;
        this.loading = false;
        return throwError(err);
      }),
    );

    this.criterias$ = this.paginationRes$.pipe(
      map((res: any) => res.results),
      map((criteriaList: any[]) => criteriaList.map(c => {
        const modelingMethodId = c.modeling_method;
        const modelingMethodName = !modelingMethodId ? null : Object.values(predictedKwhMethods)
          .find(method => method.id === modelingMethodId)
          .name;

        return {
          ...c,
          modelingMethodName,
        };
      })),
      share(),
    );
  }

}
