import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {Observable, of, Subscription, throwError} from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import {Meter} from '../../shared/models/meter.model';
import {AllOrSearchConfig} from '../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {onlyMeterFilterBasicConfig} from '../../shared/components/all-or-search-filter/all-or-search-filter.constants';

@Component({
  selector: 'exa-insight-trends-filter',
  templateUrl: './insight-trends-filter.component.html',
  styles: []
})
export class InsightTrendsFilterComponent implements OnInit {
  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;

  @Input() searchCallback;
  loading = false;
  error = false;
  searchSub: Subscription;

  form = this.fb.group({
    from: [
      moment().subtract(12, 'month').toDate(),
      Validators.required
    ],
    to: [
      moment().toDate(),
      Validators.required
    ],
    meter: [ {
      value: '',
    }, Validators.required],
  });

  buildingChangeSub: Subscription;

  metersInputConfig: AllOrSearchConfig<Meter>;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.metersInputConfig = {
      ...onlyMeterFilterBasicConfig,
      searchItems: this.metersSearchFn,
    };
  }

  onSubmit() {
    this.loading = true;
    this.error = false;

    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }

    this.searchSub = this.searchCallback(this.form.value)
      .pipe(
        catchError((err) => {
          this.error = err;
          this.loading = false;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      )
      .subscribe();
  }
}
