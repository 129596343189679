import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, EMPTY, Subscription, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { VirtualMeterBasicInfo, MediaTypeMeterBaseService } from '../../../data/meters/media-type-meter-base.service';
import { VirtualMeter } from '../../../shared/models/meter.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';

@Component({
  selector: 'exa-virtual-meter-summary-section',
  templateUrl: './virtual-meter-summary-section.component.html',
  styleUrls: ['./virtual-meter-summary-section.component.scss']
})
export class VirtualMeterSummarySectionComponent implements OnInit {

  @Input() meter: VirtualMeter;
  @Output() onStartWasteCalc = new EventEmitter<{task_id: string}>();
  basicInfo$ = new BehaviorSubject<VirtualMeterBasicInfo>(null);
  sectionLoadingStatus = new SectionLoadingStates();
  calculateWasteLoadingStatus = new SectionLoadingStates();

  subscriptionsSink = new Subscription();

  constructor(private meterSvc: MediaTypeMeterBaseService) { }

  ngOnInit() {
    this.loadBasicInfo();
  }

  loadBasicInfo() {
    const sectionLoading = this.sectionLoadingStatus;
    sectionLoading.setLoadingState();
    this.subscriptionsSink.add(
      this.meterSvc.getVMBasicInfo(this.meter).pipe(
        tap(info => this.basicInfo$.next(info)),
        tap(() => sectionLoading.resetLoadingState()),
        catchError(err => {
          sectionLoading.setErrorState(err);
          return EMPTY;
        }),
      ).subscribe(),
    );
  }

  calculateWaste() {
    const calculateLoading = this.calculateWasteLoadingStatus;
    calculateLoading.setLoadingState();
    this.subscriptionsSink.add(
      this.meterSvc.calcVmWaste(this.meter).pipe(
        tap(() => calculateLoading.resetLoadingState()),
        tap(res => this.onStartWasteCalc.emit(res)),
        catchError(err => {
          calculateLoading.setErrorState(err);
          return EMPTY;
        }),
      ).subscribe(),
    );
  }
}
