import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PowerConsumptionResponse, PowerConsumptionPoint } from '../../shared/models/waste';
import { WeatherKwhHeatmapQueryRaw } from '../../shared/models/waste/weather-kwh-heatmap-query.models';
import { WeatherDdResponsePoint, WeatherDd } from '../../shared/models/waste/weighted-wdd.models';

@Injectable({
  providedIn: 'root',
})
export class WeatherKwhDataService {

  constructor(private http: HttpClient) {
  }

  getPowerConsumption(query: WeatherKwhHeatmapQueryRaw): Observable<Array<PowerConsumptionPoint>> {
    return (this.http.post(`${environment.apiBase}/electricalDashboard/powerConsumption/queryAll`, query) as Observable<Array<PowerConsumptionResponse>>).pipe(
      map(res => res.map(p => ({...p, datetime: moment(p.time).utc().toDate()}))),
    );
  }

  getWdd(query: WeatherKwhHeatmapQueryRaw, weighted = false): Observable<Array<WeatherDd>> {
    const params = weighted ? {'weighted': 'true'} : null;
    return (this.http.post(`${environment.apiBase}/electricalDashboard/weatherDemand/queryAll`, {...query, ...params}) as Observable<Array<WeatherDdResponsePoint>>).pipe(
      map(res => res.map(p => ({...p, date: moment(p.date).utc().toDate()}))),
    );
  }
}
