import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { DataService } from '../data.service';
import { MeterMediaType } from '../meters/models/media-types.models';

export interface GbcCustodian {
  id: string;
  name: string;
  status: 'connected' | string;
  isConnected: boolean;
}

export interface GbcMappedMeter {
  id: string;
  usage_point_id: string;
  meter: {
    id: string;
    name: string;
    building: {
      id: string;
      name: string;
    };
    meter_type: MeterMediaType;
  };
}

export interface MapMetersConfig {
  filepath: string;
  custodian: string;
  import_from_date: Date;
}

@Injectable({
  providedIn: 'root',
})
export class GreenButtonConnectService {

  apiBase = `${environment.apiBase}/gbc`;
  metersApiBase = `${environment.apiBase}/gbc-meters`;

  constructor(private http: HttpClient, private data: DataService) {
  }

  listCustodians() {
    return this.http.get<{ custodians: GbcCustodian[] }>(`${this.apiBase}/custodians`).pipe(
      map(res => ({
        ...res,
        custodians: res.custodians.map(c => ({
          ...c,
          isConnected: c.status === 'connected',
        })),
      })),
    )
  }

  connect(custodianId: string) {
    return this.http.get(`${this.apiBase}/${custodianId}/authorize`);
  }

  mappedMeters(custodianId: string): Observable<GbcMappedMeter[]> {
    return this.http.get(this.metersApiBase, {
      params: {
        custodian: custodianId,
      },
    }).pipe(
      // catchError(err => {
      //   return of([{
      //     id: 'map-meter-id',
      //     usage_point_id: '2323232323254',
      //     meter: {
      //       id: 'meter-id',
      //       name: 'Meter Name',
      //       meter_type: 'electrical',
      //       building: {
      //         id: 'building-id',
      //         name: 'Building Name',
      //       },
      //     },
      //   }] as GbcMappedMeter[]);
      // }),
    ) as Observable<GbcMappedMeter[]>;
  }

  unmapMeter(gbcMeterId: string) {
    return this.http.delete(`${this.metersApiBase}/${gbcMeterId}`);
  }

  mapMeters(mapMetersConfig: MapMetersConfig) {
    return this.http.post(`${environment.apiBase}/import-gbc-meters`, {
      ...mapMetersConfig,
      import_from_date: moment(mapMetersConfig.import_from_date).format(BE_DATETIME_NO_TIMEZONE_FORMAT),
    })
  }

  getTemplate(custodianId: string) {
    return this.http.get(`${environment.apiBase}/gbc-meters-template/${custodianId}`);
  }
}
