import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { WeatherBoundariesConfig } from '../../weather-boundaries/weather-boundaries.component';
import { WeatherGroupBoundaries } from '../weather-comparison.models';

const boundariesMinHeight = 80;

@Component({
  selector: 'exa-weather-comparison-boundaries',
  templateUrl: './weather-comparison-boundaries.component.html',
  styleUrls: ['./weather-comparison-boundaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComparisonBoundariesComponent {
  _groupsBoundariesConfig: WeatherBoundariesConfig[];

  @Input()
  set groupsBoundaries(boundaries: WeatherGroupBoundaries[]) {
    if (!boundaries) {
      return;
    }
    const maxValues = boundaries.map(b => b.boundaries.max);
    const minValues = boundaries.map(b => b.boundaries.min);
    const globalBoundaries = {
      max: Math.max(...maxValues),
      min: Math.min(...minValues),
    };

    const degreeLength = boundaries
      .map(b => b.boundaries)
      .reduce((length, groupBoundaries) => {
        const {max, min} = groupBoundaries;
        const tempDiff = max - min;
        if (tempDiff >= boundariesMinHeight) {
          return length;
        }
        const suitableDegreeLength = boundariesMinHeight / tempDiff;
        return suitableDegreeLength > length ? suitableDegreeLength : length;
      }, 1);

    this._groupsBoundariesConfig = boundaries.map(b => ({
      groupName: b.groupName,
      degreeLength,
      fontSizeInPx: 14,
      localBoundaries: b.boundaries,
      globalBoundaries,
    }));
  }
}
