import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {AllOrSearchConfig} from '../all-or-search-filter/all-or-search-filter.component';


@Component({
  selector: 'exa-search-select-multiple',
  templateUrl: './search-select-multiple.component.html',
  styleUrls: ['./search-select-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchSelectMultipleComponent,
    }
  ]
})
export class SearchSelectMultipleComponent<T> implements ControlValueAccessor {

  selectedItems: T[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  itemFC = new FormControl(null);

  filteredItems$ = this.itemFC.valueChanges.pipe(
    debounceTime(300),
    switchMap(searchText => this.config.searchItems(searchText)),
    map((results) => 
    results['results'].filter(
      r => !this.selectedItems.find(item => item[this.config.uniqueKey] === r[this.config.uniqueKey])
    ))
  );

  onChange = (items: T[]) => undefined;

  @Input() config: AllOrSearchConfig<T>;

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  constructor() {
  }

  writeValue(items: T[]): void {
    this.selectedItems = items;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  pushChanges() {
    this.onChange(this.selectedItems);
  }

  remove(item: T) {
    const removedItemIndex = this.selectedItems.findIndex(
      selectedItem => selectedItem[this.config.uniqueKey] === item[this.config.uniqueKey]
    );
    const currentSelectedItems = this.selectedItems;
    currentSelectedItems.splice(removedItemIndex, 1);
    this.selectedItems = [...currentSelectedItems];
    this.pushChanges();
  }

  selected(selectedEvent) {
    this.selectedItems = [
      ...this.selectedItems,
      selectedEvent.option.value,
    ];
    this.itemInput.nativeElement.value = '';
    this.itemFC.setValue(null);
    this.pushChanges();
  }

}
