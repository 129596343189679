import { ValidatorFn, Validators } from '@angular/forms';
import {get} from 'lodash';

/*
* to show Bill I need to know its type a cording to string ID
* Each Bill has ID and Name
* Each Bill has groups
* Each Bill Group has items which can be naive Item or can be Another Group, Also each group can have title and id
* Each item can be userDefined or calculated
* userDefined Item should have input type and input (number / select / text / date) .. but now we will have only numbers
* userDefined Item should have the input data like unit, validators maybe
* calculated Item may have how to calculate this property as a function or something
*
* */

export type ItemInputType = 'number' | 'text';

export interface BaseInputOptions {
  validators?: ValidatorFn[];
}

export interface ItemNumberInputOptions extends BaseInputOptions {
  unit?: string;
}

export type CalculatedItem = {
  calculateFn: (billData: { [id: string]: any;}, billDef: UtilsBillDefinition) => any;
  unit?: string;
};

export interface UserDefinedItem {
  inputType: ItemInputType;
  inputOptions: BaseInputOptions;
}

export type BillNaiveItemType = 'userDefined' | 'calculated';

export interface BillNaiveItem {
  name: string;
  type: BillNaiveItemType;
  itemOptions: UserDefinedItem | CalculatedItem;
}

export type BillGroupsOrItems = { [itemId: string]: BillGroup | BillNaiveItem };

export interface BillGroup {
  id: string;
  name: string;
  items: BillGroupsOrItems;
}

export type BillType = 'electric' | 'gas' | 'sewer' | 'water' | 'cooling';

export interface UtilsBillDefinition {
  id: BillType;
  name: string;
  items: BillGroupsOrItems;
}

export const generateDefPath = (ids: string[] | string) => {
  const idsArr = Array.isArray(ids) ? ids : ids.split('.');
  const groupsIds = idsArr.slice(0, idsArr.length - 1);
  const groupsPath = groupsIds.reduce((accPath, id) => accPath.concat(id, 'items'), []);
  return groupsPath.concat(idsArr[idsArr.length - 1]);
};

export const UTILITY_BILLS_DEFS: UtilsBillDefinition[] = [
  <UtilsBillDefinition>{
    id: 'electric',
    name: 'Electric',
    items: {
      chargeItems: <BillGroup>{
        id: 'chargeItems',
        name: 'Charge Items',
        items: {
          monthlyCustomerCharge: <BillNaiveItem>{
            name: 'Monthly Customer Charge',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
          depositInterest: <BillNaiveItem>{
            name: 'Deposit Interest',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
          eepEnergyEfficiencyProgram: <BillNaiveItem>{
            name: 'EEP Energy Efficiency Program',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
          energyEfficiencyAdjustment: <BillNaiveItem>{
            name: 'Energy Efficiency Adjustment',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
          environmentalCostRecoveryAd: <BillNaiveItem>{
            name: 'Environmental Cost Recovery Ad',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
          franchiseCost: <BillNaiveItem>{
            name: 'Franchise Cost',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                unit: '$',
                validators: [Validators.required],
              },
            },
          },
        },
      },
      demandItems: <BillGroup>{
        id: 'demandItems',
        name: 'Demand Items',
        items: {

          distributionFacilitiesDemand: <BillNaiveItem>{
            name: 'Distribution-Facilities (Demand)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kW',
              },
            },
          },

          distributionFacilitiesDemandCharges: <BillNaiveItem>{
            name: 'Distribution-Facilities (Demand Charges)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          kwDistributionOnPeakDemand: <BillNaiveItem>{
            name: 'kW-Distribution-On Peak (Demand)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kW',
              },
            },
          },

          kwGenerationUnspecified: <BillNaiveItem>{
            name: 'kW-Generation-Unspecified',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kW',
              },
            },
          },

        },
      },
      consumptionItems: <BillGroup>{
        id: 'consumptionItems',
        name: 'Consumption Items',
        items: {

          kwhDistributionUnspecified: <BillNaiveItem>{
            name: 'KWh-DISTRIBUTION - UNSPECIFIED',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kWh',
              },
            },
          },

          kwhDistributionUnspecified$: <BillNaiveItem>{
            name: 'KWh-DISTRIBUTION - UNSPECIFIED ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          kwhGenerationUnspecified: <BillNaiveItem>{
            name: 'KWh-GENERATION-UNSPECIFIED',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kWh',
              },
            },
          },

          kwhGenerationUnspecified$: <BillNaiveItem>{
            name: 'KWh-GENERATION-UNSPECIFIED ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      feesAndTaxes: <BillGroup>{
        id: 'feesAndTaxes',
        name: 'Fees And Taxes',
        items: {

          meterLease: <BillNaiveItem>{
            name: 'Meter Lease',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          nits: <BillNaiveItem>{
            name: 'NITS',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          renewablePortfolioStandards: <BillNaiveItem>{
            name: 'Renewable Portfolio Standards',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          standardMeterCharge: <BillNaiveItem>{
            name: 'Standard Meter Charge',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          stateTax: <BillNaiveItem>{
            name: 'State Tax',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          tecAdjustment: <BillNaiveItem>{
            name: 'TEC Adjustment',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          zeroEmissionStandard: <BillNaiveItem>{
            name: 'Zero Emission Standard',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      total: <BillGroup>{
        id: 'total',
        name: 'Total',
        items: {

          total$: <BillNaiveItem>{
            name: 'Total ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          totalKw: <BillNaiveItem>{
            name: 'Total kW',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kW',
              },
            },
          },

          totalKwh: <BillNaiveItem>{
            name: 'Total kWh',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'kWh',
              },
            },
          },

          total$Kwh: <BillNaiveItem>{
            name: 'Total ($/kWh)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$/kWh',
              },
            },
          },

        },
      },
      calculatedMetrics: <BillGroup>{
        name: 'Calculated Metrics',
        items: <BillGroupsOrItems>{
          calculatedCost: <BillNaiveItem>{
            name: 'Calculated Cost',
            type: 'calculated',
            itemOptions: <CalculatedItem>{
              calculateFn: (billData) => {
                const billFieldPath = ['total', 'total$'];
                const total = get(billData, billFieldPath);
                return total;
              },
              unit: '$',
            },
          },
          calculated$GenerationKwh: <BillNaiveItem>{
            name: 'Calculated ($/Generation kWh)',
            type: 'calculated',
            itemOptions: <CalculatedItem>{
              calculateFn: (billData, billDef) => {
                const calculatedCostPath = generateDefPath(['calculatedMetrics', 'calculatedCost']);
                const calculatedCostDef = <BillNaiveItem>get(billDef.items, calculatedCostPath);
                const calculatedCost = (calculatedCostDef.itemOptions as CalculatedItem).calculateFn(billData, billDef);
                if (!calculatedCost && calculatedCost !== 0) {
                  return null;
                }
                const kwhDistributionPath = ['consumptionItems', 'kwhDistributionUnspecified'];
                const kwhDistribution = get(billData, kwhDistributionPath);
                if (!kwhDistribution) {
                  return null;
                }
                return calculatedCost / kwhDistribution;
              },
              unit: '$/Generation kWh',
            },
          },
        },
      },
    },
  },
  <UtilsBillDefinition>{
    id: 'gas',
    name: 'Gas',
    items: <BillGroupsOrItems>{
      chargeAndFees: <BillGroup>{
        id: 'chargeAndFees',
        name: 'Charge, Fees',
        items: <BillGroupsOrItems>{

          monthlyCustomerCharge: <BillNaiveItem>{
            name: 'Monthly Customer Charge',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          infrastructureCharge$: <BillNaiveItem>{
            name: 'Infrastructure Charge ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          balancingAdjustment: <BillNaiveItem>{
            name: 'Balancing Adjustment',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          eepEnergyEfficiencyProgram: <BillNaiveItem>{
            name: 'EEP (Energy Efficiency Program)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          efficiencyProgram: <BillNaiveItem>{
            name: 'Efficiency Program',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          environmentalCostRecovery: <BillNaiveItem>{
            name: 'Environmental Cost Recovery',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          franchiseCostAdjustment: <BillNaiveItem>{
            name: 'Franchise Cost Adjustment',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          governmentAgencyCompAdjust: <BillNaiveItem>{
            name: 'Government Agency Comp Adjust',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          governmentAgencyCompensation: <BillNaiveItem>{
            name: 'Government Agency Compensation',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          transportationServiceCredit: <BillNaiveItem>{
            name: 'Transportation Service Credit',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      delivery: <BillGroup>{
        id: 'delivery',
        name: 'Delivery',
        items: <BillGroupsOrItems>{

          deliveryTherms: <BillNaiveItem>{
            name: 'Delivery (Therms)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'Therms',
              },
            },
          },

          delivery$: <BillNaiveItem>{
            name: 'Delivery ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      supply: <BillGroup>{
        id: 'supply',
        name: 'Supply',
        items: <BillGroupsOrItems>{

          supplyTherms: <BillNaiveItem>{
            name: 'Supply (Therms)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'Therms',
              },
            },
          },

          supply$: <BillNaiveItem>{
            name: 'Supply ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      taxes: <BillGroup>{
        id: 'taxes',
        name: 'Taxes',
        items: <BillGroupsOrItems>{

          stateUseTax: <BillNaiveItem>{
            name: 'State Use Tax',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          taxCostAdjustment: <BillNaiveItem>{
            name: 'Tax Cost Adjustment',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          utilityFundTax: <BillNaiveItem>{
            name: 'Utility Fund Tax',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

        },
      },
      total: <BillGroup>{
        id: 'total',
        name: 'Total',
        items: <BillGroupsOrItems>{

          total$: <BillNaiveItem>{
            name: 'Total ($)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$',
              },
            },
          },

          totalTherms: <BillNaiveItem>{
            name: 'Total (Therms)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: 'Therms',
              },
            },
          },

          total$Therm: <BillNaiveItem>{
            name: 'Total ($/Therm)',
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: '$/Therm',
              },
            },
          },

        },
      },
      calculatedMetrics: <BillGroup>{
        name: 'Calculated Metrics',
        items: <BillGroupsOrItems>{
          calculatedCost: <BillNaiveItem>{
            name: 'Calculated Cost',
            type: 'calculated',
            itemOptions: <CalculatedItem>{
              calculateFn: (billData) => {
                const billFieldPath = ['total', 'total$'];
                const total = get(billData, billFieldPath);
                return total;
              },
              unit: '$',
            },
          },
          calculated$Therm: <BillNaiveItem>{
            name: 'Calculated ($/Therm)',
            type: 'calculated',
            itemOptions: <CalculatedItem>{
              calculateFn: (billData, billDef) => {
                const calculatedCostPath = generateDefPath(['calculatedMetrics', 'calculatedCost']);
                const calculatedCostDef = <BillNaiveItem>get(billDef.items, calculatedCostPath);
                const calculatedCost = (calculatedCostDef.itemOptions as CalculatedItem).calculateFn(billData, billDef);
                if (!calculatedCost && calculatedCost !== 0) {
                  return null;
                }
                const supplyThermsPath = ['supply', 'supplyTherms'];
                const supplyTherms = get(billData, supplyThermsPath);
                if (!supplyTherms) {
                  return null;
                }
                return calculatedCost / supplyTherms;
              },
              unit: '$/Therm',
            },
          },
        },
      },
    },
  },
  <UtilsBillDefinition>{
    id: 'water',
    name: 'Water',
    items: <BillGroupsOrItems>{
      supply: <BillGroup>{
        id: 'supply',
        name: 'Supply',
        items: <BillGroupsOrItems>{

          supplyGallon: <BillNaiveItem>{
            name: "Supply (Gallon)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "Gallon",
              },
            },
          },

          supply$: <BillNaiveItem>{
            name: "Supply ($)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$",
              },
            },
          },

        },
      },
      total: <BillGroup>{
        id: 'total',
        name: 'Total',
        items: <BillGroupsOrItems>{

          total$: <BillNaiveItem>{
            name: "Total ($)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$",
              },
            },
          },

          total$Gal: <BillNaiveItem>{
            name: "Total ($/Gal)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$/Gal",
              },
            },
          },

        },
      },
    },
  },
  <UtilsBillDefinition>{
    id: 'sewer',
    name: 'Sewer',
    items: <BillGroupsOrItems>{
      supply: <BillGroup>{
        id: 'supply',
        name: 'Supply',
        items: <BillGroupsOrItems>{

          supplyGallon: <BillNaiveItem>{
            name: "Supply (Gallon)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "Gallon",
              },
            },
          },

          supply$: <BillNaiveItem>{
            name: "Supply ($)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$",
              },
            },
          },

        },
      },
      total: <BillGroup>{
        id: 'total',
        name: 'Total',
        items: <BillGroupsOrItems>{

          total$: <BillNaiveItem>{
            name: "Total ($)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$",
              },
            },
          },

          total$Gal: <BillNaiveItem>{
            name: "Total ($/Gal)",
            type: 'userDefined',
            itemOptions: {
              inputType: 'number',
              inputOptions: <ItemNumberInputOptions>{
                validators: [Validators.required],
                unit: "$/Gal",
              },
            },
          },

        },
      },
    },
  },
  <UtilsBillDefinition>{
    id: 'cooling',
    name: 'District Cooling',
    items: <BillGroupsOrItems>{

      cdlFee: <BillNaiveItem>{
        name: "CDL FEE",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$",
          },
        },
      },

      lrtpCharge: <BillNaiveItem>{
        name: "LRTP Charge",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$",
          },
        },
      },

      capacity: <BillNaiveItem>{
        name: "Capacity",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "Ton",
          },
        },
      },

      capacityRate: <BillNaiveItem>{
        name: "Capacity Rate",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$/Ton",
          },
        },
      },

      capacityCost: <BillNaiveItem>{
        name: "Capacity Cost",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$",
          },
        },
      },

      usage: <BillNaiveItem>{
        name: "Usage",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "Ton.Hour",
          },
        },
      },

      usageCost: <BillNaiveItem>{
        name: "Usage Cost",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$",
          },
        },
      },

      totalBill: <BillNaiveItem>{
        name: "Total Bill",
        type: 'userDefined',
        itemOptions: {
          inputType: 'number',
          inputOptions: <ItemNumberInputOptions>{
            validators: [Validators.required],
            unit: "$",
          },
        },
      },

      averageCostPerTonHour: <BillNaiveItem>{
        name: 'Average Cost Per Ton.Hour',
        type: 'calculated',
        itemOptions: <CalculatedItem>{
          calculateFn: billData => {
            const totalBill = get(billData, ['totalBill']);
            if (!totalBill && totalBill !== 0) {
              return null;
            }
            const usage = get(billData, ['usage']);
            if (!usage) {
              return null;
            }
            return totalBill / usage;
          },
          unit: '$/ton.hour',
        },
      },

    },
  },
];
