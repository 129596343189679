import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

export interface AllOrSearchConfig<T> {
  title: string;
  selectMultiple: boolean;
  specificationTitle: string;
  inputPlaceholder: string;

  uniqueKey: string;
  displayNameExtractorFn: (item: T) => string;
  valueOnChangeExtractorFn?: (item: T) => any;

  searchItems: (keyword: string) => Observable<T[]>;
}

@Component({
  selector: 'exa-all-or-search-filter',
  templateUrl: './all-or-search-filter.component.html',
  styleUrls: ['./all-or-search-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AllOrSearchFilterComponent,
    }
  ]
})
export class AllOrSearchFilterComponent<T> implements ControlValueAccessor, OnInit {

  selectedItems: T[] = null;

  get selectAll(): boolean {
    return this.selectedItems === null;
  }

  multipleItemsFC = new FormControl([]);
  multipleSelectChange$ = this.multipleItemsFC.valueChanges.pipe(
    filter(() => !this.selectAll),
    tap((items) => {
      this.selectedItems = items;
      this.pushChange();
    })
  );

  singleItemFC = new FormControl();
  singleSelectChange$ = this.singleItemFC.valueChanges.pipe(
    filter(() => !this.selectAll),
    tap(item => {
      this.selectedItems = [item];
      this.pushChange();
    })
  );

  onChange = (items: T[]) => undefined;

  subSink = new Subscription();

  @Input() config: AllOrSearchConfig<T>;

  ngOnInit() {
    this.subSink.add(this.multipleSelectChange$.subscribe());
    this.subSink.add(this.singleSelectChange$.subscribe());
  }

  writeValue(items: T[]): void {
    this.selectedItems = items;
    this.updateChildFormControls();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  pushChange() {
    this.onChange(this.selectedItems);
  }

  toggleSelectAll(selectAll: boolean) {
    if (this.selectAll === selectAll) {
      return;
    }
    this.selectedItems = selectAll ? null : [];
    this.updateChildFormControls();
    this.pushChange();
  }

  updateChildFormControls() {
    const items = this.selectAll ? [] : this.selectedItems;
    if (this.config.selectMultiple) {
      this.multipleItemsFC.setValue(items, {emitModelToViewChange: false});
    } else {
      this.singleItemFC.setValue(items[0], {emitModelToViewChange: false});
    }
  }

}
