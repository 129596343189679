import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-weather-comparison',
  templateUrl: './weather-comparison.component.html',
  styleUrls: ['./weather-comparison.component.scss']
})
export class WeatherComparisonComponent implements OnInit {

  constructor(public customer: CustomersService) { }

  ngOnInit() {
  }

}
