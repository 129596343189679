import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Building } from '../../../shared/models/building.model';
import { WeatherQueryGroup } from '../weather-comparison.models';
import { actionsDef } from '../../../shared/permissions/permissions-actions';
import {ToastrService} from 'ngx-toastr';
import {AllOrSearchConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {
  multiBuildingsFilterBasicConfig,
} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';

export interface WeatherComparisonFiltersActions {
  exportDataFn: (groupName: string, queryData: WeatherQueryGroup) => Observable<any>,
  exportErrorFn: (groupName: string, err: any) => void,
  loadSeriesDataFn: (queryDataArr: WeatherQueryGroup[]) => Observable<any>,
}

@Component({
  selector: 'exa-weather-comparison-filters',
  templateUrl: './weather-comparison-filters.component.html',
  styleUrls: ['./weather-comparison-filters.component.scss'],
})
export class WeatherComparisonFiltersComponent implements OnInit, OnChanges {

  constructor(private fb: FormBuilder, protected toastr: ToastrService) {
  }

  @Input() buildingsSearchFn: (name: string) => Observable<Building[]>;
  @Input() groupsCount = 2;
  @Input() actionsConfig: WeatherComparisonFiltersActions;

  actionsDefs = actionsDef;
  filtersForm: FormGroup;
  comparisonGroups: FormArray;
  hasOneValidGroup: boolean;
  today = moment().startOf('d').toDate();
  lastWeek = moment(this.today).subtract(1, 'w').toDate();
  extractLoading: boolean[] = [];
  loadingSeries: boolean;

  buildingsInputConfig: AllOrSearchConfig<Building>;

  static mapComparisonGroupVal(val): WeatherQueryGroup {
    return {
      ...val,
      buildings: val.buildings.filter(b => b.id !== 'all').map(b => b.id),
    };
  }

  ngOnInit() {
    this.init();

    this.buildingsInputConfig = {
      ...multiBuildingsFilterBasicConfig,
      searchItems: this.buildingsSearchFn,
    };

    this.comparisonGroups.valueChanges.subscribe(() => {
      this.hasOneValidGroup = !!this.comparisonGroups.controls.find(c => c.valid);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filtersForm && this.init();
  }

  init() {
    this.comparisonGroups = this.fb.array([]);
    for (let i = 0; i < this.groupsCount; i++) {
      this.comparisonGroups.push(this.createComparisonGroup(`Group ${i + 1}`));
      this.extractLoading.push(false);
    }
    this.filtersForm = this.fb.group({
      comparisonGroups: this.comparisonGroups,
    });
  }

  exportData(groupIndex: number, comparisonGroupVal: any) {
    this.setExtractLoading(groupIndex);
    const groupName = `group-${groupIndex}`;
    const query = WeatherComparisonFiltersComponent.mapComparisonGroupVal(comparisonGroupVal);
    this.actionsConfig.exportDataFn(groupName, query).pipe(
      tap(() => this.resetExtractLoading(groupIndex)),
      catchError((err => {
        this.actionsConfig.exportErrorFn(groupName, err);
        this.resetExtractLoading(groupIndex);
        return EMPTY;
      })),
    ).subscribe(
      (res) => {
        this.toastr.success('We are extracting requested data, when it\'s ready you will receive an email', null, {
          timeOut: 10000,
        });
      }
    );
  }

  private setExtractLoading(groupIndex: number) {
    this.extractLoading[groupIndex] = true;
  }

  private resetExtractLoading(groupIndex: number) {
    this.extractLoading[groupIndex] = false;
  }

  private createComparisonGroup(groupName: string) {
    return this.fb.group({
      groupName,
      buildings: [[], [Validators.required]],
      timeRange: this.fb.group({
        startDate: [this.lastWeek, [Validators.required]],
        endDate: [this.today, [Validators.required]],
      }),
    });
  }

  loadSeriesData() {
    this.loadingSeries = true;
    const validGroups = this.comparisonGroups.controls.filter(c => c.valid).map(c => c.value);
    const queryArr = validGroups.map(g => WeatherComparisonFiltersComponent.mapComparisonGroupVal(g));
    return this.actionsConfig.loadSeriesDataFn(queryArr).pipe(
      tap(() => this.loadingSeries = false),
      catchError(err => {
        this.loadingSeries = false;
        return err;
      }),
    ).subscribe();
  }
}
