import {environment} from 'projects/ex-admin/src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

interface IImportBuildingBody {
  filepath: string;
}

export class CustomersApi {
  private apiBase = environment.apiBase;
  private api = `${this.apiBase}/customers`;

  constructor(private http: HttpClient) {
  }


  create(data) {
    return this.http.post(this.api, data);
  }

  list(params?) {
    return this.http.post(`${this.api}/lookup/queryAll`, params);
  }

  changeAccount(params = {}) {
    return this.http.put(`${this.apiBase}/user/changeAccount`, params);
  }

  details(id: string) {
    return this.http.get(`${this.api}`)
      .pipe(map((res: any[]) => res.find((custom) => custom.id === id)));
  }


  delete(id: string) {
    return this.http.delete(`${this.api}/${id}`);
  }


  importBuildings(customerId: string, body: IImportBuildingBody) {
    const params = {customer_id: customerId};
    return this.http.post(`${this.apiBase}/import-buildings`, body, {params});
  }

  importBuildingsTemplate(customerId: string) {
    const params = {customer_id: customerId};
    const url = `${this.apiBase}/buildings-template`;
    return this.http.get(url, {params});
  }

  importBuildingsStatus(customerId: number, taskId: number) {
    const url = `${this.apiBase}/import-buildings/status/${taskId}`;
    return this.http.get(url);
  }

  metersMetrics(filters) {
    return this.http.post(`${this.apiBase}/electricalDashboard/benchmarking/queryOne`, filters);
  }

  getOwners(params) {
    return this.http.post(`${this.apiBase}/buildings/owner/queryAll`, params);
  }

  createUser(body) {
    return this.http.post(`${this.apiBase}/users`, body);
  }

  users(params?) {
    return this.http.get(`${this.apiBase}/users`, {params});
  }

  lookupList() {
    return this.http.get(`${this.apiBase}/all-customers`);
  }

  ownersLookup(params) {
    return this.http.get(`${this.apiBase}/owners-lookup`, {params});
  }

  registerPGEforOwner(data, params) {
    return this.http.post(`${this.apiBase}/register-pge`, data, {params});
  }
}
