import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from '../../../../../ex-library/src/lib/charts/charts.module';
import { WeatherDailyHoursScatterComponent } from './weather-daily-hours-scatter.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
  ],
  declarations: [WeatherDailyHoursScatterComponent],
  exports: [WeatherDailyHoursScatterComponent],
})
export class WeatherDailyHoursScatterModule { }
