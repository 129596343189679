import { HttpClient } from '@angular/common/http';
import { orderBy, isUndefined } from 'lodash';
import { environment } from 'projects/ex-admin/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Building, BuildingPlace, BuildingPlaceScheduleResponse } from '../../shared/models/building.model';
import { MeterMediaType } from '../meters/models/media-types.models';

export class BuildingsApi {
  apiBase = environment.apiBase;
  api = `${this.apiBase}/buildings`;

  constructor(private http: HttpClient) {}
  create(building) {
    return this.http.post(`${this.api}/setup`, building);
  }

  update(id, building) {
    return this.http.put(`${this.api}/setup`, {...building, id: id});
  }

  delete(id) {
    return this.http.delete(`${this.api}/setup/${id}`);
  }

  types() {
    return this.http.get(`${this.api}/type`);
  }

  list(params?) {
    //TODO Abdalla 22 Jun 2020 : sorting should be moved to BE and having same API with different response schema is not a good practice
    const fetchObservable = this.http.post(`${this.apiBase}/buildings/lookup/queryPage?pageNumber=${params && params.page ? params.page : 1}&pageSize=${params && params.pageSize ? params.pageSize : 10}`, params);
    if (!params || (isUndefined(params.page) && isUndefined(params.pageSize) && isUndefined(params.offset))) {
      return fetchObservable.pipe(
        map(buildings => <Building[]><any>orderBy(buildings, ['name'])),
      );
    }

    return fetchObservable;
  }

  lookup(params?): Observable<Building[]> {
    return this.http.post(`${this.apiBase}/buildings/lookup/queryPage?pageNumber=${params && params.page ? params.page : 1}&pageSize=${params && params.pageSize ? params.pageSize : 10}`, params) as Observable<Building[]>;
  }

  details(id: string) {
    return this.http.get(`${this.apiBase}/buildings/detail/${id}`) as Observable<Building>;
  }

  importMeters(body: {filepath: string}, customerId) {
    const params = { customer_id: customerId };
    return this.http.post(`${this.apiBase}/import-meters`, body, { params });
  }
  importMetersTemplate(customerId) {
    const params = { customer_id: customerId };
    return this.http.get(`${this.apiBase}/meters-template`, { params });
  }

  importMetersData(buildingId: number, body: {filepath: string, is_hourly?: boolean}, meterType: MeterMediaType, customerId?: string) {
    const params = { customer_id: customerId };
    return this.http.post(`${this.api}/${buildingId}/meters-data/import/${meterType}`, body, { params });
  }

  importMetersDataTemplate(buildingId: number) {
    return this.http.get(`${this.api}/${buildingId}/meters-data/template`, {responseType: 'blob' });
  }

  importLoadFactor(buildingId: number, body: {filepath: string}) {
    return this.http.post(`${this.api}/${buildingId}/load-factor/import`, body);
  }

  importLoadFactorTemplate() {
    return this.http.get(`${environment.apiBase}/load-factor-template`, {responseType: 'blob'});
  }

  getGooglePlaces(buildingId: string) {
    return (this.http.post(`${this.apiBase}/buildings/googlePlace/QueryAll`, {building_id: buildingId}) as Observable<BuildingPlace[]>);
  }

  setGooglePlace(buildingId: string, placeId: string) {
    return <Observable<BuildingPlaceScheduleResponse>> this.http.post(`${this.apiBase}/google/googlePlace/queryOne`, {
      g_place_id: placeId,
      building_Id: buildingId
    })
  }

  getStates(){
    return this.http.get(`${environment.apiBase}/lookups/states/lookup`);
  }

  getCountries(){
    return this.http.get(`${environment.apiBase}/lookups/countries/lookup`);
  }
}
