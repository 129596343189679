import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject, zip, EMPTY, Observable } from 'rxjs';
import { map, tap, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { UtilityBillsDataService, CalendarAnalysisFilters } from '../../../data/utility-bills-data.service';
import { Building } from '../../../shared/models/building.model';
import { Customer } from '../../../shared/models/customer.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { CalendarData } from '../utility-bills-monthly-analysis-table/utility-bills-monthly-analysis-table.component';
import {onlyBuildingFilterBasicConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';

interface BasicData {
  billsLib: UtilityBillsLibraryModule.Library;
}

interface ServiceAnalysis extends UtilityBillsLibraryModule.Service {
  calendarData: CalendarData;
}

@Component({
  selector: 'exa-utility-bills-monthly-analysis',
  templateUrl: './utility-bills-monthly-analysis.component.html',
  styleUrls: ['./utility-bills-monthly-analysis.component.scss']
})
export class UtilityBillsMonthlyAnalysisComponent implements OnInit, OnChanges {

  basicDataStates = new SectionLoadingStates();
  basicData$ = new BehaviorSubject<BasicData>(null);
  loadBasicData$ = new BehaviorSubject<boolean>(true);

  filtersForm: FormGroup = this.fb.group({
    building: [null],
    year: null,
  });

  yearOptions: string[] = UtilityBillsMonthlyAnalysisComponent.generateYearOptions();

  loadingAnalysisStates = new SectionLoadingStates();
  analysis$: Observable<ServiceAnalysis[]>;

  buildingInputConfig = {
    ...onlyBuildingFilterBasicConfig,
    searchItems: this.data.buildings.filterByName.bind(this.data),
  };

  @Input() customer: Customer;

  constructor(
    private fb: FormBuilder,
    private utilityBillsSvc: UtilityBillsDataService,
    private data: DataService,
  ) {
  }

  ngOnInit() {
    this.loadBasicData$.pipe(
      switchMap(() => this.loadBasicData()),
    ).subscribe();
  }

  ngOnChanges({customer}: SimpleChanges): void {
    if (!customer.firstChange && customer.previousValue !== customer.currentValue) {
      this.loadBasicData$.next(true);
      this.analysis$ = null;
      this.filtersForm.reset({
        buildings: [[]],
        year: null,
      });
    }
  }

  loadBasicData() {
    const section = this.basicDataStates;
    section.setLoadingState();
    return zip(
      this.utilityBillsSvc.getLibrary(),
    ).pipe(
      map(([billsLib]) => ({billsLib})),
      tap(basicData => this.basicData$.next(basicData)),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      }),
    );
  }

  static generateYearOptions() {
    const currentYear = (new Date()).getFullYear();
    const pastYear = 2000;
    return (new Array(currentYear - pastYear))
      .fill(null)
      .map((v, i) => (currentYear - i).toString());
  }

  getAnalysis() {
    const section = this.loadingAnalysisStates;
    section.setLoadingState();
    const filters = this.getAnalysisFilters();
    this.analysis$ = this.utilityBillsSvc.getCalendarAnalysis(filters).pipe(
      withLatestFrom(this.basicData$),
      map(([calendarRes, basicData]) => this.mapCalendarResponse(calendarRes, basicData.billsLib)),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      })
    );
  }

  private getAnalysisFilters() {
    const {building, year} = this.filtersForm.value;
    return <CalendarAnalysisFilters> {
      building,
      from: moment().year(year).startOf('y').toDate(),
      to: moment().year(year).endOf('y').toDate(),
    };
  }

  private mapCalendarResponse(calendarRes: UtilityMonthlyAnalysisModule.AnalysisBody, billsLib: UtilityBillsLibraryModule.Library) {
    return Object.values(billsLib.services)
      .map(sDef => {
        const serviceData = calendarRes[sDef.id];
        return {
          ...sDef,
          calendarData: serviceData,
        };
      })
      .filter(service => !!service.calendarData.fields && !!service.calendarData.fields.length)
      .map(service => ({
        ...service,
        calendarData: {
          ...service.calendarData,
          fields: service.calendarData.fields.map(fieldId => service.allowedFields[fieldId]),
        },
      }));
  }
}
