import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material';
import { ChartComponent } from 'ex-library';
import { Options, AxisOptions, ColorAxisOptions, ChartEvents } from 'highcharts';
import * as moment from 'moment';
import { PowerConsumptionPoint } from '../../../shared/models/waste';
import { WeatherDd } from '../../../shared/models/waste/weighted-wdd.models';

export interface HeatmapChartData {
  powerConsumption: PowerConsumptionPoint[];
  weatherDd: WeatherDd[];
  weightedWeatherDd: WeatherDd[];
}

type chartDatetimeVal = number;
type hourIndex = number;
type kwhVal = number;
type heatmapSeriesPoint = [hourIndex, chartDatetimeVal, kwhVal];

const dateFormatter = (date) => moment.utc(date).format('l');

const datetimeLabelFormatter = function () {
  return `${dateFormatter(this.value)}`;
};

const valueFormatter = (value: number) => Math.round(value * 100) / 100;

type WddId = 'regular' | 'weighted';

class WddOption {
  constructor(public id: WddId, public name: string, public dataProperty: string, private heatmapChartComponent: WeatherKwhHeatmapChartComponent) {
  }

  get data() {
    return this.heatmapChartComponent.heatmapData[this.dataProperty];
  }
}

const chartMargin = 100;

@Component({
  selector: 'exa-weather-kwh-heatmap-chart',
  templateUrl: './weather-kwh-heatmap-chart.component.html',
  styleUrls: ['./weather-kwh-heatmap-chart.component.scss'],
})
export class WeatherKwhHeatmapChartComponent implements OnInit, OnChanges {

  heatmapChartOptions: any;
  barChartOptions: Options;
  startDate: number;
  endDate: number;
  @Input() heatmapData: HeatmapChartData;
  @ViewChild('wddChart') wddChart: ChartComponent;
  wddOptions: WddOption[] = [
    new WddOption('regular', 'Regular', 'weatherDd', this),
    new WddOption('weighted', 'Weighted', 'weightedWeatherDd', this),
  ];
  selectedWddOption = this.wddOptions[0];


  constructor() {
  }

  ngOnInit() {
    this.setDatesBoundaries();
    this.generateHeatmapChartOptions();
    this.generateBarChartOptions();
  }

  ngOnChanges({heatmapData}: SimpleChanges): void {
    if (!heatmapData.firstChange && heatmapData.previousValue !== heatmapData.currentValue) {
      this.setDatesBoundaries();
      this.generateHeatmapChartOptions();
      this.generateBarChartOptions();
    }
  }

  onWddOptionChanged({value: selectedOption}: MatButtonToggleChange) {
    this.selectedWddOption = selectedOption;
    const optionData = (selectedOption as WddOption).data;
    const mappedSerieses = WeatherKwhHeatmapChartComponent.generateWeatherDdSeries(optionData);
    this.wddChart.chart.series.map(s => {
      const mappedSeries = mappedSerieses.find(mappedS => mappedS.id === s.options.id);
      s.setData(mappedSeries.data);
    });
    const maxWddValue = WeatherKwhHeatmapChartComponent.getMaxWddValue(optionData);
    this.wddChart.chart.yAxis[0].setExtremes(-1 * maxWddValue, maxWddValue);
  }

  private setDatesBoundaries() {
    const powerConsumptionsDates = this.heatmapData.powerConsumption.map(p => new Date(moment(p.datetime).utc().format('YYYY-MM-DD')).valueOf());
    const weatherDdData = this.selectedWddOption.data;
    const wddDates = weatherDdData.map(p => new Date(p.date).valueOf());
    const allDates = [].concat(powerConsumptionsDates, wddDates);
    if (!allDates.length)
      return;
    this.startDate = Math.min(...allDates);
    this.endDate = Math.max(...allDates);
  }

  private generateHeatmapChartOptions() {
    const mappedSeries: heatmapSeriesPoint[] = WeatherKwhHeatmapChartComponent.generatePowerConsumptionSeries(this.heatmapData.powerConsumption);
    const powerConsumptionValues = this.heatmapData.powerConsumption.map(p => p.kwh);
    this.heatmapChartOptions = {
      chart: {
        type: 'heatmap',
        inverted: true,
        marginTop: chartMargin,
        marginBottom: chartMargin,
        height: this.calculateSuitableChartHeight(),
        events: <ChartEvents>{
          render: function render(event: Event): void {
            const chart = this,
              colorAxis = chart.colorAxis[0].axisParent.element,
              colorAxisWidth = (colorAxis as SVGElement).getBoundingClientRect().width,
              mainSVG = chart.container.children[0],
              xPos = chart.chartWidth / 2 - colorAxisWidth / 2,
              yPos = 25;

            mainSVG.appendChild(colorAxis);

            colorAxis.setAttribute(
              'transform', 'translate(' + xPos + ', ' + yPos + ')');
          },
        },
      },

      boost: {
        useGPUTranslations: true,
      },

      tooltip: {
        formatter(): string {
          const date = `<b>${dateFormatter(this.point.x)}</b> at <b>${this.point.y}:00</b>`;
          const value = `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}<b>: ${valueFormatter(this.point.value)}</b>  kWh<br/>`;
          return [date, value].join('<br/>');
        },
      },

      xAxis: {
        type: 'datetime',
        min: this.startDate,
        max: this.endDate,
        labels: {
          formatter: datetimeLabelFormatter,
        },
        tickLength: 16,
      },

      yAxis: <AxisOptions>{
        labels: {
          format: '{value}:00',
        },
        title: {
          text: '',
        },
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        tickPositions: [0, 6, 12, 18, 24],
        tickWidth: 1,
        min: 0,
        max: 23,
        opposite: true,
      },


      colorAxis: <ColorAxisOptions>{
        stops: [
          [0, '#55ab00'],
          [0.67, '#f1ff57'],
          [1, '#ab0a00'],
        ],
        min: Math.min(...powerConsumptionValues),
        max: Math.max(...powerConsumptionValues),
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: '{value} kWh',
        },
      },

      series: [{
        name: 'Power Consumption',
        boostThreshold: 100,
        borderWidth: 0,
        nullColor: '#EFEFEF',
        colsize: 24 * 36e5, // one day
        turboThreshold: Number.MAX_VALUE, // #3404, remove after 4.0.5 release
        data: mappedSeries,
      }],
    };
  }

  private generateBarChartOptions() {
    const weatherDdData = this.selectedWddOption.data;
    const series = WeatherKwhHeatmapChartComponent.generateWeatherDdSeries(weatherDdData);
    const maxWddValue = WeatherKwhHeatmapChartComponent.getMaxWddValue(weatherDdData);
    this.barChartOptions = <Options>{
      chart: {
        type: 'bar',
        inverted: true,
        marginTop: chartMargin,
        marginBottom: chartMargin,
        height: this.calculateSuitableChartHeight(),
      },

      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },

      legend: {
        verticalAlign: 'top',
      },

      tooltip: {
        formatter(): string {
          const date = `<b>${dateFormatter(this.point.x)}</b>`;
          const value = `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}<b>: ${Math.abs(valueFormatter(this.point.y))}</b>  F<br/>`;
          return [date, value].join('<br/>');
        },
      },

      xAxis: {
        type: 'datetime',
        min: this.startDate,
        max: this.endDate,
        labels: {
          formatter: datetimeLabelFormatter,
        },
        tickLength: 16,
      },

      yAxis: {
        min: -1 * maxWddValue,
        max: maxWddValue,
        title: {
          text: '',
        },
        labels: {
          formatter: function () {
            return `${Math.abs(this.value)} F`
          },
        },
        opposite: true,
      },
      series,
    };
  }

  static generatePowerConsumptionSeries(powerConsumptionData: PowerConsumptionPoint[]): heatmapSeriesPoint[] {
    return powerConsumptionData.map(p => {
      const hourIndex = moment.utc(p.datetime).hours();
      const date = moment.utc(p.datetime).startOf('d').toDate();
      return <heatmapSeriesPoint>[date.valueOf(), hourIndex, p.kwh];
    });
  }

  static generateWeatherDdSeries(weatherDdData: WeatherDd[]) {
    const series = [
      {
        id: 'hdd',
        name: 'HDD',
        sign: -1,
        color: 'red',
      },
      {
        id: 'cdd',
        name: 'CDD',
        sign: 1,
        color: 'blue',
      },
    ];

    return series.map(s => {
      const points = weatherDdData.filter(p => p[s.id]);
      return {
        ...s,
        data: points.map(p => [new Date(p.date).valueOf(), p[s.id] * s.sign]),
      };
    });

  }

  private calculateSuitableChartHeight() {
    const daysCount = moment(this.endDate).diff(this.startDate, 'd');
    const suitableDaysHeight = daysCount * 5;
    const plotHeight = suitableDaysHeight < 200 ? 200 : suitableDaysHeight;
    return plotHeight + 220;
  }

  static getMaxWddValue(weatherDdData: WeatherDd[]) {
    // map to get the positive value of cdd or hdd as one of them should be zero
    const wddValues = weatherDdData.map(p => Math.max(p.hdd, p.cdd));
    return Math.max(...wddValues) + 5;
  }
}
