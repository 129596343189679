import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'exa-search-box',
  template: `
    <mat-form-field>
      <input type="text"
             placeholder="Search..."
             aria-label="Search"
             matInput
             #searchBox
             (input)="searchSubject$.next(searchBox.value)">
    </mat-form-field>
  `,
  styles: [],
})
export class SearchBoxComponent implements OnInit, OnDestroy {

  searchSubject$ = new BehaviorSubject('');
  searchSubscription: Subscription;
  @Output() searchTerm: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject$.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),
    ).subscribe(
      (searchText) => this.searchTerm.emit(searchText),
    );
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }
}
