import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { GoogleMapLoaderService } from './google-map-loader.service';
import { Observable, BehaviorSubject, merge, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { styles } from './google-map-style';


/**
 * since green to red differ according to the waste value
 * if the minimum value greater or equal zero the green is 0 and red is maximum
 * if the minimum value less than zero then the green is minimum and 0 is neutral.
 */
function getMapWeightValue(value: number, min: number, max: number): number {
  return min >= 0
    ? value / max
    : min < 0 && value > 0
      ? 0.5 + (value / (2 * max))
      : 0.5 - (value / (2 * min));
}


function getMinAndMax(list: any[], key: string): number[] {
  let min = 0;
  let max = 0;

  list.forEach((item) => {
    const value = item[key];
    min = min < value ? min : value;
    max = max > value ? max : value;
  });

  return [min, max];
}

interface IDataSrc {
  google: any;
  data: any;
}


@Component({
  selector: 'exa-google-map',
  template: `
    <div class='google-map' style='position: relative'>
      <div class='google-map__content' #mapsEl style='height: 480px;'>
      </div>
      <div class='google-map__spinner' *ngIf='loading$ | async'>
        <mat-spinner diameter='40'></mat-spinner>
      </div>

      <ng-container *ngIf='tooltip$ | async as tooltip'>
        <exa-google-map-tooltip
            [targetDensity]='targetDensity'
            [active]='tooltip?.open'
            [data]='tooltip?.data'
            [posX]='tooltip?.x'
            [posY]='tooltip?.y'
            [parentBounding]='tooltip?.parentBounding'
            (close)='onCloseTooltip()'>
        </exa-google-map-tooltip>
      </ng-container>
    </div>
  `,
  styles: []
})
export class GoogleMapComponent implements OnInit, OnChanges {
  @ViewChild('mapsEl') $mapsEl: ElementRef;
  @Input() wasteData: any[];
  @Input() targetDensity: string;
  loading$: Observable<boolean>;
  tooltip$ = new BehaviorSubject({
    open: false,
    x: 0,
    y: 0,
    data: null,
    parentBounding: this.$mapsEl && this.$mapsEl.nativeElement.getBoundingClientRect(),
  });

  protected dataSrc$: BehaviorSubject<IDataSrc> = new BehaviorSubject({ google: null, data: null });
  protected google$: Observable<IDataSrc>;
  private heatSrcSub: Subscription;
  private map: any;
  private heatMap: any;

  constructor(private gMapLoader: GoogleMapLoaderService) { }

  ngOnInit() {
    this.loading$ = this.gMapLoader.loading$;

    this.google$ = this.gMapLoader.load()
      .pipe(map((google) => (Object.assign(this.dataSrc$.value, { google }))));

    this.dataSrc$.next(Object.assign(this.dataSrc$.value, { data: this.wasteData }));

    this.heatSrcSub = merge(
      this.google$,
      this.dataSrc$,
    ).pipe(
      filter(({ data, google }) => data && google),
    ).subscribe(({ data, google }) => this.render(google, data));
  }


  ngOnChanges({ wasteData }: any) {
    if (wasteData.currentValue) {
      const currentData = this.dataSrc$.value;
      this.onCloseTooltip();
      this.dataSrc$.next(Object.assign(currentData, { data: this.wasteData }));
    }
  }


  render(google, data) {
    const $map = this.$mapsEl.nativeElement;
    const center = new google.maps.LatLng(40.443028, -98.220441);
    const [min, max] = getMinAndMax(data, 'value');
    const minZoom = 3;

    this.map = new google.maps.Map($map, { center, zoom: 3, styles, minZoom });

    const heatMapData = data
      .map(({ location, value }) => ({ location: location || {}, value }))
      .map(({ location: { lat, lng }, value }) => ({
        location: new google.maps.LatLng(lat, lng),
        weight: getMapWeightValue(value, min, max),
      }));

    this.heatMap = new google.maps.visualization.HeatmapLayer({
      data: heatMapData,
    });

    this.heatMap.setMap(this.map);
    this.heatMap.set('radius', 40);

    data.forEach((dataItem) => {
      const weight = getMapWeightValue(dataItem.value, min, max);
      const self = this;
      const marker = new google.maps.Marker({
        position: dataItem.location,
        map: this.map,
        data: { ...dataItem, value: dataItem.value, weight },
        icon: '/assets/images/marker.png',
      });

      marker.addListener('click', function(event) {
        /**
         * kind of hacky way to determine the mouse event
         * @todo it should be a better way to find the mouse event
         */
        const mouseEvent = Object.values(event)
          .find((value) => value instanceof MouseEvent);
        self.onMarkerClick(this, mouseEvent);
      });
    });
  }

  onMarkerClick(marker, event) {
    event = event || {};
    this.tooltip$.next({
      open: false,
      x: event.clientX || event.screenX || event.pageX,
      y: event.clientY || event.screenY || event.pageY,
      parentBounding: this.$mapsEl && this.$mapsEl.nativeElement.getBoundingClientRect(),
      data: marker.data,
    });

    setTimeout(() => {
      this.tooltip$.next(Object.assign({}, this.tooltip$.value, { open: true }));
    }, 200);
  }

  onCloseTooltip() {
    const tooltip = this.tooltip$.value;
    this.tooltip$.next(Object.assign({}, tooltip, { open: false }));
  }
}
