import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  BillType,
  UTILITY_BILLS_DEFS,
  BillGroup,
  BillNaiveItem,
  UtilsBillDefinition, UserDefinedItem, BillGroupsOrItems,
} from '../../utility-bills-section/utils-bills.definitions';

@Component({
  selector: 'exa-utility-bills-upsert-form',
  templateUrl: './utility-bills-upsert-form.component.html',
  styleUrls: ['./utility-bills-upsert-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtilityBillsUpsertFormComponent implements OnInit, OnDestroy {

  billForm: FormGroup;
  billDef: UtilsBillDefinition;
  formSections: BillGroupsOrItems;
  hasTruthyValue = false;
  subscription = new Subscription();

  @Input() billType: BillType;
  @Input() billCurrentData: {[key: string]: any};

  constructor(private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.generateForm();
    this.generateFormSections();
    this.hasTruthyValue = this.checkTruthyValue();
    const formValueChangeSubscription = this.billForm.valueChanges.subscribe(
      () => this.hasTruthyValue = this.checkTruthyValue(),
    );
    this.subscription.add(formValueChangeSubscription);
  }


  private generateForm() {
    this.billDef = UTILITY_BILLS_DEFS.find(def => def.id === this.billType);
    const formControlsConfig = this.reduceBillGroup(this.billDef as BillGroup, this.billCurrentData);
    this.billForm = this.fb.group(formControlsConfig);
  }

  private reduceBillGroup(group: BillGroup, value: any) {
    return Object.entries(group.items).reduce((formObj, [id, itemOrGroup]) => {
      if (!(itemOrGroup as BillGroup).items) {
        formObj[id] = this.generateItemElem(itemOrGroup as BillNaiveItem, value && value[id]);
      } else {
        const controlsConfig = this.reduceBillGroup(itemOrGroup as BillGroup, value && value[id]);
        formObj[id] = this.fb.group(controlsConfig);
      }
      return formObj;
    }, {});
  }

  private generateItemElem(naiveItem: BillNaiveItem, value = '') {
    if (naiveItem.type !== 'userDefined')
      return;
    return this.fb.control(value, (naiveItem.itemOptions as UserDefinedItem).inputOptions.validators);
  }

  private checkTruthyValue() {
    const checkGroupTruthyValue = (fg: FormGroup) => {
      const controls = Object.values(fg.controls);
      let i = 0;
      let isValid = false;
      while (!isValid && i < controls.length) {
        const control = controls[i];
        isValid = (control as FormGroup).controls ? checkGroupTruthyValue(control as FormGroup) : control.valid;
        i++;
      }
      return isValid;
    };

    return checkGroupTruthyValue(this.billForm);
  }

  private generateFormSections() {
    const mapGroup = (group: BillGroup) => {
      return Object.entries(group.items).reduce((mappedChildrenObj, [childId, childDef]) => {
        if ((childDef as BillGroup).items) {
          const mappedGroup = mapGroup(childDef as BillGroup);
          return !Object.entries(mappedGroup).length ? mappedChildrenObj : {
            ...mappedChildrenObj,
            [childId]: childDef,
          };
        }
        else {
          return (childDef as BillNaiveItem).type !== 'userDefined' ? mappedChildrenObj : {
            ...mappedChildrenObj,
            [childId]: childDef,
          };
        }
      }, {});
    };
    this.formSections = mapGroup(this.billDef);
  }
}
