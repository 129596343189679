import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { map } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { CustomersService } from '../../customers/customers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'exa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  loginLoading: boolean;
  loginError: boolean;
  errorMessage: string;
  showForgotPasswordSection: boolean;
  forgotPasswordLoading: boolean;
  forgotPasswordError: boolean;

  loginForm = this.fb.group({
    'email': ['', [Validators.required, Validators.email]],
    'password': ['', [Validators.required, Validators.minLength(8)]],
  });

  forgotPasswordForm = this.fb.group({
    'email': ['', [Validators.required, Validators.email]]
  });

  constructor(
      private fb: FormBuilder,
      private router: Router,
      private auth: AuthService,
      private data: DataService,
      private customerService: CustomersService,
      private toastr: ToastrService
    ) {
  }

  getLoginFormErrorMessage(controllerName: string) {
    const { errors } = this.loginForm.controls[controllerName];
    return errors.required
      ? `${controllerName} is required`
      : `Please enter a valid ${controllerName}`;
  }

  getForgetPasswordErrorMessage(controllerName: string) {
    const { errors } = this.forgotPasswordForm.controls[controllerName];
    return errors.required
      ? `${controllerName} is required`
      : `Please enter a valid ${controllerName}`;
  }

  forgotPassword(){
    this.forgotPasswordLoading = true;
    this.forgotPasswordError = false;
    this.errorMessage = null;
    this.auth.forgotPassword(this.forgotPasswordForm.value['email']).subscribe({
      next: (res) => {
        this.forgotPasswordError = true;
        this.forgotPasswordLoading = false;
        this.toastr.success("We sent you password reset link to your email");
        this.showForgotPasswordSection = false;
      },
      error: (errorRes) => {
        this.forgotPasswordError = true;
        this.forgotPasswordLoading = false;
        this.errorMessage =  errorRes.error && errorRes.error.Message ? errorRes.error.Message : errorRes.error;
      }
    });
  }

  login() {
    this.loginLoading = true;
    this.loginError = false;
    this.errorMessage = null;
    return this.auth.login(this.loginForm.value).subscribe({
      next: (res) => {
        if(!res.user.is_superuser){
          this.data.buildings.lookup({pageSize: 1, pageNumber: 1}).subscribe(((result) =>{
            if(!localStorage["lastBuildingId"] && result.count > 0){
              localStorage["lastBuildingId"] =  result.results[0].id;
            }
            if(result.count > 1){
              this.router.navigate(['/buildings']);
            }else if(result.count == 1){
              this.router.navigate(['/dashboard/benchmarking'],{
                fragment: 'buildingId=' + result.results[0].id
              })
            }else{
              this.router.navigate(['/buildings/create']);
            }
             this.loginLoading = false;
          }))
        }else{
          this.customerService.list$.subscribe(() => {
            this.router.navigate(['/buildings']);
          });
        }
      },
      error: (errorRes) => {
        this.loginError = true;
        this.loginLoading = false;
        this.errorMessage =  errorRes.error && errorRes.error.Message ? errorRes.error.Message : errorRes.error;
      },
    });
  }
}
