import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Subscription, fromEvent, EMPTY } from 'rxjs';
import { throttleTime, map, tap, catchError } from 'rxjs/operators';
import { SectionLoadingStates } from '../utils/section-loading-states';
import { SideNavService } from './side-nav.service';
import { CustomersService } from '../customers/customers.service';

@Component({
  selector: 'exa-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  /**
   * @todo
   * (@hassan|@abdullah)
   * it's better to create model for the user object.
   */
  user: { email: string };
  initializingBasicData = new SectionLoadingStates();
  menuSub: Subscription;
  tempCollapse = this.menu.collapsed;
  customers$ = this.customers.list$;
  constructor(
      private auth: AuthService,
      public menu: SideNavService,
      public customers: CustomersService,
    ) { }

  ngOnInit() {
    const { selected } = this.customers;
    this.user = this.auth.getUser();
    this.customers.filterList(selected && selected.name);
    this.menu.onResize();
    this.menuSub = fromEvent(window, 'resize')
      .pipe(throttleTime(500))
      .subscribe(() => this.menu.onResize());
    this.initializingBasicData.setLoadingState();
    this.customers.setInitialSelectedCustomer().pipe(
      tap(() => this.initializingBasicData.resetLoadingState()),
      catchError(err => {
        this.initializingBasicData.setErrorState(err);
        return EMPTY;
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.menuSub.unsubscribe();
  }


  onToggleMenu() {
    this.menu.opened ? this.menu.close() : this.menu.open();
  }

  onToggleCollapse() {
    this.menu.collapsed ? this.menu.ellapse() : this.menu.collapse();
  }

  onLogout() {
    this.auth.logout();
  }

  onSelectCustomer(customer) {
    this.customers.select(customer);
  }

  onFilterCustomers(value) {
    this.customers.filterList(value);
  }

  onMouseEnterNav() {
    this.menu.setHover(true);
  }

  onMouseLeaveNav() {
    this.menu.setHover(false);
  }
}
