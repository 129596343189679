import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../../customers/customers.service';

@Component({
  selector: 'exa-customers-reports-pg',
  templateUrl: './customers-reports-pg.component.html',
  styleUrls: ['./customers-reports-pg.component.scss']
})
export class CustomersReportsPgComponent implements OnInit {

  constructor(public customers: CustomersService) { }

  ngOnInit() {
  }

}
