import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { OperationScheduleLegendComponent } from './operation-schedule-legend/operation-schedule-legend.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    OperationScheduleLegendComponent,
  ],
  declarations: [OperationScheduleLegendComponent],
})
export class OperationScheduleLegendModule { }
