export class SectionLoadingStates {
  loading: boolean;
  error: boolean;
  errorData: any;

  setLoadingState() {
    this.loading = true;
    this.error = false;
    this.errorData = null;
  }

  setErrorState(err?: any) {
    this.error = true;
    this.errorData = err;
    this.loading = false;
  }

  resetLoadingState() {
    this.loading = false;
  }
}
