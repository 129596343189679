// Workaround https://github.com/angular/angular/issues/10887#issuecomment-560496627
// Helper types


/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;


/* Extract a pristine changed observable from an abstract control
* @param control AbstractControl like object with markAsPristine method
*/

export const createTouchStatusChanges = (control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched;
  const prevMarkAsUntouched = control.markAsUntouched;

  const touchChanges$ = new Subject<boolean>();

  function nextMarkAsTouched(...args) {
    prevMarkAsTouched.bind(control)(...args);
    touchChanges$.next(true);
  }

  function nextMarkAsUntouched(...args) {
    prevMarkAsUntouched.bind(control)(...args);
    touchChanges$.next(false);
  }

  control.markAsTouched = nextMarkAsTouched;
  control.markAsUntouched = nextMarkAsUntouched;

  return touchChanges$;
};
