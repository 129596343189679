import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'exa-content-with-loading-error',
  template: `
    <div class="content-with-loading-and-error">
      <div *ngIf="loading" class="content-with-loading-and-error__loading" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner [diameter]="loadingDiameter"></mat-spinner>
      </div>
      <div *ngIf="error && !loading" class="content-with-loading-and-error__error">
        <exa-loading-error (reload)="onReload()"></exa-loading-error>
      </div>
      <div *ngIf="!loading && !error" class="content-with-loading-and-error__content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWithLoadingErrorComponent {
  @Input() loading = false;
  @Input() error = false;
  @Input() loadingDiameter = 40;
  @Output() reload = new EventEmitter();

  constructor() { }

  onReload() {
    this.reload.emit();
  }
}
