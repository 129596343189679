import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NotAvailableModule } from '../../widgets/not-available/not-available.module';
import { UtilityBillsMonthlyAnalysisComponent } from './utility-bills-monthly-analysis/utility-bills-monthly-analysis.component';
import { UtilityBillsMonthlyAnalysisTableComponent } from './utility-bills-monthly-analysis-table/utility-bills-monthly-analysis-table.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NotAvailableModule,
    ],
    exports: [
        UtilityBillsMonthlyAnalysisComponent,
    ],
    declarations: [UtilityBillsMonthlyAnalysisComponent, UtilityBillsMonthlyAnalysisTableComponent],
})
export class UtilityBillsMonthlyAnalysisModule { }
