import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import { Subject, Subscription, Observable, EMPTY } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { Customer } from '../../../shared/models/customer.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import {ToastrService} from 'ngx-toastr';

interface ReportDef {
  id: string;
  name: string;
}

@Component({
  selector: 'exa-customer-reports',
  templateUrl: './customer-reports.component.html',
  styleUrls: ['./customer-reports.component.scss']
})
export class CustomerReportsComponent implements OnInit {

  reportDefs: { [reportId: string]: ReportDef } = {
    wasteEvents: {
      id: 'wasteEvents',
      name: 'Waste Events',
    },
  };
  reportDefsOptions = Object.values(this.reportDefs);

  exportReport$ = new Subject<ReportDef>();
  exportReportForm = this.fb.group({
    reportType: [this.reportDefs.wasteEvents, [Validators.required]],
  });
  exportingStats = new SectionLoadingStates();
  subscriptionSink = new Subscription();

  @Input() customer: Customer;

  constructor(private fb: FormBuilder, private fileSaver: FileSaverService, private data: DataService, protected toastr: ToastrService) { }

  ngOnInit() {
    const exportReportSub = this.exportReport$.pipe(
      switchMap((reportDef) => {
        this.data.criterias.exportWasteEvents();
       return EMPTY;
      }),
    ).subscribe();
    this.subscriptionSink.add(exportReportSub);
  }

  exportReport() {
    const { reportType } = this.exportReportForm.value;
    this.exportReport$.next(reportType);
  }

}
