import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material';
import { SharedModule } from '../../shared/shared.module';
import { NotAvailableModule } from '../../widgets/not-available/not-available.module';
import { UtilityBillYearViewComponent } from './utility-bill-year-view/utility-bill-year-view.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    SharedModule,
    NotAvailableModule,
  ],
  declarations: [UtilityBillYearViewComponent],
  exports: [UtilityBillYearViewComponent],
})
export class UtilityBillYearViewModule {
}
