import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, fromEvent, of, interval, BehaviorSubject } from 'rxjs';
import { debounceTime, takeUntil, map, tap, takeWhile, filter } from 'rxjs/operators';

@Component({
  selector: 'exa-infinite-scroll',
  template: `
    <div class="infinite-scroll" #listEl>
      <div class="infinite-scroll__content">
        <ng-content></ng-content>
      </div>

      <div class="infinite-scroll__loading" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
      </div>

      <div class="infinite-scroll__end" *ngIf="end">
        <p>No More Results</p>
      </div>
    </div>
  `,
  styles: [`
    .infinite-scroll__loading,
    .infinite-scroll__end {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .infinite-scroll__loading p,
    .infinite-scroll__end .mat-spinner {
      margin: -.5rem 0 0
    }
  `]
})
export class InfiniteScrollComponent implements OnInit, OnDestroy, OnChanges {
  @Input() last: number;
  @Input() end: Boolean;
  @Input() loading: Boolean;
  @Input() page: number;
  @Input() trackedBy: string;
  @Output() loadMore: EventEmitter<any> = new EventEmitter();
  @ViewChild('listEl') $listEl: ElementRef;
  scrollSub: Subscription;
  isBottomHidden$ = new BehaviorSubject(false);
  startSub: Subscription;

  private get hitBottom() {
    const $el = this.$listEl.nativeElement;
    const box = $el.getBoundingClientRect();
    const { bottom } = box;
    return bottom <= window.innerHeight;
  }


  constructor() { }

  ngOnInit() {
    this.start();
  }


  ngOnChanges(changes: SimpleChanges): void {
    const { trackedBy } = changes;
    if (!trackedBy || trackedBy.currentValue === trackedBy.previousValue) {
      return;
    }

    // this.suspend();
    this.start();
  }

  ngOnDestroy() {
    this.suspend();
  }

  onScroll() {
    const hitBottom = this.hitBottom;

    if (this.end) {
      this.scrollSub.unsubscribe();
      return;
    }

    if (!this.loading && hitBottom) {
      this.loadMore.emit(this.page);
    }
  }



  private start() {
    this.scrollSub = fromEvent(window, 'scroll')
      .pipe(debounceTime(250))
      .subscribe(() => this.onScroll());


    this.startSub = interval(1000)
      .pipe(
        // takeWhile(() => this.hitBottom && !this.end),
        filter(() => !this.loading),
        map(val => this.onScroll()),
      ).subscribe();
  }


  private suspend() {
    if (this.scrollSub && this.scrollSub.unsubscribe) {
      this.scrollSub.unsubscribe();
    }

    if (this.startSub && this.startSub.unsubscribe) {
      this.startSub.unsubscribe();
    }
  }
}
