import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WeatherKwhHeatmapPageComponent } from './weather-kwh-heatmap-page/weather-kwh-heatmap-page.component';
import { WeatherMainPageComponent } from './weather-main-page/weather-main-page.component';
import { WeatherComparisonComponent } from './weather-comparison/weather-comparison.component';
import { WeatherKwhComponent } from './weather-kwh/weather-kwh.component';

const routes: Routes = [
  {
    path: '',
    component: WeatherMainPageComponent,
    children: [
      {
        path: '',
        component: WeatherComparisonComponent,
      },
      {
        path: 'weather-kwh',
        component: WeatherKwhComponent,
      },
      {
        path: 'weather-kwh-heatmap',
        component: WeatherKwhHeatmapPageComponent,
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WeatherRoutingModule { }
