import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meter } from '../../shared/models/meter.model';
import { ApiService } from '../api.service';
import { MediaTypeMeterBaseService, MeterDef } from './media-type-meter-base.service';
import { WaterMeter, WaterSeriesDataPoint } from './models/media-types.models';

@Injectable({
  providedIn: 'root',
})
export class WaterMeterService extends MediaTypeMeterBaseService<WaterMeter, WaterSeriesDataPoint> {

  constructor(protected http: HttpClient, protected apiSvc: ApiService) {
    super(http, apiSvc, 'water');
  }

  protected getMeterDef(meter: Meter): MeterDef {
    return WaterMeterType;
  }

}

export const WaterMeterType: MeterDef = {
  type: 'water',
  name: 'Water',
  service: <MediaTypeMeterBaseService><any>WaterMeterService,
  familyService: <MediaTypeMeterBaseService><any>WaterMeterService,
  route: '/meters/water',
  listLink: '/meters/list/water',
  defaultUnit: 'Gallon',
};
