import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import { SmtSubscriptionsService } from '../../../data/smt-subscriptions/smt-subscriptions.service';
import {
  IDeleteConfirmationData,
  DeleteConfirmationDialogComponent,
} from '../../../shared/components/delete-confirmation-dialog.component';
import { Customer } from '../../../shared/models/customer.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { SmtAddSubscriptionComponent } from '../smt-add-subscription/smt-add-subscription.component';

@Component({
  selector: 'exa-smt-subscriptions',
  templateUrl: './smt-subscriptions.component.html',
  styleUrls: ['./smt-subscriptions.component.scss'],
})
export class SmtSubscriptionsComponent implements OnInit, OnChanges {

  loadSubscriptions$ = new BehaviorSubject<boolean>(null);
  smtSubscriptions$ = new BehaviorSubject(null);
  subscriptionsLoadingStatuses = new SectionLoadingStates();
  subscriptions = new Subscription();

  @Input() customer: Customer;

  constructor(private dialog: MatDialog, private toastr: ToastrService, private smtSubSvc: SmtSubscriptionsService) {
  }

  ngOnChanges({customer}: SimpleChanges): void {
    if (customer.previousValue !== customer.currentValue && !customer.firstChange) {
      this.loadSubscriptions$.next(true);
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.loadSubscriptions$.pipe(
        switchMap(() => this.loadSubscription()),
      ).subscribe(),
    );
  }

  addNewSubscription() {
    const newSubDialog = this.dialog.open(SmtAddSubscriptionComponent, {
      data: {
        customer: this.customer,
      },
      width: DIALOG_STATIC_WIDTHS.medium,
    });
    this.subscriptions.add(
      newSubDialog.componentInstance.onSubscriptionSaved.pipe(
        tap((ev) => this.toastr.success(`Subscription [${ev.subscriptionId}] has been added successfully!`)),
        tap(() => this.loadSubscriptions$.next(true)),
      ).subscribe(),
    );
  }

  loadSubscription() {
    const loadingSection = this.subscriptionsLoadingStatuses;
    loadingSection.setLoadingState();
    return this.smtSubSvc.getList().pipe(
      tap(() => loadingSection.resetLoadingState()),
      tap(subscriptions => this.smtSubscriptions$.next(subscriptions)),
      catchError(err => {
        loadingSection.setErrorState(err);
        return EMPTY;
      }),
    );
  }

  deleteSubscription(subscription: {id: string}) {
    const dialogData: IDeleteConfirmationData = {
      deleteRequest: () => {
        return this.smtSubSvc.deleteSubscription(subscription.id).pipe(
          tap(() => this.loadSubscriptions$.next(true)),
        );
      },
      body: `Are you sure you want to delete subscription: ${subscription.id}`,
      success: `Subscription [${subscription.id}] has been deleted successfully`,
      failure: `failed to delete subscription [${subscription.id}]. please try again`,
    };

    this.dialog.open(DeleteConfirmationDialogComponent, {
      data: dialogData,
      minWidth: DIALOG_STATIC_WIDTHS.small,
    });

  }
}
