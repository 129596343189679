import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'exa-loading-error',
  template: `
    <div class="loading-error">
      <div class="loading-error__message">
        {{message}}
      </div>

      <div class="loading-error__actions">
        <button
            type="button"
            mat-raised-button
            [color]="color"
            (click)="onClick()"
            class="loading-error__action">
          <mat-icon>{{ icon }}</mat-icon>
          {{action}}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./loading-error.component.scss']
})
export class LoadingErrorComponent {
  @Input() message = 'Something went wrong, click button below to refresh';
  @Input() icon = 'refresh';
  @Input() action = 'reload';
  @Input() color = 'accent';
  @Output() reload = new EventEmitter();


  onClick() {
    this.reload.emit();
  }
}
