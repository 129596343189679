import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { DataService } from '../../../data/data.service';
import { CustomersService } from '../../../customers/customers.service';



@Component({
  selector: 'exa-new-building',
  templateUrl: './new-building.component.html',
  styles: []
})
export class NewBuildingComponent {
  buildingTypes$: Observable<{ id: number, name: string; }[]>;
  customer$ = this.customers.selected$;

  constructor(
    private data: DataService,
    private customers: CustomersService,
  ) { }

  saveCallback(building) {
    return this.data.buildings.create(building);
  }
}

