import { Injectable } from '@angular/core';
import { DataService } from '../../data/data.service';
import { Observable } from 'rxjs';
import { map, tap, debounce, debounceTime, filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { CUSTOMER_NAV_ITEMS, ALL_USERS_NAV_ITEMS } from './side-nav.const';
import { getRoutesByGroup } from '../../auth/user-group-guard.provider';

@Injectable({
  providedIn: 'root'
})
export class SideNavRoutesService {
  user$ = this.auth.token$.pipe(map(token => this.auth.getUser()));
  group$ = this.user$.pipe(map((user: any) => (user && user.group) || []));

  dataSrc = {
    alerts$: this.data.alertsList()
      .pipe(map((res: any) => res && res.length)),
  };

  customerNavItems$ = this.group$.pipe(
    debounceTime(500),
    filter(group => group && group.length),
    map(group => getRoutesByGroup(CUSTOMER_NAV_ITEMS, group)),
    map(routes => routes.map((route) => ({...route, data$: route.dataSrc && this.dataSrc[route.dataSrc]}))),
  );

  allUsersRoutes$ = this.group$.pipe(
    debounceTime(500),
    filter(group => group && group.length),
    map(group => getRoutesByGroup(ALL_USERS_NAV_ITEMS, group)),
    map(routes => routes.map((route) => ({...route, data$: route.dataSrc && this.dataSrc[route.dataSrc]}))),
  );

  constructor(private data: DataService, private auth: AuthService) {}
}
