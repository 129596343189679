import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subject, merge, EMPTY, Subscription } from 'rxjs';
import { share, tap, switchMap, catchError, map } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { Building } from '../../../shared/models/building.model';
import { Customer } from '../../../shared/models/customer.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { WeatherComparisonFiltersActions } from '../weather-comparison-filters/weather-comparison-filters.component';
import {
  WeatherQueryGroup,
  GroupWeatherSeries,
  WeatherGroupBoundaries,
  GroupTotalDegreeHoursDays,
} from '../weather-comparison.models';
import { WeatherComparisonService } from '../weather-comparison.service';
import { DashboardCommonChild, eventSubscriber } from '../../../dashboard/dashboard-common-child.interface';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { AllOrSearchConfig } from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import { onlyBuildingFilterBasicConfig } from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';
import { query } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'exa-weather-comparison-section',
  templateUrl: './weather-comparison-section.component.html',
  styleUrls: ['./weather-comparison-section.component.scss'],
})
export class WeatherComparisonSectionComponent implements OnInit, OnChanges, OnDestroy {

  loading: boolean;
  error: boolean;
  showDifferentBuildingsDropDown: boolean;
  showDifferentPeriodDateRange: boolean;
  lastQueryArr: WeatherQueryGroup[];
  comparisonDataLoadingStates = new SectionLoadingStates();
  comparisonSeriesArr$ = new Subject<GroupWeatherSeries[]>();
  groupsWeatherBoundaries$ = new Subject<WeatherGroupBoundaries[]>();
  groupsTotalDegrees$ = new Subject<GroupTotalDegreeHoursDays[]>();
  buildingsInputConfig: AllOrSearchConfig<Building>;
  compareForm: FormGroup;
  buildingFC: FormControl;
  startDateFC: FormControl;
  endDateFC: FormControl;
  firstGroupName: string;
  secondGroupName: string;

  filtersActionConfig: WeatherComparisonFiltersActions = {
    exportDataFn: ((groupName, query) => this.weatherCompSvc.exportData(groupName, query)).bind(this),
    exportErrorFn: (groupName => this.toastr.error(`Exporting Weather Data for ${groupName} has been failed`)),
    loadSeriesDataFn: this.getComparisonSeriesDate.bind(this),
  };

  buildingsSearchFn = this.data.buildings.filterByName.bind(this.data);

  @Input() customer: Customer;

  constructor(private data: DataService, 
    private weatherCompSvc: WeatherComparisonService,
    private toastr: ToastrService, 
    private appService: DashboardService,
    private formBuilder: FormBuilder) {
    this.executeAction = this.executeAction.bind(this);
    eventSubscriber(appService.subscription, this.executeAction);
  }
  
  executeAction(params?) {
    if(this.lastQueryArr && this.lastQueryArr.length > 1){
      var queryArray = this.lastQueryArr[0];
      queryArray.buildingId =  params.buildingId;
      queryArray.timeRange.startDate =  params.fromDate;
      queryArray.timeRange.endDate =  params.toDate;
    } else {
      this.lastQueryArr = [{buildingId: params.buildingId, groupName: "", timeRange: {startDate: params.fromDate, endDate: params.toDate}}]
    }

    this.data.buildings.details(params.buildingId).subscribe(building => {
      this.firstGroupName = building.name;
      this.lastQueryArr[0].groupName = building.name;
   });
    this.reloadComparisonData()
  }

  ngOnInit() {
    this.buildingsInputConfig = {
      ...onlyBuildingFilterBasicConfig,
      valueOnChangeExtractorFn: building => building && building.id,
      searchItems: (name) => this.data.buildings.lookup(typeof name == "object" ? name : { name: name || '' }).pipe(map((res: any) => {
        return res.results.map(res => {
          return { id: res.id, name: res.name }
        });
      },
      )),
    };

    const todayMoment = moment().utc();
    const initialStartDate = moment(todayMoment).subtract(1, 'w').toDate();
    const initialEndDate = todayMoment.toDate();

    this.compareForm = this.formBuilder.group({
      building: [{},],
      startDate: [],
      endDate: [],
    });
    this.buildingFC = this.compareForm.controls.building as FormControl;
    this.buildingFC.valueChanges.subscribe(buildingId => {
      if(this.lastQueryArr && this.lastQueryArr.length > 1){
        this.lastQueryArr.splice(1, 1);
      }
      this.lastQueryArr.push({buildingId: buildingId, groupName: "", timeRange: this.lastQueryArr[0].timeRange});
      this.data.buildings.details(buildingId).subscribe(building => {
        this.secondGroupName = building.name;
        this.lastQueryArr[1].groupName = building.name;
     });
      this.lastQueryArr[0].groupName = this.firstGroupName;
      this.reloadComparisonData()
    });
    
    this.startDateFC = this.compareForm.controls.startDate as FormControl;
    var startDate = null;
    var endDate = null;

    this.startDateFC.valueChanges.subscribe(v => {
      startDate = v;
      if(this.lastQueryArr && this.lastQueryArr.length > 1){
        this.lastQueryArr.splice(1, 1);
      }
      this.loadBuildings(this.lastQueryArr[0].buildingId);
      this.lastQueryArr.push({buildingId: this.lastQueryArr[0].buildingId, groupName: this.firstGroupName, timeRange: {startDate: startDate, endDate: endDate}});
      
      if(startDate && endDate){
        this.reloadComparisonData();
      }
    });
    
    this.endDateFC = this.compareForm.controls.endDate as FormControl;
    this.endDateFC.valueChanges.subscribe(v => {
      endDate = v;
      
      if(this.lastQueryArr && this.lastQueryArr.length > 1){
        this.lastQueryArr.splice(1, 1);
      }

      this.lastQueryArr.push({buildingId: this.lastQueryArr[0].buildingId, groupName: startDate.toLocaleDateString("en-US") + " - " + endDate.toLocaleDateString("en-US"), timeRange: {startDate: startDate, endDate: endDate}});
      this.lastQueryArr[0].groupName = moment(this.lastQueryArr[0].timeRange.startDate, "YYYY-MM-DD").format("MM/DD/YYYY") + " - " + moment(this.lastQueryArr[0].timeRange.endDate, "YYYY-MM-DD").format("MM/DD/YYYY") ;
      if(startDate && endDate){
        this.reloadComparisonData();
      }
    })
  }

  ngOnDestroy(): void {
    eventSubscriber(this.appService.subscription, this.executeAction, true);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  loadBuildings(id) {
    return this.data.buildings.details(id);
  }

  startLoadingState() {
    this.loading = true;
    this.error = false;
  }

  setErrorState() {
    this.error = true;
    this.loading = false;
  }

  getComparisonSeriesDate(queryArr: WeatherQueryGroup[]) {
    const loadingStates = this.comparisonDataLoadingStates;
    loadingStates.setLoadingState();
    this.lastQueryArr = queryArr;
    return this.weatherCompSvc.comparisonData(queryArr).pipe(
      tap(() => loadingStates.resetLoadingState()),
      tap(groupsData => {
        const analyzedGroupsData = groupsData.map(d => this.weatherCompSvc.analyzeAnalysisWeatherData(d));
        this.comparisonSeriesArr$.next(analyzedGroupsData.map(g => g.series));
        this.groupsWeatherBoundaries$.next(analyzedGroupsData.map(g => g.boundaries));
        this.groupsTotalDegrees$.next(analyzedGroupsData.map(g => g.totalDegree));
      }),
      catchError(() => {
        loadingStates.setErrorState();
        return EMPTY;
      }),
    );
  }

  reloadComparisonData() {
    this.getComparisonSeriesDate(this.lastQueryArr).subscribe();
  }

  compareWithDifferentBuilding(){
    this.showDifferentBuildingsDropDown = true;
    this.showDifferentPeriodDateRange = false;
  }
  
  compareWithDifferentPeriod(){
    this.showDifferentPeriodDateRange = true;
    this.showDifferentBuildingsDropDown = false;
  }

   // handler function that receives the updated date range object
   updateDateRange(dateRange: Range) {
  }
}
