import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { ImportModalBase } from '../import-modal-base';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'exa-import-building',
  templateUrl: './import-building.component.html',
  styleUrls: ['./import-building.component.scss'],
})
export class ImportBuildingComponent extends ImportModalBase {
  uploadDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<ImportBuildingComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { customer: { id: string } },
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
    protected auth: AuthService,
    ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }

  setConfig() {
    const importMethod = () => {
      return this.data.customers
        .importBuildings(this.customerId(), this.signedUrl.path);
    };

    const signedUrlMethod = () => this.data.customers
      .importBuildingUrl(this.customerId(), this.selectedFile);

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks
        .status(taskId),
      templateMethod: () => this.data.customers.importBuildingsTemplate(this.customerId()),
      acceptedFileFormats: '.xlsx, text/xlsx',
      onStartSubmitting: () => this.uploadDisabled = true,
      onSubmittingFinished: () => this.uploadDisabled = false,
      onFinish: () => this.close(),
      url: ""
    };
  }


  customerId() {
    const { customer } = this.modalData;
    return customer ? customer.id : null;
  }

}
