import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import Selectable from 'selectable.js';
import initSelectableTable from 'selectable-table-plugin';


@Component({
  selector: 'exa-weekly-hours-range',
  template: `
  <div #rangeTable class="full-width">
    <table mat-table class="full-width criteria-hours-table" [dataSource]="weekdays">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="criteria-hours-table__head" data-selectable="all">Days</th>
        <td mat-cell *matCellDef="let day" data-selectable="row" class="criteria-hours-table__cell">{{day.name}}</td>
      </ng-container>

      <ng-container *ngFor="let hour of hours" [matColumnDef]="hour">
        <th mat-header-cell *matHeaderCellDef class="criteria-hours-table__head" data-selectable="column">{{hour}}</th>
        <td mat-cell
            *matCellDef="let day"
            class="selectable criteria-hours-table__cell"
            [attr.data-day]="day.name"
            [attr.data-hour]="hour">
          {{day[hour]}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="criteria-hours-table__row"></tr>
    </table>
  </div>
  `,
  styleUrls: ['./weekly-hours-range.component.scss']
})
export class WeeklyHoursRangeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('rangeTable') rangeTable: ElementRef;
  @Input() defaultValue: any;
  @Output() select = new EventEmitter;
  weekdayNames = {
    Mon: 'monday',
    Tue: 'tuesday',
    Wed: 'wednesday',
    Thu: 'thursday',
    Fri: 'friday',
    Sat: 'saturday',
    Sun: 'sunday',
  };
  weekdays = [];
  hours = [];
  displayedColumns = ['name'];
  selectable: Selectable;
  isDefaultSet = false;
  timeout = null;

  get selected() {
    return this.selectable.items
      .filter(val => val.selected)
      .map((val) => val.node)
      .map((el) => ({
        day: this.weekdayNames[el.dataset.day],
        hour: parseInt(el.dataset.hour.replace(/-d+$/, ''), 10),
      }))
      .reduce((cur, { day, hour }) => {
        cur[day] = cur[day] || [];
        cur[day].push(hour);
        return cur;
      }, {});
  }

  constructor() { }

  ngOnInit() {
    this.initWeekDaysData();
  }

  ngAfterViewInit() {
    const $table = this.rangeTable.nativeElement;
    const $cells = $table.querySelectorAll('.selectable');

    initSelectableTable(Selectable);

    this.selectable = new Selectable({
      filter: $cells,
      toggle: true,
    });

    if (this.selectable.table) {
      this.selectable.table();
    }

    this.selectable.on('selecteditem', this.onSelect.bind(this));
    this.selectable.on('deselecteditem', this.onSelect.bind(this));
    if (this.defaultValue && !this.isDefaultSet) {
      this.setDefaultValue();
    } else {
      this.isDefaultSet = true;
    }
  }

  ngOnDestroy() {
    if (this.selectable) { this.selectable.destroy(); }
  }

  initWeekDaysData() {
    this.hours = (new Array(24))
      .fill('_')
      .map((_, h) => `${h}-${h === 23 ? 0 : h + 1}`);

    this.displayedColumns = [...this.displayedColumns, ...this.hours];

    Object.keys(this.weekdayNames).forEach(name => {
      const day = { name };
      this.hours.forEach((hour) => day[hour] = ' ');
      this.weekdays.push(day);
    });
  }


  onSelect(item) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.select.emit(this.selected);
    }, 50);
  }


  setDefaultValue() {
    const { defaultValue, selectable, weekdayNames } = this;
    const { items } = selectable;
    const defaultItems = items.filter((item) => {
      const { day, hour } =  item.node.dataset;
      const dayName = weekdayNames[day];
      const hourVal = parseInt(hour.replace(/-.*?$/, ''), 10);
      const defaultToSet =  defaultValue[dayName] || [];
      return defaultToSet.includes(hourVal);
    });

    selectable.select(defaultItems);
    this.isDefaultSet = true;
  }
}
