import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from '../../data/data.service';
import { Observable, Subscription, BehaviorSubject, throwError, of } from 'rxjs';
import { finalize, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface INotesData {
  entity: string;
  entityId: string;
}

interface INoteBody {
  entity: string;
  entity_id: string;
  content: string;
}

@Component({
  selector: 'exa-notes-create-dialog',
  template: `
    <h1 mat-dialog-title>Create New Note</h1>
    <div mat-dialog-content>
      <mat-form-field class="full-width">
        <textarea matInput [(ngModel)]="content" required placeholder="type a note"></textarea>
      </mat-form-field>
    </div>

    <div mat-dialog-actions fxLayout="flex" fxLayoutAlign="end center" class="full-width">
      <button mat-button mat-dialog-close [disabled]="loading">cancel</button>
      <button mat-button [disabled]="loading || !content" (click)="onClickSave()">save</button>
    </div>
  `,
  styles: []
})
export class NotesCreateDialogComponent {
  content: string;
  loading = false;
  error = false;
  errorMessage: any = null;
  errorMap = {
    entity: 'Note type',
    entity_id: 'Type Id',
    content: 'Note text',
  }
  saveSub: Subscription;
  newNote$ = new BehaviorSubject(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: INotesData,
    public dialogRef: MatDialogRef<NotesCreateDialogComponent>,
    private dataService: DataService,
    private toastr: ToastrService,
  ) { }

  onClickSave() {
    const { entity, entityId } = this.data;
    const { content } = this;
    const note = { content, entity, entity_id: entityId };
    this.loading = true;
    this.error = false;

    if (this.saveSub) { this.saveSub.unsubscribe(); }
    this.saveSub = this.dataService.createNote(note)
      .pipe(
        tap((res) => this.newNote$.next(res)),
        finalize(() => this.loading = false),
        catchError(err => {
          this.error = true;
          this.errorMessage = err && err.error;
          return throwError(err);
        })
      ).subscribe((res) => {
        this.dialogRef.close();
        this.toastr.success('Note has been created successfully.');
      });
  }
}
