import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { actionsDef } from '../../shared/permissions/permissions-actions';

@Component({
  selector: 'exa-waste-events-list-pin-cell',
  templateUrl: './waste-events-list-pin-cell.component.html',
  styleUrls: ['./waste-events-list-pin-cell.component.scss']
})
export class WasteEventsListPinCellComponent {

  params: any;
  data: any;
  pinned: boolean;
  updating: boolean;
  permActionDefs = actionsDef;

  constructor() { }

  agInit(params: any) {
    this.data = params.node.data;
    this.params = params;
    this.pinned = this.data && this.data.pinned;
  }

  togglePin() {
    this.updating = true;
    this.params.context.componentParent.togglePinEvent(this.data).pipe(
      tap(({pinned}) => {
        this.pinned = pinned;
        this.params.node.setData({
          ...this.data,
          pinned,
        });
        this.params.api.redrawRows({rowNodes: [this.params.node]});
      }),
      tap(() => this.updating = false),
    ).subscribe();
  }
}
