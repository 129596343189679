import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { DataService } from '../../data/data.service';

@Component({
  selector: 'exa-owner-autocomplete',
  template: `
  <div [formGroup]="ownerFormGroup" class="full-width">
    <mat-form-field *ngIf="ownerFormGroup" class="full-width">
      <input type="text"
            [placeholder]="label"
            aria-label="owner"
            matInput
            [formControlName]="ownerControlName"
            [matAutocomplete]="auto"
            #searchBox
            (input)="onInputSearch(searchBox.value)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let owner of owners$ | async" [value]="owner.name">
          {{owner.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="ownerFormGroup.get([ownerControlName]).errors">{{label}} field is required.</mat-error>
    </mat-form-field>
  </div>
  `,
  styles: [],
})
export class OwnerAutocompleteComponent {
  @Input() ownerFormGroup: FormGroup;
  @Input() ownerControlName = 'name';
  @Input() label = 'Owner Name';
  ownersSearch$ = new BehaviorSubject('');
  owners$ = this.ownersSearch$.pipe(
    debounceTime(1000),
    filter((value) => !!value),
    switchMap((search: string) => this.data.customers.getOwners({ search })),
  );


  constructor(private data: DataService) { }

  onInputSearch(value) {
    this.ownersSearch$.next(value);
  }
}
