import { Component, Input } from '@angular/core';
import { ScheduleProfileInfo } from '../../../shared/models/operation-schedule/models';

@Component({
  selector: 'exa-operation-schedule-legend',
  template: `
    <div class="text--center text margin--bottom text--gray" style="font-size: 1.2em;">Operations Schedule Profiles</div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="2rem">
      <div *ngFor="let profile of profiles" fxLayout="row" fxLayoutAlign="center center">
        <span class="dot margin--right" [style.background-color]="profile.color"></span>
        <span class="text--gray-dark weight--bold">{{profile.displayName}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./operation-schedule-legend.component.scss']
})
export class OperationScheduleLegendComponent {

  @Input() profiles: ScheduleProfileInfo[];

}
