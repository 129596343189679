import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/ex-admin/src/environments/environment';
import { debounceTime, delay, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export class CriteriasApi {
  apiBase = environment.apiBase;
  api = `${this.apiBase}/criteria`;

  constructor(private http: HttpClient) {}

  create(criteria) {
    return this.http.post(`${this.api}/setup`, criteria);
  }

  lookup(params = {}) {
    return this.http.post(`${this.apiBase}/criteria/lookup/queryAll`, params);
  }

  list(params = {}) {
    return this.http.post(`${this.apiBase}/criteria/lookup/queryAll`, params);
  }

  queryPage(params?) {
    return this.http.post(`${this.apiBase}/criteria/detail/queryPage?pageNumber=${params.page}&pageSize=${params.pageSize}`, params);
  }

  details(id: string) {
    return this.http.get(`${this.api}/detail/${id}`);
  }

  update(id, body) {
    return this.http.put(`${this.api}/setup`, {...body, Id: id });
  }

  delete(id) {
    return this.http.delete(`${this.api}/setup/${id}`);
  }

  immediateApply(id, body) {
    return this.http.post(`${this.api}/applyingWasteCriteria`, {...body, id: id});
  }

  exportWasteEvents() {
    window.open(`${this.apiBase}/reports/WasteEvents/CsvReport`, '_blank');
  }
}
