import { MatDialogRef } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data/data.service';
import { ImportBase } from './import-base';
import { OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';



export class ImportModalBase extends ImportBase implements OnInit, OnDestroy {
  tokenSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<any>,
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
    protected auth: AuthService,
  ) {
      super(fileSaver, toastr, data);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }
  }
}
