import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { chartDefaultOptions } from 'ex-library';
import { Options } from 'highcharts';
import { SideNavService } from '../../layout/side-nav.service';
import { share, debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'exa-insight-sunburst',
  template: `
    <exlib-chart #chartEl [chartOptions]='chartOptions'></exlib-chart>
  `,
  styles: []
})
export class InsightSunburstComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() title: string;
  chartOptions: Options;

  constructor(private menu: SideNavService) { }

  ngOnInit() {
    this.draw();
    this.menu.collapsed$.pipe(
      share(),
      debounceTime(300),
      tap(() => this.draw()),
    );
  }

  ngOnChanges({ data }: any) {
    if (data && data.currentValue) {
      this.draw();
    }
  }

  draw() {
    const self = this;
    this.chartOptions = Object.assign({}, chartDefaultOptions, {
      chart: {
        height: '70%',
      },

      series: [{
        type: 'sunburst',
        data: this.data,
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
          },
          {
            level: 2,
            colorByPoint: true
          },
          {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }
        ]

      }],
      tooltip: {
        headerFormat: '',
        formatter() {
          const { point } = this;
          const { title } = self;
          return `The ${title.slice(0, 1).toUpperCase() + title.slice(1)} of:
            <b>${point.name}</b> is: <br><b>${point.value.toFixed(2)}</b>KWh`;
        }
      }
    });
  }
}
