import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { Customer } from '../../shared/models/customer.model';
import { Building } from '../../shared/models/building.model';
import { MetricsPoint } from '../../shared/models/meters-metrics.model';
import { IWasteMapMetersMetricsFilter } from '../../shared/models/meters-metrics-filters.model';
import {share, finalize, tap} from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import {Meter} from '../../shared/models/meter.model';
import {AllOrSearchConfig} from '../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {OnBuildingsSelected, onBuildingsSelectedFactory} from '../../shared/components/all-or-search-filter/all-or-search-filter.utils';
import {
  multiBuildingsFilterBasicConfig,
  multiMetersFilterBasicConfig
} from '../../shared/components/all-or-search-filter/all-or-search-filter.constants';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';


interface BaseLineChoice {
  id: string;
  name: string;
  offset: number;
}

const bestPracticeBaseline = {id: 'best-practice', name: 'Best Practice'};

@Component({
  selector: 'exa-waste-map-filters',
  template: `
    <mat-card>
      <form [formGroup]="filtersForm">
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="2rem">
            <div fxLayout="row" fxLayoutGap="2rem" fxFlex="1 1 100%">

              <exa-all-or-search-filter fxFlex="1 1 50%"
                                        formControlName="buildings"
                                        [config]="buildingsInputConfig">
              </exa-all-or-search-filter>

              <exa-all-or-search-filter fxFlex="1 1 50%"
                                        formControlName="meters"
                                        [config]="metersInputConfig"
              ></exa-all-or-search-filter>

            </div>

            <div fxLayout="row" fxLayoutGap="2rem">
              <mat-form-field fxFlex="1 1 33.33333%">
                <input matInput [matDatepicker]="startTimeDp" placeholder="Start Date" formControlName="startTime" readonly
                       required>
                <mat-datepicker-toggle matSuffix [for]="startTimeDp"></mat-datepicker-toggle>
                <mat-datepicker #startTimeDp disabled="false"></mat-datepicker>
              </mat-form-field>

              <mat-form-field fxFlex="1 1 33.33333%">
                <input matInput [matDatepicker]="endTimeDp" placeholder="End Date" formControlName="endTime" readonly
                       required>
                <mat-datepicker-toggle matSuffix [for]="endTimeDp"></mat-datepicker-toggle>
                <mat-datepicker #endTimeDp disabled="false"></mat-datepicker>
              </mat-form-field>

              <mat-form-field fxFlex="1 1 33.33333%">
                <mat-select placeholder="Density" formControlName="selectedDensity" required>
                  <mat-option *ngFor="let density of heatMapDensity" [value]="density.id">{{density.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </mat-card-content>

        <mat-card-actions fxLayout="row">
          <span fxFlex="1 1 100%"></span>
          <button mat-button mat-raised-button class="mat-primary" [disabled]="searching || filtersForm.invalid" (click)="search()">
            <div fxLayout="row" fxLayoutAlign="center center">
            <span class="inline-block margin--right-tiny" [ngClass]="{'padding--left-medium': !searching}">
              <mat-spinner diameter="24" *ngIf="searching"></mat-spinner>
            </span>
              Create Map
              <span class="inline-block padding--right-medium"></span>
            </div>
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  `,
  styles: []
})
export class WasteMapFiltersComponent implements OnInit {
  metrics$: Observable<MetricsPoint[]>;

  searching: boolean;

  heatMapDensity = [
    {
      id: 'total_waste',
      text: 'Total waste of all waste events (kWh)',
    },
    {
      id: 'events_count',
      text: 'Number of waste events per meter',
    },
    {
      id: 'waste_days',
      text: 'Total number of Wasteful days',
    },
    {
      id: 'avg_days',
      text: 'Average number of days per waste event',
    }
  ];

  @Input() customer: Customer;
  @Input() buildingsSearchFn: (name: string) => Observable<Building[]>;
  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;

  @Output() metrics = new EventEmitter<{metrics$: Observable<MetricsPoint[]>, filters: IWasteMapMetersMetricsFilter}>();

  buildingsInputConfig: AllOrSearchConfig<Building>;
  metersInputConfig: AllOrSearchConfig<Meter>;
  onBuildingsSelected: OnBuildingsSelected;

  filtersForm: FormGroup;


  constructor(private fb: FormBuilder, private data: DataService) { }

  ngOnInit() {
    this.buildingsInputConfig = {
      searchItems: this.buildingsSearchFn,
      ...multiBuildingsFilterBasicConfig,
    };

    this.metersInputConfig = {
      searchItems: this.metersSearchFn,
      ...multiMetersFilterBasicConfig,
    };

    this.filtersForm = this.fb.group({
      buildings: [null],
      meters: [null],
      startTime: moment().utc().subtract(15, 'd').toDate(),
      endTime: moment().utc().toDate(),
      selectedDensity: 'total_waste',
    });

    const metersFC = this.filtersForm.get('meters') as FormControl;
    this.onBuildingsSelected = onBuildingsSelectedFactory(this.metersSearchFn, metersFC, config => {
      this.metersInputConfig = {
        ...this.metersInputConfig,
        ...config,
      };
    });

    (this.filtersForm.controls.buildings as FormControl).valueChanges.pipe(
      tap(buildings => this.onBuildingsSelected(buildings)),
    ).subscribe();

  }

  private generateTime() {
    const {startTime: startTimeValue, endTime: endTimeValue} = this.filtersForm.value;
    const startTime = moment(startTimeValue);
    const endTime = moment(endTimeValue);
    return {
      startTime,
      endTime,
      from_date: startTime.startOf('d').format('YYYY-MM-DD'),
      to_date: endTime.hour(23).minute(0).format('YYYY-MM-DD'),
    };
  }

  private generateFiltersBody() {
    const { startTime, endTime, from_date, to_date } = this.generateTime();
    const {meters, buildings, selectedDensity} = this.filtersForm.value;
    const filters = <IWasteMapMetersMetricsFilter> {
      from_date,
      to_date,
      target: selectedDensity,
      meters: meters && meters.map(m => m.id),
      building_ids: buildings && buildings.map(b => b.id),
    };

    return filters;
  }

  search() {
    this.searching = true;
    const searchFilters = this.generateFiltersBody();
    this.metrics$ = <Observable<MetricsPoint[]>> this.data.wasteMap(searchFilters).pipe(
      share(),
      finalize(() => this.searching = false)
    );
    this.metrics.emit({metrics$: this.metrics$, filters: searchFilters});
  }

}
