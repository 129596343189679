import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/ex-admin/src/environments/environment';
import { of, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class UsersApi {
  api = environment.apiBase;
  authApi = `${this.api}/auth`;
  userApi = `${this.api}/users`;

  constructor(private http: HttpClient) {}

  create(user) {
    return this.http.post(this.userApi, user);
  }

  delete(userId) {
    return this.http.delete(`${this.userApi}/${userId}`);
  }

  getUser(id: string, customer: string) {
    return this.http.get(`${this.userApi}/${id}`, { params: { customer_id: customer } });
  }

  activate(id: string) {
    return this.http.post(`${this.userApi}/${id}/activate`, {});
  }

  deactivate(id: string) {
    return this.http.post(`${this.userApi}/${id}/deactivate`, {});
  }


  getProfile() {
    return this.http.post(`${this.api}/user/userProfile/queryOne`, {});
  }

  login(credentials: { email: string, password: string }) {
    return this.http.post(`${this.api}/login/queryOne`, credentials);
  }

  forgotPassword(email: string) {
    return this.http.post(`${this.api}/user/passwordResetToken`, { Email: email });
  }

  resetPassword(resetPasswordValues: { password: string, password_confirmation: string, user_id: string, token: string }) {
    return this.http.post(`${this.api}/user/passwordReset`, resetPasswordValues);
  }

  logout() {
    return this.http.get(`${this.api}/logout`);
  }

  getGroups() {
    return this.http.get(`${this.api}/groups`);
  }
}
