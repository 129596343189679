import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomersService } from '../../../customers/customers.service';
import { Customer } from '../../../shared/models/customer.model';

@Component({
  selector: 'exa-utility-bills-list-pg',
  templateUrl: './utility-bills-list-pg.component.html',
  styleUrls: ['./utility-bills-list-pg.component.scss']
})
export class UtilityBillsListPgComponent implements OnInit {

  customer$: Observable<Customer>;

  constructor(private customerSvc: CustomersService) { }

  ngOnInit() {
    this.customer$ = this.customerSvc.selected$;
  }

}
