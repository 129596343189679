import { PageEvent } from '@angular/material';
import { Observable, from, fromEvent, EMPTY } from 'rxjs';
import { share, finalize, filter, tap, debounceTime, catchError } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { ListBaseActionsComponent } from './list-base-actions.component';
import { ListBaseLoadingComponent } from './list-base-loading.component';
import { ListBaseLinkComponent } from './list-base-link.component';
import { ListBaseFilterComponent } from './list-base-filter.component';
import { ListBaseDateFilterComponent } from './list-base-date-filter.component';
import { ListBaseTextComponent } from './list-base-text.component';
import { AuthService } from '../../../auth/auth.service';
import { Roles } from '../../../auth/user-group-guard.provider';
import { SideNavService } from '../../../layout/side-nav.service';
import { ListBaseHeaderComponent } from './list-base-header.component';


export class ListBaseComponent<T> implements OnDestroy, OnInit {
  pageSizeOptions: number[] = [5, 10, 25, 100];
  page = 1;
  pageSize = 10;
  sortingKey = 'sort_by';
  resizeToFit = true;

  paginationRes$: Observable<{ count: number, results: T[]; }>;

  // grid
  columnDefs;
  gridParams;
  gridApi;
  gridOptions = {
    floatingFilter: true,
    pagination: true,
    infiniteInitialRowCount: 4,
    rowModelType:  'infinite',
    paginationPageSize: 10,
    rowHeight: 40,
    // debug: true,
    cacheBlockSize: 10,
    cacheOverflowSize: 2,
    maxConcurrentDatasourceRequests: 2,
    animateRows: true,
    loadingOverlayComponent: 'ListBaseLoadingComponent',
    overlayNoRowsTemplate: `No Data Available`,
    frameworkComponents: {
      ListBaseActionsComponent: ListBaseActionsComponent,
      ListBaseLoadingComponent: ListBaseLoadingComponent,
      ListBaseLinkComponent: ListBaseLinkComponent,
      ListBaseTextComponent: ListBaseTextComponent,
      ListBaseFilterComponent: ListBaseFilterComponent,
      ListBaseDateFilterComponent: ListBaseDateFilterComponent,
      agColumnHeader: ListBaseHeaderComponent,
    },

    context: {
      componentParent: this,
    },
    getRowNodeId: this.getRowNodeId,
    defaultColDef: {
      cellRenderer: 'ListBaseTextComponent',
      minWidth: 130,
    },
  };

  displayedColumns: any[];
  user;
  isAdmin = false;

  filterModelMap = {};
  sortModelMap = {};
  loadingError = false;
  loading: boolean;
  resize$ = fromEvent(window, 'resize')
    .pipe(debounceTime(250));

  resizeSub = this.resize$.subscribe(() => this.onModelUpdated());
  menuSub = this.sidenav.collapsed$
    .pipe(debounceTime(350))
    .subscribe(() => this.onModelUpdated())

  constructor(protected auth: AuthService, private sidenav: SideNavService) {}


  ngOnInit() {
    this.user = this.auth.getUser() || { group: [] };
    this.isAdmin = this.user.group.indexOf(Roles.superuser) > -1;
  }


  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }

    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
  }

  loadMethod() {
    throw Error('"setConfig" method is not implemented!');
  }

  changePaginationOptions(pageEvent: PageEvent) {
    this.page = pageEvent.pageIndex + 1;
    this.pageSize = pageEvent.pageSize;
    this.loadMethod();
  }


  getDataObservable(reqParams: any): Observable<{ count: number, results: T[]; }> {
    throw new Error('getDataObservable is not defined');
  }

  private getFilters(filters) {
    const params = {};

    Object.keys(filters).forEach((filterName) => {
      const value = filters[filterName].filter;
      const key = this.filterModelMap[filterName];
      params[key] = value;
    });

    return params;
  }

  private getSortables(sortables) {
    const list = sortables || [];
    const key = list[0] ? list[0].colId : null;
    const isAsc = list[0] && list[0].sort === 'asc' ?  true : false;
    const sorting = this.sortModelMap[key];
    return key ? { [this.sortingKey]: sorting, is_ascending: isAsc } : {};
  }

  private getRows(params) {
    const {page, pageSize} = this;
    const filters = this.getFilters(params.filterModel);
    const sort = this.getSortables(params.sortModel);
    const error = this.loadingError;


    this.gridApi.hideOverlay();
    this.gridApi.showLoadingOverlay();
    this.loading = true;
    this.loadingError = false;

    this.paginationRes$ = this.getDataObservable({
      page,
      pageSize,
      ...(error ? {} : filters),
      ...(error ? {} : sort),
    }).pipe(
      tap(() => this.loading = false),
      catchError((err) => {
        this.loadingError = true;
        this.loading = false;
        return EMPTY;
      }),
      share(),
    );

    return this.paginationRes$;
  }


  onGridReady(params, resetPagination = false) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.refreshDataSource();
  }

  refreshDataSource(){
    if(!this.gridApi){
        return;
    }
    this.gridApi.refreshInfiniteCache();
    this.gridApi.setDatasource({
      getRows: (rowsParams) => {
        const { endRow } = rowsParams;
        this.page = endRow / this.pageSize;
        return this.getRows(rowsParams).subscribe(({ results, count }) => {
          rowsParams.successCallback(results, count ? count : -1);
          this.gridApi.hideOverlay();
          this.gridApi.setDomLayout('autoHeight');
          this.loading = false;

          if (!results || !results.length) {
            this.gridApi.showNoRowsOverlay();
          }

          if (this.resizeToFit) {
            this.gridApi.sizeColumnsToFit();
          }
        }, (err) => {
          this.loadingError = true;
          this.loading = false;
        });
      }
    });
  }

  getRowNodeId(item) {
    return item.id;
  }

  onModelUpdated() {
    setTimeout(() => {
      if (this.gridApi && this.resizeToFit) {
        this.gridApi.sizeColumnsToFit();
      }
    }, 0);
  }
}
