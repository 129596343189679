import { BenchmarkResponse } from '../../shared/models/meters-metrics.model';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Customer } from '../../shared/models/customer.model';
import { map, switchMap } from 'rxjs/operators';
import { MetersMetricsFilters } from '../../shared/models/meters-metrics-filters.model';
import * as _ from 'lodash';


export class CustomersData {
  constructor(private api: ApiService) { }

  create(data): Observable<any> {
    return this.api.customers.create(data);
  }

  list(params?): Observable<Customer[]> {
    return this.api.customers.list(params) as Observable<Customer[]>;
  }

  changeAccount(params?): Observable<Customer[]> {
    return this.api.customers.changeAccount(params) as Observable<Customer[]>;
  }

  details(id: string): Observable<Customer> {
    return this.api.customers.details(id) as Observable<Customer>;
  }

  delete(id) {
    return this.api.customers.delete(id);
  }

  buildings(customerId: string) {
    return this.api.buildings.list({ company_id: customerId });
  }

  importBuildings(customerId: string, filepath: string) {
    return this.api.customers.importBuildings(customerId, { filepath });
  }

  importBuildingUrl(customer_id: string, file: File) {
    return this.api.tasks.getSignedUrl({
      id: customer_id,
      content_type: 'text/xlsx',
      entity: 'building',
      filename: file.name,
    }, { customer_id }).pipe(map(res => ({ ...res, customerId: customer_id })));
  }

  importBuildingsTemplate(customerId: string) {
    return this.api.customers.importBuildingsTemplate(customerId).pipe(
      switchMap(({ path, url }: any) => {
        const extension = path.slice(path.search(/\.\w+$/)).replace(/\./, '');
        return this.api.downloadFile(url, extension, customerId);
      }),
      map(({ blob, extension }) => {
        return {
          blob,
          name: `buildings-template.${extension}`,
        };
      }),
    );
  }

  importBuildingsStatus(customerId: number, taskId: number) {
    return this.api.customers.importBuildingsStatus(customerId, taskId);
  }

  metersMetrics(filters: MetersMetricsFilters) {
    return this.api.customers.metersMetrics(filters) as Observable<BenchmarkResponse>;
  }

  getOwners(body) {
    return this.api.customers.getOwners(body);
  }

  createUser(body) {
    return this.api.customers.createUser(body);
  }

  users(params?) {
    return this.api.customers.users(params);
  }

  lookupList(): Observable<any> {
    return this.api.customers.lookupList();
  }

  ownersLookup(params): Observable<any> {
    return this.api.customers.ownersLookup(params);
  }

  registerPGEforOwner(data) {
    return this.api.customers.registerPGEforOwner(_.omit(data, 'customer_id'), _.pick(data, 'customer_id'));
  }
}
