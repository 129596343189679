import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BE_DAY_DATE_FORMAT, predictedKwhMethods } from '../../constants';
import { Meter } from '../../shared/models/meter.model';
import { ApiService } from '../api.service';
import { MediaTypeMeterBaseService, MeterDef, MappedMeter } from './media-type-meter-base.service';
import { ElectricalMeter } from './models/media-types.models';

export interface ElectricalMappedMeter extends MappedMeter<ElectricalMeter> {
  kwhMethod: any;
}

@Injectable({
  providedIn: 'root'
})
export class ElectricalMeterService extends MediaTypeMeterBaseService {

  constructor(protected http: HttpClient, protected apiSvc: ApiService) {
    super(http, apiSvc, 'electrical');
  }

  protected getMeterDef(meter: Meter): MeterDef {
    return ElectricalMeterType;
  }


  protected mapMeter(meter: Meter): ElectricalMappedMeter & any {
    const mappedMeter = super.mapMeter<ElectricalMeter>(meter);
    return {
      ...mappedMeter,
      kwhMethod: Object.values(predictedKwhMethods).find(m => m.id === meter.predicted_kwh_method),
    };
  }

  importThresholdEfficiency(meterId: string, body: { filepath: string; }) {
    const url = `${this.api}/${meterId}/import-threshold-efficiency`;
    return this.http.post(url, body);
  }

  importThresholdEfficiencyTemplate() {
    const url = `${this.apiBase}/threshold-efficiency-template`;
    return this.http.get(url, {responseType: 'blob'});
  }

  updatePredictedKwhMethod(id, body) {
    const {no_waste, method, startingFrom} = body;
    return this.http.put(`${this.api}/updateWaste`, {
      no_waste,
      predicted_kwh_method: no_waste ? null : method,
      starting_from: no_waste ? null : moment(startingFrom).format(BE_DAY_DATE_FORMAT),
      id: id
    });
  }

}

export const ElectricalMeterType: MeterDef = {
  type: 'electrical',
  name: 'Electrical',
  service: <MediaTypeMeterBaseService><any>ElectricalMeterService,
  familyService: <MediaTypeMeterBaseService><any>ElectricalMeterService,
  route: '/meters/electrical',
  listLink: '/meters/list/electrical',
  defaultUnit: 'kWh',
};
