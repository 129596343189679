import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { merge, Subject, EMPTY, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap, catchError, map } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { ElectricalMeterService } from '../../../data/meters/electrical-meter.service';
import { Customer } from '../../../shared/models/customer.model';
import { WeatherKwhScatterSeries } from '../../weather-kwh-scatter/weather-kwh-scatter.component';
import { WeatherKwhDataService } from '../weather-kwh-data.service';
import { WeatherKwhQuery } from '../weather-kwh.models';
import { eventSubscriber } from '../../../dashboard/dashboard-common-child.interface';
import { DashboardService } from '../../../dashboard/dashboard.service';

@Component({
  selector: 'exa-weather-kwh-section',
  templateUrl: './weather-kwh-section.component.html',
  styleUrls: ['./weather-kwh-section.component.scss'],
  providers: [WeatherKwhDataService],
})
export class WeatherKwhSectionComponent implements OnInit, OnDestroy {

  kwhSeriesArr$: Observable<WeatherKwhScatterSeries[]>;
  @Input() customer: Customer;

  buildingsSearchFn = this.data.buildings.filterByName.bind(this.data.buildings);
  metersSearchFn = this.metersSvc.filterByName.bind(this.metersSvc);

  constructor(private data: DataService,
     private metersSvc: ElectricalMeterService,
     private kwhDataService: WeatherKwhDataService,
     private appService: DashboardService) {

    this.executeAction = this.executeAction.bind(this);
    eventSubscriber(appService.subscription, this.executeAction);
  }

  executeAction(params?) {
    this.loadKwhData({building_id: params.buildingId, endDate: params.toDate, meters: null, startDate: params.fromDate})
  }

  ngOnInit() {
    this.kwhSeriesArr$ = this.kwhDataService.kwhData$.pipe(
      map(response => this.kwhDataService.mapKwhData(response)),
    );
  }

  loadKwhData(query: WeatherKwhQuery) {
    this.kwhDataService.loadKwhData(query).subscribe();
  }

  ngOnDestroy(): void {
    eventSubscriber(this.appService.subscription, this.executeAction, true);
  }
}
