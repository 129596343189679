import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/ex-admin/src/environments/environment';
import { Observable } from 'rxjs';
import { Building } from '../../shared/models/building.model';

export class MetersApi {
  apiBase = environment.apiBase;
  api = `${this.apiBase}/meters`;

  constructor(private http: HttpClient) { }

  create(body) {
    return this.http.post(`${this.api}/electrical/setup`, body);
  }

  update(id, body) {
    return this.http.patch(`${this.api}/${id}`, body);
  }

  delete(id) {
    return this.http.delete(`${this.api}/${id}`);
  }

  updateUtility(id, body) {
    return this.http.post(`${this.api}/${id}/utility_id`, body);
  }

  mainMeters(building_id: string) {
    return this.http.post(`${this.api}/electrical/mainMeter/queryAll`, {building_id: building_id});
  }

  updatePredictedKwhMethod(id, body) {
    return this.http.post(`${this.api}/${id}/waste_settings`, body);
  }

  list(params?) {
    return this.http.get(this.api, {params: params});
  }

  details(id: string) {
    return this.http.get(`${this.api}/${id}`);
  }

  importThresholdEfficiency(meterId: string, body: { filepath: string; }) {
    const url = `${this.api}/${meterId}/import-threshold-efficiency`;
    return this.http.post(url, body);
  }

  importThresholdEfficiencyTemplate() {
    const url = `${this.apiBase}/threshold-efficiency-template`;
    return this.http.get(url, {responseType: 'blob'});
  }
}
