import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GroupWeatherSeries, TempRangeSeries } from '../weather-comparison.models';

@Component({
  selector: 'exa-weather-comparison-hours-summary',
  templateUrl: './weather-comparison-hours-summary.component.html',
  styleUrls: ['./weather-comparison-hours-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComparisonHoursSummaryComponent {
  _mappedGroupsRanges: GroupWeatherSeries[];
  @Input()
  set groupsTempRanges(groupsRanges: GroupWeatherSeries[]) {
    if(!groupsRanges)
      return;
    const rangesSet = groupsRanges
      .map(gRanges => gRanges.series)
      .reduce((ranges, gRangeSeries) => ranges.concat(...gRangeSeries), [])
      .filter((r, i, self) => self.findIndex(elem => elem.id === r.id) === i)
      .sort((a, b) => a.start - b.start);

    this._mappedGroupsRanges = groupsRanges.map(gRange => ({
      ...gRange,
      series: rangesSet.map(range => {
        const gRangeSeries = gRange.series.find(r => r.id === range.id)
        if (!!gRangeSeries)
          return gRangeSeries;
        return {
          ...range,
          points: [],
          hours: 0,
        };
      }),
    }));
  }
}
