import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Options } from 'highcharts';

export interface WeatherDailyHoursScatterSeries {
  id: string;
  name: string;
  points: Array<{
    temperature: number;
    hour: number;
    count: number
  }>;
}

@Component({
  selector: 'exa-weather-daily-hours-scatter',
  template: `
    <exlib-chart [chartOptions]="chartOptions" *ngIf="chartOptions"></exlib-chart>
  `,
  styles: []
})
export class WeatherDailyHoursScatterComponent implements OnChanges {

  chartOptions: Options;

  @Input() series: WeatherDailyHoursScatterSeries[];
  @Input() minTemp: number;
  @Input() maxTemp: number;

  ngOnChanges({ series }: SimpleChanges): void {
    if (series.currentValue !== series.previousValue) {
      this.generateChartOptions();
    }
  }

  private generateChartOptions() {
    const mappedSeries = WeatherDailyHoursScatterComponent.mapInputSeries(this.series);
    this.chartOptions = {
      series: mappedSeries,
      chart: {
        type: 'bubble',
      },
      tooltip: {
        pointFormat: `Hour <b>{point.x}</b><br/>Count: <b>{point.z}</b>`
      },
      legend: {
        enabled: false
     },
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: 'Daily Hours'
        },
        min: -1,
        max: 24,
      },
      yAxis: {
        title: {
          text: 'Temperature in F'
        },
        min: this.minTemp,
        max: this.maxTemp,
      },
      plotOptions: {
        bubble:{
          maxSize: '10%',
          sizeBy: 'width'
        }
      }
    };
  }

  static mapInputSeries(series: WeatherDailyHoursScatterSeries[]) {
    return series.map(s => ({
      ...s,
      data: <Array<[number, number, number]>>s.points.map(p => [p.hour, p.temperature, p.count]),
      colorByPoint: true
    }));
  }

}
