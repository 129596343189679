import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { SideNavService } from '../../layout/side-nav.service';
import { map, withLatestFrom, delay } from 'rxjs/operators';
import { Observable, of, BehaviorSubject, merge } from 'rxjs';


@Component({
  selector: 'exa-create-building',
  templateUrl: './create-building.component.html',
  styles: []
})
export class CreateBuildingComponent implements OnInit {
  activeLinkIndex$: Observable<number>;
  tabClicks$ = new BehaviorSubject(0);

  routes$ = of([
    {
      id: 0,
      name: 'Create New Building',
      link: ['/buildings/create'],
    },

    {
      id: 1,
      name: 'Import New Building(s)',
      link: ['/buildings/create/bulk'],
    },
  ]);

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      public menu: SideNavService,
    ) { }

  ngOnInit() {
    this.setInitialActiveTab();
  }


  setInitialActiveTab() {
    this.activeLinkIndex$ = merge(
        this.tabClicks$,
        this.route.url,
      ).pipe(
        withLatestFrom(this.routes$),
        delay(100),
        map((data) => {
          const { url } = this.router;
          const isBuildings = /bulk$/.test(url);
          return isBuildings ? 1 : 0;
        }),
      );
  }

  onTabClick(index) {
    this.tabClicks$.next(index);
  }
}