import { Component, OnInit, ComponentFactoryResolver, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, combineLatest, of, EMPTY, merge } from 'rxjs';
import { switchMap, startWith, tap, map, filter, catchError } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { METER_SERVICES, MediaTypeMeterBaseService } from '../../../data/meters/media-type-meter-base.service';
import { createTouchStatusChanges } from '../../../utils/form-control';
import {
  EquElem,
  CombinationEquationConfig,
} from '../../combination-equation/combination-equation/combination-equation.component';
import { MeterCreateComponentBase, MeterTypeDef } from '../meter-create-base.component';

@Component({
  selector: 'exa-virtual-meter-create',
  templateUrl: './virtual-meter-create.component.html',
  styleUrls: ['./virtual-meter-create.component.scss']
})
export class VirtualMeterCreateComponent extends MeterCreateComponentBase implements OnInit {

  metersEquConfig$: Observable<CombinationEquationConfig>;

  // just to be used later in case of failure in loading meter, so we can use it in an error message
  loadMeters$ = new BehaviorSubject(true);

  equationError$: Observable<any>;


  constructor(
    resolver: ComponentFactoryResolver,
    fb: FormBuilder, data: DataService,
    router: Router,
    toastr: ToastrService,
    @Inject(METER_SERVICES) meterServices: MediaTypeMeterBaseService[]
  ) {
    super(resolver, fb, router, toastr, data, meterServices);
  }


  ngOnInit() {
    super.ngOnInit();

    this.createForm.addControl('equation', this.fb.control(
      {value: [], disabled: true},
      [Validators.required],
    ));
    const equationFC = this.createForm.get('equation');
    const equationTouchChanges$ = createTouchStatusChanges(equationFC);
    this.equationError$ = merge(equationFC.statusChanges, equationTouchChanges$).pipe(
      map(() => equationFC.untouched ? null : equationFC.errors),
    );

    const buildingFC = this.createForm.get('basicInfo.building_id');
    const building$: Observable<string> = buildingFC.valueChanges.pipe(
      startWith(buildingFC.value),
      tap(() => {
        equationFC.reset({
          value: [],
          disabled: true,
        });
        this.createForm.updateValueAndValidity();
      }),
    );
    const meterTypeFC = this.createForm.get('basicInfo.meterType');
    const meterType$: Observable<MeterTypeDef> = meterTypeFC.valueChanges.pipe(
      startWith(meterTypeFC.value),
      tap(() => {
        equationFC.reset({
          value: [],
          disabled: true,
        });
        this.createForm.updateValueAndValidity();
      }),
    );

    this.metersEquConfig$ = combineLatest(building$, meterType$, this.loadMeters$).pipe(
      map(([building, meterType]) => ({building, meterType})),
      filter(({building}) => Boolean(building)),
      switchMap(({building, meterType}) => {
        const familyMeterSvc = this.meterServices.find(svc => svc instanceof <any>meterType.familyService);
        return familyMeterSvc.list({building_id: building}).pipe(
          catchError(err => EMPTY),
        );
      }),
      map(meters => meters.filter(m => m.meter_type === 'utility')),
      map(meters => ({
        options: meters,
        displayNameExtractor: elem => elem.name,
      })),
      tap(config => !!config.options.length && equationFC.enable()),
    );
  }


  protected generateBasicBodyData() {
    const {basicInfo, equation} = this.createForm.value;
    return {
      ...basicInfo,
      main_meter: false,
      virtual_equations: equation.map((e: EquElem) => ({
        operation: e.operator,
        meter_id: e.nativeElem.id,
      })),
      meter_type: 'virtual',
    };
  }
}
