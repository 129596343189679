import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UtilityBillsUpsertFormComponent } from './utility-bills-upsert-form/utility-bills-upsert-form.component';
import { UtilityBillsUpsertModalComponent } from './utility-bills-upsert-modal/utility-bills-upsert-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [UtilityBillsUpsertFormComponent, UtilityBillsUpsertModalComponent],
  exports: [UtilityBillsUpsertFormComponent, UtilityBillsUpsertModalComponent],
  entryComponents: [UtilityBillsUpsertModalComponent],
})
export class UtilityBillsUpsertModule { }
