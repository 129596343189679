import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, BehaviorSubject, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { SectionLoadingStates } from '../../utils/section-loading-states';
import { WeatherKwhQuery, WeatherKwhDataResponse } from './weather-kwh.models';

const startTimeFormatter = (d: Date) => moment(d).startOf('d').format(BE_DATETIME_NO_TIMEZONE_FORMAT);
const endTimeFormatter = (d: Date) => moment(d).startOf('d').hour(23).format(BE_DATETIME_NO_TIMEZONE_FORMAT);

@Injectable()
export class WeatherKwhDataService {

  kwhDataStates = new SectionLoadingStates();
  kwhData$ = new BehaviorSubject<WeatherKwhDataResponse>([]);
  latestQuery: WeatherKwhQuery;

  constructor(private http: HttpClient) {
  }

  loadKwhData(query: WeatherKwhQuery) {
    this.latestQuery = query;
    this.kwhDataStates.setLoadingState();
    return (this.http.post(`${environment.apiBase}/electricalDashboard/temperatureVersusKwh/queryAll`, {
      meter_list: query.meters,
      building_id: query.building_id,
      from_date: startTimeFormatter(query.startDate),
      to_date: endTimeFormatter(query.endDate),
    }) as Observable<WeatherKwhDataResponse>).pipe(
      tap(() => this.kwhDataStates.resetLoadingState()),
      tap((kwhData) => this.kwhData$.next(kwhData)),
      catchError(err => {
        this.kwhDataStates.setErrorState();
        return EMPTY;
      }),
    );
  }

  mapKwhData(data: WeatherKwhDataResponse) {
    const hoursPerGroup = 4;
    const hourRanges = [];
    for (let i = 0; i < 24 / hoursPerGroup; i++) {
      const start = i * hoursPerGroup;
      const end = start + hoursPerGroup - 1;
      hourRanges.push({start, end});
    }
    return hourRanges
      .map(r => ({
        ...r,
        id: `${r.start}-${r.end}`,
        name: `Hour ${r.start}-${r.end}`,
        points: data.filter(p => p.hour >= r.start && p.hour <= r.end),
      }));
  }

}
