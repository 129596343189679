import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { WeatherKwhScatterModule } from '../weather-kwh-scatter/weather-kwh-scatter.module';
import { WeatherKwhSectionComponent } from './weather-kwh-section/weather-kwh-section.component';
import { WeatherKwhFiltersComponent } from './weather-kwh-filters/weather-kwh-filters.component';
import { UtilityBillsSectionModule } from '../utility-bills-section/utility-bills-section.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WeatherKwhScatterModule,
    UtilityBillsSectionModule,
  ],
  declarations: [
    WeatherKwhSectionComponent,
    WeatherKwhFiltersComponent,
  ],
  exports: [
    WeatherKwhSectionComponent,
    WeatherKwhFiltersComponent,
  ],
})
export class WeatherKwhSectionModule { }
