import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, filter, switchMap, debounceTime } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Roles } from '../auth/user-group-guard.provider';
import { DataService } from '../data/data.service';
import { CustomerListElem } from './customers-list/customers-list.component';
import { Router } from '@angular/router';

const CUSTOMER_LOCALSTORAGE_KEY = 'selected_customer';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  loading = false;
  isAdmin: boolean;
  list: CustomerListElem[] = [];
  oldSelectedUser: CustomerListElem;
  customerNameFilter$ = new BehaviorSubject('');
  selectedCustomerSubj = new BehaviorSubject(this.selected);
  list$: Observable<CustomerListElem[]> = this.customerNameFilter$.pipe(
    debounceTime(500),
    tap(() => this.loading = true),
    switchMap((search) => {
      const user = this.auth.getUser() || { group: []};
      this.isAdmin = user.group ? user.group.indexOf(Roles.superuser) > -1 : user.is_superuser;
      if (this.isAdmin) {
        if(this.selected){
            this.chengeAccount(this.selected);
        }
        return this.data.customers.list({ keyword: search });
      }
      return of([]);
    }),
    tap((val: CustomerListElem[]) => {
      this.list = val;
      this.loading = false;
      if (!this.isAdmin || !this.selectedCustomerSubj.value) {
        this.select(this.selected);
        if(this.selected){
          this.chengeAccount(this.selected);
        }
      }
    })
  );

  selected$ = this.selectedCustomerSubj.pipe(
    filter((customer) => !!customer),
  );

  get selected() {
    const { isAdmin, list } = this;
    const storageCustomer = this.getCustomerFromMem();
    return isAdmin ? storageCustomer || list[0] : list[0];
  }

  constructor(private auth: AuthService, private data: DataService,
    private router: Router) {
  }

  setInitialSelectedCustomer() {
    return this.auth.loggedInUser$.pipe(
      switchMap(userProfile => {
        if (userProfile.is_superuser) {
          return this.setInitialSelectedCustomerSAPortal();
        } else {
          this.selectedCustomerSubj.next(userProfile);
          return of([]);
        }
      })
    );
  }

  filterList(searchValue) {
    const isDefined = typeof searchValue !== 'undefined' || searchValue !== null;
    const value = searchValue || (!isDefined && this.selected ? this.selected.name : '');
    this.customerNameFilter$.next(value);
  }

  select(newCustomer: CustomerListElem) {
    const customer = JSON.stringify(newCustomer);
    const currentCustomer = this.selectedCustomerSubj.value;
    const isNotSameCustomer = newCustomer && currentCustomer &&
                              currentCustomer.id !== newCustomer.id;

    const validCustomer = this.isValidCustomer(newCustomer);

    if (!!currentCustomer && !isNotSameCustomer || isNotSameCustomer && !validCustomer && !!currentCustomer) {
      return;
    }

    if (validCustomer && newCustomer.id != this.selected.id) {
      // when select new customer
      this.setCustomerInMem(newCustomer);
      this.data.customers.changeAccount({account_id: newCustomer.id}).subscribe(() =>
      {
        this.oldSelectedUser = newCustomer;
        this.router.navigate(
          ['/buildings'],{});
        this.selectedCustomerSubj.next(newCustomer);  
      });
    } else {
      const toBeSelectedCustomer = newCustomer && newCustomer.id ? this.list.filter(item => item.id == newCustomer.id)[0] : this.list[0];
      this.setCustomerInMem(toBeSelectedCustomer);
      this.selectedCustomerSubj.next(toBeSelectedCustomer);
    }
  }

  isValidCustomer(customer: CustomerListElem): boolean {
    const list = this.list || [];
    return !!list.find(({ id }) => customer.id === id);
  }

  private setInitialSelectedCustomerSAPortal() {
    const {selected} = this;
    this.filterList(selected && selected.name);
    // as the current implementation of $list already is setting the selected customer!!
    return this.list$;
  }

  private setCustomerInMem(customer: CustomerListElem) {
    const customerStr = JSON.stringify(customer);
    localStorage.setItem(CUSTOMER_LOCALSTORAGE_KEY, customerStr);
  }

  private getCustomerFromMem(): CustomerListElem {
    const customerStr = localStorage.getItem(CUSTOMER_LOCALSTORAGE_KEY);
    return JSON.parse(customerStr);
  }

  private chengeAccount(newUser){
    if(!this.oldSelectedUser || !localStorage.getItem(CUSTOMER_LOCALSTORAGE_KEY) || this.oldSelectedUser.id != newUser.id) {
      this.data.customers.changeAccount({account_id: newUser.id}).subscribe();
      this.oldSelectedUser = newUser;
      this.setCustomerInMem(newUser);
    }
  }
}
