import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import moment from 'moment';
import { Observable, EMPTY, zip, BehaviorSubject, Subscription, of } from 'rxjs';
import {
  tap,
  catchError,
  map,
  share,
  startWith,
  withLatestFrom,
  filter,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { UtilityBillsDataService } from '../../../data/utility-bills-data.service';
import { Building } from '../../../shared/models/building.model';
import { Meter } from '../../../shared/models/meter.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { UtilityBillsCreateStateService } from '../utility-bills-create-state.service';
import { nameLengthBoundaries } from './utility-bills-basic-info.models';

interface MeterOption extends Meter {
  building: Building;
}

interface UtilityCompany extends UtilityBillsLibraryModule.UtilityCompany {
  displayName: string;
}

@Component({
  selector: 'exa-utility-bills-create-basic-info',
  templateUrl: './utility-bills-create-basic-info.component.html',
  styleUrls: ['./utility-bills-create-basic-info.component.scss'],
})
export class UtilityBillsCreateBasicInfoComponent implements OnInit, OnDestroy {

  @Input() basicInfoFormGroup: FormGroup;
  basicData$ = new BehaviorSubject<{
    buildings: Building[];
    utilityCompanies: UtilityCompany[];
  }>(null);
  filteredCompanies$: Observable<UtilityCompany[]>;
  meters$: Observable<MeterOption[]>;

  displayUtilityCompany = (company: UtilityCompany) => company ? company.displayName : '';
  monthFC: FormControl;

  sectionLoadingStates = new SectionLoadingStates();
  reloadMethod: Function;

  nameLengthBoundaries = nameLengthBoundaries;
  subscriptionsSink = new Subscription();

  constructor(
    private fb: FormBuilder,
    private stateSvc: UtilityBillsCreateStateService,
    private data: DataService,
    private billsDataSvc: UtilityBillsDataService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptionsSink.unsubscribe();
  }

  ngOnInit() {
    this.monthFC = <FormControl> this.basicInfoFormGroup.get('month');
    const buildingFC = this.basicInfoFormGroup.get('building');
    const metersFC = this.basicInfoFormGroup.get('meters');
    const utilityCompanyFC = this.basicInfoFormGroup.get('utilityCompany');
    const serviceStartDate = this.basicInfoFormGroup.get('serviceStartDate');
    const serviceEndDate = this.basicInfoFormGroup.get('serviceEndDate');


    this.subscriptionsSink.add(
      this.stateSvc.customer$.pipe(
        tap(() => UtilityBillsCreateBasicInfoComponent.resetControl(buildingFC)),
        tap(() => this.loadBasicData()),
      ).subscribe()
    );

    this.subscriptionsSink.add(
      buildingFC.valueChanges.pipe(
        tap(building => {
          UtilityBillsCreateBasicInfoComponent.resetControl(metersFC, []);
          this.loadMeters(building);
        }),
      ).subscribe()
    );

    this.subscriptionsSink.add(
      this.monthFC.valueChanges.pipe(
        tap(v => {
          if (!v) {
            return;
          }
          serviceStartDate.setValue(moment(v).startOf('month').toDate());
          serviceEndDate.setValue(moment(v).endOf('month').toDate());
          serviceStartDate.updateValueAndValidity();
          serviceEndDate.updateValueAndValidity();
        }),
      ).subscribe(),
    );

    this.filteredCompanies$ = utilityCompanyFC.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(500),
      filter(v => typeof v === 'string'),
      withLatestFrom(this.basicData$.pipe(filter(d => !!d))),
      map(([value, basicData]) => {
        return basicData.utilityCompanies.filter(c => c.displayName.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      }),
    );
  }

  private loadBasicData() {
    const section = this.sectionLoadingStates;
    this.reloadMethod = this.loadBasicData.bind(this);
    section.setLoadingState();
    const utilityCompanies$ = this.billsDataSvc.getUtilityCompanies().pipe(
      map(companies => companies.map(c => ({...c, displayName: `[${c.eia_id}] ${c.name}`}))),
    );
    zip(
      (<Observable<Building[]>>this.data.buildings.list()),
      utilityCompanies$,
    ).pipe(
      map(([buildings, utilityCompanies]) => ({buildings, utilityCompanies})),
      tap(basicData => this.basicData$.next(basicData)),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState(err);
        return EMPTY;
      }),
      share(),
    ).subscribe();
  }

  private loadMeters(building: Building) {
    // We may now use a declarative way to define the meters$, but we are leaving it now as we may need to use separate API to list all meters
    const mappedBuilding = {...building, displayName: 'All Building Meters'};
    const section = this.sectionLoadingStates;
    this.reloadMethod = this.loadMeters.bind(this, building);
    section.setLoadingState();
    this.meters$ = of(building.meters).pipe(
      map((meters) => meters.map(m => ({
        ...m,
        building: mappedBuilding,
      }))),
      tap(() => section.resetLoadingState()),
    );
  }

  static resetControl(control: AbstractControl, value: any = '') {
    control.reset(value);
    control.updateValueAndValidity();
  }

}
