import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { OperationScheduleProfiles } from '../../../constants';
import { DataService } from '../../../data/data.service';
import { ProfileSchedule } from '../../../features/weakly-hours-schedule/weakly-hourse-schedule-edit/weakly-hours-schedule-edit.component';
import { Building, BuildingPlace } from '../../../shared/models/building.model';
import { Actions, BuildingOperationScheduleEditStateService } from '../building-operation-schedule-edit-state.service';
import { OperationScheduleDataService } from '../../../data/operation-schedule-data.service';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, Subject, zip } from 'rxjs';
import { WeaklyHoursProfileSchedule } from '../../../shared/models/operation-schedule/models';
import { filter } from 'rxjs/internal/operators/filter';

export interface WeaklyHoursScheduleEditData {
  building: Building;
}

@Component({
  selector: 'exa-building-operation-schedule-edit',
  templateUrl: './building-operation-schedule-edit.component.html',
  styleUrls: ['./building-operation-schedule-edit.component.scss'],
  providers: [BuildingOperationScheduleEditStateService],
  animations: [
    trigger('showAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('500ms', style({
          opacity: 1,
        })),
      ]),
      transition(':leave', [
        animate('500ms', style({
          opacity: 0,
        })),
      ])
    ]),
  ],
})
export class BuildingOperationScheduleEditComponent implements OnInit {

  mainSection = new SectionLoadingStates();
  loadingPlaceSchedule = new SectionLoadingStates();
  updatingSchedule = new SectionLoadingStates();
  building: Building;
  triggerLoadBasicData$ = new Subject();
  useGoogle = false;
  googlePlaces: BuildingPlace[];
  selectedPlace: BuildingPlace;
  selectedSchedule: ProfileSchedule;
  profiles = Object.values(OperationScheduleProfiles);
  selectedProfile = OperationScheduleProfiles.open;

  operationsScheduleChange$: Observable<WeaklyHoursProfileSchedule[]>;

  constructor(public dialogRef: MatDialogRef<BuildingOperationScheduleEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WeaklyHoursScheduleEditData,
              private operationsScheduleDataSvc: OperationScheduleDataService,
              private dataSvc: DataService,
              private stateSvc: BuildingOperationScheduleEditStateService) {
  }

  ngOnInit() {
    this.building = this.data.building;
    const basicDataSub = this.triggerLoadBasicData$.pipe(
      switchMap(() => this.loadBasicData()),
    ).subscribe();

    this.operationsScheduleChange$ = this.stateSvc.operationScheduleChange$.pipe(
      filter(change => change.action !== Actions.updateProfileSchedule),
      map(change => change.state),
      tap(schedules => {
        const selectedSchedule = schedules.find(s => s.profile.id === this.selectedProfile.id);
        this.selectedSchedule = {
          ...selectedSchedule,
          allocatedSchedules: schedules.filter(s => s.profile.id !== selectedSchedule.profile.id),
        };
      }),
    );

    this.triggerLoadBasicData$.next();
  }

  loadBasicData() {
    const section = this.mainSection;
    section.setLoadingState();
    return zip(
      this.operationsScheduleDataSvc.getSchedule(this.building.operation_schedule),
      this.dataSvc.buildings.getGooglePlaces(this.building.id),
    ).pipe(
      tap(() => section.resetLoadingState()),
      map(([schedule, places]) => ({schedule, places})),
      tap(({schedule, places}) => {
        this.googlePlaces = places;
        this.selectedPlace = places[0];
        this.stateSvc.updateSchedule(schedule.mappedData);
      }),
      catchError(err => {
        section.setErrorState();
        return EMPTY;
      }),
    );
  }

  loadOperationScheduleData() {
    return this.operationsScheduleDataSvc.getSchedule(this.building.operation_schedule).pipe(
      tap(res => this.stateSvc.updateSchedule(res.mappedData)),
    );
  }

  loadPlaceSchedule() {
    const section = this.loadingPlaceSchedule;
    section.setLoadingState();
    const loadPlaceScheduleSub = this.dataSvc.buildings.setGooglePlace(this.building.id, this.selectedPlace.place_id).pipe(
      switchMap(() => this.loadOperationScheduleData()),
      tap(() => section.resetLoadingState()),
      catchError(err => {
        section.setErrorState();
        return EMPTY;
      }),
    ).subscribe();
  }

  onProfileScheduleUpdated(profileSchedule: WeaklyHoursProfileSchedule) {
    this.stateSvc.updateProfileSchedule(profileSchedule);
  }

  onChangeSelectedProfile() {
     const selectedSchedule = this.stateSvc.operationsScheduleStore$.getValue()
      .find(s => s.profile.id === this.selectedProfile.id);
    this.selectedSchedule = {
      ...selectedSchedule,
      allocatedSchedules: this.stateSvc.operationsScheduleStore$.getValue().filter(s => s.profile.id !== this.selectedProfile.id),
    };
  }

  updateOperationSchedule() {
    this.updatingSchedule.setLoadingState();
    const currentSchedule = this.stateSvc.operationsScheduleStore$.getValue();
    this.operationsScheduleDataSvc.updateSchedule(this.building.operation_schedule, currentSchedule).pipe(
      tap(() => this.updatingSchedule.resetLoadingState()),
      catchError(err => {
        this.updatingSchedule.setErrorState();
        return EMPTY;
      }),
    ).subscribe(() => this.dialogRef.close(true));
  }

}
