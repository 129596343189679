import { Component, OnInit, OnChanges, SimpleChanges, Input, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Subscription, merge, EMPTY, Observable } from 'rxjs';
import { Customer } from '../../../shared/models/customer.model';
import { Meter } from '../../../shared/models/meter.model';
import { WeatherKwhHeatmapQuery } from '../../../shared/models/waste/weather-kwh-heatmap-query.models';
import { WeatherKwhHeatmapStateService } from '../weather-kwh-heatmap-state.service';
import { eventSubscriber } from '../../../dashboard/dashboard-common-child.interface';
import { DashboardService } from '../../../dashboard/dashboard.service';

@Component({
  selector: 'exa-weather-kwh-heatmap-section',
  templateUrl: './weather-kwh-heatmap-section.component.html',
  styleUrls: ['./weather-kwh-heatmap-section.component.scss'],
  providers: [WeatherKwhHeatmapStateService],
})
export class WeatherKwhHeatmapSectionComponent implements OnInit, OnDestroy {

  loadHeatMapData$ = new Subject<WeatherKwhHeatmapQuery>();
  @Input() customer: Customer;

  constructor(public heatMapStateSvc: WeatherKwhHeatmapStateService,
    private appService: DashboardService) {
    this.executeAction = this.executeAction.bind(this);
    eventSubscriber(appService.subscription, this.executeAction);
  }

  executeAction(params?) {
    this.heatMapStateSvc.loadHeatMapData({building_id: params.buildingId, from_date: params.fromDate, to_date: params.toDate}).subscribe();
  }

  ngOnDestroy(): void {
    eventSubscriber(this.appService.subscription, this.executeAction, true);
  }

  ngOnInit() {
    
  }
}