import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberInputMaskDirective } from './number-input-mask.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NumberInputMaskDirective],
  exports: [NumberInputMaskDirective],
})
export class NumberInputMaskModule { }
