import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { DataService } from '../data/data.service';
import { CustomersService } from '../customers/customers.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'exa-lpassword-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {

  resetPasswordLoading: boolean;
  resetPasswordError: boolean;
  errorMessage: string;
  user_id: string;
  token: string;

  resetPasswordForm = this.fb.group({
    'password': ['', [Validators.required, Validators.minLength(8)]],
    'confirmation': ['', [Validators.required, Validators.minLength(8)]],
  }, { validator: (form)=> {
      if(form.get('password').value !== form.get('confirmation').value){
        var error = { equal : "Confirm Password is not equal to Password" };
        form.get('confirmation').setErrors(error); 
        return error;
      }
      return null;
  }});

  constructor(
      private fb: FormBuilder,
      private router: Router,
      private auth: AuthService,
      private data: DataService,
      private customerService: CustomersService,
      private toastr: ToastrService,
      private route: ActivatedRoute
    ) {
  }

  getErrorMessage(controllerName: string) {
    const { errors } = this.resetPasswordForm.controls[controllerName];
    return errors.required
      ? `${controllerName} is required`
      : (errors.equal ? errors.equal
        : `Please enter a valid ${controllerName}`);
  }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.user_id = param.userId;
      this.token = param.token;
    });
  }

  resetPassword(){
    this.resetPasswordLoading = true;
    this.resetPasswordError = false;
    this.errorMessage = null;
    
    this.auth.resetPassword({user_id: this.user_id, token: this.token, password_confirmation: this.resetPasswordForm.value['confirmation'], password: this.resetPasswordForm.value['password']}).subscribe({
      next: (res) => {
        this.resetPasswordError = true;
        this.resetPasswordLoading = false;
        this.toastr.success("Password has been updated successfully");
        this.router.navigate(['/login']);
      },
      error: (errorRes) => {
        this.resetPasswordError = true;
        this.resetPasswordLoading = false;
        this.errorMessage =  errorRes.error && errorRes.error.Message ? errorRes.error.Message : errorRes.error;
      }
    });
  }
}
