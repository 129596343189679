import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingStatesBSubject, updateLoading, startLoading } from '@sigmaproit/loading-states';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap, map, delay } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import {
  GreenButtonConnectService,
  GbcCustodian,
} from '../../../data/green-button-connect/green-button-connect.service';
import { Customer } from '../../../shared/models/customer.model';
import {
  GreenButtonConnectManageSubscriptionComponent,
  GbcManageInfo,
} from '../green-button-connect-manage-subscription/green-button-connect-manage-subscription.component';

@Component({
  selector: 'exa-green-button-connect-subscriptions',
  templateUrl: './green-button-connect-subscriptions.component.html',
  styleUrls: ['./green-button-connect-subscriptions.component.scss']
})
export class GreenButtonConnectSubscriptionsComponent implements OnInit {

  @Input()
  set customer(customer: Customer) {
    this.customerBSub$.next(customer);
  }

  customerBSub$ = new BehaviorSubject<Customer>(null);
  customer$ = this.customerBSub$.pipe(
    filter(customer => Boolean(customer)),
  );

  custodiansLoadingStates$ = new LoadingStatesBSubject();
  loadCustodians$ = new BehaviorSubject(null);
  custodians$ = combineLatest(
    this.customer$,
    this.loadCustodians$,
  ).pipe(
    delay(0),
    startLoading(this.custodiansLoadingStates$),
    switchMap(
      () => this.gbcSvc.listCustodians().pipe(
        updateLoading(this.custodiansLoadingStates$, {swallowError: true}),
      )
    ),
    map(({custodians}) => custodians)
  );

  connectLoadingStates$ = new LoadingStatesBSubject();
  connect$ = new Subject<GbcCustodian>();
  onConnect$ = this.connect$.pipe(
    startLoading(this.connectLoadingStates$),
    switchMap(
      custodian => this.gbcSvc.connect(custodian.id).pipe(
        updateLoading(this.connectLoadingStates$, {swallowError: true}),
      ),
    ),
    tap((connectRes) => window.open((connectRes as any).url, '_blank')),
  );

  subSink = new Subscription();

  constructor(private dialog: MatDialog, private gbcSvc: GreenButtonConnectService) { }

  ngOnInit() {
    this.subSink.add(
      this.onConnect$.subscribe(),
    );
  }

  connectCustodian(custodian: GbcCustodian) {
    this.connect$.next(custodian);
  }

  manageCustodian(custodian: GbcCustodian) {
    return this.dialog.open(GreenButtonConnectManageSubscriptionComponent, {
      width: DIALOG_STATIC_WIDTHS.large,
      maxHeight: '100%',
      data: {
        custodian,
      } as GbcManageInfo,
      closeOnNavigation: true,
    });
  }

}
