import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { IFilterComp, IFilterParams, IDoesFilterPassParams } from 'ag-grid-community';
import { IFloatingFilterComp, IFloatingFilterParams, IFloatingFilter } from 'ag-grid-community/dist/lib/filter/floatingFilter';
import * as moment from 'moment';

@Component({
  selector: 'exa-list-base-date-filter',
  template: `
    <input
        type="date"
        [placeholder]="colDef.filterParams?.placeholder || 'filter...'"
        #input
        [(ngModel)]="value"
        (ngModelChange)="onValueChange()">
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBaseDateFilterComponent implements IFloatingFilter<any, any, any> {
  @ViewChild('input') $input: ElementRef;
  value = null;
  debounceTime = 500;
  timeout = null;
  colDef: {filterParams?: any } = {};
  private params: any;

  constructor() { }

  agInit(params: any) {
    this.params = params;
    this.colDef = params.column.colDef;
  }

  getGui() {
    return this.$input && this.$input.nativeElement;
  }

  onParentModelChanged(parentModel): void {
    const value = parentModel && moment(parentModel.value, 'YYYY-MM-DD');
    const now = moment();
    const isFuture = now < value;

    if (!parentModel || isFuture) {
        this.value = '';
    } else {
        this.value = parentModel.filter;
    }
  }

  onValueChange() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.params.onFloatingFilterChanged({model: this.buildModel()});
    }, this.debounceTime);
  }

  buildModel() {
    if (this.value === 0 || this.value === '') {
        return null;
    }

    if (moment(this.value, 'YYYY-MM-DD').valueOf() > moment().valueOf()) {
      this.$input.nativeElement.value = '';
      return null;
    }

    return {
        filterType: 'number',
        type: 'contains',
        filter: this.value,
        filterTo: null
    };
  }
}
