import { Injectable } from '@angular/core';
import { BehaviorSubject, zip, iif, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import {mapValues} from 'lodash';
import { UtilityBillsDataService } from '../../data/utility-bills-data.service';
import { Customer } from '../../shared/models/customer.model';

export interface BillBlueprint {
  services: {
    service: UtilityBillsLibraryModule.Service;
    initialData: { [fieldId: string]: any;};
  }[];
}

interface BasicData {
  lib: UtilityBillsLibraryModule.Library;
  blueprint?: BillBlueprint;
}

@Injectable()
export class UtilityBillsCreateStateService {

  customer$ = new BehaviorSubject<Customer>(null);
  basicData$ = new BehaviorSubject<BasicData>(null);
  loadBasicData$ = new BehaviorSubject<boolean>(true);

  set customer(customer: Customer) {
    this.customer$.next(customer);
  }

  constructor(private utilityData: UtilityBillsDataService) { }

  loadBasicData(blueprintBillId: string) {
    const blueprintBill$ = iif(() => !!blueprintBillId, this.utilityData.getBill(blueprintBillId), of(null));
    return zip(
      this.utilityData.getLibrary(),
      blueprintBill$,
    ).pipe(
      map(([lib, blueprintBill]) => ({
        lib,
        blueprint: blueprintBill? this.getBlueprint(blueprintBill, lib) : null,
      })),
      tap(basicData => this.basicData$.next(basicData)),
    );
  }

  private getBlueprint(bill: UtilityBillsLibraryModule.BillBody, lib: UtilityBillsLibraryModule.Library) {
    const services = Object.values(lib.services)
      .filter(s => !!bill[s.id])
      .map(s => ({
        service: s,
        initialData: mapValues((<UtilityBillsLibraryModule.BillBodyService>bill[s.id]).data, (v) => null),
      }));
    return {services};
  }

  save(billBody) {
    return this.utilityData.saveBill(billBody);
  }

}
