import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import { Building } from '../../../shared/models/building.model';
import { Meter } from '../../../shared/models/meter.model';
import { WeatherKwhHeatmapQuery } from '../../../shared/models/waste/weather-kwh-heatmap-query.models';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import {AllOrSearchConfig} from '../../../shared/components/all-or-search-filter/all-or-search-filter.component';
import {OnBuildingsSelected, onBuildingsSelectedFactory} from '../../../shared/components/all-or-search-filter/all-or-search-filter.utils';
import {
  multiBuildingsFilterBasicConfig,
  multiMetersFilterBasicConfig
} from '../../../shared/components/all-or-search-filter/all-or-search-filter.constants';

@Component({
  selector: 'exa-weather-kwh-heatmap-filters',
  templateUrl: './weather-kwh-heatmap-filters.component.html',
  styleUrls: ['./weather-kwh-heatmap-filters.component.scss']
})
export class WeatherKwhHeatmapFiltersComponent implements OnInit, OnChanges, OnDestroy {
  filtersForm: FormGroup;
  subscription = new Subscription();

  @Input() buildingsSearchFn: (name: string) => Observable<Building[]>;
  @Input() metersSearchFn: (name: string, buildingIds?: string[]) => Observable<Meter[]>;
  @Input() customerId: string;
  @Input() loadingStats: SectionLoadingStates;

  @Output() onSubmit = new EventEmitter<WeatherKwhHeatmapQuery>();

  buildingsInputConfig: AllOrSearchConfig<Building>;
  metersInputConfig: AllOrSearchConfig<Meter>;
  onBuildingsSelected: OnBuildingsSelected;

  today = moment().startOf('d').toDate();
  lastWeek = moment(this.today).subtract(1, 'w').toDate();

  constructor(private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges({customerId}: SimpleChanges) {
    if (!customerId.firstChange && customerId.currentValue !== customerId.previousValue) {
      const buildingsFC = this.filtersForm.get('buildings') as FormControl;
      buildingsFC.reset(null);
    }
  }

  ngOnInit() {
    this.buildingsInputConfig = {
      searchItems: this.buildingsSearchFn,
      ...multiBuildingsFilterBasicConfig,
    };

    this.metersInputConfig = {
      searchItems: this.metersSearchFn,
      ...multiMetersFilterBasicConfig,
    };

    this.filtersForm = this.fb.group({
      buildings: [null],
      meters: [null],
      timeRange: this.fb.group({
        startDate: [this.lastWeek, [Validators.required]],
        endDate: [this.today, [Validators.required]],
      }),
    });

    const metersFC = this.filtersForm.get('meters') as FormControl;
    this.onBuildingsSelected = onBuildingsSelectedFactory(this.metersSearchFn, metersFC, config => {
      this.metersInputConfig = {
        ...this.metersInputConfig,
        ...config,
      };
    });

    const buildingsControl = <FormControl> this.filtersForm.get('buildings');
    buildingsControl.valueChanges.subscribe(buildingsOptions => this.onBuildingsSelected(buildingsOptions));
  }

  submit() {
    const {meters, buildingId, timeRange} = this.filtersForm.value;
    const {startDate, endDate} = timeRange;
    this.onSubmit.emit({
      building_id: buildingId,
      from_date: startDate,
      to_date: endDate,
    });
  }


}
