import { Component, OnInit } from '@angular/core';
import { ListBaseComponent } from '../../shared/components/list-base/list-base.component';
import { Customer } from '../../shared/models/customer.model';
import { DataService } from '../../data/data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, ERoles } from '../../auth/auth.service';
import { MatDialog } from '@angular/material';
import {
  DeleteConfirmationDialogComponent,
  IDeleteConfirmationData
} from '../../shared/components/delete-confirmation-dialog.component';
import { tap } from 'rxjs/internal/operators';
import { SideNavService } from '../../layout/side-nav.service';

interface IUser {
  id: string;
  name: string;
  customer: Customer;
}

const customFilterOptions = {
  suppressMenu: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  floatingFilterComponent: 'ListBaseFilterComponent',
};

@Component({
  selector: 'exa-customers-users-list',
  templateUrl: './customers-users-list.component.html',
  styleUrls: ['./customers-users-list.component.scss']
})
export class CustomersUsersListComponent extends ListBaseComponent<IUser> implements OnInit {
  filterModelMap = {
    'user.email': 'user__email__icontains',
    'customer.name': 'customer__name',
    'first_name': 'first_name__icontains',
    'last_name': 'last_name__icontains',
  };

  displayedColumns: any[] = [
    {
      headerName: 'First name',
      field: 'first_name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions
    },

    {
      headerName: 'Last name',
      field: 'last_name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions
    },



    {
      headerName: 'Email',
      field: 'user.email',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      ...customFilterOptions,
    },

    {
      headerName: 'Customer',
      field: 'customer.name',
      filter: 'agTextColumnFilter',
      filterParams: { keep: true },
      cellRenderer: 'ListBaseLinkComponent',
      ...customFilterOptions
    },

    {
      headerName: 'group',
      field: 'user.group',
      filter: false,
      // filter: 'agTextColumnFilter',
      // filterParams: { keep: true },
      ...customFilterOptions
    },

    {
      headerName: 'status',
      field: 'user.status',
      filter: false,
      // filter: 'agTextColumnFilter',
      // filterParams: { keep: true },
      ...customFilterOptions
    },

    { headerName: '', field: 'action', cellRenderer: 'ListBaseActionsComponent' },
  ];

  constructor(
      private data: DataService,
      private dialog: MatDialog,
      auth: AuthService,
      sidenav: SideNavService
    ) {
    super(auth, sidenav);
  }

  ngOnInit() {
    // this.gridOptions = Object.assign({}, this.gridOptions, { floatingFilter: false });
  }



  getDataObservable(reqParams: any): Observable<any> {
    return this.data.customers.users({...reqParams }).pipe(map((res: any) => {
      res.results = res.results || [];
      res.results.forEach(item => {
        item.user.group = item.user.group.map(g => ERoles[g]).join(', ');
        item.user.status = item.user.is_active ? 'active' : 'deactivated';
        item.gridLinks = [
          { key: 'first_name', route: (data) => [`/customers/${data.customer.id}/users`, data.id]},
          { key: 'last_name', route: (data) => [`/customers/${data.customer.id}/users`, data.id]},
          { key: 'customer.name', route: (data) => ['/customers', data.customer.id]}
        ];

        item.gridActions = [
          {
            type: 'icon',
            icon: 'delete',
            text: 'delete',
            // route: ['/customers/users/edit/' + item.id],
            method: 'onClickDeleteUser',
          },
        ];
      });
      return res;
    }));
  }

  onClickDeleteUser(user) {
    const dialogData: IDeleteConfirmationData = {
      entity: user,
      deleteRequest: this.onDeleteUser.bind(this),
      body: `Are you sure you want to delete the user: ${user.name}.
            click confirm to continue otherwise click cancel`,
      success: `user with name ${user.name} has been deleted successfully`,
      failure: `failed to delete user with name ${user.name}. please try again`,
      route: ['/customers', 'users'],
    };
    this.dialog.open(DeleteConfirmationDialogComponent, { data: dialogData, minWidth: '30%' });
  }

  onDeleteUser(user) {
    return this.data.users.delete(user.id)
      .pipe(tap(() => this.onGridReady(this.gridParams)));
  }
}
