import { Component } from '@angular/core';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-weather-kwh',
  templateUrl: './weather-kwh.component.html',
  styleUrls: ['./weather-kwh.component.scss']
})
export class WeatherKwhComponent {

  constructor(public customer: CustomersService) { }

}
