import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { pageParam } from 'ex-library';
import moment from 'moment';
import { Observable, zip } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BE_DATETIME_NO_TIMEZONE_FORMAT } from '../../constants';
import { extractFileExtension } from '../../shared/helpers/data.helpers';
import { Meter, VirtualMeter, VirtualMeterEquationElement, PaginatedMeters } from '../../shared/models/meter.model';
import { PaginationResponse } from '../../shared/models/pagination-response.model';
import { ApiService } from '../api.service';
import { MeterMediaType, MeterIdrResponse } from './models/media-types.models';

export const METER_SERVICES = new InjectionToken<MediaTypeMeterBaseService[]>('different media types meter service');
export const METER_SERVICE = new InjectionToken<MediaTypeMeterBaseService>('media type meter service');

export interface VirtualMeterEqElemMapped<T extends Meter> extends VirtualMeterEquationElement{
  meter: MappedMeter<T>;
}

export interface VirtualMeterBasicInfo<T extends Meter = any> extends VirtualMeter {
  equation: VirtualMeterEqElemMapped<T>[];
}

export interface MeterDef {
  type: MeterMediaType;
  service: MediaTypeMeterBaseService;
  familyService: MediaTypeMeterBaseService;
  name: string;
  route: string;
  listLink: string;
  defaultUnit: string;
}

export type MappedMeter<T extends Meter> = T & {
  meterDef?: MeterDef;
}

export class MediaTypeMeterBaseService <T extends Meter = any, IdrPoint = any> {
  apiBase = environment.apiBase;

  get api() {
    return `${this.apiBase}/meters/${this.mediaType}`;
  }

  constructor(protected http: HttpClient, protected apiSvc: ApiService, public mediaType: MeterMediaType) {
  }

  protected getMeterDef(meter: Meter): MeterDef {
    throw new Error("'bindMeterType' method is not implemented");
  }

  create(body) {
    return this.http.post(`${this.api}/setup`, body);
  }

  update(id, body) {
    return this.http.put(`${this.api}/update`, { ...body, id: id });
  }

  delete(id) {
    return this.http.delete(`${this.api}/${id}`);
  }

  updateUtility(id, body) {
    return this.http.put(`${this.api}/updateUtilityId`, { id: id, ...body });
  }

  protected mapMeter<T extends Meter = Meter>(meter: Meter) {
    return {
      ...meter,
      meterDef: this.getMeterDef(meter),
    } as MappedMeter<T>;
  }

  criteriaMeter(params?): Observable<T[]> {
    return this.http.post(`${this.apiBase}/criteria/meter/queryPage?pageNumber=${params.page + 1}&pageSize=${params.pageSize}`, params).pipe(
      map(res => {
        if (params && params.hasOwnProperty(pageParam)) {
          const paginatedMeters = res as PaginationResponse<Meter>;
          return {
            ...paginatedMeters,
            results: paginatedMeters.results.map(this.mapMeter.bind(this)),
          };
        }
        else {
          const meters = res as Meter[];
          return meters.map(this.mapMeter.bind(this));
        }
      }),
      catchError(err => {
        console.error(err);
        return err;
      })
    ) as Observable<T[]>;
  }

  list(params?): Observable<T[]> {
    return this.http.post(`${this.apiBase}/meters/electrical/lookup/queryPage?pageNumber=${params && params.page ? params.page : 1}&pageSize=${params && params.pageSize ? params.pageSize : 10}`, params).pipe(
      map(res => {
        if (params && params.hasOwnProperty(pageParam)) {
          const paginatedMeters = res as PaginationResponse<Meter>;
          return {
            ...paginatedMeters,
            results: paginatedMeters.results.map(this.mapMeter.bind(this)),
          };
        }
        else if(res){
          const paginatedMeters = res as PaginationResponse<Meter>;
          return paginatedMeters.results.map(this.mapMeter.bind(this)) as Meter[];
        } 
        else {
          const meters = res as Meter[];
          return meters.map(this.mapMeter.bind(this));
        }
      }),
      catchError(err => {
        console.error(err);
        return err;
      })
    ) as Observable<T[]>;
  }

  filterByBuildings(buildings: string[]): Observable<Meter[]> {
    return this.http.post<Meter[]>(`${this.api}/filter_by_buildings`, {'buildings_id': buildings});
  }

  filterByName(name: string, buildingIds?, params?): Observable<Meter[]> {
    return this.http.post<Meter[]>(`${this.apiBase}/meters/electrical/lookup/queryPage?pageNumber=1&pageSize=20`, {
      name: name,
      ...(buildingIds ? {building_id: buildingIds.join(',')} : {}),
      ...params,
    });
  }

  listUtility(params?): Observable<Meter[]> {
    return (this.list(params) as Observable<Meter[]>)
      .pipe(
        map(meters => meters.filter(m => m.meter_type === 'utility')),
      );
  }

  details<T extends Meter = Meter>(id: string) {
    return this.http.get<Meter>(`${this.apiBase}/meters/electrical/detail/${id}`).pipe(
      map(meter => this.mapMeter(meter))
    );
  }

  importMeters(filepath: string, params?) {
    return this.http.post(`${this.apiBase}/import-meters/${this.mediaType}`, {filepath}, { params });
  }

  importMetersTemplate(params?) {
    return this.http.get<{ path: string, url: string }>(`${this.apiBase}/meters-template/${this.mediaType}`, {params}).pipe(
      switchMap(({path, url}) => {
        const extension = extractFileExtension(path);
        return this.apiSvc.downloadFile(url, extension);
      }),
      map(({ blob, extension}) => {
        return {
          blob,
          name: `${this.mediaType}-meters-template.${extension}`,
        };
      }),
    );
  }

  getVMBasicInfo(meter: VirtualMeter): Observable<VirtualMeterBasicInfo> {
    const vrEqu = meter.virtual_equations;
    const metersIds = vrEqu.map(m => m.meter_id);
    return this.list({ids: metersIds}).pipe(
      map((mList: Meter[]) => mList.map(m => ({
        meter: m,
        ...(vrEqu.find(eqElem => eqElem.meter_id === m.id)),
      }))),
      map(mappedMList => ({
        ...meter,
        equation: mappedMList,
      }))
    );
  }

  calcVmWaste(meter: VirtualMeter): Observable<{ task_id: string }> {
    const url = `${this.apiBase}/meters/virtualMeterIdr`;
    return <Observable<{ task_id: string }>> this.http.post(url, { id: meter.id });
  }

  getIdr(meters: string[], startDate: Date, endDate: Date, resolution: string) {
    return this.http.post<MeterIdrResponse<IdrPoint>>(`${environment.apiBase}/meters-idr/${this.mediaType}`, {
      meters,
      start_date: moment(startDate).format(BE_DATETIME_NO_TIMEZONE_FORMAT),
      end_date: moment(endDate).format(BE_DATETIME_NO_TIMEZONE_FORMAT),
      resolution,
    });
  }

}
