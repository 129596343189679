import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { tap, filter } from 'rxjs/operators';
import { DIALOG_STATIC_WIDTHS } from '../../../constants';
import {
  UtilityBillsAddElementModalComponent,
  ElementsModalData,
} from '../utility-bills-add-element-modal/utility-bills-add-element-modal.component';
import { createFieldFormGroup } from './utility-bills-create-service.models';

@Component({
  selector: 'exa-utility-bills-create-service',
  templateUrl: './utility-bills-create-service.component.html',
  styleUrls: ['./utility-bills-create-service.component.scss']
})
export class UtilityBillsCreateServiceComponent implements OnInit {

  service: UtilityBillsLibraryModule.Service;
  attributesFormGroups: FormGroup[];

  @Input() serviceFormGroup: FormGroup;
  @Output() remove = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.service = this.serviceFormGroup.controls.service.value;
    this.attributesFormGroups = (<FormGroup[]>(<FormArray>this.serviceFormGroup.controls.fields).controls);
  }

  addField() {
    const addedFieldsIds = (<any[]>this.serviceFormGroup.controls.fields.value)
      .map(fieldFormVal => (<UtilityBillsLibraryModule.Field>fieldFormVal.field).id);
    const notAddedFields = Object.values(this.service.allowedFields)
      .filter(field => addedFieldsIds.indexOf(field.id) === -1);
    const dialogRef = this.dialog.open(UtilityBillsAddElementModalComponent, {
      width: DIALOG_STATIC_WIDTHS.small,
      data: <ElementsModalData<UtilityBillsLibraryModule.Field>> {
        title: 'Add New Attribute',
        addBtnText: 'Add',
        selectPlaceholder: 'Select an attribute',
        elements: notAddedFields,
      },
    });

    dialogRef.afterClosed().pipe(
      filter(field => !!field),
      tap(field => {
        const fieldFormGroup = createFieldFormGroup(field);
        (<FormArray>this.serviceFormGroup.controls.fields).push(fieldFormGroup);
      }),
    ).subscribe();
  }

  removeField(fieldIndex: number) {
    (<FormArray>this.serviceFormGroup.controls.fields).removeAt(fieldIndex);
  }

}
