import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BE_DAY_DATE_FORMAT } from '../../../constants';
import { Meter } from '../../../shared/models/meter.model';
import { SectionLoadingStates } from '../../../utils/section-loading-states';
import { UtilityBillsResponse, UtilityBillsCreateRequest } from '../../utility-bills-section/utility-bills.models';
import { BillType } from '../../utility-bills-section/utils-bills.definitions';
import { UtilityBillsUpsertFormComponent } from '../utility-bills-upsert-form/utility-bills-upsert-form.component';

export interface BillUpsertModalData {
  billType: BillType;
  month: number;
  year: number;
  utilityMeter: Meter;
  bill: UtilityBillsResponse;
}

interface MetaData {
  month: string;
  billType: string;
  action: string;
}

const billApiUrl = `${environment.apiBase}/utility-bills`;

@Component({
  selector: 'exa-utility-bills-upsert-modal',
  templateUrl: './utility-bills-upsert-modal.component.html',
  styleUrls: ['./utility-bills-upsert-modal.component.scss'],
})
export class UtilityBillsUpsertModalComponent implements OnInit {

  updatingLoadingStats = new SectionLoadingStates();
  title: string;
  metaData: MetaData;
  billInfoForm: FormGroup;
  errors: any;

  labelsMap = {
    service_start_date: 'Start Date',
    service_end_date: 'End Date',
  };

  get disableForm() {
    const pristineForms = this.billInfoForm.pristine && this.billDataForm.billForm.pristine;
    const invalidData = this.billInfoForm.invalid || !this.billDataForm.hasTruthyValue;
    return this.updatingLoadingStats.loading || pristineForms || invalidData;
  }

  @ViewChild(UtilityBillsUpsertFormComponent) billDataForm: UtilityBillsUpsertFormComponent;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<UtilityBillsUpsertModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: BillUpsertModalData,
  ) {
  }

  ngOnInit() {
    this.metaData = this.generateMetaData();
    const { month, year } = this.modalData;
    const { service_start_date, service_end_date } = this.modalData.bill || <UtilityBillsResponse> {};
    const predictedStart = moment().day(0).month(month - 1).year(year);
    const predictedEnd = moment(predictedStart).add(1, 'M').subtract(1, 'd');
    this.billInfoForm = this.fb.group({
      serviceTimeRange: this.fb.group({
        startDate: [this.modalData.bill ? new Date(service_start_date) : predictedStart.toDate(), [Validators.required]],
        endDate: [this.modalData.bill ? new Date(service_end_date) : predictedEnd.toDate(), [Validators.required]],
      }),
    });
  }

  private generateMetaData(): MetaData {
    return {
      billType: this.modalData.billType,
      month: moment.months('MMMM', this.modalData.month - 1),
      action: this.modalData.bill ? 'Update' : 'Add',
    };
  }

  upsert() {
    this.updatingLoadingStats.setLoadingState();
    const {serviceTimeRange} = this.billInfoForm.value;
    const basicUpsertData = <UtilityBillsCreateRequest> {
      service_start_date: moment(serviceTimeRange.startDate).format(BE_DAY_DATE_FORMAT),
      service_end_date: moment(serviceTimeRange.endDate).format(BE_DAY_DATE_FORMAT),
      bill_data: this.billDataForm.billForm.value,
    };
    const upsert$ = this.modalData.bill ?
      this.update(this.modalData.bill.id, basicUpsertData) :
      this.create(basicUpsertData);
    upsert$.subscribe((res) => this.dialogRef.close(res));
  }

  private update(billId: string, body: UtilityBillsCreateRequest) {
    return this.http.patch(`${billApiUrl}/${billId}`, body).pipe(
      tap(() => this.updatingLoadingStats.resetLoadingState()),
      catchError(err => {
        this.errors = err.error;
        this.updatingLoadingStats.setErrorState();
        return EMPTY;
      }),
    );
  }

  private create(body: UtilityBillsCreateRequest) {
    const {year, month, utilityMeter, billType} = this.modalData;
    return this.http.post(`${billApiUrl}`, <UtilityBillsCreateRequest> {
      ...body,
      utility_meter_id: utilityMeter.id,
      bill_type: billType,
      year,
      month,
    }).pipe(
      tap(() => this.updatingLoadingStats.resetLoadingState()),
      catchError(err => {
        this.errors = err.error;
        this.updatingLoadingStats.setErrorState();
        return EMPTY;
      }),
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
