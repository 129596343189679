import { Component, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import Selectable from 'selectable.js';
import { WeaklyHoursProfileSchedule } from '../../../shared/models/operation-schedule/models';
import { WEEKDAYS } from '../constants';


export interface ProfileSchedule extends WeaklyHoursProfileSchedule {
  allocatedSchedules: WeaklyHoursProfileSchedule[];
}

@Component({
  selector: 'exa-weakly-hours-schedule-edit',
  templateUrl: './weakly-hours-schedule-edit.component.html',
  styleUrls: ['./weakly-hours-schedule-edit.component.scss']
})
export class WeaklyHoursScheduleEditComponent implements AfterViewInit, OnDestroy {

  hours = new Array(24).fill(null).map((v, i) => i);
  quarters = [0, 15, 30, 45];
  days = WEEKDAYS;
  preventedQuartersIds: string[];
  nameColumn = 'name';
  displayedColumns = [].concat([this.nameColumn], this.hours.map(h => h + ''));
  selectable: any;
  selectableItems: NodeListOf<any>;
  @ViewChild('scheduleTable', {read: ElementRef}) scheduleTableElem: ElementRef<HTMLTableElement>;

  _schedule: ProfileSchedule;
  selectedCellStyle: { [key: string]: string | number; };
  selectingCellStyle: { [key: string]: string | number; };

  customTrackBy = (hour: number) => hour;

  @Output() scheduleUpdated = new EventEmitter<WeaklyHoursProfileSchedule>();

  @Input()
  set schedule(s: ProfileSchedule) {
    this._schedule = s;
    this.selectedCellStyle = {
      'background-color': s.profile.color,
    };
    this.selectingCellStyle = {
      'background-color': s.profile.contrastColor,
      transition: 'none',
    };
    this.setPreventedQuartersIds();
    setTimeout(() => {
      this.updateSelectableItems();
      this.updateSelectedItems();
    });
  }

  ngAfterViewInit(): void {
    this.selectableItems = this.scheduleTableElem.nativeElement.querySelectorAll('.selectable');
    this.selectable = new Selectable({
      filter: this.selectableItems,
      toggle: 'drag',
    });

    this.selectable.on('end', this.onSelectChange.bind(this));

    setTimeout(() => {
      this.selectable.update();
      this.updateSelectedItems();
    });
  }

  ngOnDestroy(): void {
    this.selectable.destroy();
  }

  cellStyle(cellElem: any) {
    const classList = cellElem.classList;
    const isSelected = classList.contains('ui-selected');
    const isSelecting = classList.contains('ui-selecting');
    return isSelected ? this.selectedCellStyle : isSelecting ? this.selectingCellStyle: {};
  }

  private updateSelectableItems() {
    if (!this.selectable) {
      return;
    }
    this.selectable.remove(this.selectableItems);
    this.selectableItems = this.scheduleTableElem.nativeElement.querySelectorAll('.selectable');
    this.selectable.add(this.selectableItems);
  }

  private updateSelectedItems() {
    if (!this.selectable) {
      return;
    }

    this.selectable.clear();

    const selectedClassNames = WeaklyHoursScheduleEditComponent.convertScheduleToClasses(this._schedule);
    const selectedCells = selectedClassNames.map(cls => this.scheduleTableElem.nativeElement.querySelector(`.${cls}`));
    this.selectable.select(selectedCells);
  }

  private setPreventedQuartersIds() {
    this.preventedQuartersIds = this._schedule.allocatedSchedules.map(s => {
      return Object.entries(s.scheduleData)
        .map(([dayIndex, quarters]) => {
          return quarters.map(q => `d-${dayIndex}-h-${q.hour}-m-${q.min}`);
        })
        .reduce((accIds, dayIds) => accIds.concat(dayIds), []);
    })
      .reduce((accIds, profileIds) => accIds.concat(profileIds), []);
  }

  private onSelectChange() {
    const selectedNodes = this.selectable.getSelectedNodes();
    const selectionClasses = selectedNodes.map(node => {
      return Array.from((node as HTMLElement).classList).find(cls => cls.startsWith('d-'));
    });
    const updatedSchedule = WeaklyHoursScheduleEditComponent.convertClassesToSchedule(selectionClasses);
    this._schedule = {
      ...this._schedule,
      scheduleData: updatedSchedule,
    };
    this.scheduleUpdated.emit(this._schedule);
  }

  static convertScheduleToClasses(schedule: WeaklyHoursProfileSchedule) {
    return Object.entries(schedule.scheduleData).reduce((accClasses, [dayIndex, schedule]) => {
      const dayClassNames = schedule.map(hourQuarter => `d-${dayIndex}-h-${hourQuarter.hour}-m-${hourQuarter.min}`);
      return dayClassNames && dayClassNames.length ? [].concat(accClasses, dayClassNames) : accClasses;
    }, []);
  }

  static convertClassesToSchedule(classes: string[]) {
    return classes.reduce((accSchedule, clsName) => {
      const splitedClassName = clsName.split('-');
      const day = splitedClassName[1];
      const hour = splitedClassName[3];
      const min = splitedClassName[5];
      let daySchedule = accSchedule[day];
      const quarter = {hour: parseInt(hour), min: parseInt(min)};
      daySchedule = daySchedule ? [].concat(daySchedule, quarter) : [quarter];
      return {
        ...accSchedule,
        [day]: daySchedule,
      };
    }, {});
  }
}
