import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { getRoutesByGroup, GROUPS } from '../../auth/user-group-guard.provider';
import { SideNavService } from '../../layout/side-nav.service';

const ROUTES = [
  {
    id: 0,
    name: 'Weather Comparison',
    link: ['/weather'],
    availableOn: GROUPS.all,
  },

  {
    id: 1,
    name: 'Weather vs kWh',
    link: ['/weather/weather-kwh'],
    availableOn: GROUPS.all,
  },

  {
    id: 2,
    name: 'Weather-kWh Heatmap',
    link: ['/weather/weather-kwh-heatmap'],
    availableOn: GROUPS.all,
  },
];

@Component({
  selector: 'exa-weather-main-page',
  templateUrl: './weather-main-page.component.html',
  styleUrls: ['./weather-main-page.component.scss'],
})
export class WeatherMainPageComponent implements OnInit {

  routes: any[];

  constructor(
    public menu: SideNavService,
    private auth: AuthService,
  ) {
  }

  ngOnInit() {
    const user = this.auth.getUser();
    this.routes = getRoutesByGroup(ROUTES, (user && user.group) || []);
  }

}
