import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, throwError, Subscription } from 'rxjs';
import { DataService } from '../../data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize, catchError, map, switchMap, tap } from 'rxjs/operators';
import { ERoles } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'exa-customers-users-details',
  templateUrl: './customers-users-details.component.html',
  styleUrls: ['./customers-users-details.component.scss']
})
export class CustomersUsersDetailsComponent implements OnInit, OnDestroy {
  loading$ = new BehaviorSubject(false);
  loadingError = false;
  userSub: Subscription;
  user$: Observable<any>;
  userId: any;
  user: any;
  customer: any;
  activeToggleLoading = false;
  notes$ = new BehaviorSubject([]);

  constructor(
    private data: DataService,
    private toastr: ToastrService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    const params = this.route.snapshot.params;
    this.loading$.next(true);
    this.loadingError = false;

    if (this.userSub) {
      this.userSub.unsubscribe();
    }

    this.user$ = this.data.users
      .getUser(params.id, params.customer).pipe(
        map((userData: any) => {
          userData.user.group = userData.user.group.map(g => ERoles[g]);
          return userData;
        }),
        tap((res: any) => this.notes$.next(res.notes || [])),
        finalize(() => this.loading$.next(false)),
      );

    this.userSub = this.user$.subscribe((res) => {
        this.user = {...res, ...res.user, id: res.id};
        this.customer = res.customer;
      }, (err) => {
        this.loading$.next(false);
        this.loadingError = true;
      });
  }

  ngOnDestroy(): void {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  activeToggle({ user }) {
    let toggleSub: Subscription;
    const params = this.route.snapshot.params;
    const isActive = user.is_active;
    const id = this.route.snapshot.params.id;
    const toggle$ = isActive
      ? this.data.users.deactivate(id)
      : this.data.users.activate(id);

    this.activeToggleLoading = true;
    toggleSub = toggle$
      .pipe(
        switchMap(() => this.data.users.getUser(params.id, params.customer)),
        map((userData: any) => {
          userData.user.group = userData.user.group.map(g => ERoles[g]);
          return userData;
        }),
        tap(() => this.activeToggleLoading = false),
      )
      .subscribe((res: any) => {
        this.user = res;
        this.toastr.success(isActive
          ? 'User has been deactivated successfully'
          : 'User has been activated successfully');
        toggleSub.unsubscribe();
      }, (err) => {
        this.toastr.error(`Something went wrong please try again.`);
      });
  }


  onNewNote(note) {
    const notes = this.notes$.value || [];
    this.notes$.next([note].concat(notes));
  }

  onDeleteNote(id) {
    this.notes$.next(this.notes$.value.filter(i => i.id !== id));
  }
}
