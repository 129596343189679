import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AllOrSearchConfig} from '../all-or-search-filter/all-or-search-filter.component';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {config, Subscription} from 'rxjs';
@Component({
  selector: 'exa-custom-search-select',
  template: `
    <mat-form-field [ngStyle]="isDynamicWidth ? {'width.ch': inputText.value.length, 'min-width.ch': 18, 'max-width': 15 + 'vw'} : {}" style="width: 100%;">
      <mat-label>{{config.title}}</mat-label>
      <input id="searchInputField" type="text"
             [placeholder]="config.inputPlaceholder"
             matInput
             [formControl]="itemFC"
             [matAutocomplete]="auto" #inputText>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredItems$ | async" [value]="option">
          {{option | displayNameExtractor: config.displayNameExtractorFn}}
        </mat-option>
      </mat-autocomplete>
      <ng-content select="mat-error"></ng-content>
    </mat-form-field>
    <mat-icon class="close-icon" (click)="clearInput()">close</mat-icon>
  `,
  styleUrls: ['./custom-search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomSearchSelectComponent,
    }
  ]
})
export class CustomSearchSelectComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {

  itemFC = new FormControl();

  filteredItems$ = this.itemFC.valueChanges.pipe(
    debounceTime(300),
    switchMap(searchText => this.config.searchItems(searchText)),
  );

  itemSelected$ = this.itemFC.valueChanges.pipe(
    filter(value => !value || typeof value !== 'string'),
    tap(() => this.pushChanges()),
  );

  clearInput(){
    this.itemFC.setValue("");
  }

  onChange = (item: T) => undefined;

  subSink = new Subscription();

  displayFn = (itemOrKeyword) => itemOrKeyword && typeof itemOrKeyword !== 'string' ? this.config.displayNameExtractorFn(itemOrKeyword) : itemOrKeyword;

  @Input() config: AllOrSearchConfig<T>;
  @Input() isDynamicWidth: boolean;

  constructor() {
  }

  ngOnInit() {
    this.subSink.add(this.itemSelected$.subscribe());
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(item: T): void {
    this.itemFC.setValue(item);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  pushChanges() {
    const selectionValue = this.itemFC.value;
    const {valueOnChangeExtractorFn} = this.config;
    const value = valueOnChangeExtractorFn ? valueOnChangeExtractorFn(selectionValue) : selectionValue;
    this.onChange(value);
  }


}
