import { Component, Inject, OnInit } from '@angular/core';
import { ImportModalBase } from '../import-modal-base';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../data/data.service';
import { Meter } from '../../shared/models/meter.model';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';

@Component({
  selector: 'exa-import-meter-threshold-efficiency',
  templateUrl: './import-meter-threshold-efficiency.component.html',
  styles: [],
})
export class ImportMeterThresholdEfficiencyComponent extends ImportModalBase {

  constructor(
      public dialogRef: MatDialogRef<ImportMeterThresholdEfficiencyComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: { meter: Meter }, protected fileSaver: FileSaverService,
      protected toastr: ToastrService,
      protected data: DataService,
      protected auth: AuthService,
      private customers: CustomersService,
    ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }


  setConfig() {
    const signedUrlMethod = () => this.data.meters
      .importThresholdEfficiencyUrl(this.modalData.meter.id, this.selectedFile, this.customerId());

    const importMethod = () => this.data.meters.importThresholdEfficiency(this.modalData.meter.id, this.signedUrl.path);

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.data.meters.importThresholdEfficiencyTemplate(),
      acceptedFileFormats: '.csv, text/csv',
      onFinish: () => this.close(),
      url: ""
    };
  }


  customerId() {
    return this.customers.selected.id;
  }
}
