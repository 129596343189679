import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { MonthPickerModule } from '../month-picker/month-picker.module';
import { UtilityBillsCreateComponent } from './utility-bills-create/utility-bills-create.component';
import { UtilityBillsCreateBasicInfoComponent } from './utility-bills-create-basic-info/utility-bills-create-basic-info.component';
import { UtilityBillsAddElementModalComponent } from './utility-bills-add-element-modal/utility-bills-add-element-modal.component';
import { UtilityBillsCreateServiceComponent } from './utility-bills-create-service/utility-bills-create-service.component';
import { FieldDataPipe } from './utility-bills-create-service/field-data.pipe';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MonthPickerModule,
    ],
  exports: [
    UtilityBillsCreateComponent,
  ],
  declarations: [UtilityBillsCreateComponent, UtilityBillsCreateBasicInfoComponent, UtilityBillsAddElementModalComponent, UtilityBillsCreateServiceComponent, FieldDataPipe],
  entryComponents: [
    UtilityBillsAddElementModalComponent,
  ],
})
export class UtilityBillsCreateModule {

}
