import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { WeatherBoundaries } from '../weather-comparison/weather-comparison.models';

export interface WeatherBoundariesConfig {
  localBoundaries: WeatherBoundaries;
  globalBoundaries: {max: number, min: number};
  degreeLength: number;
  fontSizeInPx: number;
  groupName: string;
}

type BoundaryLengths = {
  tickSize: number;
  tempLineLength: number;
  total: number;
};

type ChartConfig = {
  lengths: {
    maxLengths: BoundaryLengths;
    minLengths: BoundaryLengths;
    avgLengths: BoundaryLengths;
  };
  gaps: {
    top: number;
    bottom: number;
  };
  positions: {
    maxContStart: number;
    avgContStart: number;
    minContStart: number;
  };
  chart: {
    height: number;
    fontSize: number;
    tick: {
      horizontalLineLength: number;
      textDistance: number;
    };
    strokeWidth: number;
    rectWidth: number;
  };
};


@Component({
  selector: 'exa-weather-boundaries',
  templateUrl: './weather-boundaries.component.html',
  styleUrls: ['./weather-boundaries.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WeatherBoundariesComponent implements AfterViewInit, OnChanges {

  @Input() boundariesConfig: WeatherBoundariesConfig;
  @ViewChild('svgContainerElem') svgContainerElem: ElementRef;

  ngAfterViewInit(): void {
    const chartConfig = this.calculateConfig();
    this.drawChart(chartConfig);
  }

  ngOnChanges({boundariesConfig}: SimpleChanges): void {
    if (!boundariesConfig.firstChange) {
      const chartConfig = this.calculateConfig();
      this.drawChart(chartConfig);
    }
  }

  private calculateConfig() {
    const {localBoundaries, globalBoundaries, fontSizeInPx} = this.boundariesConfig;

    const maxLengths = this.calculateContLengths(localBoundaries.max, localBoundaries.avg);
    const minLengths = this.calculateContLengths(localBoundaries.min, localBoundaries.avg);
    const avgLengths: BoundaryLengths = {
      tempLineLength: 0,
      tickSize: fontSizeInPx,
      total: fontSizeInPx,
    };

    const topGap = this.calculateTempGap(globalBoundaries.max, localBoundaries.max);
    const bottomGap = this.calculateTempGap(globalBoundaries.min, localBoundaries.min);

    const
      maxContStart = 0,
      avgContStart = maxContStart + maxLengths.total,
      minContStart = avgContStart + avgLengths.total;

    return <ChartConfig> {
      lengths: {
        maxLengths,
        minLengths,
        avgLengths,
      },
      gaps: {
        top: topGap,
        bottom: bottomGap,
      },
      positions: {
        maxContStart,
        avgContStart,
        minContStart,
      },
      chart: {
        height: topGap + [maxLengths, avgLengths, minLengths].reduce((acc, l) => acc + l.total, 0) + bottomGap,
        fontSize: fontSizeInPx,
        tick: {
          horizontalLineLength: 10,
          textDistance: 40,
        },
        strokeWidth: 2,
        rectWidth: 40,
      },
    };
  }

  private drawChart(config: ChartConfig) {
    const {min, max, avg} = this.boundariesConfig.localBoundaries;
    const rectX = 0,
      centerX = config.chart.rectWidth / 2,
      mainLineX = centerX - config.chart.strokeWidth / 2,
      tickStartX = centerX - config.chart.tick.horizontalLineLength / 2,
      tickEndX = tickStartX + config.chart.tick.horizontalLineLength,
      tickTextX = centerX + (config.chart.rectWidth / 2) + config.chart.tick.textDistance;

    const tempFormatter = (temp: number) => Math.round(temp * 100) / 100;

    const svg = `
    <svg style="height: ${config.chart.height + config.chart.strokeWidth * 2}px; width: 200px">
    
  <style> line {
    stroke-width: ${config.chart.strokeWidth}px;
  } </style>
  <g transform="translate(${config.chart.strokeWidth}, ${config.gaps.top + config.chart.strokeWidth})">
    <g id="maxCont">
      <g id="maxTick">
        <line x1="${tickStartX}" y1="0" x2="${tickEndX}" y2="0" class="tick"></line>
        <text x="${tickTextX}" y="${config.chart.fontSize}" font-size="${config.chart.fontSize}" class="tick-text">
          <tspan class="tick-text__title">Max</tspan><tspan dx="10" class="tick-text__value">${tempFormatter(max)} F</tspan>
        </text>
        <line x1="${mainLineX}" y1="0" x2="${mainLineX}" y2="${config.lengths.maxLengths.tickSize}" class="temp-line"></line>
      </g>
      <g id="maxLine" transform="translate(0, ${config.lengths.maxLengths.tickSize})">
        <line x1="${mainLineX}" y1="0" x2="${mainLineX}" y2="${config.lengths.maxLengths.tempLineLength}" class="temp-line"></line>
      </g>
    </g>
    <g id="avgCont" transform="translate(0, ${config.positions.avgContStart})">
      <rect width="${config.chart.rectWidth}" height="${config.lengths.avgLengths.tickSize}" transform="translate(${rectX})" class="avg-rect"></rect>
      <text x="${tickTextX}" y="${config.chart.fontSize}" font-size="${config.chart.fontSize}">
        <tspan class="tick-text__title">Avg</tspan><tspan dx="10" class="tick-text__value">${tempFormatter(avg)} F</tspan>
      </text>
    </g>

    <g id="minCont" transform="translate(0, ${config.positions.minContStart})">
      <g id="minLine">
        <line x1="${mainLineX}" y1="0" x2="${mainLineX}" y2="${config.lengths.minLengths.tempLineLength}" class="temp-line"></line>
      </g>

      <g id="minTick" transform="translate(0, ${config.lengths.minLengths.tempLineLength})">
        <line x1="${mainLineX}" y1="0" x2="${mainLineX}" y2="${config.lengths.minLengths.tickSize}" class="temp-line"></line>
        <text x="${tickTextX}" y="${config.lengths.minLengths.tickSize}" font-size="${config.chart.fontSize}">
          <tspan class="tick-text__title">Min</tspan><tspan dx="10" class="tick-text__value">${tempFormatter(min)} F</tspan>
        </text>
        <line x1="${tickStartX}" y1="${config.lengths.minLengths.tickSize}" x2="${tickEndX}" y2="${config.lengths.minLengths.tickSize}" class="tick"></line>
      </g>
    </g>
    
  </g>
    
</svg>
    `;

    (this.svgContainerElem.nativeElement as HTMLDivElement).innerHTML = svg;
  }

  private calculateTempGap(temp1: number, temp2: number) {
    return Math.abs(temp1 - temp2) * this.boundariesConfig.degreeLength;
  }

  private calculateContLengths(temp1: number, temp2: number): BoundaryLengths {
    const tickSize = this.boundariesConfig.fontSizeInPx;
    const tempLineLength = Math.abs(temp1 - temp2) * this.boundariesConfig.degreeLength;
    return {tickSize, tempLineLength, total: tickSize + tempLineLength};
  }

}
