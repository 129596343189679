import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { CustomersService } from '../../../customers/customers.service';
import { ApiService } from '../../../data/api.service';
import { DataService } from '../../../data/data.service';
import {
  GreenButtonConnectService,
  GbcCustodian,
} from '../../../data/green-button-connect/green-button-connect.service';
import { ImportModalBase } from '../../../imports/import-modal-base';
import { Customer } from '../../../shared/models/customer.model';

@Component({
  selector: 'exa-green-button-connect-map-meters',
  templateUrl: './green-button-connect-map-meters.component.html',
  styleUrls: ['./green-button-connect-map-meters.component.scss'],
})
export class GreenButtonConnectMapMetersComponent extends ImportModalBase {

  maxHistoryDateLimit = {
    min: moment().subtract(2, 'y').toDate(),
    max: moment().toDate(),
  };

  mapMetersForm: FormGroup = this.fb.group({
    importFromDate: this.maxHistoryDateLimit.min,
  });

  errorsMap = {
    id: 'Subscription ID',
    import_from_date: 'Import Historical Data From Date',
  };

  customer: Customer;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GreenButtonConnectMapMetersComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: { custodian: GbcCustodian },
    protected fileSaver: FileSaverService,
    protected toastr: ToastrService,
    protected data: DataService,
    protected auth: AuthService,
    private api: ApiService,
    private customersSvc: CustomersService,
    private gbcSvc: GreenButtonConnectService,
  ) {
    super(dialogRef, fileSaver, toastr, data, auth);
    // we should better use the selectedCustomer$ instead but we are inheriting from legacy base class which will not support async for now
    this.customer = this.customersSvc.selected;
  }

  setConfig() {
    const customer = this.customer;
    const signedUrlMethod = () => this.api.tasks.getSignedUrl({
      id: customer.id,
      entity: 'meter',
      content_type: 'xlsx',
      filename: this.selectedFile.name,
    }, {customer_id: customer.id}).pipe(
      map((res: any) => ({
        ...res,
        url: (new URL(res.url)).pathname,
        customerId: customer.id,
      })),
    );

    const importMethod = () => {
      const {importFromDate} = this.mapMetersForm.value;
      return this.gbcSvc.mapMeters({
        custodian: this.modalData.custodian.id,
        filepath: this.signedUrl.path,
        import_from_date: importFromDate,
      }).pipe(
        catchError(err => {
          this.submitting = false;
          this.processing = false;
          this.errorMessage = err.error;
          return EMPTY;
        }),
      );
    };

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.gbcSvc.getTemplate(this.modalData.custodian.id),
      acceptedFileFormats: '.xlsx',
      onFinish: () => this.close(),
      successToastrMessage: () => `Mapped Meters added successfully for ${this.modalData.custodian.name}, please check the historical data in 1 hour from now`,
      url: ""
    };
  }


}
