import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, tap, publishBehavior, refCount } from 'rxjs/operators';
import { DataService } from '../../data/data.service';
import { MeterDef } from '../../data/meters/media-type-meter-base.service';
import { MeterMediaType } from '../../data/meters/models/media-types.models';
import { MeterTypes } from '../../data/meters/models/meters-defs.constants';
import { ImportModalBase } from '../import-modal-base';
import { AuthService } from '../../auth/auth.service';
import { CustomersService } from '../../customers/customers.service';

const idrUnitsOptions = [
  {
    id: 'electrical',
    units: {
      hourly: 'kWh',
      notHourly: 'kW',
    },
  }
] as {id: MeterMediaType; units: {hourly: string; notHourly: string; }}[];

interface MeterType extends MeterDef {
  unitsOptions: { hourly: string; notHourly: string; };
}

@Component({
  selector: 'exa-import-meters-data',
  templateUrl: './import-meters-data.component.html',
  styles: []
})
export class ImportMetersDataComponent extends ImportModalBase {

  meterTypes = Object.values(MeterTypes).map(meterType => {
    const typeUnitOptionsConfig = idrUnitsOptions.find(config => config.id === meterType.type);
    return {
      ...meterType,
      unitsOptions: typeUnitOptionsConfig ? typeUnitOptionsConfig.units : null,
    };
  }) as MeterType[];


  settingsForm = this.fb.group({
    meterMediaType: [this.meterTypes.find(t => t.type === 'electrical'), [Validators.required]],
    isHourly: true,
  });

  selectedMeterType$ = this.settingsForm.controls['meterMediaType'].valueChanges.pipe(
    publishBehavior(this.settingsForm.controls['meterMediaType'].value),
    refCount()
  ) as Observable<MeterType>;

  constructor(
      public dialogRef: MatDialogRef<ImportMetersDataComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: { building: { id: number, name: string } },
      private fb: FormBuilder,
      protected fileSaver: FileSaverService,
      protected toastr: ToastrService,
      protected data: DataService,
      protected auth: AuthService,
      private customers: CustomersService,
    ) {
    super(dialogRef, fileSaver, toastr, data, auth);
  }


  setConfig() {
    const signedUrlMethod = () => this.data.buildings
      .importMetersDataUrl(this.modalData.building.id, this.selectedFile);

    const importMethod = () => {
      const formValue = this.settingsForm.value;
      const selectedMeterType = formValue.meterMediaType as MeterType;
      const isHourly = selectedMeterType.unitsOptions ? formValue.isHourly : false;
      return { dataUnitType: isHourly ? 'kwh' : 'kw', buildingId: this.modalData.building.id};
    };

    this.config = {
      signedUrlMethod,
      importMethod,
      importingProgress: (taskId: string) => this.data.tasks.status(taskId),
      templateMethod: () => this.data.buildings.importMetersDataTemplate(this.modalData.building.id),
      acceptedFileFormats: '.csv, text/csv',
      onFinish: () => this.close(),
      url: "/api/buildings/importIdr"
    };
  }
}